import { Button } from 'primereact/button';
import './styles.css';
import PromotionEdit from '../../../../pages/Comercial/Promotion/PromotionEdit';
import PromotionView from '../../../../pages/Comercial/Promotion/PromotionView';

interface CardListProps {
  id: number;
  cdPromocao: string;
  dsPromocao: string;
  inAtivo: boolean;
  inputs: any[];
  handleDelete: (id: any) => void;
}

const CardListPromotion = ({
  id,
  cdPromocao,
  dsPromocao,
  inAtivo = true,
  handleDelete,
}: CardListProps) => {
  return (
    <div className='card-container-promotion'>
      <div className='card-promotion-content'>
        <h1>{cdPromocao}</h1>
        <h1>{dsPromocao}</h1>

        <div className='card-promotion-status'>
          <span>{inAtivo === true ? 'Ativo' : 'Inativo'}</span>
        </div>
        <div className='card-container-buttons-promotion'>
          <div className='styles-buttons'>
            <PromotionEdit id={id} />
          </div>
          <div className='styles-buttons'>
            <PromotionView id={id} />
          </div>
          <div className='styles-buttons'>
            <Button
              value={id}
              icon='pi pi-trash'
              className='p-button-rounded p-button-danger p-button-outlined'
              onClick={handleDelete}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardListPromotion;
