import { Checkbox } from "primereact/checkbox"
import { ModulesProps, ProfileProps } from "../../store/admin/admin.store"
import './styles.css'

interface CheckboxDefaultProps {
    checkbox: ModulesProps | ProfileProps;
    disabled: boolean;
    handleCheckboxes: (e:any) => void;
}

const CheckboxDefault: React.FC<CheckboxDefaultProps> = ({ checkbox, disabled, handleCheckboxes }) => {
    return (
        <span className="checkbox-default-container">
            <Checkbox
                id={checkbox.idItem.toString()}
                value={checkbox.idItem}
                checked={checkbox.checked}
                disabled={disabled}
                onChange={handleCheckboxes}
            />
            <label className="checkbox-default-label" htmlFor={checkbox.idItem.toString()}>{ checkbox.descricao }</label>
        </span>
    )
}

export default CheckboxDefault
