import ApprovalserInfo from "../../components/ApprovalUserInfo";
import { TabView, TabPanel } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApprovalById } from "../../store/approvals/approvals.actions";
import { RootState } from "../../store";
import ApprovalApp from "../../components/ApprovallFields/ApprovalApp";
import ApprovalErp from "../../components/ApprovallFields/ApprovalErp";
import ApprovalConfirm from "../../components/ApprovallFields/ApprovalConfirm";
import { ApprovalTypes } from "../../enums/ApprovalsTypes";
import { InputText } from "primereact/inputtext";
import { cpfCnpjMask } from "../../util/mask";
import moment from "moment";

interface ApprovalEvaluationProps {
  match: any;
  navigation: any;
}

const ApprovalEvaluation: React.FC<ApprovalEvaluationProps> = ({
  match,
  navigation,
}) => {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);

  const { currentApproval } = useSelector(
    (state: RootState) => state.approvals
  );

  const approvals = useSelector((state: RootState) => state.approvals);

  useEffect(() => {
    dispatch(getApprovalById(match.params.id));
  }, [dispatch]);

  const getTitle = (approvalTypeId: number) => {
    switch (approvalTypeId) {
      case ApprovalTypes.cadastro:
        return "Avaliação de Cadastro";
      case ApprovalTypes.alteração:
        return "Alteração de Dados de Estabelecimento";
      case ApprovalTypes.associacao:
        return "Associação";
      case ApprovalTypes.desassociacao:
        return "Desassociação";
      default:
        return "Título não informado";
    }
  };

  const PanelBody = () => {
    return (
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="Cadastro App Cliente">
          <ApprovalApp approval={currentApproval} />
        </TabPanel>
        {approvals.evaluation.erp !== null &&
          approvals.evaluation.erp !== "" &&
          approvals.currentApproval.idTipoSolicitacao !==
            ApprovalTypes.alteração && (
            <TabPanel header="ERP">
              <ApprovalErp />
            </TabPanel>
          )}
        {/* <TabPanel header="SERPRO">
                <ApprovalSerpro />
            </TabPanel> */}
        <TabPanel header="Confirmação de Dados Cadastrais">
          <ApprovalConfirm />
        </TabPanel>
      </TabView>
    );
  };

  const associationBody = () => {
    return (
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="Cadastro App Cliente">
          <ApprovalApp approval={currentApproval} />
        </TabPanel>
      </TabView>
    );
  };

  const desassociationBody = () => {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          padding: 10,
          marginTop: 20,
          borderRadius: 10,
          border: "1px solid #ddd",
        }}
      >
        <div className="title-field">
          <p>Estabelecimento Desassociado</p>
        </div>
        <div className="field-group-container">
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              CNPJ / CPF
            </label>
            <InputText
              id="in"
              placeholder={cpfCnpjMask(currentApproval?.cliente.txCpfCnpj)}
              disabled={true}
            />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Razão Social / Nome
            </label>
            <InputText
              id="in"
              placeholder={currentApproval?.cliente.nmCliente}
              disabled={true}
            />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              ID Digital
            </label>
            <InputText
              id="in"
              placeholder={currentApproval?.cliente.cdDigital}
              disabled={true}
            />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Data da Desassociação
            </label>
            <InputText
              id="in"
              placeholder={moment(currentApproval?.dtAprovacao).format(
                "DD/MM/YYYY"
              )}
              disabled={true}
            />
          </span>
        </div>
      </div>
    );
  };

  if (currentApproval) {
    return (
      <div className="content-page">
        <p className="title">{getTitle(currentApproval?.idTipoSolicitacao)}</p>
        <ApprovalserInfo />
        {[ApprovalTypes.desassociacao].includes(
          currentApproval?.idTipoSolicitacao
        ) && desassociationBody()}
        {[ApprovalTypes.associacao].includes(
          currentApproval?.idTipoSolicitacao
        ) && associationBody()}
        {[ApprovalTypes.cadastro, ApprovalTypes.alteração].includes(
          currentApproval?.idTipoSolicitacao
        ) && PanelBody()}
      </div>
    );
  } else {
    return <div />;
  }
};

export default ApprovalEvaluation;
