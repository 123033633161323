import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Sidebar } from "primereact/sidebar";
import React, { useEffect, useState } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { useDispatch, useSelector } from "react-redux";
import DefaultInput from "../../../../components/DefaultInput";
import { COLORS } from "../../../../constants";
import { RadioButton } from "primereact/radiobutton";
import DefaultSelect, {
  SelectOptions,
} from "../../../../components/DefaultSelect";
import { RootState } from "../../../../store";
import {
  asyncGetCupomById,
  asyncUpdateCupom,
} from "../../../../store/marketing/marketing.actions";
import ItensListCupons from "../../../../components/Marketing/ProductsList";
import CategoryListCupons from "../../../../components/Marketing/CategoriesList";
import ProgressLoad from "../../../../components/ProgressLoad";
import { StateProps } from "../../../../enums";
import * as Yup from "yup";
import getValidationErrors from "../../../../util/validationError";
import { confirmDialog } from "primereact/confirmdialog";
import EstablishmentsTypesCupons from "../../../../components/Marketing/AbrangenciaEstablishmentList";
import Select from "react-select";
import { ClientesAppProps } from "../../../../store/marketing/marketing.store";

interface CuponsEditProps {
  id: number;
}

const categories = [
  { name: "Por pedido", value: 1 },
  { name: "Por categoria", value: 2 },
  { name: "Por produto", value: 3 },
];

const categoriesCoverage = [
  { name: "Todos", value: 0 },
  { name: "Tipo de estabelecimento", value: 1 },
  { name: "Cliente", value: 3 },
  // { name: "Localização", value: 4 },
];

export type SelectProps = {
  label: string;
  value: number;
};

const CuponsEdit = ({ id }: CuponsEditProps) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [step, setStep] = useState(1);
  const [dsCupom, setDsCupom] = useState("");
  const [cdCupom, setCdCupom] = useState("");
  const [dtValidadeDe, setDtValidadeDe] = useState("");
  const [dtValidadeAte, setDtValidadeAte] = useState("");
  const [vlCupom, setVlCupom] = useState("");
  const [idTipoDesconto, setIdTipoDesconto] = useState("");
  const [inAtivo, setInAtivo] = useState<boolean>();
  const [inVisivel, setInVisivel] = useState<boolean>();
  const [qtLiberada, setQtLiberada] = useState("");
  const [qtUtilizada, setQtUtilizada] = useState("");
  const [cupom, setCupom] = useState<any>(null);
  const [errors, setErrors] = useState({} as any);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [selectedCategoryCoverage, setSelectedCategoryCoverage] = useState(
    categoriesCoverage[0]
  );
  const [clientApp, setClientApp] = useState("");
  const [allClients, setAllClients] = useState<any>([]);
  const [clientSelected, setClientSelected] = useState<any>("");
  const [filteredClients, setFilteredClients] = useState<ClientesAppProps[]>(
    []
  );
  const [buttonLoading, setButtonLoading] = useState(false);

  const { loading } = useSelector((state: RootState) => state.loading);
  const marketing = useSelector((state: RootState) => state.marketing);

  const options: SelectOptions[] = [
    {
      name: "Valor",
      value: 2,
    },
    {
      name: "Porcentagem",
      value: 1,
    },
  ];

  const getCupom = async () => {
    const response: any = await asyncGetCupomById(id);
    const checkboxesProdutos = markCheckboxesProducts(response);
    const checkboxesCategorias = markCheckboxesCategories(response);
    const checkboxesTiposEstabelecimento =
      markCheckboxesTiposEstabelecimentos(response);

    checkboxesProdutos.categorias = checkboxesCategorias.categorias;
    checkboxesProdutos.abrangenciaTipoEstabelecimento =
      checkboxesTiposEstabelecimento.abrangenciaTipoEstabelecimento;

    setSelectedCategory(categories[response?.idTipoAplicacao - 1]);

    if (response?.idTipoAbrangencia === null) {
      setSelectedCategoryCoverage(categoriesCoverage[0]);
    } else if (response?.idTipoAbrangencia === 1) {
      setSelectedCategoryCoverage(
        categoriesCoverage[response?.idTipoAbrangencia]
      );
    } else {
      setSelectedCategoryCoverage(
        categoriesCoverage[response?.idTipoAbrangencia - 1]
      );
    }

    setFilteredClients(response?.abrangenciaClienteApp);

    setCupom(checkboxesProdutos);
    setVisible(true);
  };

  const deleteConfirm = (e: any) => {
    const id = e.target.value | e.target.parentNode.value;

    confirmDialog({
      message: "Você quer remover este registro?",
      header: "Confirmação de exclusão",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => handleDeleteClient(id),
      acceptLabel: "Sim",
      rejectLabel: "Não",
    });
  };

  const handleDeleteClient = (id: number) => {
    const removingClient = filteredClients.filter((client) => {
      return client?.idClienteApp !== id;
    });

    setFilteredClients(removingClient);
  };

  const handleAddToFilteredClients = () => {
    const clientsFiltered = [...filteredClients];
    const clientExist = clientsFiltered.findIndex(
      (client) => client.idClienteApp === clientSelected.value
    );

    if (clientExist < 0) {
      clientsFiltered.push({
        idClienteApp: clientSelected.value,
        nmCliente: clientSelected.label,
      });
    } else {
      return;
    }

    setFilteredClients(clientsFiltered);
  };

  const handleCheckboxesProduto = (event: any) => {
    const newArr = { ...cupom };
    const index = newArr.itens.findIndex((chk: any) => {
      return chk.idProduto == event.target.value;
    });

    newArr.itens[index] = {
      ...newArr.itens[index],
      checked: !newArr.itens[index].checked,
    };

    setCupom(newArr);
  };

  const handleCheckboxesCategoria = (event: any) => {
    const newArr = { ...cupom };
    const index = newArr.categorias.findIndex(
      (chk: any) => chk.idCategoriaProduto == event.target.value
    );

    newArr.categorias[index] = {
      ...newArr.categorias[index],
      checked: !newArr.categorias[index].checked,
    };

    setCupom(newArr);
  };

  const handleCheckboxesTiposEstabelecimento = (event: any) => {
    const newArr = { ...cupom };
    const index = newArr.abrangenciaTipoEstabelecimento.findIndex(
      (chk: any) => chk.idTipoEstabelecimento == event.target.value
    );

    newArr.abrangenciaTipoEstabelecimento[index] = {
      ...newArr.abrangenciaTipoEstabelecimento[index],
      checked: !newArr.abrangenciaTipoEstabelecimento[index].checked,
    };

    setCupom(newArr);
  };

  const handleSubmit = async () => {
    setErrors({} as StateProps);
    setButtonLoading(true);

    try {
      const listaProdutosCupom: any = [];
      const listaCategoriasCupom: any = [];
      const listaTiposEstabelecimentosCupom: any = [];

      if (selectedCategory.value === 2) {
        cupom.categorias.map((c: any) => {
          if (c.checked) {
            listaCategoriasCupom.push({
              idCategoriaProduto: c.idCategoriaProduto,
              dsCategoriaProduto: c.dsCategoriaProduto,
            });
          }
        });
      } else if (selectedCategory.value === 3) {
        cupom.itens.map((p: any) => {
          if (p.checked) {
            listaProdutosCupom.push({
              idProduto: p.idProduto,
              dsProduto: p.dsProduto,
            });
          }
        });
      }

      if (selectedCategoryCoverage.value === 1) {
        cupom.abrangenciaTipoEstabelecimento.map((c: any) => {
          if (c.checked) {
            listaTiposEstabelecimentosCupom.push({
              idTipoEstabelecimento: c.idTipoEstabelecimento,
              dsTipoEstabelecimento: c.dsTipoEstabelecimento,
            });
          }
        });
      }

      const CupomEdit = {
        id: cupom?.id,
        cdCupom: cupom?.cdCupom,
        dsCupom: cupom?.dsCupom,
        dtValidadeDe: cupom?.dtValidadeDe,
        dtValidadeAte: cupom?.dtValidadeAte,
        qtLiberada: cupom?.qtLiberada,
        qtUtilizada: cupom?.qtUtilizada,
        inAtivo: cupom?.inAtivo,
        inVisivel: cupom?.inVisivel,
        idTipoDesconto: cupom?.idTipoDesconto,
        vlCupom: cupom?.vlCupom,
        idTipoAplicacao: cupom?.idTipoAplicacao,
        idTipoAbrangencia: cupom?.idTipoAbrangencia,
        itens: listaProdutosCupom,
        categorias: listaCategoriasCupom,
        abrangenciaTipoEstabelecimento:
          selectedCategoryCoverage.value === 1
            ? listaTiposEstabelecimentosCupom
            : [],
        abrangenciaClienteApp:
          selectedCategoryCoverage.value === 3 ? filteredClients : [],
      };

      const schema = Yup.object().shape({
        cdCupom: Yup.string().required("Código do cupom obrigatório."),
        dsCupom: Yup.string().required("Descrição do cupom obrigatório."),
        dtValidadeDe: Yup.string().required(
          "Data de início do cupom obrigatório."
        ),
        dtValidadeAte: Yup.string().required(
          "Data de encerramento do cupom obrigatório."
        ),
        idTipoDesconto: Yup.string().required(
          "Tipo de desconto do cupom obrigatório."
        ),
        vlCupom: Yup.number()
          .typeError("Valor de desconto do cupom obrigatório.")
          .min(1, "O valor deve ser maior que 0."),
        // itens: Yup.object().required('Obrigatório pelo menos um item no cupom.'),
        // categorias: Yup.object().required('Obrigatório pelo menos uma categoria no cupom.'),
      });

      await schema.validate(cupom, {
        abortEarly: false,
      });

      dispatch(asyncUpdateCupom(CupomEdit));
      setStep(1);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrors(getValidationErrors(err));
        return;
      }
    } finally {
      setTimeout(() => {
        setButtonLoading(false);
        setVisible(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (cupom) {
      let result = cupom;
      result = { ...result, cdCupom: cdCupom };
      setCupom(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cdCupom]);

  useEffect(() => {
    if (cupom) {
      let result = cupom;
      result = { ...result, dsCupom: dsCupom };
      setCupom(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dsCupom]);

  useEffect(() => {
    if (cupom) {
      let result = cupom;
      result = { ...result, dtValidadeDe: dtValidadeDe };
      setCupom(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dtValidadeDe]);

  useEffect(() => {
    if (cupom) {
      let result = cupom;
      result = { ...result, dtValidadeAte: dtValidadeAte };
      setCupom(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dtValidadeAte]);

  useEffect(() => {
    if (cupom) {
      let result = cupom;
      result = { ...result, inAtivo: inAtivo };
      setCupom(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inAtivo]);

  useEffect(() => {
    if (cupom) {
      let result = cupom;
      result = { ...result, inVisivel: inVisivel };
      setCupom(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inVisivel]);

  useEffect(() => {
    if (cupom) {
      let result = cupom;
      result = { ...result, idTipoDesconto: idTipoDesconto };
      setCupom(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idTipoDesconto]);

  useEffect(() => {
    if (cupom) {
      let result = cupom;
      result = { ...result, vlCupom: vlCupom };
      setCupom(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vlCupom]);

  useEffect(() => {
    if (cupom) {
      let result = cupom;
      result = { ...result, qtLiberada: qtLiberada };
      setCupom(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qtLiberada]);

  useEffect(() => {
    if (cupom) {
      let result = cupom;
      result = { ...result, qtUtilizada: qtUtilizada };
      setCupom(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qtUtilizada]);

  useEffect(() => {
    if (cupom) {
      let result = cupom;
      result = { ...result, idTipoAplicacao: selectedCategory?.value };
      setCupom(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory?.value]);

  useEffect(() => {
    if (cupom) {
      let result = cupom;
      result = {
        ...result,
        idTipoAbrangencia: selectedCategoryCoverage?.value,
      };
      setCupom(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryCoverage?.value]);

  useEffect(() => {
    if (cupom) {
      let result = cupom;
      result = {
        ...result,
        abrangenciaClienteApp: filteredClients,
      };
      setCupom(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredClients]);

  useEffect(() => {
    setAllClients(
      marketing.clientesAppList.map((client) => ({
        value: client?.idClienteApp,
        label: client?.nmCliente,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketing.clientesAppList]);

  const markCheckboxesProducts = (r: any) => {
    let response = { ...r };
    if (!response) {
      return;
    }

    const result = [...marketing.cuponsProductsList];
    for (let i = 0; i <= result.length; i++) {
      for (let j = 0; j <= response?.itens?.length; j++) {
        if (result[i]?.idProduto === response?.itens[j]?.idProduto) {
          if (result[i]) {
            result[i] = {
              ...result[i],
              checked: true,
            };
          }
        }
      }
    }
    response.itens = result;

    return response;
  };

  const markCheckboxesCategories = (r: any) => {
    let response = { ...r };
    if (!response) {
      return;
    }

    const result = [...marketing.cuponsCategoriesList];
    for (let i = 0; i <= result.length; i++) {
      for (let j = 0; j <= response?.categorias?.length; j++) {
        if (result[i]?.idCategoriaProduto === response?.categorias[j]?.idCategoriaProduto) {
          if (result[i]) {
            result[i] = {
              ...result[i],
              checked: true,
            };
          }
        }
      }
    }

    response.categorias = result;

    return response;
  };

  const markCheckboxesTiposEstabelecimentos = (r: any) => {
    let response = { ...r };
    if (!response) {
      return;
    }

    const result = [...marketing.abrangenciaTiposEstabelecimentosList];
    for (let i = 0; i <= result.length; i++) {
      for (
        let j = 0;
        j <= response?.abrangenciaTipoEstabelecimento?.length;
        j++
      ) {
        if (
          result[i]?.idTipoEstabelecimento ===
          response?.abrangenciaTipoEstabelecimento[j]?.idTipoEstabelecimento
        ) {
          if (result[i]) {
            result[i] = {
              ...result[i],
              checked: true,
            };
          }
        }
      }
    }

    response.abrangenciaTipoEstabelecimento = result;

    return response;
  };

  const handleCancelAccept = () => {
    setVisible(false);
    setErrors("");
  };

  const handleCancelReject = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    confirmDialog({
      message: "Você tem certeza que deseja fechar sem salvar a edição?",
      header: "Confirmação de edição de dados",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: handleCancelAccept,
      reject: handleCancelReject,
      acceptLabel: "Sim",
      rejectLabel: "Não",
      style: { width: "60%" },
    });
  };

  return (
    <div className="sidebarv2-wrapper">
      <Button
        icon="pi pi-pencil"
        onClick={getCupom}
        className="p-button-rounded p-button-warning p-button-outlined"
      />

      <Sidebar
        className="sidebar-cupons-container"
        visible={visible}
        position="right"
        onHide={handleCancel}
      >
        <div className="sidebar-cupons-content-top">
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-danger p-button-text"
            onClick={handleCancel}
          />
          <p
            style={{ color: COLORS.primaryGreen }}
            className="sidebar-cupons-title"
          >
            Editar Cupom
          </p>

          {step === 1 && (
            <div className="sidebar-cupons-board">
              <div className="sidebar-cupons-buttons">
                <div className="sidebar-cupons-active">
                  <button
                    value={step}
                    onClick={() => setStep(1)}
                    className="cupons-buttons-active"
                  >
                    Cupom
                  </button>
                </div>
                <div className="sidebar-cupons-noactive">
                  {selectedCategory?.value === 1 ? (
                    <button
                      value={step}
                      onClick={() => setStep(2)}
                      className="cupons-buttons-noactive"
                      disabled
                    >
                      Aplicação
                    </button>
                  ) : (
                    <button
                      value={step}
                      onClick={() => setStep(2)}
                      className="cupons-buttons-noactive"
                    >
                      Aplicação
                    </button>
                  )}
                </div>
                <div className="sidebar-cupons-noactive">
                  {selectedCategoryCoverage?.value === 0 ? (
                    <button
                      value={step}
                      onClick={() => setStep(3)}
                      className="cupons-buttons-noactive"
                      disabled
                    >
                      Abrangência
                    </button>
                  ) : (
                    <button
                      value={step}
                      onClick={() => setStep(3)}
                      className="cupons-buttons-noactive"
                    >
                      Abrangência
                    </button>
                  )}
                </div>
              </div>
              <div className="sidebar-cupons-content">
                <div className="sidebar-cupons-sectionOne">
                  <DefaultInput
                    label="Código"
                    value={cupom?.cdCupom}
                    onChange={setCdCupom}
                    disabled={cupom?.qtUtilizada > 0}
                    maxLength={15}
                    error={errors.cdCupom}
                  />
                  <DefaultInput
                    label="Descrição"
                    value={cupom?.dsCupom}
                    onChange={setDsCupom}
                    disabled={cupom?.qtUtilizada > 0}
                    maxLength={30}
                    error={errors.dsCupom}
                  />
                </div>
                <div className="sidebar-cupons-date">
                  <DefaultInput
                    label="De"
                    type="date"
                    value={cupom?.dtValidadeDe}
                    onChange={setDtValidadeDe}
                    disabled={cupom?.qtUtilizada > 0}
                    error={errors.dtValidadeDe}
                  />
                  <DefaultInput
                    label="Até"
                    type="date"
                    value={cupom?.dtValidadeAte}
                    onChange={setDtValidadeAte}
                    disabled={cupom?.qtUtilizada > 0}
                    min={cupom?.dtValidadeDe}
                    error={errors.dtValidadeAte}
                  />
                </div>
                <div className="sidebar-cupons-sectionTwo">
                  <DefaultSelect
                    label="Tipo de desconto"
                    options={options}
                    onChange={setIdTipoDesconto}
                    idItem="desconto"
                    value={cupom?.idTipoDesconto}
                    error={errors.idTipoDesconto}
                    disabled={cupom?.qtUtilizada > 0}
                  />
                  <div>
                    <DefaultInput
                      type="number"
                      label="Valor/Percentual"
                      value={cupom?.vlCupom}
                      onChange={setVlCupom}
                      disabled={cupom?.qtUtilizada > 0}
                      error={errors.vlCupom}
                    />
                  </div>
                </div>
                <div className="sidebar-cupons-sectionTwo">
                <DefaultInput
                      type="number"
                      label="Quantidade Liberada"
                      value={cupom?.qtLiberada}
                      onChange={setQtLiberada}
                      disabled={false}
                      error={errors.QtLiberada}
                    />
                    <DefaultInput
                      type="number"
                      label="Quantidade Utilizada"
                      value={cupom?.qtUtilizada}
                      onChange={setQtUtilizada}
                      disabled={true}
                      error={errors.QtUtilizada}
                    />
                </div>
                <div className="sidebar-cupons-sectionCheckbox">
                  <div className="p-field-checkbox">
                    <Checkbox
                      inputId="active"
                      checked={cupom?.inAtivo}
                      onChange={(e) => setInAtivo(e.checked)}
                    />
                    <label htmlFor="active" style={{ marginLeft: 4 }}>
                      Ativo
                    </label>
                  </div>

                  <div className="p-field-checkbox">
                    <Checkbox
                      inputId="plataformVisible"
                      checked={cupom?.inVisivel}
                      onChange={(e) => setInVisivel(e.checked)}
                    />
                    <label htmlFor="active" style={{ marginLeft: 4 }}>
                      Visível nas plataformas
                    </label>
                  </div>
                </div>
                <h4>Aplicação</h4>
                <div className="sidebar-cupons-sectionThree">
                  {categories.map((category) => {
                    return (
                      <div key={category.value} className="p-field-radiobutton">
                        <RadioButton
                          inputId={category.name}
                          name="category"
                          value={category}
                          onChange={(e) => setSelectedCategory(e.value)}
                          checked={selectedCategory?.value === category.value}
                          disabled={cupom?.qtUtilizada > 0}
                          className="radiobutton-style"
                        />
                        <label htmlFor={category.name}>{category.name}</label>
                      </div>
                    );
                  })}
                </div>
                <h4>Abrangência</h4>
                <div className="sidebar-promotion-coverage">
                  {categoriesCoverage.map((category) => {
                    return (
                      <div key={category.value} className="p-field-radiobutton">
                        <RadioButton
                          inputId={category.name}
                          name="category"
                          value={category}
                          onChange={(e) => setSelectedCategoryCoverage(e.value)}
                          checked={
                            selectedCategoryCoverage?.value === category.value
                          }
                          disabled={cupom?.qtUtilizada > 0}
                          className="radiobutton-style"
                        />
                        <label htmlFor={category.name}>{category.name}</label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="sidebar-cupons-board">
              <div className="sidebar-cupons-buttons">
                <div className="sidebar-cupons-noactive">
                  <button
                    value={step}
                    onClick={() => setStep(1)}
                    className="cupons-buttons-noactive"
                  >
                    Cupom
                  </button>
                </div>
                <div className="sidebar-cupons-active">
                  <button
                    value={step}
                    onClick={() => setStep(2)}
                    className="cupons-buttons-active"
                  >
                    Aplicação
                  </button>
                </div>
                <div className="sidebar-cupons-noactive">
                  {selectedCategoryCoverage.value == null ? (
                    <button
                      value={step}
                      onClick={() => setStep(3)}
                      className="cupons-buttons-noactive"
                      disabled
                    >
                      Abrangência
                    </button>
                  ) : (
                    <button
                      value={step}
                      onClick={() => setStep(3)}
                      className="cupons-buttons-noactive"
                    >
                      Abrangência
                    </button>
                  )}
                </div>
              </div>

              {selectedCategory.value === 2 && (
                <div className="sidebar-cupons-itens">
                  <h3>Categorias</h3>
                  <div className="sidebar-cupons-categories-products">
                    {loading ? (
                      <div className="divNotFind">
                        <ProgressLoad />
                      </div>
                    ) : (
                      <CategoryListCupons
                        handleCheckboxes={handleCheckboxesCategoria}
                        itens={cupom.categorias}
                      />
                    )}
                  </div>
                </div>
              )}
              {selectedCategory.value === 3 && (
                <div className="sidebar-cupons-itens">
                  <h3>Produtos</h3>
                  <div className="sidebar-cupons-categories-products">
                    {loading ? (
                      <div className="divNotFind">
                        <ProgressLoad />
                      </div>
                    ) : (
                      <ItensListCupons
                        handleCheckboxes={handleCheckboxesProduto}
                        itens={cupom.itens}
                        
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {step === 3 && (
            <div className="sidebar-cupons-board">
              <div className="sidebar-cupons-buttons">
                <div className="sidebar-cupons-noactive">
                  <button
                    value={step}
                    onClick={() => setStep(1)}
                    className="cupons-buttons-noactive"
                  >
                    Cupom
                  </button>
                </div>
                <div className="sidebar-cupons-noactive">
                  {selectedCategory.value === 1 ? (
                    <button
                      value={step}
                      onClick={() => setStep(2)}
                      className="cupons-buttons-noactive"
                      disabled
                    >
                      Aplicação
                    </button>
                  ) : (
                    <button
                      value={step}
                      onClick={() => setStep(2)}
                      className="cupons-buttons-noactive"
                    >
                      Aplicação
                    </button>
                  )}
                </div>
                <div className="sidebar-cupons-active">
                  <button
                    value={step}
                    onClick={() => setStep(3)}
                    className="cupons-buttons-active"
                  >
                    Abrangência
                  </button>
                </div>
              </div>

              {selectedCategoryCoverage.value === 1 && (
                <div className="sidebar-cupons-abrangencia-container">
                  <h3>Tipos de Estabelecimento</h3>
                  <div className="sidebar-cupons-establishmentsTypes-content">
                    {loading ? (
                      <div className="divNotFind">
                        <ProgressLoad />
                      </div>
                    ) : (
                      <EstablishmentsTypesCupons
                        handleCheckboxes={handleCheckboxesTiposEstabelecimento}
                        establishments={cupom.abrangenciaTipoEstabelecimento}
                      />
                    )}
                  </div>
                </div>
              )}

              {selectedCategoryCoverage.value === 3 && (
                <div className="sidebar-cupons-abrangencia-container">
                  <h3>Clientes disponíveis</h3>
                  <div className="sidebar-cupons-clients-content">
                    {loading ? (
                      <div className="divNotFind">
                        <ProgressLoad />
                      </div>
                    ) : (
                      <div className="sidebar-cupons-clients-input">
                        <div className="sidebar-cupons-clients-input-header">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder="Clientes..."
                            value={clientSelected}
                            isClearable={true}
                            isSearchable={true}
                            options={allClients}
                            onChange={(text) => {
                              setClientSelected(text as SelectProps);
                            }}
                            noOptionsMessage={() =>
                              "Não foi encontrado nenhum registro."
                            }
                          />
                          {clientSelected !== "" ? (
                            <Button
                              label="Adicionar"
                              className="p-button-raised p-button-secondary p-button-text"
                              style={{ height: 36, marginLeft: 20 }}
                              onClick={handleAddToFilteredClients}
                            />
                          ) : (
                            <Button
                              label="Adicionar"
                              className="p-button-raised p-button-secondary p-button-text"
                              style={{ height: 36, marginLeft: 20 }}
                              onClick={handleAddToFilteredClients}
                              disabled
                            />
                          )}
                        </div>
                        <h1 style={{ margin: 0, fontSize: 20, marginTop: 24 }}>
                          Clientes Selecionados
                        </h1>
                        <div className="sidebar-cupons-clients-filteredList">
                          {filteredClients.length > 0 ? (
                            filteredClients.map((client) => {
                              return (
                                <div
                                  key={client?.idClienteApp}
                                  className="sidebar-cupons-clients-filteredList-client"
                                >
                                  <h1>
                                    {client?.clienteApp?.nmCliente || client?.nmCliente}
                                  </h1>
                                  <Button
                                    value={client?.idClienteApp}
                                    icon="pi pi-trash"
                                    className="p-button-rounded p-button-danger p-button-outlined"
                                    onClick={deleteConfirm}
                                    style={{ width: 28, height: 28 }}
                                  />
                                </div>
                              );
                            })
                          ) : (
                            <h1 style={{ margin: 0, fontSize: 16 }}>
                              Nenhum cliente foi adicionado.
                            </h1>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* {selectedCategoryCoverage.value === 4 && (
            <div className='sidebar-cupons-abrangencia-container'>
              <h3>Localização</h3>
              <div className='sidebar-cupons-localization-content'>
                {loading ? (
                  <div className='divNotFind'>
                    <ProgressLoad />
                  </div>
                ) : (
                  // <ItensListCupons
                  //   handleCheckboxes={handleCheckboxesProduto}
                  //   itens={updateProductsCupons}
                  // />
                  <div className='sidebar-cupons-localization-selects'>
                    <div className='localization-selects-content'>
                      <div>
                          <p>UF</p>
                          <Dropdown value={selectUF} emptyMessage="Não foi encontrado nenhum registro."  onChange={(e) => setSelectUF(e.value)} className="localization-selects"/>
                      </div>
                      <div>
                          <p>Cidade</p>
                          <Dropdown value={selectUF} emptyMessage="Não foi encontrado nenhum registro."  onChange={(e) => setSelectUF(e.value)} className="localization-selects"/>
                      </div>
                      <div>
                          <p>Bairro</p>
                          <Dropdown value={selectUF} emptyMessage="Não foi encontrado nenhum registro."  onChange={(e) => setSelectUF(e.value)} className="localization-selects"/>
                      </div>
                      <button style={{width: '140px', height: '28px', marginTop: '60px'}}>Adicionar</button>
                    </div>
                  
                    <div className='sidebar-cupons-localization-list'>
                      <h3>Locais selecionados</h3>
                      <span>Bairro, cidade, UF</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )} */}
        </div>

        <div className="sidebar-cupons-content-bottom">
          <div className="sidebar-cupons-actions">
            <Button
              label="Cancelar"
              className="p-button-outlined p-button-secondary"
              onClick={handleCancel}
              style={{ marginRight: "20px", width: "40%" }}
            />
            <Button
              id="button"
              label="Salvar"
              className="p-button-lg"
              onClick={handleSubmit}
              style={{ width: "40%" }}
              loading={buttonLoading}
            />
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default CuponsEdit;
