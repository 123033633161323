import GuaracampAPI from "../../services/GuaracampAPI";
import { login, setUserEmail } from "./token.service";

interface SiginProps{
    email: string;
    senha: string;
    app: boolean;
}

export const asyncSignIn = async ({email, senha, app}: SiginProps) => {
    try {
        const response: any = await GuaracampAPI.post('/Seguranca/signin', { email, senha, app })
        login(response.data.data.token)
        setUserEmail(response.data.data.email)
        return response
    } catch (error) {
        throw error
    }
}

