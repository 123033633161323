import { Dispatch } from "redux";
import { SelectOptions } from "../../components/DefaultSelect";
import GuaracampAPI from "../../services/GuaracampAPI";
import { updateLoading } from "../loading/loading.store";
import {
  setCurrentComodato,
  updateComodatos,
  updateStatus,
  updateTypes,
  updateTypesById,
} from "./comodatos.store";

export const getComodatos = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get("/solicitacaoComodato/pesquisa?pagina=1&registros=500")
      .then((res: any) => {
        dispatch(updateComodatos(res.data.lista));
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const putComodatos = async (data: any) => {
  let response = null;
  await GuaracampAPI.post("/solicitacaocomodato/analisar", data)
    .then(async (res: any) => {
      response = res;
    })
    .catch((error) => {
      alert(error.response.data.erros[0].message);
      console.error({
        msg: "Erro ao atualizar o comodato",
        error: error.response.data,
        status: error.response.status,
      });
    });
  return response;
};

export const getComodatoById = (id: number) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get(`/solicitacaoComodato/${id}`)
      .then((res: any) => {
        dispatch(setCurrentComodato(res.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const searchComodatos = (data: any) => {
  const type = data.type ? `&tipo=${data.type}` : "";
  const status = data.status ? `&status=${data.status}` : "";
  const document = data.document ? `&documento=${data.document}` : "";

  return (dispatch: Dispatch) => {
    GuaracampAPI.get(
      `/solicitacaoComodato/pesquisa?pagina=1&registros=500${type}${status}${document}`
    )
      .then((res: any) => {
        dispatch(updateComodatos(res.data.lista));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getComodatoStatusByType = (id: number) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get(
      `/statusSolicitacaoComodato/getPorIdTipoSolicitacaoComodato?idTipoSolicitacaoComodato=${id}`
    )
      .then(async (res: any) => {
        const selectOptions = (await res.data.map((v: any) => {
          return {
            name: v.dsStatusSolicitacaoComodato,
            value: v.id,
          };
        })) as SelectOptions[];
        dispatch(updateTypesById(selectOptions));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getComodatoTypes = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get("/tipoSolicitacaoComodato")
      .then(async (res: any) => {
        const selectOptions = (await res.data.map((v: any) => {
          return { name: v.dsTipoSolicitacaoComodato, value: v.id };
        })) as SelectOptions[];
        dispatch(updateTypes(selectOptions));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getComodatoStatus = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get("/statusSolicitacaoComodato")
      .then(async (res: any) => {
        const selectOptions = (await res.data.map((v: any) => {
          return { name: v.dsStatusSolicitacaoComodato, value: v.id };
        })) as SelectOptions[];
        dispatch(updateStatus(selectOptions));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
