import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { getApprovals } from '../../store/approvals/approvals.actions';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import StatusTag from '../../components/StatusTag';
import { COLORS } from '../../constants';
import { StatusApprovals } from '../../enums/StatusApprovals';
import { cpfCnpjMask } from '../../util/mask';
import './styles.css'
import ApprovalsFilter from './ApprovalsFilter';
import { useHistory } from 'react-router';
import { ApprovalTypes } from '../../enums/ApprovalsTypes';
import ProgressLoad from '../../components/ProgressLoad';

const Approvals: React.FC = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const approvals = useSelector((state: RootState) => state.approvals)

    const {loading} = useSelector((state: RootState) => state.loading)

    useEffect(() => {
        dispatch(getApprovals());
    }, []);

    const statusTemplate = (e: any) => {
        let color: string = '';
        switch (e.idStatusSolicitacao) {
            case StatusApprovals.analise:
                color = COLORS.primaryOrange
                break
            case StatusApprovals.aprovado:
                color = COLORS.primaryGreen
                break
            case StatusApprovals.reprovado:
                color = 'red'
                break
            case StatusApprovals.desassociado:
                color = '#AA0061'
                break
            default:
                break;
        }
        return (
            <StatusTag color={color} value={e.txStatusSolicitacao} />
        )
    };

    const typeTemplate = (e: any) => {
        return (<strong>{ e.tipoAprovacao.dsTipoSolicitacaoCadastro }</strong>)
    }

    const userTemplate = (e: any) => (
        <span>{ e.usuarioSolicitante.nmUsuario }</span>
    )

    const emailTemplate = (e: any) => {
        if(e.idTipoSolicitacao === ApprovalTypes.cadastro){
            return <span>{ e.preCadastro.txEmail }</span>
        }else{
            return <span>{ e.cliente.txEmail }</span>
        }
    }

    const documentTemplate = (e: any) => {
        if(e.idTipoSolicitacao === ApprovalTypes.cadastro){
            return <span>{ cpfCnpjMask(e.preCadastro.txCpfCnpj) }</span>
        }else{
            return <span>{ cpfCnpjMask(e.cliente.txCpfCnpj) }</span>
        }
    }

    const nameTemplate = (e: any) => {
        if(e.idTipoSolicitacao === ApprovalTypes.cadastro){
            return <span>{ e.preCadastro.nmCliente }</span>
        }else{
            return <span>{ e.cliente.nmCliente }</span>
        }
    }

    const styles = {
        table: {
            backgroundColor: COLORS.white,
            fontSize: 12,
            border: '1px solid #E0E0E0',
        }
    }

    const handleRow = (row: any) => {
        history.push(`/approvals/evaluation/${row.data.id}`)
    }

    const headerStyle = {
        // textAlign: 'center',
        color: '#606060',
        cursor: 'default'
    }

    const columnStyle = {
        // textAlign: 'center',
        color: '#606060',
        cursor: 'pointer'
    }

    return (
        <div className="main-page">
            <div className="card">
                <p className="title">Sol. de Cadastro (Clientes)</p>
                <ApprovalsFilter />
                {loading ? 
                    <div style={{margin: '200px 0px'}}>
                        <ProgressLoad/>
                    </div>
                :
                    <DataTable 
                    className="approval-table"
                    value={approvals.list}
                    stripedRows
                    onRowClick={handleRow}
                    style={styles.table}responsiveLayout="stack"
                    paginator
                    rows={5}
                    rowsPerPageOptions={[5,10,15,20,30,50]}
                    rowHover
                    emptyMessage="Não foi encontrado nenhum registro."
                    >
                    <Column style={columnStyle} headerStyle={headerStyle} header="Tipo" body={typeTemplate}></Column>
                    <Column style={columnStyle} headerStyle={headerStyle} header="Usuário" body={userTemplate}></Column>
                    <Column style={columnStyle} headerStyle={headerStyle} header="E-mail" body={emailTemplate}></Column>
                    <Column style={columnStyle} headerStyle={headerStyle} header="CNPJ / CPF" body={documentTemplate}></Column>
                    <Column style={columnStyle} headerStyle={headerStyle} header="Razão Social / Nome" body={nameTemplate}></Column>
                    <Column style={columnStyle} headerStyle={headerStyle} header="Status" body={statusTemplate}></Column>
                    </DataTable>
                }
            </div>
        </div>
    )
}

export default Approvals;
