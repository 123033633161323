import './style.css'
import { Checkbox } from 'primereact/checkbox';

interface CardListProps {
  id: number;
  dsProduto: string;
  inDestaque: boolean;
  handleChange: (event:any) => void;
}

const CardFeatured = ({id, dsProduto, inDestaque, handleChange}: CardListProps) => { 

  return (
    <div className='card-container-cardFeatured'>
        <div className='cardFeatured-container-text'>
          <p>{dsProduto}</p>
        </div>
      <div className='cardFeatured-container-buttons'>
        <div className='styles-buttons'>
            <Checkbox inputId="binary" checked={inDestaque} id={id.toString()} onChange={handleChange} />
        </div>
      </div>
    </div>
  );
};

export default CardFeatured;

