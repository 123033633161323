import React, { useEffect } from 'react';

import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { useState } from 'react';
import DefaultInput from '../../../../components/DefaultInput';
// import '../styles.css'
import CheckboxDefault from '../../../../components/CheckboxDefault';
import { COLORS } from '../../../../constants';
import { ProfileProps } from '../../../../store/admin/admin.store';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { asyncGetUserById } from '../../../../store/admin/admin.actions';

interface UserViewProps {
  id: number;
}

const UserView: React.FC<UserViewProps> = ({ id }) => {
  const [visible, setVisible] = useState(false);
  const [nmUsuario, setNmUsuario] = useState('');
  const [txEmail, setTxEmail] = useState('');
  const [perfis, setPerfis] = useState<ProfileProps[]>([]);
  const [user, setUser] = useState<any>();

  const admin = useSelector((state: RootState) => state.admin);

  const getUser = async () => {
    const response = await asyncGetUserById(id);
    setUser(response);
    setVisible(true);
  };

  useEffect(() => {
    markCheckboxes();
  }, [user?.perfis]);

  const markCheckboxes = () => {
    const result = [...perfis];
    for (let i = 0; i < result.length; i++) {
      for (let j = 0; j < user?.perfis.length; j++) {
        if (result[i]?.idItem === user?.perfis[j]?.idPerfil) {
          if (result[i]) {
            result[i] = { ...result[i], checked: true };
          }
        }
      }
    }
    setPerfis(result);
  };

  useEffect(() => {
    setPerfis(admin.profilesList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admin.profilesList]);

  const handleCheckboxes = (event: any) => {};

  return (
    <div className='sidebarv2-wrapper'>
      <Button
        icon='pi pi-search'
        onClick={getUser}
        className='p-button-rounded p-button-secondary p-button-outlined'
      />
      <Sidebar
        className='sidebar-users-container'
        visible={visible}
        position='right'
        onHide={() => setVisible(false)}
      >
        <div className='sidebar-users-content-top'>
          <Button
            icon='pi pi-times'
            className='p-button-rounded p-button-danger p-button-text'
            onClick={() => setVisible(false)}
          />
          <p
            style={{ color: COLORS.primaryGreen }}
            className='sidebar-users-title'
          >
            Visualização de Usuário
          </p>
          <div className='sidebar-users-inputs'>
            <DefaultInput
              label='Username(Email)'
              value={user?.txEmail}
              onChange={setTxEmail}
              disabled={true}
            />
            <DefaultInput
              label='Nome'
              value={user?.nmUsuario}
              onChange={setNmUsuario}
              disabled={true}
            />
          </div>
          <p className='sidebar-users-checkbox-title'>
            Defina os perfis do usuário
          </p>
          <div className='sidebar-users-checkboxes'>
            {perfis.map((module, i) => (
              <CheckboxDefault
                key={i}
                checkbox={module}
                disabled={true}
                handleCheckboxes={handleCheckboxes}
              />
            ))}
          </div>
        </div>
        <div></div>
      </Sidebar>
    </div>
  );
};

export default UserView;
