import { Checkbox } from 'primereact/checkbox';
import { PromotionsAbrangenciaTipoEstabelecimentoProps } from '../../../../store/comercial/comercial.store';


interface EstablishmentsTypesPromotionProps {
  view?: boolean;
  checkboxes?: PromotionsAbrangenciaTipoEstabelecimentoProps;
  cod?: number;
  id?: number;
  handleDelete?: (id: any) => void;
  handleCheckboxes: (e: any) => void;
  establishments: PromotionsAbrangenciaTipoEstabelecimentoProps[];
}

const EstablishmentsTypesPromotion = ({
  view,
  handleCheckboxes,
  establishments,
}: EstablishmentsTypesPromotionProps) => {
  return (
    <>
      {establishments?.map((itens, i) => {
        return (
          <div className='sidebar-cupons-itens' key={i}>
            <div className='produtos-list' key={i}>
              <Checkbox
                checked={itens.checked}
                value={itens.idTipoEstabelecimento}
                onChange={handleCheckboxes}
                disabled={view && true}
              />
              <label htmlFor='product'>{itens.dsTipoEstabelecimento}</label>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default EstablishmentsTypesPromotion;
