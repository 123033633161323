import { useEffect, useState } from "react";
import "./style.css";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { DataScroller } from "primereact/datascroller";
import { confirmDialog } from "primereact/confirmdialog";

import CardImages from "../../../components/Marketing/CardImages";
import { COLORS } from "../../../constants";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";

import {
  asyncGetTiposProduto,
  asyncGetGrupos,
  asyncGetSubGrupos,
  asyncGetClasses,
  asyncGetSabores,
  asyncGetFilterProduct,
  asyncPutImagesProducts,
  newCardSaveImagesProduct,
  asyncClearFilterProducts,
} from "../../../store/marketing/marketing.actions";
import {
  modifiedCardImages,
  deleteImageCard,
  updateCardsImages,
} from "../../../store/marketing/marketing.store";
import getBase64 from "../../../util/images";
import ProgressLoad from "../../../components/ProgressLoad";

import { updateLoading } from "../../../store/loading/loading.store";

const Images: React.FC = () => {
  const dispatch = useDispatch();

  const [selectTipo, setSelectTipo] = useState<any>("");
  const [selectGrupo, setSelectGrupo] = useState<any>("");
  const [selectSubGrupo, setSelectSubGrupo] = useState<any>("");
  const [selectClasse, setSelectClasse] = useState<any>("");
  const [selectSabor, setSelectSabor] = useState<any>("");

  const { loading } = useSelector((state: RootState) => state.loading);

  const [txImagemProduto, setTxImagemProduto] = useState("");

  useEffect(() => {
    dispatch(asyncGetTiposProduto());
    dispatch(asyncGetGrupos());
    dispatch(asyncGetSubGrupos());
    dispatch(asyncGetClasses());
    dispatch(asyncGetSabores());
    dispatch(
      asyncGetFilterProduct(
        selectTipo.id,
        selectGrupo.id,
        selectSubGrupo.id,
        selectClasse.id,
        selectSabor.id
      )
    );
  }, [dispatch]);

  const marketing = useSelector((state: RootState) => state.marketing);

  const handdleSubmitSelect = () => {
    dispatch(
      asyncGetFilterProduct(
        selectTipo.id,
        selectGrupo.id,
        selectSubGrupo.id,
        selectClasse.id,
        selectSabor.id
      )
    );
  };

  const deleteConfirm = (e: any) => {
    confirmDialog({
      message: "Deseja apagar essa imagem?",
      header: "Confirmação de exclusão",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => handleDelete(e),
      acceptLabel: "Sim",
      rejectLabel: "Não",
    });
  };

  const handleDelete = (idCard: number) => {
    dispatch(deleteImageCard(idCard));
  };

  const setFile = async (file: any, idCard: number) => {
    const imgBase64Convert = await getBase64(file[0]);

    const infoData = {
      id: idCard,
      txImagemProduto: imgBase64Convert,
    };

    dispatch(modifiedCardImages(infoData));
  };

  const itemTemplate = () => {
    return (
      <>
        {loading ? (
          <div className="divNotFind">
            <ProgressLoad />
          </div>
        ) : marketing.cardsImages.length === 0 ? (
          <div className="divNotFind">
            <p>Não foi encontrado nenhum registro.</p>
          </div>
        ) : (
          marketing.cardsImages.map((card) => (
            <CardImages
              key={card.id}
              name={card.dsProduto}
              setFile={setFile}
              idCard={card.id}
              src={card.txImagemProduto}
              handleDelete={deleteConfirm}
            />
          ))
        )}
      </>
    );
  };

  const handdleClickSave = () => {
    if (marketing.cardsImages.length > 0 && !loading) {
      const arraySave: newCardSaveImagesProduct[] = [];

      dispatch(updateLoading(true));
      console.log("marketing.cardsImages", marketing.cardsImages);
      for (let card of marketing.cardsImages) {
        arraySave.push({
          id: card.id,
          imagem: card.txImagemProduto,
        });
      }

      if (arraySave.length > 0 && !loading) {
        dispatch(asyncPutImagesProducts(arraySave));
        // console.log(arraySave);
      }
    }
  };

  const handdleClear = () => {
    dispatch(updateLoading(true));
    setSelectTipo("");
    setSelectGrupo("");
    setSelectSubGrupo("");
    setSelectClasse("");
    setSelectSabor("");
    dispatch(updateCardsImages(""));
    dispatch(asyncClearFilterProducts());
  };

  return (
    <main className="main-page">
      <section>
        <p className="title">Imagens de Produtos</p>
      </section>

      <section
        style={{ background: COLORS.white }}
        className="sectionSelectImages"
      >
        <div>
          <p className="dropdown-label">Tipo</p>
          <Dropdown
            value={selectTipo}
            emptyMessage="Não foi encontrado nenhum registro."
            options={marketing.selectsOptions.tipos}
            onChange={(e) => setSelectTipo(e.value)}
            className="TreeSelect"
          />
        </div>
        <div>
          <p className="dropdown-label">Grupo</p>
          <Dropdown
            value={selectGrupo}
            emptyMessage="Não foi encontrado nenhum registro."
            options={marketing.selectsOptions.grupos}
            onChange={(e) => setSelectGrupo(e.value)}
            className="TreeSelect"
          />
        </div>
        <div>
          <p className="dropdown-label">SubGrupo</p>
          <Dropdown
            value={selectSubGrupo}
            emptyMessage="Não foi encontrado nenhum registro."
            options={marketing.selectsOptions.subGrupos}
            onChange={(e) => setSelectSubGrupo(e.value)}
            className="TreeSelect"
          />
        </div>
        <div>
          <p className="dropdown-label">Classe</p>
          <Dropdown
            value={selectClasse}
            emptyMessage="Não foi encontrado nenhum registro."
            options={marketing.selectsOptions.classes}
            onChange={(e) => setSelectClasse(e.value)}
            className="TreeSelect"
          />
        </div>
        <div>
          <p className="dropdown-label">Sabor</p>
          <Dropdown
            value={selectSabor}
            emptyMessage="Não foi encontrado nenhum registro."
            options={marketing.selectsOptions.sabores}
            onChange={(e) => setSelectSabor(e.value)}
            className="TreeSelect"
          />
        </div>
        <div className="divBtnImages">
          <Button
            label="Limpar filtros"
            style={{ color: COLORS.primaryGreen }}
            className="p-button-outlined btnSelect"
            onClick={handdleClear}
          />
          <Button
            label="Listar produtos"
            style={{ color: COLORS.primaryGreen }}
            className="p-button-outlined btnSelect"
            onClick={handdleSubmitSelect}
          />
        </div>
      </section>

      <div className="btnCardImages">
        {marketing.cardsImages.length === 0 ? (
          <Button
            label="Salvar"
            onClick={handdleClickSave}
            className="btn-salvar"
            disabled
          />
        ) : (
          <Button
            label="Salvar"
            onClick={handdleClickSave}
            className="btn-salvar"
          />
        )}
      </div>
      <section
        style={{ background: COLORS.white }}
        className="sectionCardsImages"
      >
        <div className="card">
          <DataScroller
            value={[{}]}
            itemTemplate={itemTemplate}
            rows={5}
            inline
            scrollHeight="500px"
          />
        </div>
      </section>
    </main>
  );
};

export default Images;
