import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckboxDefault from "../../../../components/CheckboxDefault";
import DefaultInput from "../../../../components/DefaultInput";
import { COLORS } from "../../../../constants";
import { RootState } from "../../../../store";
import {
  asyncGetProfileById,
  asyncGetUserById,
  asyncUpdateProfile,
} from "../../../../store/admin/admin.actions";
import { ModulesProps } from "../../../../store/admin/admin.store";
import ProgressLoad from "../../../../components/ProgressLoad";

import * as Yup from "yup";
import getValidationErrors from "../../../../util/validationError";
import { StateProps } from "../../../../enums";
import { confirmDialog } from "primereact/confirmdialog";

interface ProfileEditProps {
  id: number;
}

const ProfileEdit: React.FC<ProfileEditProps> = ({ id }) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [dsPerfil, setDsPerfil] = useState("");
  const [modules, setModules] = useState<ModulesProps[]>([]);
  const [profile, setProfile] = useState<any>();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<any>();

  const admin = useSelector((state: RootState) => state.admin);

  const [errors, setErrors] = useState({} as any);

  const getProfile = async () => {
    setLoading(true);
    const response = await asyncGetProfileById(id);
    setProfile(response);

    setVisible(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const markCheckboxes = () => {
      const result = [...modules];
      for (let i = 0; i < result.length; i++) {
        for (let j = 0; j < profile?.modulos.length; j++) {
          if (result[i]?.idItem === profile?.modulos[j]?.idModulo) {
            if (result[i]) {
              result[i] = { ...result[i], checked: true };
            }
          }
        }
      }
      setModules(result);
    };
    markCheckboxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.modulos]);

  useEffect(() => {
    let result = profile;
    result = { ...result, dsPerfil: dsPerfil };
    setProfile(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dsPerfil]);

  useEffect(() => {
    setModules(admin.modulos);
  }, [admin.modulos]);

  const handleSubmit = async () => {
    setErrors({} as StateProps);
    setButtonLoading(true);

    try {
      const profileModules: any = [];

      modules.map((module) => {
        if (module.checked)
          profileModules.push({
            idModulo: module.idItem,
          });
        return 0;
      });

      const profileEdit = {
        id: id,
        dsPerfil: profile?.dsPerfil,
        modulos: profileModules,
      };
      const schema = Yup.object().shape({
        dsPerfil: Yup.string().required("Descrição obrigatória."),
      });
      await schema.validate(profile, {
        abortEarly: false,
      });

      dispatch(asyncUpdateProfile(profileEdit));
      setModules(admin.modulos);
      setDsPerfil("");
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setErrors(getValidationErrors(error));
        return;
      }
    } finally {
      setTimeout(() => {
        setButtonLoading(false);
        setVisible(false);
      }, 1000);
    }
  };

  const handleCheckboxes = (event: any) => {
    const index = modules.findIndex((chk) => chk.idItem === event.target.value);
    const newArr = [...modules];
    newArr[index] = { ...newArr[index], checked: !newArr[index].checked };
    setModules(newArr);
  };

  const handleCancelAccept = () => {
    setVisible(false);
    setErrors("");
  };

  const handleCancelReject = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    confirmDialog({
      message: "Você tem certeza que deseja fechar sem salvar a edição?",
      header: "Confirmação de edição de dados",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: handleCancelAccept,
      reject: handleCancelReject,
      acceptLabel: "Sim",
      rejectLabel: "Não",
      style: { width: "60%" },
    });
  };

  const idUsuario = Number(localStorage.getItem("@USER_ID_GUARACAMP"));

  const getUser = async () => {
    const response = await asyncGetUserById(idUsuario);
    setUser(response);
  };

  useEffect(() => {
    if (idUsuario) {
      getUser();
    }
  }, []);

  const administrativePerfil = user?.perfis.find(
    (perfil: any) => perfil.idPerfil === 74
  );

  return (
    <div className="sidebarv2-wrapper">
      <Button
        icon="pi pi-pencil"
        onClick={getProfile}
        className="p-button-rounded p-button-warning p-button-outlined"
        disabled={administrativePerfil === undefined}
      />
      <Sidebar
        className="sidebar-perfis-container"
        visible={visible}
        position="right"
        onHide={handleCancel}
      >
        <div className="sidebar-perfis-content-top">
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-danger p-button-text"
            onClick={handleCancel}
          />
          <p
            style={{ color: COLORS.primaryGreen }}
            className="sidebar-perfis-title"
          >
            Edição de Perfil
          </p>
          <div className="sidebar-perfis-inputs">
            <DefaultInput
              label="Descrição"
              value={profile?.dsPerfil}
              onChange={setDsPerfil}
              disabled={false}
              maxLength={30}
              error={errors.dsPerfil}
            />
          </div>
          <p className="sidebar-perfis-checkbox-title">
            Defina as permissões do perfil
          </p>
          <div className="sidebar-perfis-checkboxes">
            {loading ? (
              <div className="divNotFind">
                <ProgressLoad />
              </div>
            ) : admin.modulos.length === 0 ? (
              <div className="divNotFind">
                <p>Não foi encontrado nenhum registro.</p>
              </div>
            ) : (
              modules.map((module, i) => (
                <CheckboxDefault
                  key={i}
                  checkbox={module}
                  disabled={false}
                  handleCheckboxes={handleCheckboxes}
                />
              ))
            )}
          </div>
        </div>
        <div className="sidebar-perfis-content-bottom">
          <div className="sidebar-perfis-actions">
            <Button
              label="Cancelar"
              className="p-button-outlined p-button-secondary"
              onClick={handleCancel}
              style={{ marginRight: "20px", width: "30%" }}
            />
            <Button
              id="button"
              label="Salvar"
              className="p-button-lg"
              onClick={handleSubmit}
              style={{ width: "30%" }}
              loading={buttonLoading}
            />
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default ProfileEdit;
