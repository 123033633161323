import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../store";
import { cellMask } from "../../util/mask";

const ComodatoUserInfo = () => {
  const { currentComodato } = useSelector(
    (state: RootState) => state.comodatos
  );

  const styles = {
    actions: {
      display: "flex",
      alignItems: "center",
    },
    infoText: {
      margin: "10px 0",
    },
  };

  return (
    <div className="userinfo-container">
      {currentComodato && (
        <div className="userinfo-container-info">
          <span>
            <strong>Nome Completo: </strong>
            {currentComodato?.cliente?.nmCliente}
          </span>
          <span style={styles.infoText}>
            <strong>E-mail: </strong>
            {currentComodato?.cliente?.txEmail}
          </span>
          <span>
            <strong>Celular: </strong>
            {currentComodato?.cliente?.txCelular}
          </span>
        </div>
      )}
    </div>
  );
};

export default ComodatoUserInfo;
