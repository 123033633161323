import { Editor } from "primereact/editor";

interface DefaultInputProps {
    value: string;
    onTextChange: any;
    error?: string;
}

  const DefaultEditor: React.FC<DefaultInputProps> = ({ value, onTextChange, error = '',}) => {
    return (
        <span className="text-area-input">
            <Editor style={{ minHeight: '120px',maxHeight: '120px', overflow: 'auto' }} value={value} onTextChange={onTextChange}/>
            {error && <span style={{ fontSize: '12px', color: 'red', margin: '0'}}>{error}</span>}
        </span>
    )   
}


export default DefaultEditor
