import { createSlice } from "@reduxjs/toolkit";

export interface LoadingProps {
  loading: boolean;
}

const initialState: LoadingProps = {
  loading: true,
};

const loading = createSlice({
  name: "loading",
  initialState,
  reducers: {
    updateLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const { updateLoading } = loading.actions;
export default loading.reducer;
