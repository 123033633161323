import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { updateLoading } from "../../../store/loading/loading.store";
import {
  asyncClearFilterPointsAcumulation,
  asyncGetAbrangenciaProgramaDePontos,
  asyncGetClasses,
  asyncGetGrupos,
  asyncGetProgramPoints,
  asyncGetSabores,
  asyncGetSubGrupos,
  asyncGetTiposProduto,
  asyncPutProgramPoints,
  newCardSavePutProgramPoints,
} from "../../../store/marketing/marketing.actions";
import {
  ProgramaDePontosAbrangenciaTipoEstabelecimentoProps,
  updateProgramaDePontos,
} from "../../../store/marketing/marketing.store";
import "./style.css";
import { COLORS } from "../../../constants";
import ProgressLoad from "../../../components/ProgressLoad";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Image } from "primereact/image";
import EstablishmentsTypesCupons from "../../../components/Marketing/AbrangenciaEstablishmentList";
import { Dialog } from "primereact/dialog";

const ProgramaDePontos: React.FC = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState({} as any);
  const [updatePontoRegraResgate, setUpdatePontoRegraResgate] = useState<
    ProgramaDePontosAbrangenciaTipoEstabelecimentoProps[]
  >([]);

  const [selectTipo, setSelectTipo] = useState<any>("");
  const [selectGrupo, setSelectGrupo] = useState<any>("");
  const [selectSubGrupo, setSelectSubGrupo] = useState<any>("");
  const [selectClasse, setSelectClasse] = useState<any>("");
  const [selectSabor, setSelectSabor] = useState<any>("");
  const [useStyleZindex, setUseStyleZindex] = useState<string>("");

  const { loading } = useSelector((state: RootState) => state.loading);
  const marketing = useSelector((state: RootState) => state.marketing);

  const onShowImg = () => {
    setUseStyleZindex("p-image-mask");
  };
  const onHideImg = () => {
    setUseStyleZindex("");
  };

  const handdleSubmitSelect = () => {
    dispatch(updateLoading(true));
    dispatch(
      asyncGetProgramPoints(
        selectTipo.id,
        selectGrupo.id,
        selectSubGrupo.id,
        selectClasse.id,
        selectSabor.id
      )
    );
  };

  const handdleClear = () => {
    dispatch(updateLoading(true));
    setSelectTipo("");
    setSelectGrupo("");
    setSelectSubGrupo("");
    setSelectClasse("");
    setSelectSabor("");
    dispatch(updateProgramaDePontos(""));
    dispatch(asyncClearFilterPointsAcumulation());
  };

  const handleQtPontoResgate = (e: any) => {
    const id = parseInt(e.target.id);

    const index = marketing.programaDePontosList.findIndex(
      (item) => item.idProduto === id
    );

    const newArr = [...marketing.programaDePontosList];

    newArr[index] = {
      ...newArr[index],
      qtPontoResgate: Math.abs(parseInt(e.target.value)),
    };

    dispatch(updateProgramaDePontos(newArr));
  };

  const handleQtMaxItemPedido = (e: any) => {
    const id = parseInt(e.target.id);

    const index = marketing.programaDePontosList.findIndex(
      (item) => item.idProduto === id
    );

    const newArr = [...marketing.programaDePontosList];

    newArr[index] = {
      ...newArr[index],
      qtMaxItemPedido: Math.abs(parseInt(e.target.value)),
    };

    dispatch(updateProgramaDePontos(newArr));
  };

  const handleInAtivo = (event: any) => {
    const id = parseInt(event.target.id);

    const index = marketing.programaDePontosList.findIndex(
      (item) => item.idProduto === id
    );

    const newArr = [...marketing.programaDePontosList];

    newArr[index] = { ...newArr[index], inAtivo: event.target.value };

    dispatch(updateProgramaDePontos(newArr));
  };

  // const handleCheckboxesEstabelecimentos = (event: any) => {
  //   const index = currentItem.pontoRegraResgateTipoEstabelecimento.findIndex(
  //     (chk: any) => chk.idTipoEstabelecimento === event.target.value
  //   );

  //   const newArr = [...currentItem.pontoRegraResgateTipoEstabelecimento];
  //   newArr[index] = { ...newArr[index], checked: event.target.checked };

  //   const currentItemListIndex = marketing.programaDePontosList.findIndex(
  //     (item) => item.idProduto == currentItem.idProduto
  //   );

  //   const arrayCurrentList = [...marketing.programaDePontosList];
  //   arrayCurrentList[currentItemListIndex] = {
  //     ...arrayCurrentList[currentItemListIndex],
  //     pontoRegraResgateTipoEstabelecimento: newArr,
  //   };

  //   setCurrentItem(arrayCurrentList[currentItemListIndex])
  //   dispatch(updateProgramaDePontos(arrayCurrentList));
  // };
  const handleCheckboxesEstabelecimentos = (event: any) => {
    const currentIndexTipoEstabelecimento =
      marketing.pontoRegraResgateTipoEstabelecimentoList.findIndex(
        (item) => item.idTipoEstabelecimento == event.target.value
      );

    const newArr = [...currentItem.pontoRegraResgateTipoEstabelecimento];

    const currentItemListIndex = marketing.programaDePontosList.findIndex(
      (item) => item.idProduto == currentItem.idProduto
    );

    const index = currentItem.pontoRegraResgateTipoEstabelecimento.findIndex(
      (chk: any) => chk.idTipoEstabelecimento === event.target.value
    );

    if (index !== -1) {
      newArr[index] = { ...newArr[index], checked: event.target.checked };
    } else if (event.target.checked) {
      newArr.push({
        ...marketing.pontoRegraResgateTipoEstabelecimentoList[
          currentIndexTipoEstabelecimento
        ],
        checked: true,
      });
    }

    const arrayCurrentList = [...marketing.programaDePontosList];
    arrayCurrentList[currentItemListIndex] = {
      ...arrayCurrentList[currentItemListIndex],
      pontoRegraResgateTipoEstabelecimento: newArr,
    };

    setCurrentItem(arrayCurrentList[currentItemListIndex]);
    dispatch(updateProgramaDePontos(arrayCurrentList));
  };

  const listaFinal = () => {
    const objetosIds: { [key: string]: number } = {};

    currentItem.pontoRegraResgateTipoEstabelecimento &&
      currentItem.pontoRegraResgateTipoEstabelecimento.forEach(
        (item: any, i: number) => {
          if (item.checked) objetosIds[item.idTipoEstabelecimento] = i;
        }
      );

    const listaDosObjetos =
      marketing.pontoRegraResgateTipoEstabelecimentoList.map((item) => {
        if (objetosIds[item.idTipoEstabelecimento] !== undefined) {
          return { ...item, checked: true };
        } else {
          return { ...item, checked: false };
        }
      });

    return listaDosObjetos;
  };

  const handleClickSave = () => {
    if (marketing.programaDePontosList.length > 0 && !loading) {
      dispatch(updateLoading(true));
      const arrayProgramPoints: newCardSavePutProgramPoints[] = [];

      for (let card of marketing.programaDePontosList) {
        arrayProgramPoints.push({
          id: card.id ? card.id : 0,
          descricao: card.descricao ? card.descricao : "",
          idProduto: card.idProduto,
          imagem: card.imagem ? card.imagem : "",
          qtPontoResgate: card.qtPontoResgate ? card.qtPontoResgate : 0,
          qtMaxItemPedido: card.qtMaxItemPedido ? card.qtMaxItemPedido : 0,
          inAtivo: card.inAtivo,
          pontoRegraResgateTipoEstabelecimento:
            card.pontoRegraResgateTipoEstabelecimento
              ? card.pontoRegraResgateTipoEstabelecimento.filter(
                  (item) => item.checked
                )
              : [],
        });
      }

      if (arrayProgramPoints.length > 0 && !loading) {
        dispatch(asyncPutProgramPoints(arrayProgramPoints));
      }

      // asyncGetProgramPoints(
      //   selectTipo.id,
      //   selectGrupo.id,
      //   selectSubGrupo.id,
      //   selectClasse.id,
      //   selectSabor.id
      // );
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  };

  useEffect(() => {
    dispatch(asyncGetAbrangenciaProgramaDePontos());
    dispatch(asyncGetTiposProduto());
    dispatch(asyncGetGrupos());
    dispatch(asyncGetSubGrupos());
    dispatch(asyncGetClasses());
    dispatch(asyncGetSabores());
    dispatch(
      asyncGetProgramPoints(
        selectTipo.id,
        selectGrupo.id,
        selectSubGrupo.id,
        selectClasse.id,
        selectSabor.id
      )
    );
  }, [dispatch]);

  useEffect(() => {
    setUpdatePontoRegraResgate(
      marketing.pontoRegraResgateTipoEstabelecimentoList
    );
  }, [marketing.pontoRegraResgateTipoEstabelecimentoList]);

  return (
    <main className="main-page">
      <Dialog
        header="Tipos de estabelecimento"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <EstablishmentsTypesCupons
          handleCheckboxes={handleCheckboxesEstabelecimentos}
          establishments={listaFinal()}
        />
        {/* {listaFinal().length === 0 && (
          <span style={{ fontSize: 12, color: "red" }}>
            Obrigatório selecionar ao menos 1 tipo de estabelecimento.
          </span>
        )} */}
      </Dialog>
      <p className="title">Programa de pontos</p>
      <section className="notifications-container-filters">
        <div>
          <p className="dropdown-label">Tipo</p>
          <Dropdown
            value={selectTipo}
            emptyMessage="Não foi encontrado nenhum registro."
            options={marketing.selectsOptions.tipos}
            onChange={(e) => setSelectTipo(e.value)}
            className="TreeSelect"
          />
        </div>
        <div>
          <p className="dropdown-label">Grupo</p>
          <Dropdown
            value={selectGrupo}
            emptyMessage="Não foi encontrado nenhum registro."
            options={marketing.selectsOptions.grupos}
            onChange={(e) => setSelectGrupo(e.value)}
            className="TreeSelect"
          />
        </div>
        <div>
          <p className="dropdown-label">SubGrupo</p>
          <Dropdown
            value={selectSubGrupo}
            emptyMessage="Não foi encontrado nenhum registro."
            options={marketing.selectsOptions.subGrupos}
            onChange={(e) => setSelectSubGrupo(e.value)}
            className="TreeSelect"
          />
        </div>
        <div>
          <p className="dropdown-label">Classe</p>
          <Dropdown
            value={selectClasse}
            emptyMessage="Não foi encontrado nenhum registro."
            options={marketing.selectsOptions.classes}
            onChange={(e) => setSelectClasse(e.value)}
            className="TreeSelect"
          />
        </div>
        <div>
          <p className="dropdown-label">Sabor</p>
          <Dropdown
            value={selectSabor}
            emptyMessage="Não foi encontrado nenhum registro."
            options={marketing.selectsOptions.sabores}
            onChange={(e) => setSelectSabor(e.value)}
            className="TreeSelect"
          />
        </div>
        <div className="divBtnImages">
          <Button
            label="Limpar filtros"
            style={{ color: COLORS.primaryGreen }}
            className="p-button-outlined btnSelect"
            onClick={handdleClear}
          />
          <Button
            label="Listar produtos"
            style={{ color: COLORS.primaryGreen }}
            className="p-button-outlined btnSelect"
            onClick={handdleSubmitSelect}
          />
        </div>
      </section>
      <div className="btnCardImages">
        {marketing.programaDePontosList.length === 0 ? (
          <Button
            label="Salvar"
            onClick={handleClickSave}
            className="btn-salvar"
            disabled
          />
        ) : (
          <Button
            label="Salvar"
            onClick={handleClickSave}
            className="btn-salvar"
          />
        )}
      </div>
      <div className="programaDePontos-header-table">
        <p>Produtos</p>
        <p>Imagem</p>
        <p>Pontos para resgate</p>
        <p>Quantidade máxima por pedido</p>
        <p>Abrangência</p>
        <p>Ativo</p>
      </div>
      <section
        style={{ background: COLORS.white }}
        className="sectionCardsImages"
      >
        <div className="card">
          {loading ? (
            <div className="divNotFind">
              <ProgressLoad />
            </div>
          ) : marketing.programaDePontosList.length === 0 ? (
            <div className="divNotFind">
              <p>Não foi encontrado nenhum registro.</p>
            </div>
          ) : (
            marketing.programaDePontosList.map((card, i) => (
              <div className="programaDePontos-container-principal" key={i}>
                <div className="programaDePontos-card-container">
                  <div className="programaDePontos-card-container-label">
                    <label htmlFor="product">{card.descricao}</label>
                  </div>
                  <div className="programaDePontos-card-container-imagem">
                    {card.imagem !== "" || null ? (
                      <Image
                        className={`${useStyleZindex} imageFit`}
                        src={
                          "https://apihomolog.guaracamp.com.br/" + card.imagem
                        }
                        alt={card.descricao}
                        onShow={onShowImg}
                        onHide={onHideImg}
                        preview
                      />
                    ) : (
                      <Image
                        className={`${useStyleZindex} imageFit`}
                        src="https://www.corsan.com.br/themes/padrao2019/images/outros/GD_imgSemImagem.png"
                        alt={card.descricao}
                      />
                    )}
                  </div>

                  <div className="programaDePontos-card-container-quantidade">
                    <InputText
                      name={"qtPontoResgate" + card.idProduto}
                      type="number"
                      className="programaDePontos-input-quantidade"
                      id={card.idProduto.toString()}
                      value={card.qtPontoResgate}
                      onChange={handleQtPontoResgate}
                      min={1}
                      style={
                        card.qtPontoResgate === 0
                          ? { width: "100px", borderColor: "red" }
                          : { width: "100px" }
                      }
                    />
                  </div>
                  <div className="programaDePontos-card-container-input">
                    <InputText
                      name={"qtMaxItemPedido" + card.idProduto}
                      type="number"
                      className="programaDePontos-input-quantidade"
                      id={card.idProduto.toString()}
                      value={card.qtMaxItemPedido}
                      onChange={handleQtMaxItemPedido}
                      min={1}
                      style={
                        card.qtMaxItemPedido === 0
                          ? { width: "100px", borderColor: "red" }
                          : { width: "100px" }
                      }
                    />
                  </div>
                  <div className="programaDePontos-card-container-abrangencia">
                    <Button
                      onClick={() => {
                        setVisible(true);
                        setCurrentItem(
                          Array.isArray(
                            card.pontoRegraResgateTipoEstabelecimento
                          ) &&
                            card.pontoRegraResgateTipoEstabelecimento.length > 0
                            ? card
                            : {
                                ...card,
                                pontoRegraResgateTipoEstabelecimento:
                                  updatePontoRegraResgate,
                              }
                        );
                      }}
                      style={{
                        background: "#fff",
                        fontSize: "1em",
                        border: "none",
                      }}
                    >
                      <i
                        className="pi pi-search-plus"
                        style={{ color: "#3DAE2B", fontSize: "1.5em" }}
                      ></i>
                    </Button>
                    {/* {Array.isArray(card.pontoRegraResgateTipoEstabelecimento) &&
                      card.pontoRegraResgateTipoEstabelecimento.length ===
                        0 && (
                        <span>
                          Obrigatório selecionar ao menos 1 tipo de
                          estabelecimento.
                        </span>
                      )} */}
                  </div>
                  <div>
                    <InputSwitch
                      id={card.idProduto.toString()}
                      checked={card.inAtivo}
                      onChange={handleInAtivo}
                      name={"inAtivo" + card.idProduto}
                    />
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </section>
    </main>
  );
};

export default ProgramaDePontos;
