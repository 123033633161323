import React, { useState, useEffect } from "react";
import "./style.css";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";

import Filter from "../../../components/Filter";
import CardList from "../../../components/CardList";
import {
  asyncDeleteProfile,
  asyncGetProfiles,
  asyncGetUserById,
  asyncSearchProfile,
} from "../../../store/admin/admin.actions";
import { confirmDialog } from "primereact/confirmdialog";
import ProfileCreate from "./ProfileCreate";
import ProgressLoad from "../../../components/ProgressLoad";
import { updateLoading } from "../../../store/loading/loading.store";

export interface ModulosProps {
  idModulo: number;
}

const Profiles: React.FC = () => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState("");
  const [user, setUser] = useState<any>();

  const { loading } = useSelector((state: RootState) => state.loading);

  const [newDescription, setNewDescription] = useState("");

  const deleteConfirm = (e: any) => {
    const id = e.target.value | e.target.parentNode.value;
    confirmDialog({
      message: "Você quer deletar este registro?",
      header: "Confirmação de exclusão",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => handleDelete(id),
      acceptLabel: "Sim",
      rejectLabel: "Não",
    });
  };

  const admin = useSelector((state: RootState) => state.admin);

  useEffect(() => {
    dispatch(asyncGetProfiles());
  }, [dispatch]);

  const passInputs = [
    {
      type: "text",
      label: "Descrição",
      value: description,
      onChange: setDescription,
    },
  ];

  const passInputsSideBar = [
    {
      type: "text",
      label: "Descrição",
      value: newDescription,
      onChange: setNewDescription,
    },
  ];

  //para pesquisar
  const handleClickFilter = () => {
    if (description !== "") {
      dispatch(updateLoading(true));
      dispatch(asyncSearchProfile(description));
    } else {
      dispatch(updateLoading(true));
      dispatch(asyncGetProfiles());
    }
  };

  const handleDelete = (id: number) => {
    dispatch(asyncDeleteProfile(id));
  };

  const handleClickClean = () => {
    dispatch(updateLoading(true));
    setDescription("");
    dispatch(asyncGetProfiles());
  };

  const idUsuario = Number(localStorage.getItem("@USER_ID_GUARACAMP"));

  const getUser = async () => {
    const response = await asyncGetUserById(idUsuario);
    setUser(response);
  };

  useEffect(() => {
    if (idUsuario) {
      getUser();
    }
  }, []);

  const administrativePerfil = user?.perfis.find(
    (perfil: any) => perfil.idPerfil === 74
  );

  return (
    <main className="main-page">
      <p className="title">Perfis (grupos)</p>
      <Filter
        inputs={passInputs}
        handleClickFilter={handleClickFilter}
        handleClickClean={handleClickClean}
      />
      <ProfileCreate />
      <div className="divCardList">
        {loading ? (
          <div className="div-not-find">
            <ProgressLoad />
          </div>
        ) : admin.profiles.length > 0 ? (
          admin.profiles.map((profile, i) => (
            <CardList
              handleDelete={deleteConfirm}
              key={i}
              name={profile.dsPerfil}
              idItem={profile.id}
              inputs={passInputsSideBar}
              disabled={administrativePerfil === undefined}
              route="profile"
            />
          ))
        ) : (
          <div className="div-not-find">
            <p>Não foi encontrado nenhum registro.</p>
          </div>
        )}
      </div>
    </main>
  );
};

export default Profiles;
