import { createSlice } from "@reduxjs/toolkit";

interface AdminUserProps {
  id: number;
  nmUsuario: string;
  txEmail: string;
  perfis: ProfileProps[];
}

export interface ModulesProps {
  idItem: number;
  descricao: string;
  checked: boolean;
}

export interface ProfileProps {
  idItem: number;
  descricao: string;
  checked: boolean;
}

export interface AdminProfileProps {
  id: number;
  dsPerfil: string;
  modulos: ModulesProps[];
}

export interface HelpProps {
  id: number;
  txPergunta: string;
  txResposta: string;
}

export interface AdminParameters {
  txChaveParametro: string;
  dsParametro: string;
  txTipoParametro: string;
  txValorParametro: string;
  id: number;
}

export interface TermsAndPolicies {
  txTipoConteudo: string;
  txConteudo: string;
  id: number;
}

interface adminProps {
  users: AdminUserProps[];
  profiles: AdminProfileProps[];
  modulos: ModulesProps[];
  profilesList: ProfileProps[];
  topics: HelpProps[];
  parameters: AdminParameters[];
  termsAndPolicies: TermsAndPolicies[];
}

const initialState: adminProps = {
  users: [],
  profiles: [],
  modulos: [],
  profilesList: [],
  topics: [],
  parameters: [],
  termsAndPolicies: [
    {
      txTipoConteudo: "",
      txConteudo: "",
      id: 1,
    },
    {
      txTipoConteudo: "",
      txConteudo: "",
      id: 2,
    },
  ],
};

const admin = createSlice({
  name: "admin",
  initialState,
  reducers: {
    updateUsers(state, action) {
      state.users = action.payload;
    },
    addUser(state, action) {
      state.users.push(action.payload);
    },
    deleteUser(state, action) {
      state.users = state.users.filter((user) => user.id !== action.payload);
    },
    updateProfiles(state, action) {
      state.profiles = action.payload;
    },
    updateProfilesList(state, action) {
      state.profilesList = action.payload;
    },
    deleteProfile(state, action) {
      state.profiles = state.profiles.filter(
        (profile) => profile.id !== action.payload
      );
    },
    addProfile(state, action) {
      state.profiles.push(action.payload);
    },
    updateProfile(state, action) {
      state.profiles = state.profiles.map((profile) =>
        profile.id === action.payload.id ? action.payload : profile
      );
    },
    updateTopics(state, action) {
      state.topics = action.payload;
    },
    addTopic(state, action) {
      state.topics.push(action.payload);
    },
    deleteTopic(state, action) {
      state.topics = state.topics.filter(
        (topic) => topic.id !== action.payload
      );
    },
    updateUser(state, action) {
      state.users = state.users.map((user) =>
        user.id === action.payload.id ? action.payload : user
      );
    },
    updateParameters(state, action) {
      state.parameters = action.payload;
    },
    updateTopic(state, action) {
      state.topics = state.topics.map((topic) =>
        topic.id === action.payload.id ? action.payload : topic
      );
    },
    updateTermsAndPolicies(state, action) {
      state.termsAndPolicies = action.payload;
    },
    updateModules(state, action) {
      state.modulos = action.payload;
    },
  },
});

export const {
  updateUsers,
  addUser,
  updateUser,
  deleteUser,
  updateProfiles,
  deleteProfile,
  addProfile,
  updateProfilesList,
  updateProfile,
  updateTopics,
  addTopic,
  deleteTopic,
  updateParameters,
  updateTopic,
  updateTermsAndPolicies,
  updateModules,
} = admin.actions;

export default admin.reducer;
