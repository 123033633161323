import { confirmDialog } from "primereact/confirmdialog";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardListCupons from "../../../components/Marketing/CardListCupons";
import ProgressLoad from "../../../components/ProgressLoad";
import { RootState } from "../../../store";

import {
  asyncDeleteCupom,
  asyncGetClientesApps,
  asyncGetCupons,
  asyncGetAbrangenciaTipoEstabelecimento,
  asyncGetCuponsCategories,
  asyncGetCuponsProducts,
} from "../../../store/marketing/marketing.actions";
import CuponsCreate from "./CuponsCreate";
import "./styles.css";

const Cupons: React.FC = () => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState("");

  const { loading } = useSelector((state: RootState) => state.loading);
  const marketing = useSelector((state: RootState) => state.marketing);

  const deleteConfirm = (e: any) => {
    const id = e.target.value | e.target.parentNode.value;
    confirmDialog({
      message: "Você quer deletar este registro?",
      header: "Confirmação de exclusão",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => handleDelete(id),
      acceptLabel: "Sim",
      rejectLabel: "Não",
    });
  };

  const handleDelete = (id: number) => {
    if (id) dispatch(asyncDeleteCupom(id));
    // accept()
  };

  const inputsSideBar = [
    {
      type: "text",
      label: "Descrição",
      value: description,
      onChange: setDescription,
    },
  ];

  useEffect(() => {
    dispatch(asyncGetCupons());
    dispatch(asyncGetCuponsProducts());
    dispatch(asyncGetClientesApps());
    dispatch(asyncGetCuponsCategories());
    dispatch(asyncGetAbrangenciaTipoEstabelecimento());
  }, [dispatch]);

  return (
    <div className="main-page">
      <p className="title">Cupons de Desconto</p>
      <CuponsCreate />

      {loading ? (
        <div className="divNotFind">
          <ProgressLoad />
        </div>
      ) : marketing.cupons.length === 0 ? (
        <div className="divNotFind">
          <p>Não foi encontrado nenhum registro.</p>
        </div>
      ) : (
        marketing.cupons.map((cupons, i) => (
          <CardListCupons
            key={i}
            dsCupom={cupons.dsCupom}
            cdCupom={cupons.cdCupom}
            id={cupons.id}
            inAtivo={cupons.inAtivo}
            inputs={inputsSideBar}
            handleDelete={deleteConfirm}
          />
        ))
      )}
    </div>
  );
};

export default Cupons;
