import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { updateLoading } from '../../../store/loading/loading.store';
import {
  asyncClearFilterMerchandising,
  asyncGetAbrangenciaMerchandising,
  asyncGetClasses,
  asyncGetFilterProduct,
  asyncGetGrupos,
  asyncGetMerchandising,
  asyncGetSabores,
  asyncGetSubGrupos,
  asyncGetTiposProduto,
  asyncPutMerchandising,
  newCardSavePutMerchandising,
} from '../../../store/marketing/marketing.actions';
import {
  MerchandisingAbrangenciaTipoEstabelecimentoProps,
  updateMerchandising,
} from '../../../store/marketing/marketing.store';
import './style.css';
import { COLORS } from '../../../constants';
import { DataScroller } from 'primereact/datascroller';
import ProgressLoad from '../../../components/ProgressLoad';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Image } from 'primereact/image';
import { Dialog } from 'primereact/dialog';
import EstablishmentsTypesCupons from '../../../components/Marketing/AbrangenciaEstablishmentList';

const Merchandising: React.FC = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState({} as any);
  const [updateMerchandisingRegas, setUpdateMerchandisingRegras] = useState<
    MerchandisingAbrangenciaTipoEstabelecimentoProps[]
  >([]);

  const [selectTipo, setSelectTipo] = useState<any>('');
  const [selectGrupo, setSelectGrupo] = useState<any>('');
  const [selectSubGrupo, setSelectSubGrupo] = useState<any>('');
  const [selectClasse, setSelectClasse] = useState<any>('');
  const [selectSabor, setSelectSabor] = useState<any>('');
  const [useStyleZindex, setUseStyleZindex] = useState<string>('');

  const { loading } = useSelector((state: RootState) => state.loading);
  const marketing = useSelector((state: RootState) => state.marketing);

  const onShowImg = () => {
    setUseStyleZindex('p-image-mask');
  };
  const onHideImg = () => {
    setUseStyleZindex('');
  };

  const handdleSubmitSelect = () => {
    dispatch(updateLoading(true));

    dispatch(
      asyncGetMerchandising(
        selectTipo.id,
        selectGrupo.id,
        selectSubGrupo.id,
        selectClasse.id,
        selectSabor.id
      )
    );
  };

  const handdleClear = () => {
    dispatch(updateLoading(true));
    setSelectTipo('');
    setSelectGrupo('');
    setSelectSubGrupo('');
    setSelectClasse('');
    setSelectSabor('');
    dispatch(updateMerchandising(''));
    dispatch(asyncClearFilterMerchandising());
  };

  useEffect(() => {
    setUpdateMerchandisingRegras(
      marketing.merchandisingTipoEstabelecimentoList
    );
  }, [marketing.merchandisingTipoEstabelecimentoList]);

  useEffect(() => {
    dispatch(asyncGetAbrangenciaMerchandising());
    dispatch(asyncGetTiposProduto());
    dispatch(asyncGetGrupos());
    dispatch(asyncGetSubGrupos());
    dispatch(asyncGetClasses());
    dispatch(asyncGetSabores());
    dispatch(
      asyncGetMerchandising(
        selectTipo.id,
        selectGrupo.id,
        selectSubGrupo.id,
        selectClasse.id,
        selectSabor.id
      )
    );
  }, [dispatch]);

  const handleQtMinDiaResgate = (e: any) => {
    const id = parseInt(e.target.id);

    const index = marketing.merchandisingList.findIndex(
      (item) => item.idProduto === id
    );

    const newArr = [...marketing.merchandisingList];

    newArr[index] = {
      ...newArr[index],
      qtMinDiaResgate: Math.abs(parseInt(e.target.value)),
    };

    dispatch(updateMerchandising(newArr));
  };

  const handleQtMaxPedido = (e: any) => {
    const id = parseInt(e.target.id);

    const index = marketing.merchandisingList.findIndex(
      (item) => item.idProduto === id
    );

    const newArr = [...marketing.merchandisingList];

    newArr[index] = {
      ...newArr[index],
      qtMaxPedido: Math.abs(parseInt(e.target.value)),
    };

    dispatch(updateMerchandising(newArr));
  };

  const handleInAtivo = (event: any) => {
    console.log(event);
    const id = parseInt(event.target.id);

    const index = marketing.merchandisingList.findIndex(
      (item) => item.idProduto === id
    );

    const newArr = [...marketing.merchandisingList];

    newArr[index] = { ...newArr[index], inAtivo: event.target.value };

    dispatch(updateMerchandising(newArr));
  };

  const handleCheckboxesEstabelecimentos = (event: any) => {
    const currentIndexTipoEstabelecimento =
      marketing.merchandisingTipoEstabelecimentoList.findIndex(
        (item) => item.idTipoEstabelecimento == event.target.value
      );

    const newArr = [...currentItem.matMerchanTipoEstabelecimento];

    const currentItemListIndex = marketing.merchandisingList.findIndex(
      (item) => item.idProduto == currentItem.idProduto
    );

    const index = currentItem.matMerchanTipoEstabelecimento.findIndex(
      (chk: any) => chk.idTipoEstabelecimento === event.target.value
    );

    if (index !== -1) {
      newArr[index] = { ...newArr[index], checked: event.target.checked };
    } else if (event.target.checked) {
      newArr.push({
        ...marketing.merchandisingTipoEstabelecimentoList[
          currentIndexTipoEstabelecimento
        ],
        checked: true,
      });
    }

    const arrayCurrentList = [...marketing.merchandisingList];
    arrayCurrentList[currentItemListIndex] = {
      ...arrayCurrentList[currentItemListIndex],
      matMerchanTipoEstabelecimento: newArr,
    };

    setCurrentItem(arrayCurrentList[currentItemListIndex]);
    dispatch(updateMerchandising(arrayCurrentList));
  };

  const listaFinal = () => {
    const objetosIds: { [key: string]: number } = {};
    currentItem.matMerchanTipoEstabelecimento &&
      currentItem.matMerchanTipoEstabelecimento.forEach(
        (item: any, i: number) => {
          if (item.checked) objetosIds[item.idTipoEstabelecimento] = i;
        }
      );

    const listaDosObjetos = marketing.merchandisingTipoEstabelecimentoList.map(
      (item) => {
        if (objetosIds[item.idTipoEstabelecimento] !== undefined) {
          return { ...item, checked: true };
        } else {
          return { ...item, checked: false };
        }
      }
    );

    return listaDosObjetos;
  };

  const handleClickSave = () => {
    if (marketing.merchandisingList.length > 0 && !loading) {
      dispatch(updateLoading(true));
      const arrayMerchandising: newCardSavePutMerchandising[] = [];

      for (let card of marketing.merchandisingList) {
        arrayMerchandising.push({
          id: card.id ? card.id : 0,
          descricao: card.descricao ? card.descricao : '',
          idProduto: card.idProduto,
          imagem: card.imagem ? card.imagem : '',
          qtMinDiaResgate: card.qtMinDiaResgate ? card.qtMinDiaResgate : 0,
          qtMaxPedido: card.qtMaxPedido ? card.qtMaxPedido : 0,
          inAtivo: card.inAtivo,
          matMerchanTipoEstabelecimento: card.matMerchanTipoEstabelecimento
            ? card.matMerchanTipoEstabelecimento.filter((item) => item.checked)
            : [],
        });
      }

      if (arrayMerchandising.length > 0 && !loading) {
        dispatch(asyncPutMerchandising(arrayMerchandising));
      }

      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  };

  return (
    <main className="main-page">
      <Dialog
        header="Tipos de estabelecimento"
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
      >
        <EstablishmentsTypesCupons
          handleCheckboxes={handleCheckboxesEstabelecimentos}
          establishments={listaFinal()}
        />
      </Dialog>
      <p className="title">Materiais de Merchandising</p>
      <section className="notifications-container-filters">
        <div>
          <p className="dropdown-label">Tipo</p>
          <Dropdown
            value={selectTipo}
            emptyMessage="Não foi encontrado nenhum registro."
            options={marketing.selectsOptions.tipos}
            onChange={(e) => setSelectTipo(e.value)}
            className="TreeSelect"
          />
        </div>
        <div>
          <p className="dropdown-label">Grupo</p>
          <Dropdown
            value={selectGrupo}
            emptyMessage="Não foi encontrado nenhum registro."
            options={marketing.selectsOptions.grupos}
            onChange={(e) => setSelectGrupo(e.value)}
            className="TreeSelect"
          />
        </div>
        <div>
          <p className="dropdown-label">SubGrupo</p>
          <Dropdown
            value={selectSubGrupo}
            emptyMessage="Não foi encontrado nenhum registro."
            options={marketing.selectsOptions.subGrupos}
            onChange={(e) => setSelectSubGrupo(e.value)}
            className="TreeSelect"
          />
        </div>
        <div>
          <p className="dropdown-label">Classe</p>
          <Dropdown
            value={selectClasse}
            emptyMessage="Não foi encontrado nenhum registro."
            options={marketing.selectsOptions.classes}
            onChange={(e) => setSelectClasse(e.value)}
            className="TreeSelect"
          />
        </div>
        <div>
          <p className="dropdown-label">Sabor</p>
          <Dropdown
            value={selectSabor}
            emptyMessage="Não foi encontrado nenhum registro."
            options={marketing.selectsOptions.sabores}
            onChange={(e) => setSelectSabor(e.value)}
            className="TreeSelect"
          />
        </div>
        <div className="divBtnImages">
          <Button
            label="Limpar filtros"
            style={{ color: COLORS.primaryGreen }}
            className="p-button-outlined btnSelect"
            onClick={handdleClear}
          />
          <Button
            label="Listar produtos"
            style={{ color: COLORS.primaryGreen }}
            className="p-button-outlined btnSelect"
            onClick={handdleSubmitSelect}
          />
        </div>
      </section>
      <div className="btnCardImages">
        {marketing.merchandisingList.length === 0 ? (
          <Button
            label="Salvar"
            onClick={handleClickSave}
            className="btn-salvar"
            disabled
          />
        ) : (
          <Button
            label="Salvar"
            onClick={handleClickSave}
            className="btn-salvar"
          />
        )}
      </div>
      <div className="programaDePontos-header-table">
        <p>Material de merchandising</p>
        <p>Imagem</p>
        <p>Quantidade mínima de dias para novo resgate</p>
        <p>Quantidade máximo de produto por pedido</p>
        <p>Abrangência</p>
        <p>Ativo</p>
      </div>
      <section
        style={{ background: COLORS.white }}
        className="sectionCardsImages"
      >
        <div className="card">
          {loading ? (
            <div className="divNotFind">
              <ProgressLoad />
            </div>
          ) : marketing.merchandisingList.length === 0 ? (
            <div className="divNotFind">
              <p>Não foi encontrado nenhum registro.</p>
            </div>
          ) : (
            marketing.merchandisingList.map((card, i) => (
              <div key={i}>
                <div className="programaDePontos-card-container">
                  <div className="programaDePontos-card-container-label">
                    <label htmlFor="product">{card.descricao}</label>
                  </div>
                  <div className="programaDePontos-card-container-imagem">
                    {card.imagem !== '' || null ? (
                      <Image
                        className={`${useStyleZindex} imageFit`}
                        src={
                          'https://api.guaracamp.com.br/' + card.imagem
                        }
                        alt={card.descricao}
                        onShow={onShowImg}
                        onHide={onHideImg}
                        preview
                      />
                    ) : (
                      <Image
                        className={`${useStyleZindex} imageFit`}
                        src="https://www.corsan.com.br/themes/padrao2019/images/outros/GD_imgSemImagem.png"
                        alt={card.descricao}
                      />
                    )}
                  </div>

                  <div className="programaDePontos-card-container-quantidade">
                    <InputText
                      name={'qtMinDiaResgate' + card.idProduto}
                      type="number"
                      className="programaDePontos-input-quantidade"
                      id={card.idProduto.toString()}
                      value={card.qtMinDiaResgate}
                      onChange={handleQtMinDiaResgate}
                      min={1}
                      style={
                        card.qtMinDiaResgate === 0
                          ? { width: '100px', borderColor: 'red' }
                          : { width: '100px' }
                      }
                    />
                  </div>
                  <div className="programaDePontos-card-container-input">
                    <InputText
                      name={'qtMaxPedido' + card.idProduto}
                      type="number"
                      className="programaDePontos-input-quantidade"
                      id={card.idProduto.toString()}
                      value={card.qtMaxPedido}
                      onChange={handleQtMaxPedido}
                      min={1}
                      style={
                        card.qtMaxPedido === 0
                          ? { width: '100px', borderColor: 'red' }
                          : { width: '100px' }
                      }
                    />
                  </div>
                  <div className="programaDePontos-card-container-abrangencia">
                    <Button
                      onClick={() => {
                        setVisible(true);
                        setCurrentItem(
                          Array.isArray(card.matMerchanTipoEstabelecimento) &&
                            card.matMerchanTipoEstabelecimento.length > 0
                            ? card
                            : {
                                ...card,
                                matMerchanTipoEstabelecimento:
                                  updateMerchandisingRegas,
                              }
                        );
                      }}
                      style={{
                        background: '#fff',
                        fontSize: '1em',
                        border: 'none',
                      }}
                    >
                      <i
                        className="pi pi-search-plus"
                        style={{ color: '#3DAE2B', fontSize: '1.5em' }}
                      ></i>
                    </Button>
                  </div>
                  <div>
                    <InputSwitch
                      id={card.idProduto.toString()}
                      checked={card.inAtivo}
                      onChange={handleInAtivo}
                      name={'inAtivo' + card.id}
                    />
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </section>
    </main>
  );
};

export default Merchandising;
