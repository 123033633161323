import { Checkbox } from "primereact/checkbox";
import { AbrangenciaTipoEstabelecimentoProps } from "../../../store/marketing/marketing.store";

interface EstablishmentsTypesCuponsProps {
  view?: boolean;
  checkboxes?: AbrangenciaTipoEstabelecimentoProps;
  cod?: number;
  id?: number;
  handleDelete?: (id: any) => void;
  handleCheckboxes: (e: any) => void;
  establishments: AbrangenciaTipoEstabelecimentoProps[];
}

const EstablishmentsTypesCupons = ({
  view,
  handleCheckboxes,
  establishments,
}: EstablishmentsTypesCuponsProps) => {

  return (
    <>
      {establishments?.map((itens, i) => {
        return (
          <div className="sidebar-cupons-itens" key={i}>
            <div className="produtos-list" key={i}>
              <Checkbox
                checked={itens.checked}
                value={itens.idTipoEstabelecimento}
                onChange={handleCheckboxes}
                disabled={view && true}
              />
              <label htmlFor="product">{itens.dsTipoEstabelecimento}</label>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default EstablishmentsTypesCupons;
