import { Sidebar } from "primereact/sidebar";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { asyncGetUserById } from "../../store/admin/admin.actions";

import "./styles.css";

const MainMenu2 = () => {
  const [user, setUser] = useState<any>();
  const modulos = useSelector((state: RootState) => state.admin.modulos);

  const idUsuario = Number(localStorage.getItem("@USER_ID_GUARACAMP"));

  const getUser = async () => {
    const response = await asyncGetUserById(idUsuario);
    setUser(response);
  };

  useEffect(() => {
    if (idUsuario) {
      getUser();
    }
  }, []);

  const [visible, setVisible] = useState(false);
  const [subMenu, setSubmenu] = useState([
    { section: "Usuários", route: "/admin/users" },
    { section: "Comandos", route: "/admin/users" },
    { section: "Testes", route: "/admin/users" },
  ]);

  const financeiro = [
    { section: "Sol. de Cadastro (Clientes)", route: "/approvals" },
  ];

  const ti = [
    { section: "Termos e Políticas", route: "/admin/termsAndPolicies" },
    { section: "Parâmetros", route: "/admin/parameters" },
    { section: "Perfis", route: "/admin/profiles" },
    { section: "Usuários", route: "/admin/users" },
  ];

  const marketing = [
    { section: "Banner Carrossel", route: "/marketing/bannercarrossel" },
    { section: "Imagens de Produtos", route: "/marketing/images" },
    { section: "Produtos Destaques", route: "/marketing/featured" },
    { section: "Cupons de Desconto", route: "/marketing/cupons" },
    { section: "Promoções", route: "/marketing/promocoes" },
    {
      section: "Pontos (Regras de Pontuação)",
      route: "/marketing/acumulodepontos",
    },
    {
      section: "Pontos (Regras de Resgate)",
      route: "/marketing/programadepontos",
    },
    {
      section: "Materiais de Merchandising",
      route: "/marketing/merchandising",
    },
    { section: "Sol. de Comodatos", route: "/comodatos" },
    { section: "Notificações", route: "/comercial/notificacoes" },
    { section: "Ajuda (FAQ)", route: "/admin/ajuda" },
  ];

  const itemsSubMenu = {
    financeiro,
    marketing,
    ti,
  };

  const handleMouseLeave = (e: any) => {
    setVisible(false);
  };

  const handleMouseEnter = (e: any) => {
    if (e.target.id === "financeiro" || e.target.parentNode.id === "financeiro")
      setSubmenu(itemsSubMenu["financeiro"]);

    if (e.target.id === "ti" || e.target.parentNode.id === "ti")
      setSubmenu(itemsSubMenu["ti"]);

    if (e.target.id === "marketing" || e.target.parentNode.id === "marketing")
      setSubmenu(itemsSubMenu["marketing"]);

    setVisible(true);
  };

  const handleClick = (route: string) => {
    window.location.pathname = route;
  };

  const administrativePerfil = user?.perfis.some(
    (perfil: any) => perfil.idPerfil === 74
  );

  const marketingPerfil = user?.perfis.some(
    (perfil: any) => perfil.idPerfil === 55
  );

  const financePerfil = user?.perfis.some(
    (perfil: any) => perfil.idPerfil === 46
  );

  const tiPerfil = user?.perfis.some((perfil: any) => perfil.idPerfil === 73);

  return (
    <div className="mainmenu2-container" onMouseLeave={handleMouseLeave}>
      <Sidebar
        className="mainmenu2-sidebar"
        visible={visible}
        onHide={() => setVisible(false)}
        showCloseIcon={false}
      >
        {subMenu.map((item, i) => (
          <div
            onClick={() => handleClick(item.route)}
            key={i}
            className="mainmenu2-item-submenu"
          >
            {item.section}
          </div>
        ))}
      </Sidebar>

      {(user?.perfis?.length === 0 || administrativePerfil) && (
        <>
          <div
            id="financeiro"
            className="mainmenu2-item"
            onClick={handleMouseEnter}
            onMouseEnter={handleMouseEnter}
          >
            <i className="pi pi-chart-line mainmenu2-item-icon"></i>
            <span>Financeiro</span>
          </div>
          <div
            id="marketing"
            className="mainmenu2-item"
            onClick={handleMouseEnter}
            onMouseEnter={handleMouseEnter}
          >
            <i className="pi pi-comments mainmenu2-item-icon"></i>
            <span>Marketing</span>
          </div>
          <div
            id="ti"
            className="mainmenu2-item"
            onClick={handleMouseEnter}
            onMouseEnter={handleMouseEnter}
          >
            <i className="pi pi-cog mainmenu2-item-icon"></i>
            <span>TI</span>
          </div>
        </>
      )}
      {marketingPerfil && (
        <div
          id="marketing"
          className="mainmenu2-item"
          onClick={handleMouseEnter}
          onMouseEnter={handleMouseEnter}
        >
          <i className="pi pi-comments mainmenu2-item-icon"></i>
          <span>Marketing</span>
        </div>
      )}
      {financePerfil && (
        <div
          id="financeiro"
          className="mainmenu2-item"
          onClick={handleMouseEnter}
          onMouseEnter={handleMouseEnter}
        >
          <i className="pi pi-chart-line mainmenu2-item-icon"></i>
          <span>Financeiro</span>
        </div>
      )}
      {tiPerfil && (
        <div
          id="ti"
          className="mainmenu2-item"
          onClick={handleMouseEnter}
          onMouseEnter={handleMouseEnter}
        >
          <i className="pi pi-cog mainmenu2-item-icon"></i>
          <span>TI</span>
        </div>
      )}
    </div>
  );
};

export default MainMenu2;
