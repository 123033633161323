import { Checkbox } from 'primereact/checkbox';
import { CuponsProductsProps } from '../../../store/marketing/marketing.store';


interface ProductsListProps {
  view?: boolean;
  checkboxes?: CuponsProductsProps;
  cod?: number;
  idProduto?: number;
  handleDelete?: (id: any) => void;
  handleCheckboxes: (e: any) => void;
  itens: CuponsProductsProps[];
}

const ProductListCupons = ({
  view,
  handleCheckboxes,
  itens,
}: ProductsListProps) => {
  return (
    <>
      {itens?.map((itens, i) => {
        return (
          <div className='sidebar-cupons-itens' key={i}>
            <div className='produtos-list' key={i}>
              <Checkbox
                checked={itens.checked}
                value={itens.idProduto}
                onChange={handleCheckboxes}
                disabled={view && true}
              />
              <label htmlFor='product'>{itens.dsProduto}</label>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ProductListCupons;
