import { createSlice } from "@reduxjs/toolkit";

export interface PromotionProps {
  id: number;
  dsPromocao: string;
  dtVlidadeDe: string;
  dtValidadeAte: string;
  inAtivo: boolean;
  cdPromocao: string;
  jaUtilizada: false;
  txImagemPromocao: string;
  itens: PromotionItensProps[];
  idTipoAbrangencia: number | null;
  abrangenciaTipoEstabelecimento: PromotionsAbrangenciaTipoEstabelecimentoProps[];
  abrangenciaClienteApp: PromotionsAbrangenciaClienteAppProps[];
}

export interface PromotionsAbrangenciaTipoEstabelecimentoProps {
  idTipoEstabelecimento: number;
  dsTipoEstabelecimento: string;
  checked: boolean;
}

export interface PromotionsAbrangenciaClienteAppProps {
  idClientAPP: number;
  nmCliente: string;
}

export interface PromotionItensProps {
  qtItemPromocao: any;
  vlItemPromocao: any;
  idTipoDesconto: any;
  idProduto: number ;
  checked: boolean;
  dsProduto: string;
}

export interface ClientesAppProps {
  idClienteApp: number;
  nmCliente: string;
  clienteApp?: any;
}

interface comercialProps {
  promotions: PromotionProps[];
  productsList: PromotionItensProps[];
  promotionsAbrangenciaTiposEstabelecimentosList: PromotionsAbrangenciaTipoEstabelecimentoProps[];
  promotionsAbrangenciaClienteAppList: PromotionsAbrangenciaClienteAppProps[];
  clientesAppList: ClientesAppProps[];
}

const initialState: comercialProps = {
  promotions: [],
  productsList: [],
  promotionsAbrangenciaTiposEstabelecimentosList: [],
  promotionsAbrangenciaClienteAppList: [],
  clientesAppList: [],
};

const comercial = createSlice({
  name: "comercial",
  initialState,
  reducers: {
    updatePromotions(state, action) {
      state.promotions = action.payload;
    },
    deletePromotion(state, action) {
      state.promotions = state.promotions.filter(
        (promotion) => promotion.id !== action.payload
      );
    },
    updateProducts(state, action) {
      state.productsList = action.payload;
    },
    addPromotion(state, action) {
      state.promotions.push(action.payload);
    },
    updatePromotion(state, action) {
      state.promotions = state.promotions.map((promotion) =>
        promotion.id === action.payload.id ? action.payload : promotion
      );
    },
    addJustification(state, action) {
      state.promotions.push(action.payload);
    },
    updateAbrangenciaTipoEstabelecimento(state, action) {
      state.promotionsAbrangenciaTiposEstabelecimentosList = action.payload;
    },
    updateAbrangenciaClienteApp(state, action) {
      state.promotionsAbrangenciaClienteAppList = action.payload;
    },
    updateClientesApp(state, action) {
      state.clientesAppList = action.payload;
    },
  },
});

export const {
  updatePromotions,
  deletePromotion,
  updateProducts,
  addPromotion,
  updatePromotion,
  addJustification,
  updateAbrangenciaTipoEstabelecimento,
  updateAbrangenciaClienteApp,
  updateClientesApp,
} = comercial.actions;
export default comercial.reducer;
