import { createSlice } from "@reduxjs/toolkit";

interface authProps {
  isAuthenticate: boolean;
  user?: userAuth;
}

interface userAuth {
  email: string;
}

const initialState: authProps = {
  isAuthenticate: false,
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateLogin(state, action) {
      state.isAuthenticate = action.payload;
    },
    updateUserAuth(state, action) {      
      state.user = action.payload;
    },
  },
});

export const { updateLogin, updateUserAuth } = auth.actions;
export default auth.reducer;
