import { Checkbox } from 'primereact/checkbox';
import {
  CuponsCategoriasProps,
} from '../../../store/marketing/marketing.store';


interface ItensListProps {
  view?: boolean;
  checkboxes?: CuponsCategoriasProps;
  cod?: number;
  idProduto?: number;
  handleDelete?: (id: any) => void;
  handleCheckboxes: (e: any) => void;
  itens: CuponsCategoriasProps[];
}

const CategoryListCupons = ({
  view,
  handleCheckboxes,
  itens,
}: ItensListProps) => {
  return (
    <>
      {itens?.map((itens, i) => {
        return (
          <div className='sidebar-cupons-itens' key={i}>
            <div className='produtos-list' key={i}>
              <Checkbox
                checked={itens.checked}
                value={itens.idCategoriaProduto}
                onChange={handleCheckboxes}
                disabled={view && true}
              />
              <label htmlFor='product'>{itens.dsCategoriaProduto}</label>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default CategoryListCupons;
