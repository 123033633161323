import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApprovalTypes } from '../../../enums/ApprovalsTypes';
import { ToasterTypes } from '../../../enums/ToasterTypes';
import { RootState } from '../../../store';
import {
  asyncGetAppData,
  asyncGetErpData,
  asyncGetPreData,
} from '../../../store/approvals/approvals.actions';
import {
  updateAppData,
  updateErpData,
  updatePreData,
} from '../../../store/approvals/approvals.store';
import { updateToaster } from '../../../store/helper/helper.store';
import { cellMask, cepMask, cpfCnpjMask, phoneMask } from '../../../util/mask';
import '../../FieldGroup/styles.css';
import ProgressLoad from '../../ProgressLoad';
import '../styles.css';

interface ApprovallAppProps {
  approval?: any;
}

const ApprovalApp: React.FC<ApprovallAppProps> = ({ approval }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [numeroDocumento, setNumeroDocumento] = useState('');
  const approvals = useSelector((state: RootState) => state.approvals);

  const handleInput = (e: any) => {};

  const getAppData = async (id: number) => {
    let request = await asyncGetAppData(id)
      .then((value: any) => {
        if (value.data === null) {
          setData(null);
        } else {
          dispatch(updateAppData(value.data));
          setNumeroDocumento(
            value.data?.numeroDocumento
              ? value.data?.numeroDocumento
              : value.data?.txCpfCnpj
          );
          setData(value.data);
        }
      })
      .catch((error: any) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: 'Erro',
            message: error.response.data.erros[0].message,
          })
        );
      });
  };

  const getPreData = async (id: number) => {
    setLoading(true);
    let request = await asyncGetPreData(id)
      .then((value: any) => {
        if (value.data === null) {
          setData(null);
        } else {
          dispatch(updatePreData(value.data));
          setNumeroDocumento(value.data.txCpfCnpj);
          setData(value.data);
        }
      })
      .catch((error: any) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: 'Erro',
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getErpData = async (id: number) => {
    setLoading(true);

    let request = await asyncGetErpData(id)
      .then((value: any) => {
        const dadosDoEnpoint = value.data;
        const dadosERP = {
          enderecos: [
            {
              cdUf: dadosDoEnpoint.txUfPrincipal,
              id: dadosDoEnpoint.id,
              // idClientePreCadastro: 20319,
              idTipoEndereco: 1,
              idTipoEnderecoNavigation: null,
              nmBairro: dadosDoEnpoint.txBairroPrincipal,
              nmCidade: dadosDoEnpoint.txMunicipioPrincipal,
              txCep: dadosDoEnpoint.txCepPrincipal,
              txComplemento: dadosDoEnpoint.txComplementoPrincipal,
              txLogradouro: dadosDoEnpoint.txEnderecoPrincipal,
              txNumero: '',
              txPontoReferencia: dadosDoEnpoint.txComplementoPrincipal,
            },
            {
              cdUf: dadosDoEnpoint.txUfEntrega,
              id: dadosDoEnpoint.id,
              // idClientePreCadastro: 20319,
              idTipoEndereco: 2,
              idTipoEnderecoNavigation: null,
              nmBairro: dadosDoEnpoint.txBairroEntrega,
              nmCidade: dadosDoEnpoint.txMunicipioEntrega,
              txCep: dadosDoEnpoint.txCepEntrega,
              txComplemento: dadosDoEnpoint.txComplementoEntrega,
              txLogradouro: dadosDoEnpoint.txEnderecoEntrega,
              txNumero: '',
              txPontoReferencia: dadosDoEnpoint.txComplementoEntrega,
            },
          ],
          id: dadosDoEnpoint.id,
          idSolicitacaoCadastro: '',
          idTipoEstabelecimento: Number(dadosDoEnpoint.idTipoEstabelecimento),
          idTipoPessoa: Number(dadosDoEnpoint.idTipoPessoa),
          nmCliente: dadosDoEnpoint.nmCliente,
          nmFantasia: dadosDoEnpoint.nmFantasia,
          tipoEstabelecimento: {
            cdTipoEstabelecimentoErp: dadosDoEnpoint.cdTipoEstabelecimento,
            dsTipoEstabelecimento: dadosDoEnpoint.dsTipoEstabelecimento,
            id: Number(dadosDoEnpoint.idTipoEstabelecimento),
          },
          //não tem os tipos de estabelecimento quando vem do
          tipoPessoa: {
            dsTipoPessoa: dadosDoEnpoint.dsTipoPessoa,
            cdTipoPessoa: dadosDoEnpoint.cdCliente,
            id: Number(dadosDoEnpoint.idTipoPessoa),
          },
          txCelular: dadosDoEnpoint.txDddCelular + dadosDoEnpoint.txCelular,
          txCpfCnpj: dadosDoEnpoint.txCpfCnpj,
          txEmail: dadosDoEnpoint.txEmail,
          txTelefone: dadosDoEnpoint.txDdd + dadosDoEnpoint.txTelefone,
        };
        if (dadosDoEnpoint) {
          dispatch(updateErpData(dadosERP));
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (approval.idTipoSolicitacao === ApprovalTypes.cadastro) {
      getPreData(approval.idClientePreCadastro);
    } else {
      getAppData(approval.idClienteApp);
    }
  }, []);

  useEffect(() => {
    if (approvals.evaluation.pre) {
      getErpData(approvals.evaluation.pre.txCpfCnpj);
    }
  }, [approvals.evaluation.pre]);

  return (
    <>
      {loading && <ProgressLoad />}
      {!loading && (
        <>
          <div className="field-group-container">
            <span className="field-group-item">
              <label className="field-group-label" htmlFor="in">
                Tipo de estabelecimento
              </label>
              <InputText
                id="in"
                placeholder={data?.tipoEstabelecimento.dsTipoEstabelecimento}
                disabled={true}
              />
            </span>
            <span className="field-group-item">
              <label className="field-group-label" htmlFor="in">
                Tipo de cliente
              </label>
              <InputText
                id="in"
                placeholder={data?.tipoPessoa.dsTipoPessoa}
                disabled={true}
              />
            </span>
          </div>
          <>
            <div className="title-field">
              <p>Dados</p>
            </div>
            <div className="field-group-container">
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  CNPJ / CPF
                </label>
                <InputText
                  id="in"
                  placeholder={cpfCnpjMask(numeroDocumento)}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Razão Social / Nome
                </label>
                <InputText
                  id="in"
                  placeholder={data?.nmCliente}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  E-mail
                </label>
                <InputText
                  id="in"
                  placeholder={data?.txEmail}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Celular
                </label>
                <InputText
                  id="in"
                  placeholder={cellMask(data?.txCelular)}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Telefone
                </label>
                <InputText
                  id="in"
                  placeholder={phoneMask(data?.txTelefone)}
                  onChange={handleInput}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Nome Fantasia
                </label>
                <InputText
                  id="in"
                  placeholder={data?.nmFantasia}
                  onChange={handleInput}
                  disabled={true}
                />
              </span>
            </div>
          </>
          <>
            <div className="title-field">
              <p>Endereço de Cadastro</p>
            </div>
            <div className="field-group-container">
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  CEP
                </label>
                <InputText
                  id="in"
                  placeholder={cepMask(data?.enderecos[0]?.txCep)}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Logradouro
                </label>
                <InputText
                  id="in"
                  placeholder={data?.enderecos[0]?.txLogradouro}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Número
                </label>
                <InputText
                  id="in"
                  placeholder={data?.enderecos[0]?.txNumero}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Bairro
                </label>
                <InputText
                  id="in"
                  placeholder={data?.enderecos[0]?.nmBairro}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Cidade
                </label>
                <InputText
                  id="in"
                  placeholder={data?.enderecos[0]?.nmCidade}
                  onChange={handleInput}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  UF
                </label>
                <InputText
                  id="in"
                  placeholder={data?.enderecos[0]?.cdUf}
                  onChange={handleInput}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Complemento
                </label>
                <InputText
                  id="in"
                  placeholder={data?.enderecos[0]?.txComplemento}
                  onChange={handleInput}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Ponto de Referência
                </label>
                <InputText
                  id="in"
                  placeholder={data?.enderecos[0]?.txPontoReferencia}
                  onChange={handleInput}
                  disabled={true}
                />
              </span>
            </div>
          </>
          <>
            <div className="title-field">
              <p>Endereço de Entrega</p>
            </div>
            <div className="field-group-container">
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  CEP
                </label>
                <InputText
                  id="in"
                  placeholder={cepMask(data?.enderecos[1]?.txCep)}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Logradouro
                </label>
                <InputText
                  id="in"
                  placeholder={data?.enderecos[1]?.txLogradouro}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Número
                </label>
                <InputText
                  id="in"
                  placeholder={data?.enderecos[1]?.txNumero}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Bairro
                </label>
                <InputText
                  id="in"
                  placeholder={data?.enderecos[1]?.nmBairro}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Cidade
                </label>
                <InputText
                  id="in"
                  placeholder={data?.enderecos[1]?.nmCidade}
                  onChange={handleInput}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  UF
                </label>
                <InputText
                  id="in"
                  placeholder={data?.enderecos[0]?.cdUf}
                  onChange={handleInput}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Complemento
                </label>
                <InputText
                  id="in"
                  placeholder={data?.enderecos[1]?.txComplemento}
                  onChange={handleInput}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Ponto de Referência
                </label>
                <InputText
                  id="in"
                  placeholder={data?.enderecos[1]?.txPontoReferencia}
                  onChange={handleInput}
                  disabled={true}
                />
              </span>
            </div>
          </>
        </>
      )}
    </>
  );
};

export default ApprovalApp;
