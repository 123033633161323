import React, { Dispatch, SetStateAction } from 'react';
import './style.css';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { COLORS } from '../../constants';

interface InputFilter {
  label: string;
  type: string;
  onChange: Dispatch<SetStateAction<string>>;
  value: string;
}

interface FilterProps {
  inputs: InputFilter[];
  handleClickFilter: ()=> void;
  handleClickClean: ()=> void;
}

const Filter: React.FC<FilterProps> = ({ inputs, handleClickFilter, handleClickClean }) => {
  return (
    <section style={{ background: COLORS.white }} className='secFilter'>
      <div className='divCard'>
        {inputs.length <= 3 &&
          inputs.map((input, i) => {
            switch (input.type) {
              case 'text':
                return (
                  <div className='card' key={i}>
                    <label>{input.label}</label>
                    <InputText
                      type={input.type}
                      value={input.value}
                      onChange={(e) => input.onChange(e.target.value)}
                    />
                  </div>
                );
              default:
                return null;
            }
          })}
      </div>
      <div>
      <Button
          label='Limpar filtros'
          style={{ color: COLORS.primaryGreen }}
          className='p-button-outlined'
          onClick={handleClickClean}
        />
        <Button
          label='Pesquisar'
          style={{ color: COLORS.primaryGreen }}
          className='p-button-outlined'
          onClick={handleClickFilter}
        />
      </div>
    </section>
  );
};
export default Filter;
