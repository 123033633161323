import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { asyncSignIn } from "../../store/auth/auth.actions";
import {
  isAuthenticated,
  login,
  setIdUsuario,
  setUserEmail,
} from "../../store/auth/token.service";

import headerImg from "../../assets/images/logo-guaracamp.png";

import "./styles.css";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { RootState } from "../../store";
import { Password } from "primereact/password";
import { updateToaster } from "../../store/helper/helper.store";
import { ToasterTypes } from "../../enums/ToasterTypes";
import ProgressLoad from "../../components/ProgressLoad";
import { updateLoading } from "../../store/loading/loading.store";
import { updateUserAuth } from "../../store/auth/auth.store";
import Oval from "react-loader-spinner/dist/loader/Oval";
import GuaracampAPI from "../../services/GuaracampAPI";
import { useHistory } from "react-router-dom";

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loginUser, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [spanEmailError, setSpanEmailError] = useState<boolean>(false);
  const [spanPasswordError, setSpanPasswordError] = useState<boolean>(false);

  const { loading } = useSelector((state: RootState) => state.loading);

  useEffect(() => {
    dispatch(updateLoading(false));
  }, [dispatch]);

  const handleLogin = async () => {
    if (loginUser === "") {
      setSpanEmailError(true);
      setSpanPasswordError(false);
    } else if (password === "") {
      setSpanPasswordError(true);
      setSpanEmailError(false);
    } else {
      dispatch(updateLoading(true));
      setSpanEmailError(false);
      setSpanPasswordError(false);

      const result: any = await asyncSignIn({
        email: loginUser,
        senha: password,
        app: false,
      })
        .then((value: any) => {
          login(value.data.data.token);
          setUserEmail(value.data.data.email);
          setIdUsuario(value.data.data.idUsuario);
          dispatch(updateUserAuth(value.data.data));
          window.location.href = "/";
        })
        .catch((error: any) => {
          dispatch(updateLoading(true));
          if (error.response.data.errors?.email?.length > 0) {
            if (error.response.data.errors?.email) {
              dispatch(updateLoading(false));
              for (let errorMessage of error.response.data.errors?.email) {
                dispatch(
                  updateToaster({
                    type: ToasterTypes.error,
                    title: "Erro",
                    message: errorMessage,
                  })
                );
              }
            } else {
              dispatch(
                updateToaster({
                  type: ToasterTypes.error,
                  title: "Erro",
                  message: "Insira seu e-mail para realizar o acesso.",
                })
              );
            }
          } else if (error.response.data.errors?.senha?.length > 0) {
            if (error.response.data.errors?.senha) {
              dispatch(updateLoading(false));
              for (let errorMessage of error.response.data.errors?.senha) {
                dispatch(
                  updateToaster({
                    type: ToasterTypes.error,
                    title: "Erro",
                    message: errorMessage,
                  })
                );
              }
            } else {
              dispatch(
                updateToaster({
                  type: ToasterTypes.error,
                  title: "Erro",
                  message: "Insira sua senha para realizar o acesso.",
                })
              );
            }
          } else {
            dispatch(updateLoading(false));
            dispatch(
              updateToaster({
                type: ToasterTypes.error,
                title: "Erro",
                message: error.response.data.erros[0]?.message,
              })
            );
          }
        });
    }
  };

  return isAuthenticated() ? (
    <Redirect to={{ pathname: "/" }} />
  ) : (
    <div className="login-container">
      <div className="login-left">
        <h1>Administrativo</h1>
      </div>
      <div className="login-right">
        <div className="login-right-content">
          <img src={headerImg} alt="Logo Guaracamp" className="headerImg" />
          {loading ? (
            <div style={{ margin: "200px 0px" }}>
              <Oval color="#3dae2b" height={40} width={40} />
            </div>
          ) : (
            <div className="board-inputs-login">
              <div className="login-content">
                <InputText
                  placeholder="Login"
                  type="text"
                  value={loginUser}
                  onChange={(e) => setLogin(e.target.value)}
                  className="input-login"
                  style={{ marginBottom: "0px" }}
                />
                {spanEmailError && (
                  <span className="p-error-login">O e-mail é obrigatório.</span>
                )}
              </div>
              <div className="password-content">
                <Password
                  toggleMask
                  placeholder="Senha"
                  type="text"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="input-login"
                  feedback={false}
                  style={{ marginBottom: "0px" }}
                />
                {spanPasswordError && (
                  <span className="p-error-login">Senha requerida.</span>
                )}
              </div>

              <Button
                label="Entrar"
                style={{ marginTop: "0px" }}
                onClick={handleLogin}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
