import React, { useState } from "react";
import { Button } from "primereact/button";
import "./styles.css";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import DefaultTextarea from "../../../components/DefaultTextArea";
import { confirmDialog } from "primereact/confirmdialog";
import { asyncPostNotification } from "../../../store/comercial/comercial.actions";
import DefaultEditor from "../../../components/DefaultEditor";

const Notifications: React.FC = () => {
  const dispatch = useDispatch();
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  const comercial = useSelector((state: RootState) => state.comercial);

  const handleCancelSendNotification = () => {
    setNotificationTitle('')
    setNotificationText('')
  };

  const handleSendNotification = async () => {
    setButtonLoading(true);

    try {
      const email = await localStorage.getItem("@USER_EMAIL_GUARACAMP");

      const notification = {
        origem: "admin/manual",
        usuarioCriacao: email,
        assunto: notificationTitle,
        mensagem: notificationText,
        email: false,
        sms: false,
        push: false,
        notas: true,
        NotasRemetente: email,
      };

      dispatch(asyncPostNotification(notification));
      setNotificationTitle("");
      setNotificationText("");
    } catch {
    } finally {
      setTimeout(() => {
        setButtonLoading(false);
      }, 200);
    }
  };

  const handleNotification = () => {
    confirmDialog({
      header: "Tem certeza que deseja enviar essa notificação?",
      message:
        "Após a confirmação, o envio não poderá ser cancelado e a notificação será enviada para todos usuários do aplicativo de vendas do cliente.",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: handleSendNotification,
      reject: handleCancelSendNotification,
      acceptLabel: "Confirmar",
      rejectLabel: "Cancelar",
      style: { width: "50%" },
    });
  };

  return (
    <div className="main-page">
      <p className="title">Notificações</p>
      <section className="notifications-container">
        <div className="notifications-button-save">
          <Button
            label="Enviar"
            onClick={handleNotification}
            className="btn-send-notifications"
            loading={buttonLoading}
          />
        </div>
        <div className="notifications-textarea">
          <div className="notifications-textarea-title">
            <h4 style={{ marginBottom: "8px" }}>Assunto</h4>            
            <DefaultEditor  value={notificationTitle} onTextChange={(e: any) => setNotificationTitle(e.htmlValue)} /> 
          </div>

          <div className="notifications-textarea-text">
            <h4 style={{ marginBottom: "8px" }}>Mensagem</h4>
            <DefaultEditor  value={notificationText} onTextChange={(e: any) => setNotificationText(e.htmlValue)} />      
          </div>
        </div>
      </section>
    </div>
  );
};

export default Notifications;
