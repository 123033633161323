import { useEffect } from "react";
import "./style.css";
import { COLORS } from "../../../constants";

import { Button } from "primereact/button";

import CardUploadImg from "../../../components/Uploads/CardUploadImg";

//Usando Redux
import { useDispatch, useSelector } from "react-redux";

import {
  modifiedCardUpload,
  trashCardUpload,
} from "../../../store/marketing/marketing.store";
import { RootState } from "../../../store";

import {
  asyncGetCardsUpload,
  asyncPostCardsUpload,
} from "../../../store/marketing/marketing.actions";
import getBase64 from "../../../util/images";
import ProgressLoad from "../../../components/ProgressLoad";
import { updateLoading } from "../../../store/loading/loading.store";

const BannerCarrossel: React.FC = () => {
  const dispatch = useDispatch();

  const marketing = useSelector((state: RootState) => state.marketing);
  const { loading } = useSelector((state: RootState) => state.loading);

  useEffect(() => {
    dispatch(updateLoading(true));
    dispatch(asyncGetCardsUpload());
  }, [dispatch]);

  const setFile = async (file: string, id: number, url: string) => {
    const imgBase64Convert = await getBase64(file[0]);

    const copyData = {
      id: id,
      txUrl: url,
      txImagemBase64: imgBase64Convert,
    };

    dispatch(modifiedCardUpload(copyData));
  };

  const onClickTrash = (id: number, url: string) => {
    const copyData = {
      id: id,
      txUrl: url,
      txImagemBase64: "",
    };
    dispatch(trashCardUpload(copyData));
  };

  const handleSubmit = () => {
    if (marketing.cardsUpload.length > 0 && !loading) {
      dispatch(updateLoading(true));
      dispatch(asyncPostCardsUpload(marketing.cardsUpload));
    }
  };

  return (
    <main className="main-page">
      <p className="title">Banner Carrossel</p>
      <section className="secInfoBanner" style={{ background: COLORS.white }}>
        <p>
          Arraste as imagens para definir a ordem de exibição{" "}
          <i
            style={{ transform: "rotate(90deg)" }}
            className="pi pi-sort-alt"
          />
        </p>
        <Button label="Salvar" onClick={handleSubmit} className="btn-salvar" />
      </section>
      <section className="secCardsImgsBanner">
        {loading ? (
          <div style={{ margin: "200px 0px" }}>
            <ProgressLoad />
          </div>
        ) : marketing.cardsUpload.length > 0 ? (
          <div
            className="cardsUploadImgBanner"
            style={{ background: COLORS.white }}
          >
            {marketing.cardsUpload.map((cardUpload) => (
              <CardUploadImg
                id={cardUpload.id}
                key={cardUpload.id}
                txUrl={cardUpload.txUrl}
                onClickTrash={onClickTrash}
                txImagemBase64={cardUpload.txImagemBase64}
                setFile={setFile}
              />
            ))}
          </div>
        ) : (
          <div className="divNotFind">
            <p>Não foi encontrado nenhum registro.</p>
          </div>
        )}
      </section>
    </main>
  );
};

export default BannerCarrossel;
