import "./style.css";
import { useDropzone } from "react-dropzone";
import {
  addCardsUpload,
  updateCardsPositionDestiny,
  updateCardsPositionOrigin,
} from "../../../store/marketing/marketing.store";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";

interface CardUploadImgProps {
  txImagemBase64: string;
  txUrl: string;
  setFile: (acceptedFiles: any, id: number, txUrl: string) => void;
  onClickTrash: (id: number, txUrl: string) => void;
  id: number;
}

const CardUploadImg: React.FC<CardUploadImgProps> = ({
  txImagemBase64,
  txUrl,
  id,
  setFile,
  onClickTrash,
}) => {
  const dispatch = useDispatch();

  const marketing = useSelector((state: RootState) => state.marketing);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles, id, txUrl);
    },
  });

  const onDragDrop = {
    onDragEnter: (e: any) => {
      dispatch(updateCardsPositionDestiny(e.target.id));
    },
    onDragStart: (e: any) => {
      dispatch(updateCardsPositionOrigin(e.target.id));
    },
    onDragEnd: (e: any) => {
      // Copia lista de imagens
      let copy = [...marketing.cardsUpload];

      // Pega os index de origin e destino
      const indexOrigin = copy.findIndex(
        (item) => String(item.id) === marketing.cardPosition.origin
      );
      const indexDestiny = copy.findIndex(
        (item) => String(item.id) === marketing.cardPosition.destiny
      );

      // Faz a troca de imagens
      copy[indexOrigin] = {
        ...copy[indexOrigin],
        txImagemBase64: marketing.cardsUpload[indexDestiny].txImagemBase64,
      };
      copy[indexDestiny] = {
        ...copy[indexDestiny],
        txImagemBase64: marketing.cardsUpload[indexOrigin].txImagemBase64,
      };

      dispatch(addCardsUpload(copy));
    },
  };

  return (
    <div className="cardUploadBanner">
      <div className="cardUploadTitle">
        <p>{txUrl}</p>
      </div>
      {txImagemBase64 ? (
        <div
          className="cardUploadImg"
          draggable={true}
          id={String(id)}
          {...onDragDrop}
          style={{ background: "txUrl()" }}
        >
          <img
            src={
              txImagemBase64.substring(0, 10) === "data:image"
                ? txImagemBase64
                : "https://api.guaracamp.com.br/" + txImagemBase64
            }
            draggable={true}
            id={String(id)}
            {...onDragDrop}
            className="uploadImg"
            alt={txUrl}
          />
          <i
            className="pi pi-trash iconTrash"
            onClick={() => onClickTrash(id, txUrl)}
            style={{ cursor: "pointer" }}
          />
        </div>
      ) : (
        <div
          className="dropzone cardUploadImg"
          draggable={true}
          id={String(id)}
          {...onDragDrop}
          {...getRootProps()}
          style={{ cursor: "pointer" }}
        >
          <img
            src={""}
            draggable={true}
            id={String(id)}
            {...onDragDrop}
            style={{
              opacity: 0,
              objectFit: "cover",
              width: "100%",
              height: "100%",
            }}
            alt={txUrl}
          />
          <input {...getInputProps()} />
          <i className="pi pi-trash iconTrash" />
        </div>
      )}
    </div>
  );
};
export default CardUploadImg;
