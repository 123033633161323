import { Checkbox } from "primereact/checkbox";
import { PromotionItensProps } from "../../../../store/comercial/comercial.store";
import { InputText } from "primereact/inputtext";
import { SelectOptions } from "../../../DefaultSelect";
import DefaultSelectPromotion from "../../../DefaultSelectPromotion";
import NumberFormat from "react-number-format";

interface ItensListProps {
  view?: boolean;
  // inputs: any[];
  checkboxes?: PromotionItensProps;
  cod?: number;
  idProduto?: number;
  handleDelete?: (id: any) => void;
  handleCheckboxes: (e: any) => void;
  handleSelect: (e: any) => void;
  handleAmountDiscount: (e: any) => void;
  handleQuantity: (e: any) => void;
  itens: PromotionItensProps[];
  errors?: any;
}

const ItensListPromotion = ({
  view,
  handleCheckboxes,
  handleSelect,
  handleAmountDiscount,
  handleQuantity,
  itens,
  errors,
}: ItensListProps) => {
  const options: SelectOptions[] = [
    {
      name: "Porcentagem",
      value: 1,
    },
    {
      name: "Valor",
      value: 2,
    },
  ];

  return (
    <>
      {itens?.map((item, i) => {
        return (
          <div className="sidebar-promotion-itens-content-list" key={i}>
            <div className="itens-list-row1" key={i}>
              <Checkbox
                checked={item.checked}
                value={item.idProduto}
                onChange={handleCheckboxes}
                disabled={view}
              />
              <label htmlFor="product">{item.dsProduto}</label>
            </div>
            <div className="itens-list-row2">
              <div className="div-separate-input-error">
                <InputText
                  name={"quantidade" + item.idProduto}
                  type="number"
                  className="sidebar-promotion-itens-content-input"
                  disabled={!item.checked || view}
                  id={item.idProduto.toString()}
                  value={item.qtItemPromocao}
                  onChange={handleQuantity}
                  min={0}
                />
                {errors["allItens[" + i + "].quantidade"] && (
                  <span style={{ fontSize: "12px", color: "red", margin: "0" }}>
                    {errors["allItens[" + i + "].qtItemPromocao"]}
                  </span>
                )}
              </div>
            </div>

            <div className="itens-list-row3">
              <div className="div-separate-input-error">
                <DefaultSelectPromotion
                  name={"idTipoDesconto" + item.idProduto}
                  label=""
                  options={options}
                  onChange={handleSelect}
                  idItem={item.idProduto.toString()}
                  value={item.idTipoDesconto}
                  disabled={!item.checked || view}
                />
                {errors["allItens[" + i + "].idTipoDesconto"] && (
                  <span
                    style={{
                      fontSize: "12px",
                      color: "red",
                      margin: "0",
                    }}
                  >
                    {errors["allItens[" + i + "].idTipoDesconto"]}
                  </span>
                )}
              </div>
            </div>

            <div className="itens-list-row4">
              <div className="div-separate-input-error">
                <InputText
                  name={"valor" + item.idProduto}
                  type="number"
                  className="sidebar-promotion-itens-content-input"
                  disabled={!item.checked || view}
                  id={item.idProduto.toString()}
                  value={item.vlItemPromocao}
                  onChange={handleAmountDiscount}
                  min={0}
                />
                {errors["allItens[" + i + "].vlItem"] && (
                  <span style={{ fontSize: "12px", color: "red", margin: "0" }}>
                    {errors["allItens[" + i + "].vlItem"]}
                  </span>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ItensListPromotion;
