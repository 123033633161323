import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckboxDefault from "../../../../components/CheckboxDefault";
import DefaultInput from "../../../../components/DefaultInput";
import { COLORS } from "../../../../constants";
import { RootState } from "../../../../store";
import {
  asyncGetUserById,
  asyncGetUsers,
  asyncUpdateUser,
} from "../../../../store/admin/admin.actions";
import { ProfileProps } from "../../../../store/admin/admin.store";
import * as Yup from "yup";
import getValidationErrors from "../../../../util/validationError";
import { StateProps } from "../../../../enums";
import ProgressLoad from "../../../../components/ProgressLoad";
import { confirmDialog } from "primereact/confirmdialog";
import { updateToaster } from "../../../../store/helper/helper.store";
import { ToasterTypes } from "../../../../enums/ToasterTypes";

interface UserEditProps {
  id: number;
}

const UserEdit: React.FC<UserEditProps> = ({ id }) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [nmUsuario, setNmUsuario] = useState("");
  const [txEmail, setTxEmail] = useState("");
  const [perfis, setPerfis] = useState<ProfileProps[]>([]);
  const [user, setUser] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errors, setErrors] = useState({} as any);
  const [userDisabled, setUserDisabled] = useState<any>();

  const admin = useSelector((state: RootState) => state.admin);

  const getUser = async () => {
    setLoading(true);

    try {
      const response = await asyncGetUserById(id);
      setUser(response);

      setVisible(true);
    } catch {
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  useEffect(() => {
    const markCheckboxes = () => {
      const result = [...perfis];
      for (let i = 0; i < result.length; i++) {
        for (let j = 0; j < user?.perfis.length; j++) {
          if (result[i]?.idItem === user?.perfis[j]?.idPerfil) {
            if (result[i]) {
              result[i] = { ...result[i], checked: true };
            }
          }
        }
      }
      setPerfis(result);
    };
    markCheckboxes();
  }, [user?.perfis]);

  useEffect(() => {
    let result = user;
    result = { ...result, nmUsuario: nmUsuario };
    setUser(result);
  }, [nmUsuario]);

  useEffect(() => {
    let result = user;
    result = { ...result, txEmail: txEmail };
    setUser(result);
  }, [txEmail]);

  useEffect(() => {
    setPerfis(admin.profilesList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admin.profilesList]);

  const handleSubmit = async () => {
    setErrors({} as StateProps);
    setButtonLoading(true);

    try {
      const userPerfil: any = [];
      perfis.map((module) => {
        if (module.checked)
          userPerfil.push({
            idPerfil: module.idItem,
          });
      });
      //retorno dentro do if, por isso da o erro de prototype. (precisa ter retorno)

      const UserEdit = {
        id: user?.id,
        nmUsuario: user?.nmUsuario,
        txEmail: user?.txEmail,
        perfis: userPerfil,
      };

      const schema = Yup.object().shape({
        txEmail: Yup.string().required("Username obrigatório."),
        nmUsuario: Yup.string().required("Nome obrigatório."),
      });

      await schema.validate(user, {
        abortEarly: false,
      });

      if (userPerfil.length === 0) {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: "Perfil obrigatório.",
          })
        );
        return;
      }

      dispatch(asyncUpdateUser(UserEdit));
      dispatch(asyncGetUsers());
      setVisible(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrors(getValidationErrors(err));
        return;
      }
    } finally {
      setTimeout(() => {
        setButtonLoading(false);
      }, 1000);
    }
  };

  const handleCancelAccept = () => {
    setVisible(false);
    setErrors("");
  };

  const handleCancelReject = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    confirmDialog({
      message: "Você tem certeza que deseja fechar sem salvar a edição?",
      header: "Confirmação de edição de dados",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: handleCancelAccept,
      reject: handleCancelReject,
      acceptLabel: "Sim",
      rejectLabel: "Não",
      style: { width: "60%" },
    });
  };

  const handleCheckboxes = (event: any) => {
    const index = perfis.findIndex((chk) => chk.idItem === event.target.value);
    const newArr = [...perfis];
    newArr[index] = { ...newArr[index], checked: !newArr[index].checked };
    setPerfis(newArr);
  };

  const idUsuario = Number(localStorage.getItem("@USER_ID_GUARACAMP"));

  const getUserDisabled = async () => {
    const response = await asyncGetUserById(idUsuario);
    setUserDisabled(response);
  };

  useEffect(() => {
    if (idUsuario) {
      getUserDisabled();
    }
  }, []);

  const administrativePerfil = userDisabled?.perfis.find(
    (perfil: any) => perfil.idPerfil === 74
  );

  return (
    <div className="sidebarv2-wrapper">
      <Button
        icon="pi pi-pencil"
        onClick={getUser}
        className="p-button-rounded p-button-warning p-button-outlined"
        disabled={administrativePerfil === undefined}
      />
      <Sidebar
        className="sidebar-users-container"
        visible={visible}
        position="right"
        onHide={handleCancel}
      >
        <div className="sidebar-users-content-top">
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-danger p-button-text"
            onClick={handleCancel}
          />
          <p
            style={{ color: COLORS.primaryGreen }}
            className="sidebar-users-title"
          >
            Edição de Usuário
          </p>
          <div className="sidebar-users-inputs">
            <DefaultInput
              label="Username(Email)"
              value={user?.txEmail}
              onChange={setTxEmail}
              disabled={false}
              error={errors.txEmail}
            />
            <DefaultInput
              label="Nome"
              value={user?.nmUsuario}
              onChange={setNmUsuario}
              disabled={false}
              error={errors.nmUsuario}
            />
          </div>
          <p className="sidebar-users-checkbox-title">
            Defina os perfis do usuário
          </p>
          <div className="sidebar-users-checkboxes">
            {loading ? (
              <div className="divNotFind">
                <ProgressLoad />
              </div>
            ) : admin.users.length === 0 ? (
              <div className="divNotFind">
                <p>Não foi encontrado nenhum registro.</p>
              </div>
            ) : (
              perfis.map((module, i) => (
                <CheckboxDefault
                  key={i}
                  checkbox={module}
                  disabled={false}
                  handleCheckboxes={handleCheckboxes}
                />
              ))
            )}
          </div>
        </div>

        <div className="sidebar-users-content-bottom">
          <div className="sidebar-users-actions">
            <Button
              label="Cancelar"
              className="p-button-outlined p-button-secondary"
              onClick={handleCancel}
              style={{ marginRight: "20px", width: "40%" }}
            />
            <Button
              id="button"
              label="Salvar"
              className="p-button-lg"
              onClick={handleSubmit}
              style={{ width: "40%" }}
              loading={buttonLoading}
            />
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default UserEdit;
