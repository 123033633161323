import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { updateLoading } from "../../../store/loading/loading.store";
import {
  asyncClearFilterAcumulation,
  asyncGetClasses,
  asyncGetGrupos,
  asyncGetPointsAccumulation,
  asyncGetSabores,
  asyncGetSubGrupos,
  asyncGetTiposProduto,
  asyncPutPointsAccumulation,
  newCardSavePutPointsAccumulation,
} from "../../../store/marketing/marketing.actions";
import {
  AcumuloDePontosProps,
  updateAcumuloDePontos,
} from "../../../store/marketing/marketing.store";
import "./style.css";
import { COLORS } from "../../../constants";
import { DataScroller } from "primereact/datascroller";
import ProgressLoad from "../../../components/ProgressLoad";
import DefaultSelectPromotion from "../../../components/DefaultSelectPromotion";
import { InputText } from "primereact/inputtext";
import { SelectOptions } from "../../../components/DefaultSelect";

const AcumuloDePontos: React.FC = () => {
  const dispatch = useDispatch();

  const [selectTipo, setSelectTipo] = useState<any>("");
  const [selectGrupo, setSelectGrupo] = useState<any>("");
  const [selectSubGrupo, setSelectSubGrupo] = useState<any>("");
  const [selectClasse, setSelectClasse] = useState<any>("");
  const [selectSabor, setSelectSabor] = useState<any>("");

  const { loading } = useSelector((state: RootState) => state.loading);
  const marketing = useSelector((state: RootState) => state.marketing);

  const options: SelectOptions[] = [
    {
      name: "Valor",
      value: 1,
    },
    {
      name: "Quantidade",
      value: 2,
    },
  ];

  const handdleSubmitSelect = () => {
    dispatch(updateLoading(true));

    dispatch(
      asyncGetPointsAccumulation(
        selectTipo.id,
        selectGrupo.id,
        selectSubGrupo.id,
        selectClasse.id,
        selectSabor.id
      )
    );
  };

  const handdleClear = () => {
    dispatch(updateLoading(true));
    setSelectTipo("");
    setSelectGrupo("");
    setSelectSubGrupo("");
    setSelectClasse("");
    setSelectSabor("");
    dispatch(updateAcumuloDePontos(""));
    dispatch(asyncClearFilterAcumulation());
  };

  const handleSelect = (e: any) => {
    const id = parseInt(e.target.id);

    const index = marketing.acumuloDePontosList.findIndex(
      (item) => item.idProduto === id
    );

    const newArr = [...marketing.acumuloDePontosList];

    newArr[index] = {
      ...newArr[index],
      idTipoPontoRegraPontuacao: e.target.value,
    };

    dispatch(updateAcumuloDePontos(newArr));
  };

  const handleVlItem = (e: any) => {
    const id = parseInt(e.target.id);

    const index = marketing.acumuloDePontosList.findIndex(
      (item) => item.idProduto === id
    );

    const newArr = [...marketing.acumuloDePontosList];

    if (
      Number(e.target.value) > 0 ||
      e.target.value === "" ||
      e.target.value === null
    ) {
      newArr[index] = {
        ...newArr[index],
        vlItem: Math.abs(parseInt(e.target.value)),
      };
    }

    dispatch(updateAcumuloDePontos(newArr));
  };

  const handleQtPonto = (e: any) => {
    const id = parseInt(e.target.id);

    const index = marketing.acumuloDePontosList.findIndex(
      (item) => item.idProduto === id
    );

    const newArr = [...marketing.acumuloDePontosList];

    if (Number(e.target.value) > 0 || e.target.value === "") {
      newArr[index] = {
        ...newArr[index],
        qtPonto: Math.abs(parseInt(e.target.value)),
      };
    }
    dispatch(updateAcumuloDePontos(newArr));
  };
  console.log("passei aqui fora", handleQtPonto);

  const handleClickSave = () => {
    if (marketing.acumuloDePontosList.length > 0 && !loading) {
      dispatch(updateLoading(true));
      const arrayPointsAccumulation: newCardSavePutPointsAccumulation[] = [];

      for (let card of marketing.acumuloDePontosList) {
        arrayPointsAccumulation.push({
          id: card.id,
          descricao: card.descricao ? card.descricao : "",
          idProduto: card.idProduto,
          idTipoPontoRegraPontuacao: card.idTipoPontoRegraPontuacao
            ? card.idTipoPontoRegraPontuacao
            : 0,
          vlItem: card.vlItem ? card.vlItem : 0,
          qtPonto: card.qtPonto ? card.qtPonto : 0,
        });
      }

      if (arrayPointsAccumulation.length > 0 && !loading) {
        dispatch(asyncPutPointsAccumulation(arrayPointsAccumulation));
      }

      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  };

  useEffect(() => {
    dispatch(asyncGetTiposProduto());
    dispatch(asyncGetGrupos());
    dispatch(asyncGetSubGrupos());
    dispatch(asyncGetClasses());
    dispatch(asyncGetSabores());
    dispatch(
      asyncGetPointsAccumulation(
        selectTipo.id,
        selectGrupo.id,
        selectSubGrupo.id,
        selectClasse.id,
        selectSabor.id
      )
    );
  }, [dispatch]);

  return (
    <main className="main-page">
      <p className="title">Acúmulo de pontos</p>
      <section className="notifications-container-filters">
        <div>
          <p className="dropdown-label">Tipo</p>
          <Dropdown
            value={selectTipo}
            emptyMessage="Não foi encontrado nenhum registro."
            options={marketing.selectsOptions.tipos}
            onChange={(e) => setSelectTipo(e.value)}
            className="TreeSelect"
          />
        </div>
        <div>
          <p className="dropdown-label">Grupo</p>
          <Dropdown
            value={selectGrupo}
            emptyMessage="Não foi encontrado nenhum registro."
            options={marketing.selectsOptions.grupos}
            onChange={(e) => setSelectGrupo(e.value)}
            className="TreeSelect"
          />
        </div>
        <div>
          <p className="dropdown-label">SubGrupo</p>
          <Dropdown
            value={selectSubGrupo}
            emptyMessage="Não foi encontrado nenhum registro."
            options={marketing.selectsOptions.subGrupos}
            onChange={(e) => setSelectSubGrupo(e.value)}
            className="TreeSelect"
          />
        </div>
        <div>
          <p className="dropdown-label">Classe</p>
          <Dropdown
            value={selectClasse}
            emptyMessage="Não foi encontrado nenhum registro."
            options={marketing.selectsOptions.classes}
            onChange={(e) => setSelectClasse(e.value)}
            className="TreeSelect"
          />
        </div>
        <div>
          <p className="dropdown-label">Sabor</p>
          <Dropdown
            value={selectSabor}
            emptyMessage="Não foi encontrado nenhum registro."
            options={marketing.selectsOptions.sabores}
            onChange={(e) => setSelectSabor(e.value)}
            className="TreeSelect"
          />
        </div>
        <div className="divBtnImages">
          <Button
            label="Limpar filtros"
            style={{ color: COLORS.primaryGreen }}
            className="p-button-outlined btnSelect"
            onClick={handdleClear}
          />
          <Button
            label="Listar produtos"
            style={{ color: COLORS.primaryGreen }}
            className="p-button-outlined btnSelect"
            onClick={handdleSubmitSelect}
          />
        </div>
      </section>
      <div className="btnCardImages">
        {marketing.acumuloDePontosList.length === 0 ? (
          <Button
            label="Salvar"
            onClick={handleClickSave}
            className="btn-salvar"
            disabled
          />
        ) : (
          <Button
            label="Salvar"
            onClick={handleClickSave}
            className="btn-salvar"
          />
        )}
      </div>
      {marketing.acumuloDePontosList.length === 0 ? (
        <div className="acumulo-header-table-none">
          <p>Produtos</p>
          <p>Tipo de acúmulo</p>
          <p>Quantidade/Valor</p>
          <p>Pontos</p>
        </div>
      ) : (
        <div className="acumulo-header-table">
          <p>Produtos</p>
          <p>Tipo de acúmulo</p>
          <p>Quantidade/Valor</p>
          <p>Pontos</p>
        </div>
      )}
      <section
        style={{ background: COLORS.white }}
        className="sectionCardsImages"
      >
        <div className="card">
          {loading ? (
            <div className="divNotFind">
              <ProgressLoad />
            </div>
          ) : marketing.acumuloDePontosList.length === 0 ? (
            <div className="divNotFind">
              <p>Não foi encontrado nenhum registro.</p>
            </div>
          ) : (
            marketing.acumuloDePontosList.map((card, i) => (
              <div key={i}>
                <div className="acumulo-card-container">
                  <div className="acumulo-card-container-label">
                    <label htmlFor="product">{card.descricao}</label>
                  </div>

                  <div className="acumulo-card-container-tipo">
                    <DefaultSelectPromotion
                      name="tipoAcumulo"
                      label=""
                      options={options}
                      onChange={handleSelect}
                      value={card.idTipoPontoRegraPontuacao}
                      idItem={card.idProduto.toString()}
                    />
                  </div>
                  <div className="acumulo-card-container-vlItem">
                    <InputText
                      name={"quantidade" + card.idProduto}
                      type="number"
                      className={
                        card.vlItem === 0
                          ? "acumuloDePontos-input-quantidade-zero"
                          : "acumuloDePontos-input-quantidade"
                      }
                      id={card.idProduto.toString()}
                      value={card.vlItem}
                      onChange={handleVlItem}
                      min={1}
                      style={
                        card.vlItem === 0
                          ? { width: "140px", borderColor: "red" }
                          : { width: "140px" }
                      }
                    />
                    {/* {card.vlItem === 0 && <span>Obrigatório ser maior que 0.</span>} */}
                  </div>
                  <div className="acumulo-card-container-qtPonto">
                    <InputText
                      name={"pontos" + card.idProduto}
                      type="number"
                      className="acumuloDePontos-input-pontos"
                      id={card.idProduto.toString()}
                      value={card.qtPonto}
                      onChange={handleQtPonto}
                      min={1}
                      style={
                        card.qtPonto === 0
                          ? { width: "140px", borderColor: "red" }
                          : { width: "140px" }
                      }
                    />
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </section>
    </main>
  );
};

export default AcumuloDePontos;
