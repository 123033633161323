import { RadioButton } from 'primereact/radiobutton';
import { Dispatch, SetStateAction } from 'react';
import './styles.css'

interface DefaultRadioButtonProps {
    label: string;
    value: string;
    name: string;
    onChange: Dispatch<SetStateAction<string>>;
    disabled?: boolean
}

const DefaultRadioButton: React.FC<DefaultRadioButtonProps> = ({ label, value, onChange, name, disabled }) => {
    return (
        <div className="p-field-radiobutton">
            <RadioButton
                name={name}
                value={value}
                onChange={(e) => onChange(name)}
                checked={value === name}
                disabled={disabled}
            />
            <label htmlFor={name}>{ label }</label>
        </div>
    )
}

export default DefaultRadioButton
