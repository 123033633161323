import { useEffect } from "react";

import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { useState } from "react";
import DefaultInput from "../../../../components/DefaultInput";
// import '../styles.css'
import CheckboxDefault from "../../../../components/CheckboxDefault";
import { COLORS } from "../../../../constants";
import { ModulesProps } from "../../../../store/admin/admin.store";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { asyncPostProfile } from "../../../../store/admin/admin.actions";
import { StateProps } from "../../../../enums";

import * as Yup from "yup";
import getValidationErrors from "../../../../util/validationError";
import { updateLoading } from "../../../../store/loading/loading.store";

const ProfileCreate = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [dsPerfil, setDsPerfil] = useState("");
  const [modules, setModules] = useState<ModulesProps[]>([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errors, setErrors] = useState({} as any);

  const admin = useSelector((state: RootState) => state.admin);

  useEffect(() => {
    setModules(admin.modulos);
  }, [admin.modulos]);

  const handleSubmit = async () => {
    setErrors({} as StateProps);
    setButtonLoading(true);

    try {
      const profileModules: any = [];
      modules.map((module) => {
        if (module.checked)
          profileModules.push({
            idModulo: module.idItem,
          });
        return 0;
      });
      const profile = {
        dsPerfil: dsPerfil,
        modulos: profileModules,
      };
      const schema = Yup.object().shape({
        dsPerfil: Yup.string().required("Descrição obrigatória."),
        modulos: Yup.array().min(1, "Defina uma ou mais permissões."),
      });

      await schema.validate(profile, {
        abortEarly: false,
      });
      dispatch(asyncPostProfile(profile));
      setModules(admin.modulos);
      setDsPerfil("");
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setErrors(getValidationErrors(error));
        return;
      }
    } finally {
      setTimeout(() => {
        setButtonLoading(false);
        setVisible(false);
      }, 1000);
    }
  };

  const handleCheckboxes = (event: any) => {
    const index = modules.findIndex((chk) => chk.idItem === event.target.value);
    const newArr = [...modules];
    newArr[index] = { ...newArr[index], checked: !newArr[index].checked };
    setModules(newArr);
  };

  const handleCancel = () => {
    setDsPerfil("");
    setModules(admin.modulos);
    setVisible(false);
    setErrors("");
  };

  return (
    <div className="sidebarv2-wrapper">
      <Button
        label="Novo perfil"
        onClick={() => setVisible(true)}
        className="sidebarv2-open-button"
        color="#AA0061"
      />
      <Sidebar
        className="sidebar-perfis-container"
        visible={visible}
        position="right"
        onHide={handleCancel}
      >
        <div className="sidebar-perfis-content-top">
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-danger p-button-text"
            onClick={handleCancel}
          />
          <p
            style={{ color: COLORS.primaryGreen }}
            className="sidebar-perfis-title"
          >
            Cadastrar Perfil
          </p>
          <div className="sidebar-perfis-inputs">
            <DefaultInput
              label="Descrição"
              value={dsPerfil}
              onChange={setDsPerfil}
              disabled={false}
              maxLength={30}
              error={errors.dsPerfil}
            />
          </div>
          <p className="sidebar-perfis-checkbox-title">
            Defina as permissões do usuário:
          </p>
          <div className="sidebar-perfis-checkboxes">
            {modules.map((module, i) => (
              <CheckboxDefault
                key={i}
                checkbox={module}
                disabled={false}
                handleCheckboxes={handleCheckboxes}
              />
            ))}
            {errors.modulos && (
              <span style={{ fontSize: "12px", color: "red", margin: "0" }}>
                {errors.modulos}
              </span>
            )}
          </div>
        </div>
        <div className="sidebar-perfis-content-bottom">
          <div className="sidebar-perfis-actions">
            <Button
              label="Cancelar"
              className="p-button-outlined p-button-secondary"
              onClick={handleCancel}
              style={{ marginRight: "20px", width: "40%" }}
            />
            <Button
              id="button"
              label="Salvar"
              className="p-button-lg"
              onClick={handleSubmit}
              style={{ width: "40%" }}
              loading={buttonLoading}
            />
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default ProfileCreate;
