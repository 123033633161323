import { Dispatch } from "redux";
import { ToasterTypes } from "../../enums/ToasterTypes";
import GuaracampAPI from "../../services/GuaracampAPI";
import { updateToaster } from "../helper/helper.store";
import { updateLoading } from "../loading/loading.store";
import {
  addJustification,
  addPromotion,
  ClientesAppProps,
  deletePromotion,
  PromotionItensProps,
  PromotionsAbrangenciaClienteAppProps,
  PromotionsAbrangenciaTipoEstabelecimentoProps,
  updateAbrangenciaClienteApp,
  updateAbrangenciaTipoEstabelecimento,
  updateClientesApp,
  updateProducts,
  updatePromotion,
  updatePromotions,
} from "./comercial.store";

// Promotion
export const asyncGetPromotions = () => {
  return (dispatch: any) => {
    GuaracampAPI.get("/promocao")
      .then((res) => {
        dispatch(updatePromotions(res.data));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

export const asyncGetProducts = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get("/produto")
      .then((res: any) => {
        const result: PromotionItensProps[] = [];
        res.data.map((r: any) => {
          result.push({
            idProduto: r.id,
            dsProduto: r.dsProduto,
            checked: false,
            qtItemPromocao: r.qtItemPromocao,
            idTipoDesconto: r.idTipoDesconto,
            vlItemPromocao: r.vlItemPromocao,
          });
        });
        dispatch(updateProducts(result));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
    // .finally(() => {
    //   dispatch(updateLoading(false));
    // });
  };
};

export const asyncPostPromotion = (data: any) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.post("/promocao", data)
      .then((res: any) => {
        
        dispatch(addPromotion(res.data));
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Promoção criada com sucesso.",
          })
        );
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

export const asyncGetPromotionById = async (id: number) => {
  let response = null;
  await GuaracampAPI.get(`/promocao/${id}`)
    .then((res) => {
      response = res.data;
    })
    .catch((error) => {
      
    });
  return response;
};

export const asyncUpdatePromotion = (data: any) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.post("/promocao", data)
      .then((res: any) => {
        dispatch(updatePromotion(res.data));
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Promoção atualizada com sucesso.",
          })
        );
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

export const asyncDeletePromotion = (id: number) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.delete(`/promocao/${id}`)
      .then((res) => {
        dispatch(deletePromotion(id));
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Promoção deletada com sucesso.",
          })
        );
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

export const asyncGetPromotionsAbrangenciaTipoEstabelecimento = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get("/tipoestabelecimento")
      .then((res: any) => {
        const result: PromotionsAbrangenciaTipoEstabelecimentoProps[] = [];
        res.data.map((r: any) => {
          result.push({
            idTipoEstabelecimento: r.id,
            dsTipoEstabelecimento: r.dsTipoEstabelecimento,
            checked: r.checked,
          });
        });
        dispatch(updateAbrangenciaTipoEstabelecimento(result));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
    // .finally(() => {
    //   dispatch(updateLoading(false));
    // });
  };
};

export const asyncGetPromotionsAbrangenciaClienteApp = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get("/clientes/app")
      .then((res: any) => {
        const result: PromotionsAbrangenciaClienteAppProps[] = [];
        res.data.value.map((r: any) => {
          result.push({
            idClientAPP: r.id,
            nmCliente: r.nmCliente,
          });
        });
        dispatch(updateAbrangenciaClienteApp(result));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
    // .finally(() => {
    //   dispatch(updateLoading(false));
    // });
  };
};

export const asyncGetClientesApps = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get("/clientes/app")
      .then((res: any) => {
        const result: ClientesAppProps[] = [];
        res.data.value.map((r: any) => {
          result.push({
            idClienteApp: r.id,
            nmCliente: r.nmCliente,
          });
        });
        dispatch(updateClientesApp(result));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
    // .finally(() => {
    //   dispatch(updateLoading(false));
    // });
  };
};

// Notificações
export const asyncPostNotification = (data: any) => {
  return (dispatch: Dispatch) => {
    
    GuaracampAPI.post("/notificacao", data)
      .then((res: any) => {
        
        dispatch(addJustification(res.data));
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Notificação enviada com sucesso.",
          })
        );
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};
