import { useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const Toaster = ()=> {
    const toastBR = useRef<any>(null);

    const { toaster } = useSelector((state: RootState) => state.helper)
    
    const showToaster = () => {
        toastBR.current.show({severity: toaster.type, summary: toaster.title, detail: toaster.message, life: 3000});
    }

    useEffect(() => {
        if(toaster.message){
            showToaster()
        }
    }, [toaster])

    return (
        <Toast ref={toastBR} position="bottom-right"/>
    )
}

export default Toaster
