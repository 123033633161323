import { Button } from "primereact/button";
import HelpEdit from "../../../pages/Admin/Help/HelpEdit";
import HelpView from "../../../pages/Admin/Help/HelpView";
import Parser from "react-html-parser";

import "./styles.css";

interface CardListProps {
  id: number;
  question: string;
  handleDelete: (id: any) => void;
}

const CardListHelp = ({ id, question, handleDelete }: CardListProps) => {
  return (
    <div className="card-container-help">
      <div className="card-help-content">
        <h1>{Parser(question)}</h1>

        <div className="card-container-buttons-help">
          <div className="styles-buttons">
            <HelpEdit id={id} />
          </div>
          <div className="styles-buttons">
            <HelpView id={id} />
          </div>
          <div className="styles-buttons">
            <Button
              value={id}
              icon="pi pi-trash"
              className="p-button-rounded p-button-danger p-button-outlined"
              onClick={handleDelete}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardListHelp;
