import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Sidebar } from "primereact/sidebar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ItensListPromotion from "../../../../components/Comercial/Promotion/ItensList";
import DefaultInput from "../../../../components/DefaultInput";
import { COLORS } from "../../../../constants";
import getBase64 from "../../../../ConvertBase64Img";
import { RootState } from "../../../../store";
import {
  asyncGetPromotionById,
  asyncUpdatePromotion,
} from "../../../../store/comercial/comercial.actions";

import { useDropzone } from "react-dropzone";
import ProgressLoad from "../../../../components/ProgressLoad";
import * as Yup from "yup";
import getValidationErrors from "../../../../util/validationError";
import {
  ClientesAppProps,
  PromotionItensProps,
} from "../../../../store/comercial/comercial.store";
import { confirmDialog } from "primereact/confirmdialog";
import { RadioButton } from "primereact/radiobutton";
import EstablishmentsTypesPromotion from "../../../../components/Comercial/Promotion/AbrangenciaEstablishmentList";
import Select from "react-select";

interface PromotionEditProps {
  id: number;
}

export type SelectProps = {
  label: string;
  value: number;
};

const categories = [
  { name: "Todos", value: 0 },
  { name: "Tipo de estabelecimento", value: 1 },
  { name: "Cliente", value: 3 },
  // { name: "Localização", value: 3 },
];

const PromotionEdit: React.FC<PromotionEditProps> = ({ id }) => {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [dsPromocao, setDsPromocao] = useState("");
  const [dtValidadeDe, setDtValidadeDe] = useState("");
  const [dtValidadeAte, setDtValidadeAte] = useState("");
  const [inAtivo, setInAtivo] = useState<boolean>();
  const [promotion, setPromotion] = useState<any>(null);
  const [txImagemPromocao, setTxImagemPromocao] = useState("");
  const [errors, setErrors] = useState({} as any);
  const [updateItens, setUpdateItens] = useState<PromotionItensProps[]>([]);
  const [step, setStep] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [allClients, setAllClients] = useState<any>([]);
  const [clientSelected, setClientSelected] = useState<any>("");
  const [filteredClients, setFilteredClients] = useState<ClientesAppProps[]>(
    []
  );

  const { loading } = useSelector((state: RootState) => state.loading);
  const comercial = useSelector((state: RootState) => state.comercial);

  const getPromotion = async () => {
    const response: any = await asyncGetPromotionById(id);
    const checkboxesProdutos = markCheckboxes(response);
    const checkboxesTiposEstabelecimento =
      markCheckboxesTiposEstabelecimentos(response);

    checkboxesProdutos.abrangenciaTipoEstabelecimento =
      checkboxesTiposEstabelecimento.abrangenciaTipoEstabelecimento;

    if (response?.idTipoAbrangencia === null) {
      setSelectedCategory(categories[0]);
    } else if (response?.idTipoAbrangencia === 1) {
      setSelectedCategory(categories[response?.idTipoAbrangencia]);
    } else {
      setSelectedCategory(categories[response?.idTipoAbrangencia - 1]);
    }

    setFilteredClients(response?.abrangenciaClienteApp);

    setTxImagemPromocao(response?.txImagemPromocao);
    setPromotion(checkboxesProdutos);
    setVisible(true);
  };

  const deleteConfirm = (e: any) => {
    const id = e.target.value | e.target.parentNode.value;

    confirmDialog({
      message: "Você quer remover este registro?",
      header: "Confirmação de exclusão",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => handleDeleteClient(id),
      acceptLabel: "Sim",
      rejectLabel: "Não",
    });
  };

  const handleDeleteClient = (id: number) => {
    const removingClient = filteredClients.filter((client) => {
      return client?.idClienteApp !== id;
    });
    setFilteredClients(removingClient);
  };

  const handleAddToFilteredClients = () => {
    const clientsFiltered = [...filteredClients];

    const clientExist = clientsFiltered.findIndex(
      (client) => client.idClienteApp === clientSelected.value
    );

    if (clientExist < 0) {
      clientsFiltered.push({
        idClienteApp: clientSelected.value,
        nmCliente: clientSelected.label,
      });
    } else {
      return;
    }

    setFilteredClients(clientsFiltered);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles);
    },
  });

  const setFile = async (file: any) => {
    const imgBase64Convert = await getBase64(file[0]);
    setTxImagemPromocao(imgBase64Convert);
  };

  const deleteImage = () => {
    setTxImagemPromocao("");
  };

  const handleCancelAccept = () => {
    setVisible(false);
    setErrors("");
  };

  const handleCancelReject = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    confirmDialog({
      message: "Você tem certeza que deseja fechar sem salvar a edição?",
      header: "Confirmação de edição de dados",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: handleCancelAccept,
      reject: handleCancelReject,
      acceptLabel: "Sim",
      rejectLabel: "Não",
      style: { width: "60%" },
    });
  };

  const handleCheckboxes = (event: any) => {
    const newArr = { ...promotion };
    const index = newArr.itens.findIndex(
      (chk: any) => chk.idProduto == event.target.value
    );

    newArr.itens[index] = {
      ...newArr.itens[index],
      checked: !newArr.itens[index].checked,
    };

    if (newArr.itens[index].checked === false) {
      newArr.itens[index] = {
        ...newArr.itens[index],
        qtItemPromocao: "",
        idTipoDesconto: "",
        vlItemPromocao: "",
      };
    }

    setPromotion(newArr);
  };

  const handleSelect = (e: any) => {
    const index = promotion.itens.findIndex(
      (item: any) => item.idProduto == parseInt(e.target.id)
    );

    let promo = { ...promotion };
    const newArr = [...promotion.itens];
    newArr[index] = { ...newArr[index], idTipoDesconto: e.target.value };

    promo.itens = newArr;

    setPromotion(promo);
  };

  const handleAmountDiscount = (e: any) => {
    const index = promotion.itens.findIndex(
      (item: any) => item.idProduto == e.target.id
    );

    let promo = { ...promotion };
    const newArr = [...promotion.itens];
    newArr[index] = {
      ...newArr[index],
      vlItemPromocao: parseInt(e.target.value),
    };

    promo.itens = newArr;

    setPromotion(promo);
  };

  const handleQuantity = (e: any) => {
    const index = promotion.itens.findIndex(
      (item: any) => item.idProduto == e.target.id
    );
    let promo = { ...promotion };
    const newArr = [...promotion.itens];
    newArr[index] = { ...newArr[index], qtItemPromocao: e.target.value };

    promo.itens = newArr;

    setPromotion(promo);
  };

  const markCheckboxesTiposEstabelecimentos = (r: any) => {
    let response = { ...r };
    if (!response) {
      return;
    }

    const result = [
      ...comercial.promotionsAbrangenciaTiposEstabelecimentosList,
    ];
    for (let i = 0; i <= result.length; i++) {
      for (
        let j = 0;
        j <= response?.abrangenciaTipoEstabelecimento?.length;
        j++
      ) {
        if (
          result[i]?.idTipoEstabelecimento ===
          response?.abrangenciaTipoEstabelecimento[j]?.idTipoEstabelecimento
        ) {
          if (result[i]) {
            result[i] = {
              ...result[i],
              checked: true,
            };
          }
        }
      }
    }

    response.abrangenciaTipoEstabelecimento = result;

    return response;
  };

  const handleCheckboxesTiposEstabelecimento = (event: any) => {
    const newArr = { ...promotion };
    const index = newArr.abrangenciaTipoEstabelecimento.findIndex(
      (chk: any) => chk.idTipoEstabelecimento == event.target.value
    );

    newArr.abrangenciaTipoEstabelecimento[index] = {
      ...newArr.abrangenciaTipoEstabelecimento[index],
      checked: !newArr.abrangenciaTipoEstabelecimento[index].checked,
    };

    setPromotion(newArr);
  };

  const handleSubmit = async () => {
    setErrors({} as any);

    try {
      const listaProdutosPromocao: any = [];
      const listaTiposEstabelecimentosPromotion: any = [];

      if (selectedCategory.value === 1) {
        promotion.abrangenciaTipoEstabelecimento.map((c: any) => {
          if (c.checked) {
            listaTiposEstabelecimentosPromotion.push({
              idTipoEstabelecimento: c.idTipoEstabelecimento,
              dsTipoEstabelecimento: c.dsTipoEstabelecimento,
            });
          }
        });
      }

      promotion.itens.map((p: any) => {
        if (p.checked) {
          listaProdutosPromocao.push({
            idProduto: p.idProduto,
            dsPromocao: p.dsPromocao,
            qtItemPromocao: Number(p.qtItemPromocao),
            vlItemPromocao: Number(p.vlItemPromocao),
            idTipoDesconto: p.idTipoDesconto,
            checked: p.checked,
          });
        }
      });

      const PromotionEdit = {
        id: promotion?.id,
        dsPromocao: promotion?.dsPromocao,
        dtValidadeDe: promotion?.dtValidadeDe,
        dtValidadeAte: promotion?.dtValidadeAte,
        inAtivo: promotion?.inAtivo,
        txImagemPromocao: promotion?.txImagemPromocao,
        itens: listaProdutosPromocao,
        allItens: promotion?.itens,
        idTipoAbrangencia:
          selectedCategory.value === 0 ? null : selectedCategory.value,
        abrangenciaTipoEstabelecimento:
          selectedCategory.value === 1
            ? listaTiposEstabelecimentosPromotion
            : [],
        abrangenciaClienteApp:
          selectedCategory.value === 3 ? filteredClients : [],
      };

      const schema = Yup.object().shape({
        dsPromocao: Yup.string().required("Descrição obrigatória."),
        dtValidadeDe: Yup.string().required(
          "Data de início da promoção obrigatória."
        ),
        dtValidadeAte: Yup.string().required(
          "Data de término da promoção obrigatória."
        ),
        txImagemPromocao: Yup.string().required("Imagem da promoção obrigatória."),
        itens: Yup.array().min(
          1,
          "Defina um ou mais produtos. Quantidade do produto (maior que 0), tipo de desconto e valor/percentual de desconto (maior que 0) obrigatórios."
        ),
        allItens: Yup.array()
          .min(
            1,
            "Defina um ou mais produtos. Quantidade do produto (maior que 0), tipo de desconto e valor/percentual de desconto (maior que 0) obrigatórios."
          )
          .of(
            Yup.object().shape({
              ["checked"]: Yup.boolean(),
              ["qtItemPromocao"]: Yup.number().when("checked", (checked: any) => {
                if (checked) {
                  return Yup.number()
                    .typeError("É obrigatorio.")
                    .required("Obrigatório.")
                    .min(1, "Deve ser maior que 0.");
                } else {
                  return Yup.string();
                }
              }),

              ["vlItemPromocao"]: Yup.number().when("checked", (checked: any) => {
                if (checked) {
                  return Yup.number()
                    .typeError("É obrigatorio.")
                    .required("Obrigatório.")
                    .min(1, "Deve ser maior que 0.");
                } else {
                  return Yup.string();
                }
              }),

              ["idTipoDesconto"]: Yup.string().when("checked", {
                is: true,
                then: Yup.string()
                  .required("Obrigatório.")
                  .typeError("É obrigatorio."),
              }),
            })
          ),
      });

      await schema.validate(PromotionEdit, {
        abortEarly: false,
      });
      delete PromotionEdit.allItens;
      dispatch(asyncUpdatePromotion(PromotionEdit));
      setVisible(false);
      setStep(1);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrors(getValidationErrors(err));

        return;
      }
    }
  };

  useEffect(() => {
    if (promotion) {
      let result = promotion;
      result = { ...result, dsPromocao: dsPromocao };
      setPromotion(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dsPromocao]);

  useEffect(() => {
    if (promotion) {
      let result = promotion;
      result = { ...result, dtValidadeDe: dtValidadeDe };
      setPromotion(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dtValidadeDe]);

  useEffect(() => {
    if (promotion) {
      let result = promotion;
      result = { ...result, dtValidadeAte: dtValidadeAte };
      setPromotion(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dtValidadeAte]);

  useEffect(() => {
    if (promotion) {
      let result = promotion;
      result = { ...result, inAtivo: inAtivo };
      setPromotion(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inAtivo]);

  useEffect(() => {
    if (promotion) {
      let result = promotion;
      result = { ...result, txImagemPromocao: txImagemPromocao };
      setPromotion(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txImagemPromocao]);

  useEffect(() => {
    if (promotion) {
      let result = promotion;
      result = { ...result, idTipoAbrangecia: selectedCategory?.value };
      setPromotion(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory?.value]);

  useEffect(() => {
    setAllClients(
      comercial.clientesAppList.map((client) => ({
        value: client?.idClienteApp,
        label: client?.nmCliente,
      }))
    );
  }, [comercial.clientesAppList]);

  useEffect(() => {
    if (promotion) {
      let result = promotion;
      result = {
        ...result,
        abrangenciaClienteApp: filteredClients,
      };
      setPromotion(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredClients]);

  const markCheckboxes = (r: any) => {
    let response = { ...r };
    if (!response) {
      return;
    }

    const result = [...comercial.productsList];
    for (let i = 0; i <= result.length; i++) {
      for (let j = 0; j <= response?.itens?.length; j++) {
        if (result[i]?.idProduto === response?.itens[j]?.idProduto) {
          if (result[i]) {
            result[i] = {
              ...result[i],
              checked: true,
              qtItemPromocao: response?.itens[j]?.qtItemPromocao,
              idTipoDesconto: response?.itens[j]?.idTipoDesconto,
              vlItemPromocao: response?.itens[j]?.vlItemPromocao,
            };
          }
        }
      }
    }
    response.itens = result;
    return response;
  };

  return (
    <div className="sidebarv2-wrapper">
      <Button
        icon="pi pi-pencil"
        onClick={getPromotion}
        className="p-button-rounded p-button-warning p-button-outlined"
      />

      <Sidebar
        className="sidebar-promotion-container"
        visible={visible}
        position="right"
        onHide={handleCancel}
      >
        <div className="sidebar-promotion-content-top">
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-danger p-button-text"
            onClick={handleCancel}
          />

          <p
            style={{ color: COLORS.primaryGreen }}
            className="sidebar-promotion-title"
          >
            Editar Promoção
          </p>
          {step === 1 ? (
            <div className="sidebar-promotion-principal">
              <div className="sidebar-promotion-buttons">
                <div className="sidebar-promotion-active">
                  <button
                    value={step}
                    onClick={() => setStep(1)}
                    className="promotion-buttons-active"
                  >
                    Promoção
                  </button>
                </div>
                <div className="sidebar-promotion-noactive">
                  {selectedCategory.value === 0 ? (
                    <button
                      value={step}
                      onClick={() => setStep(2)}
                      className="promotion-buttons-noactive"
                      disabled
                    >
                      Abrangência
                    </button>
                  ) : (
                    <button
                      value={step}
                      onClick={() => setStep(2)}
                      className="promotion-buttons-noactive"
                    >
                      Abrangência
                    </button>
                  )}
                </div>
              </div>
              <section className="inputs-section">
                <div className="sidebar-promotion-content">
                  <div className="sidebar-promotion-left">
                    <DefaultInput
                      label="Descrição"
                      value={promotion?.dsPromocao}
                      onChange={setDsPromocao}
                      disabled={false}
                      error={errors.dsPromocao}
                      maxLength={50}
                    />
                    <div className="sidebar-promotion-left-inputs">
                      <DefaultInput
                        label="De"
                        type="date"
                        value={promotion?.dtValidadeDe}
                        onChange={setDtValidadeDe}
                        disabled={false}
                        error={errors.dtValidadeDe}
                      />
                      <DefaultInput
                        label="Até"
                        type="date"
                        value={promotion?.dtValidadeAte}
                        onChange={setDtValidadeAte}
                        disabled={false}
                        min={promotion?.dtValidadeDe}
                        error={errors.dtValidadeAte}
                      />
                      <div className="p-field-checkbox">
                        <Checkbox
                          inputId="active"
                          checked={promotion?.inAtivo}
                          onChange={(e) => setInAtivo(e.checked)}
                        />
                        <label htmlFor="active">Ativo</label>
                      </div>
                    </div>
                  </div>
                  <div className="sidebar-promotion-right">
                    <p>Imagem</p>
                    <div className="sidebar-promotion-right-border">
                      {txImagemPromocao ? (
                        <div className="cardImgSrc">
                          <img
                            src={txImagemPromocao}
                            alt="Imagem do promoção"
                            width="100px"
                          />
                          <i
                            className="pi pi-trash iconTrash"
                            onClick={deleteImage}
                            style={{
                              cursor: "pointer",
                              color: "red",
                              backgroundColor: "white",
                              padding: "4px",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                      ) : (
                        <>
                          <div
                            className="divDropZone"
                            {...getRootProps()}
                            style={{
                              cursor: "pointer",
                              borderWidth: "1px",
                              borderStyle: "dashed",
                              width: "220px",
                              height: "110px",
                              borderRadius: "10px",
                            }}
                          >
                            <input {...getInputProps()} />
                          </div>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "red",
                              margin: "0",
                            }}
                          >
                            {errors.txImagemPromocao}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </section>

              <p>Itens</p>
              <div className="sidebar-promotion-itens-header" >
                <h2 className="sidebar-promotion-itens-header-t1">Produto</h2>
                <h2 className="sidebar-promotion-itens-header-t2">
                  Quantidade
                </h2>
                <h2 className="sidebar-promotion-itens-header-t3">
                  Tipo de desconto
                </h2>
                <h2 className="sidebar-promotion-itens-header-t4">
                  Valor/Percentual
                </h2>
              </div>

              <div className="sidebar-promotion-itens-header-responsive">
                <h2 className="sidebar-promotion-itens-header-t1-responsive">
                  Prod
                </h2>
                <h2 className="sidebar-promotion-itens-header-t2-responsive">
                  Quant
                </h2>
                <h2 className="sidebar-promotion-itens-header-t3-responsive">
                  Tipo Desc
                </h2>
                <h2 className="sidebar-promotion-itens-header-t4-responsive">
                  V/P
                </h2>
              </div>
              <div className="sidebar-promotion-itens">
                <div className="sidebar-promotion-itens-content">
                  {promotion &&
                    (loading ? (
                      <div className="divNotFind">
                        <ProgressLoad />
                      </div>
                    ) : (
                      <ItensListPromotion
                        itens={promotion.itens}
                        // products={promotion.products}
                        handleCheckboxes={handleCheckboxes}
                        handleSelect={handleSelect}
                        handleAmountDiscount={handleAmountDiscount}
                        handleQuantity={handleQuantity}
                        errors={errors}
                      />
                    ))}
                </div>
              </div>
              {errors.itens && (
                <span style={{ fontSize: "12px", color: "red", margin: "0" }}>
                  {errors.itens}
                </span>
              )}
              <h4 style={{ marginLeft: "16px", marginBottom: "4px" }}>
                Abrangência
              </h4>
              <div className="sidebar-promotion-coverage">
                {categories.map((category) => {
                  return (
                    <div key={category.value} className="p-field-radiobutton">
                      <RadioButton
                        inputId={category.name}
                        name="category"
                        value={category}
                        onChange={(e) => setSelectedCategory(e.value)}
                        checked={selectedCategory?.value === category.value}
                        disabled={false}
                        className="radiobutton-style"
                      />
                      <label htmlFor={category.name}>{category.name}</label>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="sidebar-promotion-principal">
              <div className="sidebar-promotion-buttons">
                <div className="sidebar-promotion-noactive">
                  <button
                    value={step}
                    onClick={() => setStep(1)}
                    className="promotion-buttons-noactive"
                  >
                    Promoção
                  </button>
                </div>
                <div className="sidebar-promotion-active">
                  <button
                    value={step}
                    onClick={() => setStep(2)}
                    className="promotion-buttons-active"
                  >
                    Abrangência
                  </button>
                </div>
              </div>

              {selectedCategory.value === 1 && (
                <div className="sidebar-promotion-establishmentsTypes-container">
                  <h3>Tipos de Estabelecimento</h3>
                  <div className="sidebar-promotion-establishmentsTypes-content">
                    {loading ? (
                      <div className="divNotFind">
                        <ProgressLoad />
                      </div>
                    ) : (
                      <EstablishmentsTypesPromotion
                        handleCheckboxes={handleCheckboxesTiposEstabelecimento}
                        establishments={
                          promotion.abrangenciaTipoEstabelecimento
                        }
                      />
                    )}
                  </div>
                </div>
              )}

              {selectedCategory.value === 3 && (
                <div className="sidebar-promotion-clients-container">
                  <h3>Clientes disponíveis</h3>
                  <div className="sidebar-promotion-clients-content">
                    {loading ? (
                      <div className="divNotFind">
                        <ProgressLoad />
                      </div>
                    ) : (
                      <div className="sidebar-cupons-clients-input">
                        <div className="sidebar-cupons-clients-input-header">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder="Clientes..."
                            value={clientSelected}
                            isClearable={true}
                            isSearchable={true}
                            options={allClients}
                            onChange={(text) => {
                              setClientSelected(text as SelectProps);
                            }}
                            noOptionsMessage={() =>
                              "Não foi encontrado nenhum registro."
                            }
                          />

                          {clientSelected !== "" ? (
                            <Button
                              label="Adicionar"
                              className="p-button-raised p-button-secondary p-button-text"
                              style={{ height: 36, marginLeft: 20 }}
                              onClick={handleAddToFilteredClients}
                            />
                          ) : (
                            <Button
                              label="Adicionar"
                              className="p-button-raised p-button-secondary p-button-text"
                              style={{ height: 36, marginLeft: 20 }}
                              onClick={handleAddToFilteredClients}
                              disabled
                            />
                          )}
                        </div>
                        <h1 style={{ margin: 0, fontSize: 20, marginTop: 24 }}>
                          Clientes Selecionados
                        </h1>
                        <div className="sidebar-cupons-clients-filteredList">
                          {filteredClients.length > 0 ? (
                            filteredClients.map((client) => {
                              return (
                                <div
                                  key={client?.idClienteApp}
                                  className="sidebar-cupons-clients-filteredList-client"
                                >
                                  {client?.clienteApp?.nmCliente || client?.nmCliente}

                                  <Button
                                    value={client?.idClienteApp}
                                    icon="pi pi-trash"
                                    className="p-button-rounded p-button-danger p-button-outlined"
                                    onClick={deleteConfirm}
                                    style={{ width: 28, height: 28 }}
                                  />
                                </div>
                              );
                            })
                          ) : (
                            <h1 style={{ margin: 0, fontSize: 16 }}>
                              Nenhum cliente foi adicionado.
                            </h1>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* {selectedCategory.value === 4 && (
                <div className='sidebar-promotion-localization-container'>
                  <h3>Localização</h3>
                  <div className='sidebar-promotion-localization-content'>
                    {loading ? (
                      <div className='divNotFind'>
                        <ProgressLoad />
                      </div>
                    ) : (
                      // <ItensListCupons
                      //   handleCheckboxes={handleCheckboxesProduto}
                      //   itens={updateProductsCupons}
                      // />
                      <div className='sidebar-promotion-localization-selects'>
                        <div className='localization-selects-content'>
                          <div>
                              <p>UF</p>
                              <Dropdown value={selectUF} emptyMessage="Não foi encontrado nenhum registro."  onChange={(e) => setSelectUF(e.value)} className="localization-selects"/>
                          </div>
                          <div>
                              <p>Cidade</p>
                              <Dropdown value={selectUF} emptyMessage="Não foi encontrado nenhum registro."  onChange={(e) => setSelectUF(e.value)} className="localization-selects"/>
                          </div>
                          <div>
                              <p>Bairro</p>
                              <Dropdown value={selectUF} emptyMessage="Não foi encontrado nenhum registro."  onChange={(e) => setSelectUF(e.value)} className="localization-selects"/>
                          </div>
                          <button style={{width: '140px', height: '28px', marginTop: '60px'}}>Adicionar</button>
                        </div>
                        

                        <div className='sidebar-promotion-localization-list'>
                          <h3>Locais selecionados</h3>
                          <span>Bairro, cidade, UF</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )} */}
            </div>
          )}
        </div>
        <div className="sidebar-promotion-content-bottom">
          <div className="sidebar-promotion-actions">
            <Button
              label="Cancelar"
              className="p-button-outlined p-button-secondary"
              onClick={handleCancel}
              style={{ marginRight: "20px", width: "40%" }}
            />
            <Button
              id="button"
              label="Salvar"
              className="p-button-lg"
              onClick={handleSubmit}
              style={{ width: "40%" }}
            />
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default PromotionEdit;
