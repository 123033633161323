import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Sidebar } from "primereact/sidebar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DefaultInput from "../../../../components/DefaultInput";
import { COLORS } from "../../../../constants";
import { RootState } from "../../../../store";
import ItensListPromotion from "../../../../components/Comercial/Promotion/ItensList";
import { asyncPostPromotion } from "../../../../store/comercial/comercial.actions";
import {
  ClientesAppProps,
  PromotionItensProps,
  PromotionsAbrangenciaTipoEstabelecimentoProps,
} from "../../../../store/comercial/comercial.store";
import getBase64 from "../../../../ConvertBase64Img";
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";
import getValidationErrors from "../../../../util/validationError";
import { RadioButton } from "primereact/radiobutton";
import EstablishmentsTypesPromotion from "../../../../components/Comercial/Promotion/AbrangenciaEstablishmentList";
import Select from "react-select";
import { confirmDialog } from "primereact/confirmdialog";
import ProgressLoad from "../../../../components/ProgressLoad";

export type SelectProps = {
  label: string;
  value: number;
};

const categories = [
  { name: "Todos", value: 0 },
  { name: "Tipo de estabelecimento", value: 1 },
  { name: "Cliente", value: 3 },
  // { name: "Localização", value: 3 },
];

const PromotionCreate: React.FC = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [dsPromocao, setDsPromocao] = useState("");
  const [dtValidadeDe, setDtValidadeDe] = useState("");
  const [dtValidadeAte, setDtValidadeAte] = useState("");
  const [inAtivo, setInAtivo] = useState<boolean>(false);
  const [updateItens, setUpdateItens] = useState<PromotionItensProps[]>([]);
  const [txImagemPromocao, setTxImagemPromocao] = useState("");
  const [errors, setErrors] = useState({} as any);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [step, setStep] = useState(1);
  const [selectUF, setSelectUF] = useState<any>("");
  const [
    updateAbrangenciaTipoEstabelecimentoPromotions,
    setUpdateAbrangenciaTipoEstabelecimentoPromotions,
  ] = useState<PromotionsAbrangenciaTipoEstabelecimentoProps[]>([]);
  const [clientSelected, setClientSelected] = useState<any>("");
  const [allClients, setAllClients] = useState<any>([]);
  const [filteredClients, setFilteredClients] = useState<ClientesAppProps[]>(
    []
  );

  const { loading } = useSelector((state: RootState) => state.loading);
  const comercial = useSelector((state: RootState) => state.comercial);

  const deleteConfirm = (e: any) => {
    const id = e.target.value | e.target.parentNode.value;

    confirmDialog({
      message: "Você quer remover este registro?",
      header: "Confirmação de exclusão",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => handleDeleteClient(id),
      acceptLabel: "Sim",
      rejectLabel: "Não",
    });
  };

  const handleDeleteClient = (id: number) => {
    const removingClient = filteredClients.filter((client) => {
      return client?.idClienteApp !== id;
    });
    setFilteredClients(removingClient);
  };

  const handleAddToFilteredClients = () => {
    const clientsFiltered = [...filteredClients];

    const clientExist = clientsFiltered.findIndex(
      (client) => client.idClienteApp === clientSelected.value
    );

    if (clientExist < 0) {
      clientsFiltered.push({
        idClienteApp: clientSelected.value,
        nmCliente: clientSelected.label,
      });
    } else {
      return;
    }

    setFilteredClients(clientsFiltered);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles);
    },
  });

  const setFile = async (file: any) => {
    const imgBase64Convert = await getBase64(file[0]);

    setTxImagemPromocao(imgBase64Convert);
  };

  const deleteImage = () => {
    setTxImagemPromocao("");
  };

  const handleCheckboxes = (event: any) => {
    const index = updateItens.findIndex(
      (chk) => chk.idProduto == event.target.value
    );
    const newArr = [...updateItens];
    newArr[index] = { ...newArr[index], checked: !newArr[index].checked };

    if (newArr[index].checked === false) {
      newArr[index] = {
        ...newArr[index],
        qtItemPromocao: "",
        idTipoDesconto: "",
        vlItemPromocao: "",
      };
    }

    setUpdateItens(newArr);
  };

  const handleSelect = (e: any) => {
    const index = updateItens.findIndex(
      (item) => item.idProduto == parseInt(e.target.id)
    );
    const newArr = [...updateItens];
    newArr[index] = { ...newArr[index], idTipoDesconto: e.target.value };

    setUpdateItens(newArr);
  };

  const handleAmountDiscount = (e: any) => {
    const index = updateItens.findIndex(
      (item) => item.idProduto == e.target.id
    );

    const newArr = [...updateItens];
    newArr[index] = {
      ...newArr[index],
      vlItemPromocao: Math.abs(parseInt(e.target.value)),
    };

    setUpdateItens(newArr);
  };

  const handleQuantity = (e: any) => {
    const index = updateItens.findIndex(
      (item) => item.idProduto == e.target.id
    );

    const newArr = [...updateItens];
    newArr[index] = {
      ...newArr[index],
      qtItemPromocao: Math.abs(e.target.value),
    };

    setUpdateItens(newArr);
  };

  const handleCheckboxesEstabelecimentos = (event: any) => {
    const index = updateAbrangenciaTipoEstabelecimentoPromotions.findIndex(
      (chk) => chk.idTipoEstabelecimento == event.target.value
    );
    const newArr = [...updateAbrangenciaTipoEstabelecimentoPromotions];
    newArr[index] = { ...newArr[index], checked: !newArr[index].checked };

    setUpdateAbrangenciaTipoEstabelecimentoPromotions(newArr);
  };

  const handleSubmit = async () => {
    setErrors({} as any);

    try {
      const filteredItens = updateItens.filter((item) => {
        return item.checked;
      });

      const filteredAbrangenciaTipoEstabelecimento =
        updateAbrangenciaTipoEstabelecimentoPromotions.filter((item) => {
          return item.checked;
        });

      const promotion = {
        dsPromocao: dsPromocao,
        dtValidadeDe: dtValidadeDe,
        dtValidadeAte: dtValidadeAte,
        inAtivo: inAtivo,
        txImagemPromocao: txImagemPromocao,
        itens: filteredItens,
        allItens: updateItens,
        idTipoAbrangencia:
          selectedCategory.value === 0 ? null : selectedCategory.value,
        abrangenciaTipoEstabelecimento:
          selectedCategory.value === 1
            ? filteredAbrangenciaTipoEstabelecimento
            : [],
        abrangenciaClienteApp:
          selectedCategory.value === 3 ? filteredClients : [],
      };

      const schema = Yup.object().shape({
        dsPromocao: Yup.string().required("Descrição obrigatória."),
        dtValidadeDe: Yup.string().required(
          "Data de início da promoção obrigatória."
        ),
        dtValidadeAte: Yup.string().required(
          "Data de término da promoção obrigatória."
        ),
        txImagemPromocao: Yup.string().required(
          "Imagem da promoção obrigatória."
        ),
        itens: Yup.array().min(
          1,
          "Defina um ou mais produtos. Quantidade do produto (maior que 0), tipo de desconto e valor/percentual de desconto (maior que 0) obrigatórios."
        ),
        allItens: Yup.array()
          .min(
            1,
            "Defina um ou mais produtos. Quantidade do produto (maior que 0), tipo de desconto e valor/percentual de desconto (maior que 0) obrigatórios."
          )
          .of(
            Yup.object().shape({
              ["checked"]: Yup.boolean(),
              ["qtItemPromocao"]: Yup.number().when(
                "checked",
                (checked: any) => {
                  if (checked) {
                    return Yup.number()
                      .typeError("É obrigatorio.")
                      .required("Obrigatório.")
                      .min(1, "Deve ser maior que 0.");
                  } else {
                    return Yup.string();
                  }
                }
              ),

              ["vlItemPromocao"]: Yup.number().when(
                "checked",
                (checked: any) => {
                  if (checked) {
                    return Yup.number()
                      .typeError("É obrigatorio.")
                      .required("Obrigatório.")
                      .min(1, "Deve ser maior que 0.");
                  } else {
                    return Yup.string();
                  }
                }
              ),

              ["idTipoDesconto"]: Yup.string().when("checked", {
                is: true,
                then: Yup.string()
                  .required("Obrigatório.")
                  .typeError("É obrigatorio."),
              }),
            })
          ),
      });

      await schema.validate(promotion, {
        abortEarly: false,
      });

      // @ts-expect-error
      delete promotion.allItens;
      dispatch(asyncPostPromotion(promotion));
      setStep(1);
      setDsPromocao("");
      setDtValidadeDe("");
      setDtValidadeAte("");
      setTxImagemPromocao("");
      setInAtivo(false);
      setUpdateItens(comercial.productsList);
      setSelectedCategory(categories[0]);
      setUpdateAbrangenciaTipoEstabelecimentoPromotions(
        comercial.promotionsAbrangenciaTiposEstabelecimentosList
      );
      setClientSelected("");
      setFilteredClients([]);
      setVisible(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrors(getValidationErrors(err));
        return;
      }
    }
  };

  const handleCancel = () => {
    setStep(1);
    setDsPromocao("");
    setDtValidadeDe("");
    setDtValidadeAte("");
    setInAtivo(false);
    setTxImagemPromocao("");
    setUpdateItens(comercial.productsList);
    setVisible(false);
    setErrors("");
    setClientSelected("");
    setSelectedCategory(categories[0]);
    setUpdateAbrangenciaTipoEstabelecimentoPromotions(
      comercial.promotionsAbrangenciaTiposEstabelecimentosList
    );
    setClientSelected("");
    setFilteredClients([]);
  };

  useEffect(() => {
    setUpdateItens(comercial.productsList);
    setUpdateAbrangenciaTipoEstabelecimentoPromotions(
      comercial.promotionsAbrangenciaTiposEstabelecimentosList
    );
    setAllClients(
      comercial.clientesAppList.map((client) => ({
        value: client?.idClienteApp,
        label: client?.nmCliente,
      }))
    );
  }, [comercial.productsList]);

  return (
    <div className="sidebarv2-wrapper">
      <Button
        label="Nova promoção"
        onClick={() => setVisible(true)}
        className="sidebarv2-open-button"
        color="#AA0061"
      />

      <Sidebar
        className="sidebar-promotion-container"
        visible={visible}
        position="right"
        onHide={handleCancel}
      >
        <div className="sidebar-promotion-content-top">
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-danger p-button-text"
            onClick={handleCancel}
          />
          <p
            style={{ color: COLORS.primaryGreen }}
            className="sidebar-promotion-title"
          >
            Cadastrar Promoção
          </p>
          {step === 1 ? (
            <div className="sidebar-promotion-principal">
              <div className="sidebar-promotion-buttons">
                <div className="sidebar-promotion-active">
                  <button
                    value={step}
                    onClick={() => setStep(1)}
                    className="promotion-buttons-active"
                  >
                    Promoção
                  </button>
                </div>
                <div className="sidebar-promotion-noactive">
                  {selectedCategory.value === 0 ? (
                    <button
                      value={step}
                      onClick={() => setStep(2)}
                      className="promotion-buttons-noactive"
                      disabled
                    >
                      Abrangência
                    </button>
                  ) : (
                    <button
                      value={step}
                      onClick={() => setStep(2)}
                      className="promotion-buttons-noactive"
                    >
                      Abrangência
                    </button>
                  )}
                </div>
              </div>
              <section className="inputs-section">
                <div className="sidebar-promotion-content">
                  <div className="sidebar-promotion-left">
                    <DefaultInput
                      label="Descrição"
                      value={dsPromocao}
                      onChange={setDsPromocao}
                      disabled={false}
                      required={true}
                      error={errors.dsPromocao}
                      maxLength={50}
                    />
                    <div className="sidebar-promotion-left-inputs">
                      <DefaultInput
                        label="De"
                        type="date"
                        value={dtValidadeDe}
                        onChange={setDtValidadeDe}
                        disabled={false}
                        required={true}
                        error={errors.dtValidadeDe}
                      />
                      <DefaultInput
                        label="Até"
                        type="date"
                        value={dtValidadeAte}
                        onChange={setDtValidadeAte}
                        disabled={false}
                        min={dtValidadeDe}
                        required={true}
                        error={errors.dtValidadeAte}
                      />
                      <div className="p-field-checkbox">
                        <Checkbox
                          inputId="active"
                          checked={inAtivo}
                          onChange={(e) => setInAtivo(e.checked)}
                        />
                        <label htmlFor="active" style={{ marginLeft: 4 }}>
                          Ativo
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="sidebar-promotion-right">
                    <p>Imagem</p>
                    <div className="sidebar-promotion-right-border">
                      {txImagemPromocao ? (
                        <div className="cardImgSrc">
                          <img
                            src={txImagemPromocao}
                            alt="Imagem do promoção"
                            width="100px"
                          />
                          <i
                            className="pi pi-trash iconTrash"
                            onClick={deleteImage}
                            style={{
                              cursor: "pointer",
                              color: "red",
                              backgroundColor: "white",
                              padding: "4px",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                      ) : (
                        <>
                          <div
                            className="divDropZone"
                            {...getRootProps()}
                            style={{
                              cursor: "pointer",
                              borderWidth: "1px",
                              borderStyle: "dashed",
                              width: "220px",
                              height: "110px",
                              borderRadius: "10px",
                            }}
                          >
                            <input {...getInputProps()} />
                          </div>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "red",
                              margin: "0",
                            }}
                          >
                            {errors.imagem}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </section>

              <p className="sidebar-title-itens">Itens</p>
              <div className="sidebar-promotion-itens-header">
                <h2 className="sidebar-promotion-itens-header-t1">Produto</h2>
                <h2 className="sidebar-promotion-itens-header-t2">
                  Quantidade
                </h2>
                <h2 className="sidebar-promotion-itens-header-t3">
                  Tipo de desconto
                </h2>
                <h2 className="sidebar-promotion-itens-header-t4">
                  Valor/Percentual
                </h2>
              </div>

              <div className="sidebar-promotion-itens-header-responsive">
                <h2 className="sidebar-promotion-itens-header-t1-responsive">
                  Prod
                </h2>
                <h2 className="sidebar-promotion-itens-header-t2-responsive">
                  Quant
                </h2>
                <h2 className="sidebar-promotion-itens-header-t3-responsive">
                  Tipo Desc
                </h2>
                <h2 className="sidebar-promotion-itens-header-t4-responsive">
                  V/P
                </h2>
              </div>

              <div className="sidebar-promotion-itens">
                <div className="sidebar-promotion-itens-content">
                  {loading ? (
                    <div className="divNotFind">
                      <ProgressLoad />
                    </div>
                  ) : (
                    <ItensListPromotion
                      handleCheckboxes={handleCheckboxes}
                      handleSelect={handleSelect}
                      handleAmountDiscount={handleAmountDiscount}
                      handleQuantity={handleQuantity}
                      itens={updateItens}
                      errors={errors}
                    />
                  )}
                </div>
              </div>
              {errors.itens && (
                <span style={{ fontSize: "12px", color: "red", margin: "0" }}>
                  {errors.itens}
                </span>
              )}
              <h4 style={{ marginLeft: "16px", marginBottom: "4px" }}>
                Abrangência
              </h4>
              <div className="sidebar-promotion-coverage">
                {categories.map((category) => {
                  return (
                    <div key={category.value} className="p-field-radiobutton">
                      <RadioButton
                        inputId={category.name}
                        name="category"
                        value={category}
                        onChange={(e) => setSelectedCategory(e.value)}
                        checked={selectedCategory.value === category.value}
                        disabled={false}
                        className="radiobutton-style"
                      />
                      <label htmlFor={category.name}>{category.name}</label>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="sidebar-promotion-principal">
              <div className="sidebar-promotion-buttons">
                <div className="sidebar-promotion-noactive">
                  <button
                    value={step}
                    onClick={() => setStep(1)}
                    className="promotion-buttons-noactive"
                  >
                    Promoção
                  </button>
                </div>
                <div className="sidebar-promotion-active">
                  <button
                    value={step}
                    onClick={() => setStep(2)}
                    className="promotion-buttons-active"
                  >
                    Abrangência
                  </button>
                </div>
              </div>

              {selectedCategory.value === 1 && (
                <div className="sidebar-promotion-establishmentsTypes-container">
                  <h3>Tipos de Estabelecimento</h3>
                  <div className="sidebar-promotion-establishmentsTypes-content">
                    {loading ? (
                      <div className="divNotFind">
                        <ProgressLoad />
                      </div>
                    ) : (
                      <EstablishmentsTypesPromotion
                        handleCheckboxes={handleCheckboxesEstabelecimentos}
                        establishments={
                          updateAbrangenciaTipoEstabelecimentoPromotions
                        }
                      />
                    )}
                  </div>
                </div>
              )}

              {selectedCategory.value === 3 && (
                <div className="sidebar-promotion-clients-container">
                  <h3>Clientes disponíveis</h3>
                  <div className="sidebar-promotion-clients-content">
                    {loading ? (
                      <div className="divNotFind">
                        <ProgressLoad />
                      </div>
                    ) : (
                      <div className="sidebar-promotion-clients-input">
                        <div className="sidebar-promotion-clients-input-header">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder="Clientes..."
                            value={clientSelected}
                            isClearable={true}
                            isSearchable={true}
                            options={allClients}
                            onChange={(text) => {
                              setClientSelected(text as SelectProps);
                            }}
                            noOptionsMessage={() =>
                              "Não foi encontrado nenhum registro."
                            }
                          />

                          {clientSelected !== "" ? (
                            <Button
                              label="Adicionar"
                              className="p-button-raised p-button-secondary p-button-text"
                              style={{ height: 36, marginLeft: 20 }}
                              onClick={handleAddToFilteredClients}
                            />
                          ) : (
                            <Button
                              label="Adicionar"
                              className="p-button-raised p-button-secondary p-button-text"
                              style={{ height: 36, marginLeft: 20 }}
                              onClick={handleAddToFilteredClients}
                              disabled
                            />
                          )}
                        </div>
                        <h1 style={{ margin: 0, fontSize: 20, marginTop: 24 }}>
                          Clientes Selecionados
                        </h1>
                        <div className="sidebar-promotion-clients-filteredList">
                          {filteredClients.length > 0 ? (
                            filteredClients.map((client) => {
                              return (
                                <div
                                  key={client?.idClienteApp}
                                  className="sidebar-promotion-clients-filteredList-client"
                                >
                                  <h1>{client?.nmCliente}</h1>
                                  <Button
                                    value={client?.idClienteApp}
                                    icon="pi pi-trash"
                                    className="p-button-rounded p-button-danger p-button-outlined"
                                    onClick={deleteConfirm}
                                    style={{ width: 28, height: 28 }}
                                  />
                                </div>
                              );
                            })
                          ) : (
                            <h1 style={{ margin: 0, fontSize: 16 }}>
                              Nenhum cliente foi adicionado.
                            </h1>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {/* {selectedCategory.value === 4 && (
                <div className='sidebar-promotion-localization-container'>
                  <h3>Localização</h3>
                  <div className='sidebar-promotion-localization-content'>
                    {loading ? (
                      <div className='divNotFind'>
                        <ProgressLoad />
                      </div>
                    ) : (
                      // <ItensListpromotion
                      //   handleCheckboxes={handleCheckboxesProduto}
                      //   itens={updateProductsCupons}
                      // />
                      <div className='sidebar-promotion-localization-selects'>
                        <div className='localization-selects-content'>
                          <div>
                              <p>UF</p>
                              <Dropdown value={selectUF} emptyMessage="Não foi encontrado nenhum registro."  onChange={(e) => setSelectUF(e.value)} className="localization-selects"/>
                          </div>
                          <div>
                              <p>Cidade</p>
                              <Dropdown value={selectUF} emptyMessage="Não foi encontrado nenhum registro."  onChange={(e) => setSelectUF(e.value)} className="localization-selects"/>
                          </div>
                          <div>
                              <p>Bairro</p>
                              <Dropdown value={selectUF} emptyMessage="Não foi encontrado nenhum registro."  onChange={(e) => setSelectUF(e.value)} className="localization-selects"/>
                          </div>
                          <button style={{width: '140px', height: '28px', marginTop: '60px'}}>Adicionar</button>
                        </div>
                        

                        <div className='sidebar-promotion-localization-list'>
                          <h3>Locais selecionados</h3>
                          <span>Bairro, cidade, UF</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )} */}
        </div>

        <div className="sidebar-promotion-content-bottom">
          <div className="sidebar-promotion-actions">
            <Button
              label="Cancelar"
              className="p-button-outlined p-button-secondary"
              onClick={handleCancel}
              style={{ marginRight: "20px", width: "40%" }}
            />
            <Button
              id="button"
              label="Salvar"
              className="p-button-lg"
              onClick={handleSubmit}
              style={{ width: "40%" }}
            />
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default PromotionCreate;
