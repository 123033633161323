export const TOKEN_KEY = "@guaracamp-admin-Token";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const login = (token: any) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const setUserEmail = (userEmail: any) => {
  localStorage.setItem("@USER_EMAIL_GUARACAMP", userEmail);
};

export const setIdUsuario = (idUsuario: any) => {
  localStorage.setItem("@USER_ID_GUARACAMP", idUsuario);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem("@USER_EMAIL_GUARACAMP");
  localStorage.removeItem("@USER_ID_GUARACAMP");
};
