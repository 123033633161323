import { useState } from "react";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { useDropzone } from "react-dropzone";

import "./style.css";
import { COLORS } from "../../../constants";

interface CardListProps {
  name: string;
  src: string;
  idCard: number;
  handleDelete: (idCard: any) => void;
  setFile: (acceptedFiles: any, idCard: number) => void;
}

const CardImages = ({
  name,
  src,
  idCard,
  handleDelete,
  setFile,
}: CardListProps) => {
  const [useStyleZindex, setUseStyleZindex] = useState<string>("");

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles, idCard);
    },
  });

  const onShowImg = () => {
    setUseStyleZindex("p-image-mask");
  };
  const onHideImg = () => {
    setUseStyleZindex("");
  };

  return (
    <div className="cardImageContainer">
      <div className="cardImageContainerText">
        <h1 style={{ color: COLORS.primaryGreen }}>{name}</h1>
      </div>
      {src ? (
        <Image
          className={`${useStyleZindex} imageFit`}
          src={
            src.substring(0, 10) === "data:image"
              ? src
              : "https://apihomolog.guaracamp.com.br/" + src
          }
          alt={name}
          onShow={onShowImg}
          onHide={onHideImg}
          preview
        />
      ) : (
        <div
          className="divDropZone"
          {...getRootProps()}
          style={{ cursor: "pointer" }}
        >
          <input {...getInputProps()} />
        </div>
      )}
      <div className="cardImages-container-buttons">
        <div className="styles-buttons">
          {src ? (
            <Button
              value={idCard}
              icon="pi pi-trash"
              className="p-button-rounded p-button-danger p-button-outlined"
              onClick={() => handleDelete(idCard)}
            />
          ) : (
            <Button
              icon="pi pi-trash"
              className="p-button-rounded p-button-danger p-button-outlined"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CardImages;
