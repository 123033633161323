import { InputTextarea } from "primereact/inputtextarea"

interface DefaultInputProps {
    value: string;
    onChange: React.Dispatch<React.SetStateAction<string>>
    disabled: boolean;
    error?: string;
}

  const DefaultTextarea: React.FC<DefaultInputProps> = ({ value, onChange, disabled, error = '',}) => {
    return (
        <span className="text-area-input">
            <InputTextarea value={value} onChange={(e) => onChange(e.target.value)} disabled={disabled} autoResize/>
            {error && <span style={{ fontSize: '12px', color: 'red', margin: '0'}}>{error}</span>}
        </span>
    )   
}


export default DefaultTextarea
