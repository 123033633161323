import { Dropdown } from 'primereact/dropdown';

export interface SelectOptions {
    name: string;
    value: string | number;
}

interface DefaultSelectProps {
    label: string;
    value: string | number;
    options: SelectOptions[];
    onChange: any;
    idItem?: string;
    showLabel?: boolean;
    disabled?: boolean;
    error?: string
}

const DefaultSelect: React.FC<DefaultSelectProps> = ({ label, value, onChange, options, idItem, showLabel = true, disabled, error = '' }) => {
    return (
        <span className="field-group-item">
            { showLabel && <label className="field-group-label" htmlFor="in">{ label }</label> }
            <Dropdown
                optionLabel="name"
                id={idItem}
                value={value}
                options={options}
                onChange={(e) => onChange(e.target.value)}
                disabled={disabled}
                emptyMessage="Nenhum registro encontrado"
                showClear
            />
            {error && <span style={{ fontSize: '12px', color: 'red', margin: '0'}}>{error}</span>}
        </span>
    )
}

export default DefaultSelect
