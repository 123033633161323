import { confirmDialog } from "primereact/confirmdialog";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardListPromotion from "../../../components/Comercial/Promotion/CardList";
import ProgressLoad from "../../../components/ProgressLoad";
import { RootState } from "../../../store";
import {
  asyncDeletePromotion,
  asyncGetClientesApps,
  asyncGetProducts,
  asyncGetPromotions,
  asyncGetPromotionsAbrangenciaClienteApp,
  asyncGetPromotionsAbrangenciaTipoEstabelecimento,
} from "../../../store/comercial/comercial.actions";
import PromotionCreate from "./PromotionCreate";
import "./styles.css";

const Promotion: React.FC = () => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState("");

  const { loading } = useSelector((state: RootState) => state.loading);
  const comercial = useSelector((state: RootState) => state.comercial);

  const deleteConfirm = (e: any) => {
    const id = e.target.value | e.target.parentNode.value;
    confirmDialog({
      message: "Você quer deletar este registro?",
      header: "Confirmação de exclusão",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => handleDelete(id),
      acceptLabel: "Sim",
      rejectLabel: "Não",
    });
  };

  const handleDelete = (id: number) => {
    if (id) dispatch(asyncDeletePromotion(id));
  };

  const inputsSideBar = [
    {
      type: "text",
      label: "Descrição",
      value: description,
      onChange: setDescription,
    },
  ];

  useEffect(() => {
    dispatch(asyncGetPromotions());
    dispatch(asyncGetProducts());
    dispatch(asyncGetClientesApps());
    dispatch(asyncGetPromotionsAbrangenciaTipoEstabelecimento());
    dispatch(asyncGetPromotionsAbrangenciaClienteApp());
  }, []);

  return (
    <div className="main-page">
      <p className="title">Promoções</p>
      <PromotionCreate />

      {loading ? (
        <div className="divNotFind">
          <ProgressLoad />
        </div>
      ) : comercial.promotions.length > 0 ? (
        comercial.promotions.map((promotion, i) => (
          <CardListPromotion
            key={i}
            dsPromocao={promotion?.dsPromocao}
            cdPromocao={promotion?.cdPromocao}
            id={promotion?.id}
            inAtivo={promotion?.inAtivo}
            inputs={inputsSideBar}
            handleDelete={deleteConfirm}
          />
        ))
      ) : (
        <div className="divNotFind">
          <p>Não foi encontrado nenhum registro.</p>
        </div>
      )}
    </div>
  );
};

export default Promotion;
