import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Sidebar } from "primereact/sidebar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DefaultInput from "../../../../components/DefaultInput";
import { COLORS } from "../../../../constants";
import { RadioButton } from "primereact/radiobutton";
import DefaultSelect, {
  SelectOptions,
} from "../../../../components/DefaultSelect";
import {
  ClientesAppProps,
  CuponsAbrangenciaClienteAppProps,
  AbrangenciaTipoEstabelecimentoProps,
  CuponsCategoriasProps,
  CuponsProductsProps,
} from "../../../../store/marketing/marketing.store";
import { RootState } from "../../../../store";
import { asyncPostCupom } from "../../../../store/marketing/marketing.actions";
import ItensListCupons from "../../../../components/Marketing/ProductsList";
import CategoryListCupons from "../../../../components/Marketing/CategoriesList";
import ProgressLoad from "../../../../components/ProgressLoad";
import * as Yup from "yup";
import getValidationErrors from "../../../../util/validationError";
import { StateProps } from "../../../../enums";
import EstablishmentsTypesCupons from "../../../../components/Marketing/AbrangenciaEstablishmentList";
import Select from "react-select";
import { confirmDialog } from "primereact/confirmdialog";

interface Props {
  view?: boolean;
  id?: number;
  clientesApps?: ClientesAppProps[];
}

export type SelectProps = {
  label: string;
  value: number;
};

const categories = [
  { name: "Por pedido", value: 1 },
  { name: "Por categoria", value: 2 },
  { name: "Por produto", value: 3 },
];

const categoriesCoverage = [
  { name: "Todos", value: 0 },
  { name: "Tipo de estabelecimento", value: 1 },
  { name: "Cliente", value: 3 },
  // { name: 'Localização', value: 4 },
];

const CuponsCreate = ({ view }: Props) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [updateProductsCupons, setUpdateProductsCupons] = useState<
    CuponsProductsProps[]
  >([]);
  const [updateCategoriesCupons, setUpdateCategoriesCupons] = useState<
    CuponsCategoriasProps[]
  >([]);
  const [
    updateAbrangenciaTipoEstabelecimentoCupons,
    setUpdateAbrangenciaTipoEstabelecimentoCupons,
  ] = useState<AbrangenciaTipoEstabelecimentoProps[]>([]);
  const [
    updateAbrangenciaAppClientCupons,
    setUpdateAbrangenciaAppClientCupons,
  ] = useState<CuponsAbrangenciaClienteAppProps[]>([]);
  const [step, setStep] = useState(1);
  const [dsCupom, setDsCupom] = useState("");
  const [cdCupom, setCdCupom] = useState("");
  const [dtValidadeDe, setDtValidadeDe] = useState("");
  const [dtValidadeAte, setDtValidadeAte] = useState("");
  const [vlCupom, setVlCupom] = useState("");
  const [idTipoDesconto, setIdTipoDesconto] = useState("");
  const [inAtivo, setInAtivo] = useState<boolean>(false);
  const [inVisivel, setInVisivel] = useState<boolean>(false);
  const [qtLiberada, setQtLiberada] = useState("");
  const [qtUtilizada, setQtUtilizada] = useState("");
  const [errors, setErrors] = useState({} as any);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [selectedCategoryCoverage, setSelectedCategoryCoverage] = useState(
    categoriesCoverage[0]
  );
  const [allClients, setAllClients] = useState<any>([]);
  const [clientSelected, setClientSelected] = useState<any>("");
  const [filteredClients, setFilteredClients] = useState<ClientesAppProps[]>(
    []
  );
  const [buttonLoading, setButtonLoading] = useState(false);

  const { loading } = useSelector((state: RootState) => state.loading);
  const marketing = useSelector((state: RootState) => state.marketing);

  const deleteConfirm = (e: any) => {
    const id = e.target.value | e.target.parentNode.value;
    confirmDialog({
      message: "Você quer remover este registro?",
      header: "Confirmação de exclusão",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => handleDeleteClient(id),
      acceptLabel: "Sim",
      rejectLabel: "Não",
    });
  };

  const handleDeleteClient = (id: number) => {
    const removingClient = filteredClients.filter((client) => {
      return client?.idClienteApp !== id;
    });
    setFilteredClients(removingClient);
  };

  const handleAddToFilteredClients = () => {
    const clientsFiltered = [...filteredClients];

    const clientExist = clientsFiltered.findIndex(
      (client) => client.idClienteApp === clientSelected.value
    );

    if (clientExist < 0) {
      clientsFiltered.push({
        idClienteApp: clientSelected.value,
        nmCliente: clientSelected.label,
      });
    } else {
      return;
    }

    setFilteredClients(clientsFiltered);
  };

  const handleCancel = () => {
    setStep(1);
    setCdCupom("");
    setDsCupom("");
    setDtValidadeDe("");
    setDtValidadeAte("");
    setIdTipoDesconto("");
    setVlCupom("");
    setQtLiberada("0");
    setQtUtilizada("");
    setInAtivo(false);
    setSelectedCategory(categories[0]);
    setSelectedCategoryCoverage(categoriesCoverage[0]);
    setInVisivel(false);
    setVisible(false);
    setUpdateCategoriesCupons(marketing.cuponsCategoriesList);
    setUpdateProductsCupons(marketing.cuponsProductsList);
    setUpdateAbrangenciaTipoEstabelecimentoCupons(
      marketing.abrangenciaTiposEstabelecimentosList
    );
    setUpdateAbrangenciaAppClientCupons(
      marketing.cuponsAbrangenciaClienteAppList
    );
    setClientSelected("");
    setErrors("");
  };

  const options: SelectOptions[] = [
    {
      name: "Valor",
      value: 2,
    },
    {
      name: "Porcentagem",
      value: 1,
    },
  ];

  const handleCheckboxesProduto = (event: any) => {
    const index = updateProductsCupons.findIndex(
      (chk) => chk.idProduto == event.target.value
    );
    const newArr = [...updateProductsCupons];
    newArr[index] = { ...newArr[index], checked: !newArr[index].checked };

    setUpdateProductsCupons(newArr);
  };

  const handleCheckboxesCategoria = (event: any) => {
    const index = updateCategoriesCupons.findIndex(
      (chk) => chk.idCategoriaProduto == event.target.value
    );
    const newArr = [...updateCategoriesCupons];
    newArr[index] = { ...newArr[index], checked: !newArr[index].checked };

    setUpdateCategoriesCupons(newArr);
  };

  const handleCheckboxesEstabelecimentos = (event: any) => {
    const index = updateAbrangenciaTipoEstabelecimentoCupons.findIndex(
      (chk) => chk.idTipoEstabelecimento == event.target.value
    );
    const newArr = [...updateAbrangenciaTipoEstabelecimentoCupons];
    newArr[index] = { ...newArr[index], checked: !newArr[index].checked };

    setUpdateAbrangenciaTipoEstabelecimentoCupons(newArr);
  };

  const handleSubmit = async () => {
    setErrors({} as StateProps);
    setButtonLoading(true);

    try {
      const filteredProducts = updateProductsCupons.filter((item) => {
        return item.checked;
      });

      const filteredCategories = updateCategoriesCupons.filter((item) => {
        return item.checked;
      });

      const filteredAbrangenciaTipoEstabelecimento =
        updateAbrangenciaTipoEstabelecimentoCupons.filter((item) => {
          return item.checked;
        });

      const cupom = {
        cdCupom: cdCupom,
        dsCupom: dsCupom,
        dtValidadeDe: dtValidadeDe,
        dtValidadeAte: dtValidadeAte,
        inAtivo: inAtivo,
        inVisivel: inVisivel,
        idTipoDesconto: idTipoDesconto,
        qtLiberada: qtLiberada,
        qtUtilizada: qtUtilizada == '' ? 0 : qtUtilizada,
        vlCupom: vlCupom,
        idTipoAplicacao: selectedCategory.value,
        idTipoAbrangencia:
          selectedCategoryCoverage.value === 0
            ? null
            : selectedCategoryCoverage.value,
        itens: selectedCategory.value === 3 ? filteredProducts : [],
        categorias: selectedCategory.value === 2 ? filteredCategories : [],
        abrangenciaTipoEstabelecimento:
          selectedCategoryCoverage.value === 1
            ? filteredAbrangenciaTipoEstabelecimento
            : [],
        abrangenciaClienteApp:
          selectedCategoryCoverage.value === 3 ? filteredClients : [],
      };

      const schema = Yup.object().shape({
        cdCupom: Yup.string().required("Código do cupom obrigatório."),
        dsCupom: Yup.string().required("Descrição do cupom obrigatório."),
        dtValidadeDe: Yup.string().required(
          "Data de início do cupom obrigatório."
        ),
        dtValidadeAte: Yup.string().required(
          "Data de encerramento do cupom obrigatório."
        ),
        idTipoDesconto: Yup.string().required(
          "Tipo de desconto do cupom obrigatório."
        ),
        vlCupom: Yup.number()
          .typeError("Valor de desconto do cupom obrigatório.")
          .min(1, "O valor deve ser maior que 0."),
        // itens: Yup.object().required('Obrigatório pelo menos um item no cupom.'),
        // categorias: Yup.object().required('Obrigatório pelo menos uma categoria no cupom.'),
        qtLiberada: Yup.string().required(
          "Quantidade Liberada é obrigatória."
        ),
      });

      await schema.validate(cupom, {
        abortEarly: false,
      });

      dispatch(asyncPostCupom(cupom));

      setStep(1);
      setCdCupom("");
      setDsCupom("");
      setDtValidadeDe("");
      setDtValidadeAte("");
      setIdTipoDesconto("");
      setVlCupom("");
      setQtLiberada("");
      setQtUtilizada("0");
      setInAtivo(false);
      setInVisivel(false);
      setSelectedCategory(categories[0]);
      setSelectedCategoryCoverage(categories[0]);
      setUpdateCategoriesCupons(marketing.cuponsCategoriesList);
      setUpdateProductsCupons(marketing.cuponsProductsList);
      setUpdateAbrangenciaTipoEstabelecimentoCupons(
        marketing.abrangenciaTiposEstabelecimentosList
      );
      setUpdateAbrangenciaTipoEstabelecimentoCupons(
        marketing.abrangenciaTiposEstabelecimentosList
      );
      setFilteredClients([]);
      setClientSelected("");
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrors(getValidationErrors(err));
        return;
      }
    } finally {
      setTimeout(() => {
        setButtonLoading(false);
        setVisible(false);
      }, 2000);
    }
  };

  useEffect(() => {
    setUpdateProductsCupons(marketing.cuponsProductsList);
    setUpdateCategoriesCupons(marketing.cuponsCategoriesList);
    setUpdateAbrangenciaTipoEstabelecimentoCupons(
      marketing.abrangenciaTiposEstabelecimentosList
    );
    setAllClients(
      marketing.clientesAppList.map((client) => ({
        value: client?.idClienteApp,
        label: client?.nmCliente,
      }))
    );
  }, [
    marketing.cuponsProductsList,
    marketing.cuponsCategoriesList,
    marketing.abrangenciaTiposEstabelecimentosList,
    marketing.clientesAppList,
  ]);

  return (
    <div className="sidebarv2-wrapper">
      <Button
        label="Novo Cupom"
        onClick={() => setVisible(true)}
        className="sidebarv2-open-button"
        color="#AA0061"
      />

      <Sidebar
        className="sidebar-cupons-container"
        visible={visible}
        position="right"
        onHide={handleCancel}
      >
        <div className="sidebar-cupons-content-top">
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-danger p-button-text"
            onClick={handleCancel}
          />
          <p
            style={{ color: COLORS.primaryGreen }}
            className="sidebar-cupons-title"
          >
            Cadastrar Cupom
          </p>

          {step === 1 && (
            <div className="sidebar-cupons-board">
              <div className="sidebar-cupons-buttons">
                <div className="sidebar-cupons-active">
                  <button
                    value={step}
                    onClick={() => setStep(1)}
                    className="cupons-buttons-active"
                  >
                    Cupom
                  </button>
                </div>
                <div className="sidebar-cupons-noactive">
                  {selectedCategory.value === 1 ? (
                    <button
                      value={step}
                      onClick={() => setStep(2)}
                      className="cupons-buttons-noactive"
                      disabled
                    >
                      Aplicação
                    </button>
                  ) : (
                    <button
                      value={step}
                      onClick={() => setStep(2)}
                      className="cupons-buttons-noactive"
                    >
                      Aplicação
                    </button>
                  )}
                </div>
                <div className="sidebar-cupons-noactive">
                  {selectedCategoryCoverage.value === 0 ? (
                    <button
                      value={step}
                      onClick={() => setStep(3)}
                      className="cupons-buttons-noactive"
                      disabled
                    >
                      Abrangência
                    </button>
                  ) : (
                    <button
                      value={step}
                      onClick={() => setStep(3)}
                      className="cupons-buttons-noactive"
                    >
                      Abrangência
                    </button>
                  )}
                </div>
              </div>
              <div className="sidebar-cupons-content">
                <div className="sidebar-cupons-sectionOne">
                  <DefaultInput
                    label="Código"
                    value={cdCupom}
                    onChange={setCdCupom}
                    disabled={false}
                    error={errors.cdCupom}
                    maxLength={15}
                  />
                  <DefaultInput
                    label="Descrição"
                    value={dsCupom}
                    onChange={setDsCupom}
                    disabled={false}
                    error={errors.dsCupom}
                    maxLength={30}
                  />
                </div>
                <div className="sidebar-cupons-date">
                  <DefaultInput
                    label="De"
                    type="date"
                    value={dtValidadeDe}
                    onChange={setDtValidadeDe}
                    disabled={false}
                    error={errors.dtvalidadeDe}
                  />
                  <DefaultInput
                    label="Até"
                    type="date"
                    value={dtValidadeAte}
                    onChange={setDtValidadeAte}
                    disabled={false}
                    min={dtValidadeDe}
                    error={errors.dtValidadeAte}
                  />
                </div>
                <div className="sidebar-cupons-sectionTwo">
                  <DefaultSelect
                    label="Tipo de desconto"
                    options={options}
                    onChange={setIdTipoDesconto}
                    idItem={idTipoDesconto}
                    value={idTipoDesconto}
                    disabled={view && true}
                    error={errors.idTipoDesconto}
                  />
                  <div>
                    <DefaultInput
                      type="number"
                      label="Valor/Percentual"
                      value={vlCupom}
                      onChange={setVlCupom}
                      disabled={false}
                      min={0}
                      error={errors.vlCupom}
                    />
                  </div>
                </div>
                <div className="sidebar-cupons-sectionOne">
                <DefaultInput
                      type="number"
                      label="Quantidade Liberada"
                      value={qtLiberada}
                      onChange={setQtLiberada}
                      disabled={false}
                      min={0}
                      error={errors.QtLiberada}
                    />
                </div>
                <div className="sidebar-cupons-sectionCheckbox">
                  <div className="p-field-checkbox">
                    <Checkbox
                      inputId="active"
                      checked={inAtivo}
                      onChange={(e) => setInAtivo(e.checked)}
                    />
                    <label htmlFor="active" style={{ marginLeft: 4 }}>
                      Ativo
                    </label>
                  </div>

                  <div className="p-field-checkbox">
                    <Checkbox
                      inputId="plataformVisible"
                      checked={inVisivel}
                      onChange={(e) => setInVisivel(e.checked)}
                    />
                    <label htmlFor="active" style={{ marginLeft: 4 }}>
                      Visível nas plataformas
                    </label>
                  </div>
                </div>

                <h4>Aplicação</h4>
                <div className="sidebar-cupons-sectionThree">
                  {categories.map((category) => {
                    return (
                      <div key={category.value} className="p-field-radiobutton">
                        <RadioButton
                          inputId={category.name}
                          name="category"
                          value={category}
                          onChange={(e) => setSelectedCategory(e.value)}
                          checked={selectedCategory.value === category.value}
                          disabled={false}
                          className="radiobutton-style"
                        />
                        <label htmlFor={category.name}>{category.name}</label>
                      </div>
                    );
                  })}
                </div>
                <h4>Abrangência</h4>
                <div className="sidebar-promotion-coverage">
                  {categoriesCoverage.map((category) => {
                    return (
                      <div key={category.value} className="p-field-radiobutton">
                        <RadioButton
                          inputId={category.name}
                          name="category"
                          value={category}
                          onChange={(e) => setSelectedCategoryCoverage(e.value)}
                          checked={
                            selectedCategoryCoverage.value === category.value
                          }
                          disabled={false}
                          className="radiobutton-style"
                        />
                        <label htmlFor={category.name}>{category.name}</label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="sidebar-cupons-board">
              <div className="sidebar-cupons-buttons">
                <div className="sidebar-cupons-noactive">
                  <button
                    value={step}
                    onClick={() => setStep(1)}
                    className="cupons-buttons-noactive"
                  >
                    Cupom
                  </button>
                </div>
                <div className="sidebar-cupons-active">
                  <button
                    value={step}
                    onClick={() => setStep(2)}
                    className="cupons-buttons-active"
                  >
                    Aplicação
                  </button>
                </div>
                <div className="sidebar-cupons-noactive">
                  {selectedCategoryCoverage.value === 0 ? (
                    <button
                      value={step}
                      onClick={() => setStep(3)}
                      className="cupons-buttons-noactive"
                      disabled
                    >
                      Abrangência
                    </button>
                  ) : (
                    <button
                      value={step}
                      onClick={() => setStep(3)}
                      className="cupons-buttons-noactive"
                    >
                      Abrangência
                    </button>
                  )}
                </div>
              </div>

              {selectedCategory.value === 2 && (
                <div className="sidebar-cupons-itens">
                  <h3>Categorias</h3>
                  <div className="sidebar-cupons-categories-products">
                    {loading ? (
                      <div className="divNotFind">
                        <ProgressLoad />
                      </div>
                    ) : (
                      <CategoryListCupons
                        handleCheckboxes={handleCheckboxesCategoria}
                        itens={updateCategoriesCupons}
                      />
                    )}
                  </div>
                </div>
              )}
              {selectedCategory.value === 3 && (
                <div className="sidebar-cupons-itens">
                  <h3>Produtos</h3>
                  <div className="sidebar-cupons-categories-products">
                    {loading ? (
                      <div className="divNotFind">
                        <ProgressLoad />
                      </div>
                    ) : (
                      <ItensListCupons
                        handleCheckboxes={handleCheckboxesProduto}
                        itens={updateProductsCupons}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {step === 3 && (
            <div className="sidebar-cupons-board">
              <div className="sidebar-cupons-buttons">
                <div className="sidebar-cupons-noactive">
                  <button
                    value={step}
                    onClick={() => setStep(1)}
                    className="cupons-buttons-noactive"
                  >
                    Cupom
                  </button>
                </div>
                <div className="sidebar-cupons-noactive">
                  {selectedCategory.value === 1 ? (
                    <button
                      value={step}
                      onClick={() => setStep(2)}
                      className="cupons-buttons-noactive"
                      disabled
                    >
                      Aplicação
                    </button>
                  ) : (
                    <button
                      value={step}
                      onClick={() => setStep(2)}
                      className="cupons-buttons-noactive"
                    >
                      Aplicação
                    </button>
                  )}
                </div>
                <div className="sidebar-cupons-active">
                  <button
                    value={step}
                    onClick={() => setStep(3)}
                    className="cupons-buttons-active"
                  >
                    Abrangência
                  </button>
                </div>
              </div>

              {selectedCategoryCoverage.value === 1 && (
                <div className="sidebar-cupons-abrangencia-container">
                  <h3>Tipos de Estabelecimento</h3>
                  <div className="sidebar-cupons-establishmentsTypes-content">
                    {loading ? (
                      <div className="divNotFind">
                        <ProgressLoad />
                      </div>
                    ) : (
                      <EstablishmentsTypesCupons
                        handleCheckboxes={handleCheckboxesEstabelecimentos}
                        establishments={
                          updateAbrangenciaTipoEstabelecimentoCupons
                        }
                      />
                    )}
                  </div>
                </div>
              )}

              {selectedCategoryCoverage.value === 3 && (
                <div className="sidebar-cupons-abrangencia-container">
                  <h3>Clientes disponíveis</h3>
                  <div className="sidebar-cupons-clients-content">
                    {loading ? (
                      <div className="divNotFind">
                        <ProgressLoad />
                      </div>
                    ) : (
                      <div className="sidebar-cupons-clients-input">
                        <div className="sidebar-cupons-clients-input-header">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder="Clientes..."
                            value={clientSelected}
                            isClearable={true}
                            isSearchable={true}
                            options={allClients}
                            onChange={(text) => {
                              setClientSelected(text as SelectProps);
                            }}
                            noOptionsMessage={() =>
                              "Não foi encontrado nenhum registro."
                            }
                          />
                          {clientSelected !== "" ? (
                            <Button
                              label="Adicionar"
                              className="p-button-raised p-button-secondary p-button-text"
                              style={{ height: 36, marginLeft: 20 }}
                              onClick={handleAddToFilteredClients}
                            />
                          ) : (
                            <Button
                              label="Adicionar"
                              className="p-button-raised p-button-secondary p-button-text"
                              style={{ height: 36, marginLeft: 20 }}
                              onClick={handleAddToFilteredClients}
                              disabled
                            />
                          )}
                        </div>
                        <h1 style={{ margin: 0, fontSize: 20, marginTop: 24 }}>
                          Clientes Selecionados
                        </h1>
                        <div className="sidebar-cupons-clients-filteredList">
                          {filteredClients.length > 0 ? (
                            filteredClients.map((client) => {
                              return (
                                <div
                                  key={client?.idClienteApp}
                                  className="sidebar-cupons-clients-filteredList-client"
                                >
                                  <h1>{client?.nmCliente}</h1>
                                  <Button
                                    value={client?.idClienteApp}
                                    icon="pi pi-trash"
                                    className="p-button-rounded p-button-danger p-button-outlined"
                                    onClick={deleteConfirm}
                                    style={{ width: 28, height: 28 }}
                                  />
                                </div>
                              );
                            })
                          ) : (
                            <h1 style={{ margin: 0, fontSize: 16 }}>
                              Nenhum cliente foi adicionado.
                            </h1>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* {selectedCategoryCoverage.value === 4 && (
            <div className='sidebar-cupons-abrangencia-container'>
              <h3>Localização</h3>
              <div className='sidebar-cupons-localization-content'>
                {loading ? (
                  <div className='divNotFind'>
                    <ProgressLoad />
                  </div>
                ) : (
                  // <ItensListCupons
                  //   handleCheckboxes={handleCheckboxesProduto}
                  //   itens={updateProductsCupons}
                  // />
                  <div className='sidebar-cupons-localization-selects'>
                    <div className='localization-selects-content'>
                      <div>
                          <p>UF</p>
                          <Dropdown value={selectUF} emptyMessage='Não foi encontrado nenhum registro.'  onChange={(e) => setSelectUF(e.value)} className='localization-selects'/>
                      </div>
                      <div>
                          <p>Cidade</p>
                          <Dropdown value={selectUF} emptyMessage='Não foi encontrado nenhum registro.'  onChange={(e) => setSelectUF(e.value)} className='localization-selects'/>
                      </div>
                      <div>
                          <p>Bairro</p>
                          <Dropdown value={selectUF} emptyMessage='Não foi encontrado nenhum registro.'  onChange={(e) => setSelectUF(e.value)} className='localization-selects'/>
                      </div>
                      <button style={{width: '140px', height: '28px', marginTop: '60px'}}>Adicionar</button>
                    </div>
                  
                    <div className='sidebar-cupons-localization-list'>
                      <h3>Locais selecionados</h3>
                      <span>Bairro, cidade, UF</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )} */}
        </div>
        <div className="sidebar-cupons-content-bottom">
          <div className="sidebar-cupons-actions">
            <Button
              label="Cancelar"
              className="p-button-outlined p-button-secondary"
              onClick={handleCancel}
              style={{ marginRight: "20px", width: "40%" }}
            />
            <Button
              id="button"
              label="Salvar"
              className="p-button-lg"
              onClick={handleSubmit}
              style={{ width: "40%" }}
              loading={buttonLoading}
            />
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default CuponsCreate;

// autocomplete do prime
// const [allClients, setAllClients] = useState<any>([]);
// const [clienteSelected, setClienteSelected] = useState<any>(null);
// const searchClient = (e: { query: string }) => {
//   let filterClient;
//   if (!e.query.trim().length) {
//     filterClient = [...allClients];
//   } else {
//     filterClient = allClients.filter((client: any) => {
//       return client.nome.toLowerCase().startsWith(e.query.toLowerCase());
//     });
//   }

//   setFilteredClients(filterClient);
// };

// setAllClients(marketing.clientesAppList);
// setFilteredClients(marketing.clientesAppList);

{
  /* <AutoComplete
                            value={clienteSelected}
                            suggestions={filteredClients}
                            completeMethod={searchClient}
                            field="nome"
                            onChange={(e) => setClienteSelected(e.value)}
                          /> */
}
