export enum StatusComodatos {
  solicitado = 1,
  reprovado,
  aprovado,
  aguardandoVisitaComercial,
  emElaboracaoContratual,
  aguardandoEntrega,
  entregue,
  cancelado,
  aguardandoContatoTelefonico,
  aguardandoVisitaTecnica,
  aguardandoTrocaDoProduto,
  concluido,
  contratoCancelado,
}
