import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { AppData, EnderecoApp } from "..";
import DefaultRadioButton from "../../DefaultRadioButton";
import "../../FieldGroup/styles.css";
import "../styles.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { cellMask, cepMask, cpfCnpjMask, phoneMask } from "../../../util/mask";
import { updateConfirmData } from "../../../store/approvals/approvals.store";
import { ApprovalTypes } from "../../../enums/ApprovalsTypes";
import {
  asyncGetAppData,
  asyncGetErpData,
  asyncGetPreData,
} from "../../../store/approvals/approvals.actions";
import { updateToaster } from "../../../store/helper/helper.store";
import { ToasterTypes } from "../../../enums/ToasterTypes";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";

interface ApprovallAppProps {}

const ApprovalApp: React.FC<ApprovallAppProps> = () => {
  const dispatch = useDispatch();
  const [chkDados, setChkDados] = useState("");
  const [inputs, setInputs] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      approvals.currentApproval.idTipoSolicitacao !== ApprovalTypes.associacao
    ) {
      dispatch(updateConfirmData(inputs));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    // if(approvals.currentApproval.idTipoSolicitacao !== ApprovalTypes.associacao){
    dispatch(updateConfirmData(inputs));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, inputs]);

  const approvals = useSelector((state: RootState) => state.approvals);

  const handleInput =
    (fieldName: keyof AppData) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const arr: any = { ...inputs, [fieldName]: e.target.value };
      setInputs(arr);
    };

  const handleAddressInput =
    (fieldName: keyof EnderecoApp) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let index = inputs.enderecos.findIndex(
        (item: any) => item.idTipoEndereco === 1
      );

      if (index >= 0) {
        let inputArr = { ...inputs };
        const newArr = [...inputs.enderecos];
        newArr[index] = { ...newArr[index], [fieldName]: e.target.value };
        inputArr.enderecos = newArr;
        setInputs(inputArr);
      } else {
        let inputArr = { ...inputs };
        const newArr = [...inputs?.enderecos];
        newArr.push({
          txCep: "",
          txLogradouro: "",
          nmBairro: "",
          txNumero: "",
          nmCidade: "",
          txComplemento: "",
          txPontoReferencia: "",
          cdUf: "",
          idTipoEndereco: 1,
        });
        index = newArr.findIndex((item: any) => item.idTipoEndereco === 1);
        const result = { ...newArr[index], [fieldName]: e.target.value };
        inputArr.enderecos = [...inputArr.enderecos, result];
        setInputs(inputArr);
      }
    };

  const handleDeliveryAddressInput =
    (fieldName: keyof EnderecoApp) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let index = inputs.enderecos.findIndex(
        (item: any) => item.idTipoEndereco === 2
      );

      if (index >= 0) {
        let inputArr = { ...inputs };
        const newArr = [...inputs.enderecos];
        newArr[index] = { ...newArr[index], [fieldName]: e.target.value };
        inputArr.enderecos = newArr;
        setInputs(inputArr);
      } else {
        let inputArr = { ...inputs };
        const newArr = [...inputs?.enderecos];
        newArr.push({
          txCep: "",
          txLogradouro: "",
          nmBairro: "",
          txNumero: "",
          nmCidade: "",
          txCmplemento: "",
          txPontoReferencia: "",
          cdUf: "",
          idTipoEndereco: 2,
        });
        index = newArr.findIndex((item: any) => item.idTipoEndereco === 2);
        const result = { ...newArr[index], [fieldName]: e.target.value };
        inputArr.enderecos = [...inputArr.enderecos, result];
        setInputs(inputArr);
      }
    };

  const handleRadioButton = (e: any) => {
    if (
      (approvals.currentApproval.idTipoSolicitacao ===
        ApprovalTypes.alteração ||
        approvals.currentApproval.idTipoSolicitacao ===
          ApprovalTypes.cadastro) &&
      e === "dados"
    ) {
      setInputs(approvals.evaluation.pre);
    } else if (
      (approvals.currentApproval.idTipoSolicitacao ===
        ApprovalTypes.alteração ||
        approvals.currentApproval.idTipoSolicitacao ===
          ApprovalTypes.cadastro) &&
      e === "erp"
    ) {
      setInputs(approvals.evaluation.erp);
    }

    setChkDados(e);
  };

  const getAppData = async (id: number) => {
    let request = await asyncGetAppData(id)
      .then((value: any) => {
        if (value.data === null) {
          setInputs(null);
        } else {
          setInputs(value.data);
        }
      })
      .catch((error: any) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };

  const getPreData = async (id: number) => {
    setLoading(true);
    let request = await asyncGetPreData(id)
      .then((value: any) => {
        if (value.data === null) {
          setInputs(null);
        } else {
          setInputs(value.data);
        }
      })
      .catch((error: any) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      approvals.currentApproval.idTipoSolicitacao === ApprovalTypes.associacao
    ) {
      getAppData(approvals.currentApproval.idClienteApp);
    }

    if (
      approvals.currentApproval.idTipoSolicitacao === ApprovalTypes.cadastro &&
      approvals.currentApproval.status.id !== 2
    ) {
      setInputs({
        txCpfcnpj: "",
        nmCliente: "",
        txEmail: "",
        txCelular: "",
        txTelefone: "",
        tipoCliente: "",
        enderecos: [],
      });
    } else {
      getPreData(approvals.currentApproval.idClientePreCadastro);
      // getErpData(approvals.evaluation.erp);
    }

    if (
      approvals.currentApproval.idTipoSolicitacao === ApprovalTypes.alteração
    ) {
      getPreData(approvals.currentApproval.idClientePreCadastro);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const numeroDocumento = inputs?.numeroDocumento
    ? inputs?.numeroDocumento
    : inputs?.txCpfCnpj;

  return !inputs ? (
    <div />
  ) : (
    <>
      {approvals.currentApproval.idTipoSolicitacao !==
        ApprovalTypes.associacao &&
        approvals.currentApproval.tipoAprovacao.id !== 2 &&
        approvals.currentApproval.status.id === 1 && (
          <div className="approvalfiels-top-radios">
            <DefaultRadioButton
              label="Usar dados de cadastro"
              value={chkDados}
              onChange={handleRadioButton}
              name="dados"
            />
            <DefaultRadioButton
              label="Usar dados ERP"
              value={chkDados}
              onChange={handleRadioButton}
              name="erp"
              disabled={!approvals.evaluation.erp}
            />
            {/* <DefaultRadioButton
                    label="Usar dados SERPRO"
                    value={chkDados}
                    onChange={handleRadioButton}
                    name="serpro"
                    disabled={true}
                /> */}
          </div>
        )}
      <div className="field-group-container">
        <span className="field-group-item">
          <label className="field-group-label" htmlFor="in">
            Tipo de estabelecimento
          </label>
          <InputText
            id="in"
            placeholder={
              inputs?.tipoEstabelecimento?.dsTipoEstabelecimento
                ? inputs?.tipoEstabelecimento.dsTipoEstabelecimento
                : ""
            }
            disabled={true}
          />
        </span>
        <span className="field-group-item">
          <label className="field-group-label" htmlFor="in">
            Tipo de cliente
          </label>
          <InputText
            id="in"
            placeholder={
              inputs?.tipoPessoa?.dsTipoPessoa
                ? inputs?.tipoPessoa.dsTipoPessoa
                : ""
            }
            disabled={true}
          />
        </span>
      </div>
      <>
        <div className="title-field">
          <p>Dados</p>
        </div>
        <div className="field-group-container">
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              CNPJ / CPF
            </label>
            <InputText
              id="in"
              onChange={handleInput("txCpfCnpj")}
              value={cpfCnpjMask(numeroDocumento)}
              disabled={true}
              className={
                approvals.currentApproval.idTipoSolicitacao !==
                  ApprovalTypes.associacao &&
                inputs?.txCpfCnpj !== numeroDocumento
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.currentApproval.idTipoSolicitacao !==
              ApprovalTypes.associacao &&
              approvals.evaluation.app &&
              inputs?.txCpfCnpj !== numeroDocumento && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Razão Social / Nome
            </label>
            <InputText
              id="in"
              name="nmCliente"
              onChange={handleInput("nmCliente")}
              value={inputs?.nmCliente}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id !== 1) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id !== 1)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.nmCliente !== approvals.evaluation.app.nmCliente
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.evaluation.app &&
              inputs?.nmCliente !== approvals.evaluation.app.nmCliente && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              E-mail
            </label>
            <InputText
              id="in"
              onChange={handleInput("txEmail")}
              value={inputs?.txEmail}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id === 2) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id === 2)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.txEmail !== approvals.evaluation.app.txEmail
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.evaluation.app &&
              inputs?.txEmail !== approvals.evaluation.app.txEmail && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Celular
            </label>
            <InputText
              id="in"
              onChange={handleInput("txCelular")}
              value={cellMask(inputs?.txCelular)}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id === 2) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id === 2)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.txCelular !== approvals.evaluation.app.txCelular
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.evaluation.app &&
              inputs?.txCelular !== approvals.evaluation.app.txCelular && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Telefone
            </label>
            <InputText
              id="in"
              onChange={handleInput("txTelefone")}
              value={phoneMask(inputs?.txTelefone)}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id === 2) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id === 2)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.txTelefone !== approvals.evaluation.app.txTelefone
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.evaluation.app &&
              inputs?.txTelefone !== approvals.evaluation.app.txTelefone && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Nome Fantasia
            </label>
            <InputText
              id="in"
              onChange={handleInput("nmFantasia")}
              value={inputs?.nmFantasia}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id === 2) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id === 2)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.nmFantasia !== approvals.evaluation.app.nmFantasia
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.evaluation.app &&
              inputs?.nmFantasia !== approvals.evaluation.app.nmFantasia && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
        </div>
      </>
      <>
        <div className="title-field">
          <p>Endereço de Cadastro</p>
        </div>
        <div className="field-group-container">
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              CEP
            </label>
            <InputText
              id="in"
              onChange={handleAddressInput("txCep")}
              value={cepMask(inputs?.enderecos[0]?.txCep)}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id === 2) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id === 2)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.enderecos[0]?.txCep !==
                  approvals.evaluation.app.enderecos[0]?.txCep
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.evaluation.app &&
              inputs?.enderecos[0]?.txCep !==
                approvals.evaluation.app.enderecos[0]?.txCep && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Logradouro
            </label>
            <InputText
              id="in"
              onChange={handleAddressInput("txLogradouro")}
              value={inputs?.enderecos[0]?.txLogradouro}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id === 2) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id === 2)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.enderecos[0]?.txLogradouro !==
                  approvals.evaluation.app.enderecos[0]?.txLogradouro
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.evaluation.app &&
              inputs?.enderecos[0]?.txLogradouro !==
                approvals.evaluation.app.enderecos[0]?.txLogradouro && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Número
            </label>
            <InputText
              id="in"
              onChange={handleAddressInput("txNumero")}
              value={chkDados === "erp" ? "" : inputs?.enderecos[0]?.txNumero}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id === 2) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id === 2)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.enderecos[0]?.txNumero !==
                  approvals.evaluation.app.enderecos[0]?.txNumero
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.evaluation.app &&
              inputs?.enderecos[0]?.txNumero !==
                approvals.evaluation.app.enderecos[0]?.txNumero && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Bairro
            </label>
            <InputText
              id="in"
              onChange={handleAddressInput("nmBairro")}
              value={inputs?.enderecos[0]?.nmBairro}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id === 2) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id === 2)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.enderecos[0]?.nmBairro !==
                  approvals.evaluation.app.enderecos[0]?.nmBairro
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.evaluation.app &&
              inputs?.enderecos[0]?.nmBairro !==
                approvals.evaluation.app.enderecos[0]?.nmBairro && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Cidade
            </label>
            <InputText
              id="in"
              onChange={handleAddressInput("nmCidade")}
              value={inputs?.enderecos[0]?.nmCidade}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id === 2) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id === 2)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.enderecos[0]?.nmCidade !==
                  approvals.evaluation.app.enderecos[0]?.nmCidade
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.evaluation.app &&
              inputs?.enderecos[0]?.nmCidade !==
                approvals.evaluation.app.enderecos[0]?.nmCidade && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              UF
            </label>
            <InputText
              id="in"
              onChange={handleAddressInput("cdUf")}
              value={inputs?.enderecos[0]?.cdUf}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id === 2) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id === 2)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.enderecos[0]?.cdUf !==
                  approvals.evaluation.app.enderecos[0]?.cdUf
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.evaluation.app &&
              inputs?.enderecos[0]?.cdUf !==
                approvals.evaluation.app.enderecos[0]?.cdUf && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Complemento
            </label>
            <InputText
              id="in"
              onChange={handleAddressInput("txComplemento")}
              value={inputs?.enderecos[0]?.txComplemento}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id === 2) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id === 2)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.enderecos[0]?.txComplemento !==
                  approvals.evaluation.app.enderecos[0]?.txComplemento
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.evaluation.app &&
              inputs?.enderecos[0]?.txComplemento !==
                approvals.evaluation.app.enderecos[0]?.txComplemento && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Ponto de Referência
            </label>
            <InputText
              id="in"
              onChange={handleAddressInput("txPontoReferencia")}
              value={inputs?.enderecos[0]?.txPontoReferencia}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id === 2) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id === 2)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.enderecos[0]?.txPontoReferencia !==
                  approvals.evaluation.app.enderecos[0]?.txPontoReferencia
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.evaluation.app &&
              inputs?.enderecos[0]?.txPontoReferencia !==
                approvals.evaluation.app.enderecos[0]?.txPontoReferencia && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
        </div>
      </>
      <>
        <div className="title-field">
          <p>Endereço de Entrega</p>
        </div>
        <div className="field-group-container">
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              CEP
            </label>
            <InputText
              id="in"
              onChange={handleDeliveryAddressInput("txCep")}
              value={inputs?.enderecos[1]?.txCep}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id === 2) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id === 2)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.enderecos[1]?.txCep !==
                  approvals.evaluation.app.enderecos[1]?.txCep
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.currentApproval.idTipoSolicitacao ===
              ApprovalTypes.alteração &&
              approvals.evaluation.app &&
              inputs?.enderecos[1]?.txCep !==
                approvals.evaluation.app.enderecos[1]?.txCep && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Logradouro
            </label>
            <InputText
              id="in"
              onChange={handleDeliveryAddressInput("txLogradouro")}
              value={inputs?.enderecos[1]?.txLogradouro}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id === 2) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id === 2)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.enderecos[1]?.txLogradouro !==
                  approvals.evaluation.app.enderecos[1]?.txLogradouro
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.evaluation.app &&
              inputs?.enderecos[1]?.txLogradouro !==
                approvals.evaluation.app.enderecos[1]?.txLogradouro && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Número
            </label>
            <InputText
              id="in"
              onChange={handleDeliveryAddressInput("txNumero")}
              value={chkDados === "erp" ? "" : inputs?.enderecos[1]?.txNumero}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id === 2) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id === 2)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.enderecos[1]?.txNumero !==
                  approvals.evaluation.app.enderecos[1]?.txNumero
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.evaluation.app &&
              inputs?.enderecos[1]?.txNumero !==
                approvals.evaluation.app.enderecos[1]?.txNumero && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Bairro
            </label>
            <InputText
              id="in"
              onChange={handleDeliveryAddressInput("nmBairro")}
              value={inputs?.enderecos[1]?.nmBairro}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id === 2) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id === 2)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.enderecos[1]?.nmBairro !==
                  approvals.evaluation.app.enderecos[1]?.nmBairro
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.evaluation.app &&
              inputs?.enderecos[1]?.nmBairro !==
                approvals.evaluation.app.enderecos[1]?.nmBairro && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Cidade
            </label>
            <InputText
              id="in"
              onChange={handleDeliveryAddressInput("nmCidade")}
              value={inputs?.enderecos[1]?.nmCidade}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id === 2) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id === 2)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.enderecos[1]?.nmCidade !==
                  approvals.evaluation.app.enderecos[1]?.nmCidade
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.evaluation.app &&
              inputs?.enderecos[1]?.nmCidade !==
                approvals.evaluation.app.enderecos[1]?.nmCidade && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              UF
            </label>
            <InputText
              id="in"
              onChange={handleAddressInput("cdUf")}
              value={inputs?.enderecos[1]?.cdUf}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id === 2) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id === 2)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.enderecos[1]?.cdUf !==
                  approvals.evaluation.app.enderecos[1]?.cdUf
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.evaluation.app &&
              inputs?.enderecos[1]?.cdUf !==
                approvals.evaluation.app.enderecos[1]?.cdUf && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Complemento
            </label>
            <InputText
              id="in"
              onChange={handleDeliveryAddressInput("txComplemento")}
              value={inputs?.enderecos[1]?.txComplemento}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id === 2) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id === 2)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.enderecos[1]?.txComplemento !==
                  approvals.evaluation.app.enderecos[1]?.txComplemento
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.evaluation.app &&
              inputs?.enderecos[1]?.txComplemento !==
                approvals.evaluation.app.enderecos[1]?.txComplemento && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Ponto de Referência
            </label>
            <InputText
              id="in"
              onChange={handleDeliveryAddressInput("txPontoReferencia")}
              value={inputs?.enderecos[1]?.txPontoReferencia}
              disabled={
                approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.associacao ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.cadastro &&
                  approvals.currentApproval.status.id === 2) ||
                (approvals.currentApproval.idTipoSolicitacao ===
                  ApprovalTypes.alteração &&
                  approvals.currentApproval.status.id === 2)
                  ? true
                  : false
              }
              className={
                approvals.evaluation.app &&
                inputs?.enderecos[1]?.txPontoReferencia !==
                  approvals.evaluation.app.enderecos[1]?.txPontoReferencia
                  ? "p-invalid p-d-block"
                  : ""
              }
            />
            {approvals.evaluation.app &&
              inputs?.enderecos[1]?.txPontoReferencia !==
                approvals.evaluation.app.enderecos[1]?.txPontoReferencia && (
                <small id="username2-help" className="p-error p-d-block">
                  Dado alterado
                </small>
              )}
          </span>
        </div>
      </>
    </>
  );
};

export default ApprovalApp;
