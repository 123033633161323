import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DefaultInput from "../../../components/DefaultInput";
import DefaultSelect from "../../../components/DefaultSelect";
import { RootState } from "../../../store";
import {
  getApprovalStatus,
  getApprovalTypes,
  seacrhApprovals,
} from "../../../store/approvals/approvals.actions";
import { cpfCnpjMask, onlyNumbers } from "../../../util/mask";
import { COLORS } from "../../../constants";

import "./styles.css";
import {
  getComodatoStatus,
  getComodatoTypes,
  searchComodatos,
} from "../../../store/comodatos/comodatos.actions";
const ComodatosFilter = () => {
  const dispatch = useDispatch();
  const [type, setType] = useState<any>(null);
  const [status, setStatus] = useState<any>(null);
  const [document, setDocument] = useState("");

  const comodatos = useSelector((state: RootState) => state.comodatos);

  const documentModified = cpfCnpjMask(document);

  useEffect(() => {
    dispatch(getComodatoTypes());
    dispatch(getComodatoStatus());
  }, [dispatch]);

  const handleFilter = () => {
    dispatch(
      searchComodatos({ type, status, document: onlyNumbers(document) })
    );
  };

  const handleClickClean = () => {
    setType("");
    setStatus("");
    setDocument("#");
  };

  return (
    <div className="approvals-filter-container">
      <div className="approvals-filter-inputs">
        <DefaultSelect
          label="Tipo"
          value={type}
          onChange={setType}
          options={comodatos.types}
        />
        <DefaultSelect
          label="Status"
          value={status}
          onChange={setStatus}
          options={comodatos.status}
        />
        <DefaultInput
          label="CNPJ / CPF"
          value={cpfCnpjMask(document)}
          onChange={(t) => setDocument(onlyNumbers(t))}
          disabled={false}
        />
      </div>
      <div>
        <Button
          label="Limpar filtros"
          style={{ color: COLORS.primaryGreen }}
          className="p-button-outlined"
          onClick={handleClickClean}
        />
        <Button
          onClick={handleFilter}
          className="approvals-filter-button"
          label="Pesquisar"
        />
      </div>
    </div>
  );
};

export default ComodatosFilter;
