import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ApprovalTypes } from '../../enums/ApprovalsTypes';
import { ToasterTypes } from '../../enums/ToasterTypes';
import GuaracampAPI from '../../services/GuaracampAPI';
import { RootState } from '../../store';
import { approve, disapprove } from '../../store/approvals/approvals.actions';
import { updateToaster } from '../../store/helper/helper.store';
import { cellMask } from '../../util/mask';
import './styles.css';

const ApprovalUserInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentApproval, evaluation } = useSelector(
    (state: RootState) => state.approvals
  );

  const styles = {
    actions: {
      display: 'flex',
      alignItems: 'center',
    },
    infoText: {
      margin: '10px 0',
    },
  };

  const disaproveConfirm = (e: any) => {
    confirmDialog({
      message: 'Você quer reprovar esta solicitação?',
      header: 'Confirmação',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-success',
      accept: handleDisapprove,
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
    });
  };

  const acceptConfirm = (e: any) => {
    confirmDialog({
      message: 'Você quer aprovar esta solicitação?',
      header: 'Confirmação',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-success',
      accept: handleApprove,
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
    });
  };

  const handleDisapprove = async () => {
    try {
      const response: any = await disapprove(currentApproval.id);
      dispatch(
        updateToaster({
          type: ToasterTypes.success,
          title: 'Sucesso',
          message: 'Alteração realizada com sucesso',
        })
      );
      if (response.status == 200) {
        history.push('/approvals');
      }
    } catch (error: any) {
      dispatch(
        updateToaster({
          type: ToasterTypes.error,
          title: 'Erro',
          message: error.response.data.erros[0].message,
        })
      );
    }
  };

  const handleApprove = async () => {
    if (
      currentApproval.idTipoSolicitacao === ApprovalTypes.cadastro &&
      !evaluation.confirm?.nmCliente
    ) {
      dispatch(
        updateToaster({
          type: ToasterTypes.error,
          title: 'Error',
          message: 'Por favor, preencha todos os campos obrigatórios',
        })
      );

      return;
    }

    try {
      const approveData = {
        id: currentApproval.id,
        cliente: {
          ...evaluation.confirm,
          idSolicitacaoCadastro: currentApproval.id,

          //txCpfCnpj: evaluation.confirm.txCpfCnpj,
          //numeroDocumento: evaluation.confirm.numeroDocumento,
        },
      };
      const response: any = await GuaracampAPI.post(
        '/solicitacaocadastro/aprovacao',
        approveData
      );

      dispatch(
        updateToaster({
          type: ToasterTypes.error,
          title: 'Sucesso',
          message: 'Aprovação realizada com sucesso.',
        })
      );

      if (response.status == 200) {
        history.push('/approvals');
      }
    } catch (error: any) {
      dispatch(
        updateToaster({
          type: ToasterTypes.error,
          title: 'Erro',
          message: error.response.data.erros[0].message,
        })
      );
    }
  };

  return (
    <div className="userinfo-container">
      {currentApproval && (
        <div className="userinfo-container-info">
          <span>
            <strong>Nome Completo: </strong>
            {currentApproval?.usuarioSolicitante.nmUsuario}
          </span>
          <span style={styles.infoText}>
            <strong>E-mail: </strong>
            {currentApproval?.usuarioSolicitante.txEmail}
          </span>
          <span>
            <strong>Celular: </strong>
            {cellMask(currentApproval?.usuarioSolicitante.txCelular)}
          </span>
        </div>
      )}
      {currentApproval.idTipoSolicitacao !== ApprovalTypes.desassociacao &&
        currentApproval.status.id === 1 && (
          <div style={styles.actions}>
            <Button
              className="userinfo-action-button"
              onClick={disaproveConfirm}
              label="Reprovar"
            />
            <Button
              className="userinfo-action-button"
              onClick={acceptConfirm}
              label="Aprovar"
            />
          </div>
        )}
    </div>
  );
};

export default ApprovalUserInfo;
