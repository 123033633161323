import React, { useEffect } from "react";

import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { useState } from "react";
import { COLORS } from "../../../../constants";
import { useDispatch } from "react-redux";
import {
  asyncGetTopicById,
  asyncUpdateTopic,
} from "../../../../store/admin/admin.actions";
import * as Yup from "yup";
import getValidationErrors from "../../../../util/validationError";
import { StateProps } from "../../../../enums";
import DefaultTextarea from "../../../../components/DefaultTextArea";
import { confirmDialog } from "primereact/confirmdialog";
import DefaultEditor from "../../../../components/DefaultEditor";

interface TopicEditProps {
  id: number;
}

const HelpEdit = ({ id }: TopicEditProps) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [topic, setTopic] = useState<any>(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errors, setErrors] = useState({} as any);

  const getTopic = async () => {
    const response: any = await asyncGetTopicById(id);
    setTopic(response);

    setVisible(true);
  };

  const handleSubmit = async () => {
    setErrors({} as StateProps);
    setButtonLoading(true);

    try {
      const TopicEdit = {
        id: topic?.id,
        txPergunta: topic?.txPergunta,
        txResposta: topic?.txResposta,
      };

      const schema = Yup.object().shape({
        txPergunta: Yup.string().required("Pergunta obrigatória."),
        txResposta: Yup.string().required("Resposta obrigatória."),
      });

      await schema.validate(topic, {
        abortEarly: false,
      });

      dispatch(asyncUpdateTopic(TopicEdit));
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrors(getValidationErrors(err));

        return;
      }
    } finally {
      setTimeout(() => {
        setButtonLoading(false);
        setVisible(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (topic) {
      let result = topic;
      result = { ...result, txPergunta: question };
      setTopic(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  useEffect(() => {
    if (topic) {
      let result = topic;
      result = { ...result, txResposta: answer };
      setTopic(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer]);

  const handleCancelAccept = () => {
    setVisible(false);
    setErrors("");
  };

  const handleCancelReject = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    confirmDialog({
      message: "Você tem certeza que deseja fechar sem salvar a edição?",
      header: "Confirmação de edição de dados",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: handleCancelAccept,
      reject: handleCancelReject,
      acceptLabel: "Sim",
      rejectLabel: "Não",
      style: { width: "60%" },
    });
  };

  return (
    <div className="sidebarv2-wrapper">
      <Button
        icon="pi pi-pencil"
        onClick={getTopic}
        className="p-button-rounded p-button-warning p-button-outlined"
      />
      <Sidebar
        className="sidebar-help-container"
        visible={visible}
        position="right"
        onHide={handleCancel}
      >
        <div className="sidebar-help-content-top">
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-danger p-button-text"
            onClick={handleCancel}
          />
          <p
            style={{ color: COLORS.primaryGreen }}
            className="sidebar-help-title"
          >
            Editar Tópico
          </p>
          <div className="sidebar-help-inputs">
            <h4>Pergunta</h4>
            <DefaultEditor  value={topic?.txPergunta} onTextChange={(e: any) => setQuestion(e.htmlValue)} error={errors.txPergunta} />     
            <h4>Resposta</h4>
            <DefaultEditor  value={topic?.txResposta} onTextChange={(e: any) => setQuestion(e.htmlValue)} error={errors.txPergunta} />   
          </div>
        </div>

        <div className="sidebar-help-actions">
          <Button
            label="Cancelar"
            className="p-button-outlined p-button-secondary"
            onClick={handleCancel}
            style={{ marginRight: "20px", width: "40%" }}
          />
          <Button
            id="button"
            label="Salvar"
            className="p-button-lg"
            onClick={handleSubmit}
            style={{ width: "40%" }}
            loading={buttonLoading}
          />
        </div>
      </Sidebar>
    </div>
  );
};

export default HelpEdit;
