import { Provider } from "react-redux";
import store from "./store";

import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Layout from "./layout";
import Toaster from "./components/Toaster";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const App = () => (
  <Provider store={store}>
    <Layout />
    <Toaster />
  </Provider>
);

export default App;
