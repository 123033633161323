/* Deletar está pasta, agora o caminho está na util/images*/

const getBase64 = (file:any):any =>{
    return new Promise((resolve, reject)=>{
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve(reader.result)
        };
        reader.onerror = function (error) {
            reject(error);
        };
    })
}

export default getBase64