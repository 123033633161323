import { Button } from 'primereact/button';
import CuponsEdit from '../../../pages/Marketing/Cupons/CuponsEdit';
import CuponsView from '../../../pages/Marketing/Cupons/CuponsView';
import './styles.css';
// import PromotionCreate from '../../../../pages/Comercial/Promotion/PromotionCreate';
// import PromotionEdit from '../../../../pages/Comercial/Promotion/PromotionEdit';
// import PromotionView from '../../../../pages/Comercial/Promotion/PromotionView';

interface CardListProps {
  id: number;
  cdCupom: string;
  dsCupom: string;
  inAtivo: boolean;
  inputs: any[];
  handleDelete: (id: any) => void;
}

const CardListCupons = ({
  id,
  cdCupom,
  dsCupom,
  inAtivo = true,
  handleDelete,
}: CardListProps) => {
  return (
    <div className='card-container-cupons'>
      <div className='card-promotion-content'>
        <h1>{cdCupom}</h1>
        <h1>{dsCupom}</h1>
        <div className='card-promotion-status'>
          <span>{inAtivo === true ? 'Ativo' : 'Inativo'}</span>
        </div>
        <div className='card-container-buttons-cupons'>
          <div className='styles-buttons'>
            <CuponsEdit id={id} />
          </div>
          <div className='styles-buttons'>
            <CuponsView id={id} />
          </div>
          <div className='styles-buttons'>
            <Button
              value={id}
              icon='pi pi-trash'
              className='p-button-rounded p-button-danger p-button-outlined'
              onClick={handleDelete}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardListCupons;
