import { Dispatch } from "redux";
import { ToasterTypes } from "../../enums/ToasterTypes";
import GuaracampAPI from "../../services/GuaracampAPI";
import { updateToaster } from "../helper/helper.store";
import {
  updateCardsUpload,
  addCardsUpload,
  updateSelectOptionsTiposProduto,
  updateSelectOptionsGrupos,
  updateSelectOptionsSubGrupos,
  updateSelectOptionsClasses,
  updateSelectOptionsSabores,
  updateCardsImages,
  updateCupons,
  addCupom,
  deleteCupom,
  CuponsProductsProps,
  CuponsCategoriasProps,
  updateProducts,
  updateCategories,
  updateFeatured,
  updateCupom,
  updateAbrangenciaTipoEstabelecimento,
  updateAbrangenciaClienteApp,
  AbrangenciaTipoEstabelecimentoProps,
  CuponsAbrangenciaClienteAppProps,
  updateClientesApp,
  ClientesAppProps,
  updateAcumuloDePontos,
  updateProgramaDePontos,
  updateMerchandising,
  ProgramaDePontosAbrangenciaTipoEstabelecimentoProps,
  updateResgateTipoEstabelecimento,
  updateMerchandisingTipoEstabelecimento,
} from "./marketing.store";

import { updateLoading } from "../loading/loading.store";

export interface newCardSaveImagesProduct {
  id: number;
  imagem: string;
}

export interface newCardSavePutFeatured {
  id: number;
  inDestaque: boolean;
}

export interface newCardSavePutPointsAccumulation {
  descricao: string;
  vlItem: number;
  qtPonto: number;
  idProduto: number;
  idTipoPontoRegraPontuacao: number;
  id: number;
}

export interface newCardSavePutProgramPoints {
  id: number;
  idProduto: number;
  descricao: string;
  imagem: string;
  qtPontoResgate: number;
  qtMaxItemPedido: number;
  inAtivo: boolean;
  pontoRegraResgateTipoEstabelecimento: PontoRegraResgateTipoEstabelecimentoProps[];
}

export interface PontoRegraResgateTipoEstabelecimentoProps {
  idTipoEstabelecimento: number;
  dsTipoEstabelecimento: string;
  checked: boolean;
}

export interface newCardSavePutMerchandising {
  id: number;
  idProduto: number;
  descricao: string;
  imagem: string;
  qtMinDiaResgate: any | null;
  qtMaxPedido: any | null;
  inAtivo: boolean;
  matMerchanTipoEstabelecimento: MerchandisingAbrangenciaTipoEstabelecimentoProps[];
}

export interface MerchandisingAbrangenciaTipoEstabelecimentoProps {
  idTipoEstabelecimento: number;
  dsTipoEstabelecimento: string;
  checked: boolean;
}

export const asyncGetCardsUpload = () => {
  return (dispatch: any) => {
    GuaracampAPI.get("/imagens/carrossel")
      .then((res) => {
        dispatch(updateCardsUpload(res.data));
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

export const asyncPostCardsUpload = (data: any) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.post("/imagens/carrossel/lista", data)
      .then((res: any) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Imagens salvas com sucesso.",
          })
        );
        dispatch(addCardsUpload(data));
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

// Get para os Select:
export const asyncGetTiposProduto = () => {
  return (dispatch: any) => {
    GuaracampAPI.get("/tipoproduto")
      .then((res: any) => {
        // ordenar
        const resultOrder = res.data.sort((a: any, b: any) => {
          return a.dsTipoProduto > b.dsTipoProduto
            ? 1
            : b.dsTipoProduto > a.dsTipoProduto
            ? -1
            : 0;
        });

        dispatch(updateSelectOptionsTiposProduto(resultOrder));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

export const asyncGetGrupos = () => {
  return (dispatch: any) => {
    GuaracampAPI.get("/grupoproduto")
      .then((res: any) => {
        const resultOrder = res.data.sort((a: any, b: any) => {
          return a.dsGrupoProduto > b.dsGrupoProduto
            ? 1
            : b.dsGrupoProduto > a.dsGrupoProduto
            ? -1
            : 0;
        });

        dispatch(updateSelectOptionsGrupos(resultOrder));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

export const asyncGetSubGrupos = () => {
  return (dispatch: any) => {
    GuaracampAPI.get("/subgrupoproduto")
      .then((res: any) => {
        const resultOrder = res.data.sort((a: any, b: any) => {
          return a.dsSubgrupoProduto > b.dsSubgrupoProduto
            ? 1
            : b.dsSubgrupoProduto > a.dsSubgrupoProduto
            ? -1
            : 0;
        });

        dispatch(updateSelectOptionsSubGrupos(resultOrder));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

export const asyncGetClasses = () => {
  return (dispatch: any) => {
    GuaracampAPI.get("/classeproduto")
      .then((res: any) => {
        const resultOrder = res.data.sort((a: any, b: any) => {
          return a.dsClasseProduto > b.dsClasseProduto
            ? 1
            : b.dsClasseProduto > a.dsClasseProduto
            ? -1
            : 0;
        });

        dispatch(updateSelectOptionsClasses(resultOrder));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

export const asyncGetSabores = () => {
  return (dispatch: any) => {
    GuaracampAPI.get("/saborproduto")
      .then((res: any) => {
        const resultOrder = res.data.sort((a: any, b: any) => {
          return a.dsSaborProduto > b.dsSaborProduto
            ? 1
            : b.dsSaborProduto > a.dsSaborProduto
            ? -1
            : 0;
        });

        dispatch(updateSelectOptionsSabores(resultOrder));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};
/* Images */
export const asyncClearFilterProducts = () => {
  return (dispatch: any) => {
    GuaracampAPI.get("produto/admin/filtro")
      .then((res: any) => {
        dispatch(updateCardsImages(res.data));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

export const asyncGetFilterProduct = (
  idTipoProduto: number,
  idGrupoProduto: number,
  idSubgrupoProduto: number,
  idClasseProduto: number,
  idSaborProduto: number
) => {
  const tipo = idTipoProduto ? `&tipo=${idTipoProduto}` : "";
  const grupo = idGrupoProduto ? `&grupo=${idGrupoProduto}` : "";
  const subGrupo = idSubgrupoProduto ? `&subgrupo=${idSubgrupoProduto}` : "";
  const classe = idClasseProduto ? `&classe=${idClasseProduto}` : "";
  const sabor = idSaborProduto ? `&sabor=${idSaborProduto}` : "";

  return (dispatch: any) => {
    GuaracampAPI.get(
      `produto/admin/filtro?${tipo}${grupo}${subGrupo}${classe}${sabor}`
    )
      .then((res: any) => {
        const resultOrder = res.data.sort((a: any, b: any) => {
          return a.dsProduto > b.dsProduto
            ? 1
            : b.dsProduto > a.dsProduto
            ? -1
            : 0;
        });
        dispatch(updateCardsImages(resultOrder));
        dispatch(updateAcumuloDePontos(resultOrder));
        dispatch(updateProgramaDePontos(resultOrder));
        dispatch(updateMerchandising(resultOrder));
        dispatch(updateFeatured(resultOrder));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

export const asyncPutImagesProducts = (
  arraySave: newCardSaveImagesProduct[]
) => {
  return async (dispatch: any) => {
    await GuaracampAPI.put("/produto/imagens", arraySave)
      .then((res) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Imagens salvas com sucesso.",
          })
        );
        dispatch(updateLoading(false));
        return res.data;
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

// cupons
export const asyncGetCupons = () => {
  return (dispatch: any) => {
    GuaracampAPI.get("/cupom")
      .then((res) => {
        dispatch(updateCupons(res.data));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

export const asyncPostCupom = (data: any) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.post("/cupom", data)
      .then((res: any) => {
        dispatch(addCupom(res.data.data));
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Cupom criado com sucesso.",
          })
        );
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

export const asyncDeleteCupom = (id: number) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.delete(`/cupom/${id}`)
      .then((res) => {
        dispatch(deleteCupom(id));
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Cupom deletado com sucesso.",
          })
        );
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

export const asyncGetCupomById = async (id: number) => {
  let response = null;
  await GuaracampAPI.get(`/cupom/${id}`)
    .then((res) => {
      response = res.data;
    })
    .catch((error) => {});

  return response;
};

export const asyncGetProducts = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get("/produto")
      .then((res: any) => {
        const result: CuponsProductsProps[] = [];
        res.data.map((r: any) => {
          result.push({
            idProduto: r.id,
            dsProduto: r.dsProduto,
            checked: false,
          });
        });
        dispatch(updateProducts(result));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

export const asyncGetCuponsProducts = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get("/produto")
      .then((res: any) => {
        const result: CuponsProductsProps[] = [];
        res.data.map((r: any) => {
          result.push({
            idProduto: r.id,
            dsProduto: r.dsProduto,
            checked: r.checked,
          });
        });
        dispatch(updateProducts(result));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

export const asyncGetCuponsCategories = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get("/categoriaproduto")
      .then((res: any) => {
        const result: CuponsCategoriasProps[] = [];
        res.data.map((r: any) => {
          result.push({
            idCategoriaProduto: r.id,
            dsCategoriaProduto: r.dsCategoriaProduto,
            checked: r.checked,
          });
        });
        dispatch(updateCategories(result));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

export const asyncGetAbrangenciaTipoEstabelecimento = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get("/tipoestabelecimento")
      .then((res: any) => {
        const result: AbrangenciaTipoEstabelecimentoProps[] = [];
        res.data.map((r: any) => {
          result.push({
            idTipoEstabelecimento: r.id,
            dsTipoEstabelecimento: r.dsTipoEstabelecimento,
            checked: r.checked,
          });
        });
        dispatch(updateAbrangenciaTipoEstabelecimento(result));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

export const asyncGetClientesApps = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get("/clientes/app")
      .then((res: any) => {
        const result: ClientesAppProps[] = [];
        res.data.value.map((r: any) => {
          result.push({
            idClienteApp: r.id,
            nmCliente: r.nmCliente,
          });
        });
        dispatch(updateClientesApp(result));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

export const asyncGetCuponsAbrangenciaClienteApp = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get("/clientes/app")
      .then((res: any) => {
        const result: CuponsAbrangenciaClienteAppProps[] = [];
        res.data.value.map((r: any) => {
          result.push({
            idClienteApp: r.id,
            nmCliente: r.nmCliente,
          });
        });
        dispatch(updateAbrangenciaClienteApp(result));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

export const asyncUpdateCupom = (data: any) => {
  return (dispatch: Dispatch) => {

    GuaracampAPI.post("/cupom", data)
      .then((res: any) => {
        dispatch(updateCupom(res.data.data));
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Cupom atualizado com sucesso.",
          })
        );
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

/* featured */
export const asyncPutFeatured = (arraySave: newCardSavePutFeatured[]) => {
  return async (dispatch: any) => {
    await GuaracampAPI.put("/produto/destaques", arraySave)
      .then((res) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Destaques salvos com sucesso.",
          })
        );
        dispatch(updateLoading(false));
        return res.data;
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

// acumulo de pontos
export const asyncPutPointsAccumulation = (
  arrayPointsAccumulation: newCardSavePutPointsAccumulation[]
) => {
  return async (dispatch: any) => {
    await GuaracampAPI.put("/pontos/acumulo/admin", arrayPointsAccumulation)
      .then((res) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Regras de acúmulo de pontos salvas com sucesso.",
          })
        );
        dispatch(updateLoading(false));
        return res.data;
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

export const asyncClearFilterAcumulation = () => {
  return (dispatch: any) => {
    GuaracampAPI.get("produto/admin/acumulopontos")
      .then((res: any) => {
        dispatch(updateAcumuloDePontos(res.data));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

export const asyncGetPointsAccumulation = (
  idTipo: number,
  idGrupoProduto: number,
  idSubGrupoProduto: number,
  idClasseProduto: number,
  idSaborProduto: number
) => {
  const tipo = idTipo ? `&tipo=${idTipo}` : "";
  const grupo = idGrupoProduto ? `&grupo=${idGrupoProduto}` : "";
  const subGrupo = idSubGrupoProduto ? `&subgrupo=${idSubGrupoProduto}` : "";
  const classe = idClasseProduto ? `&classe=${idClasseProduto}` : "";
  const sabor = idSaborProduto ? `&sabor=${idSaborProduto}` : "";

  return (dispatch: any) => {
    GuaracampAPI.get(
      `/produto/admin/acumulopontos?${tipo}${grupo}${subGrupo}${classe}${sabor}`
    )
      .then((res: any) => {
        dispatch(updateAcumuloDePontos(res.data));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

// programa de pontos

export const asyncClearFilterPointsAcumulation = () => {
  return (dispatch: any) => {
    GuaracampAPI.get("produto/admin/resgatepontos")
      .then((res: any) => {
        dispatch(updateProgramaDePontos(res.data));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

export const asyncGetProgramPoints = (
  idTipo: number,
  idGrupoProduto: number,
  idSubGrupoProduto: number,
  idClasseProduto: number,
  idSaborProduto: number
) => {
  const tipo = idTipo ? `&tipo=${idTipo}` : "";
  const grupo = idGrupoProduto ? `&grupo=${idGrupoProduto}` : "";
  const subGrupo = idSubGrupoProduto ? `&subgrupo=${idSubGrupoProduto}` : "";
  const classe = idClasseProduto ? `&classe=${idClasseProduto}` : "";
  const sabor = idSaborProduto ? `&sabor=${idSaborProduto}` : "";

  return (dispatch: any) => {
    GuaracampAPI.get(
      `/produto/admin/resgatepontos?${tipo}${grupo}${subGrupo}${classe}${sabor}`
    )
      .then((res: any) => {
        dispatch(updateProgramaDePontos(res.data));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

export const asyncPutProgramPoints = (
  arrayProgramPoints: newCardSavePutProgramPoints[]
) => {
  return async (dispatch: any) => {
    await GuaracampAPI.put("/pontos/resgate/admin", arrayProgramPoints)
      .then((res) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Regras de programa de pontos salvas com sucesso.",
          })
        );
        dispatch(updateLoading(false));
        return res.data;
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

export const asyncGetAbrangenciaProgramaDePontos = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get("/tipoestabelecimento")
      .then((res: any) => {
        const result: ProgramaDePontosAbrangenciaTipoEstabelecimentoProps[] =
          [];
        res.data.map((r: any) => {
          result.push({
            idTipoEstabelecimento: r.id,
            dsTipoEstabelecimento: r.dsTipoEstabelecimento,
            checked: r.checked,
          });
        });
        dispatch(updateResgateTipoEstabelecimento(result));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

// merchandising
export const asyncClearFilterMerchandising = () => {
  return (dispatch: any) => {
    GuaracampAPI.get("produto/admin/matmerchan")
      .then((res: any) => {
        dispatch(updateMerchandising(res.data));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

export const asyncGetMerchandising = (
  idTipo: number,
  idGrupo: number,
  idSubGrupo: number,
  idClasse: number,
  idSabor: number
) => {
  const tipo = idTipo ? `&tipo=${idTipo}` : "";
  const grupo = idGrupo ? `&grupo=${idGrupo}` : "";
  const subGrupo = idSubGrupo ? `&subgrupo=${idSubGrupo}` : "";
  const classe = idClasse ? `&classe=${idClasse}` : "";
  const sabor = idSabor ? `&sabor=${idSabor}` : "";

  return (dispatch: any) => {
    GuaracampAPI.get(
      `/produto/admin/matmerchan?${tipo}${grupo}${subGrupo}${classe}${sabor}`
    )
      .then((res: any) => {
        console.log(res.data);
        dispatch(updateMerchandising(res.data));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

export const asyncPutMerchandising = (
  arrayMerchandising: newCardSavePutMerchandising[]
) => {
  return async (dispatch: any) => {
    await GuaracampAPI.put("/matmerchan/admin", arrayMerchandising)
      .then((res) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Regras de merchandising salvas com sucesso.",
          })
        );
        dispatch(updateLoading(false));
        return res.data;
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

export const asyncGetAbrangenciaMerchandising = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get("/tipoestabelecimento")
      .then((res: any) => {
        const result: MerchandisingAbrangenciaTipoEstabelecimentoProps[] = [];
        res.data.map((r: any) => {
          result.push({
            idTipoEstabelecimento: r.id,
            dsTipoEstabelecimento: r.dsTipoEstabelecimento,
            checked: r.checked,
          });
        });
        dispatch(updateMerchandisingTipoEstabelecimento(result));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};
