import { Button } from "primereact/button";
import ProfileEdit from "../../pages/Admin/Profiles/ProfileEdit";
import ProfileView from "../../pages/Admin/Profiles/ProfileView";
import UserEdit from "../../pages/Admin/Users/UserEdit";
import UserView from "../../pages/Admin/Users/UserView";

//import { SidebarTypes } from '../../enums/SidebarTypes';
//import SidebarV2 from '../SiderbarV2';
import "./styles.css";

interface CardListProps {
  name: string;
  email?: string;
  icon?: string;
  status?: boolean;
  inputs: any[];
  route: string;
  idItem: number;
  handleDelete: (id: any) => void;
  disabled: boolean;
}

const CardList = ({
  name,
  email,
  icon,
  status,
  inputs,
  route,
  idItem,
  handleDelete,
  disabled,
}: CardListProps) => {
  return (
    <div className="card-container">
      <div className="card-container-user">
        {route !== "profile" && (
          <div className="card-container-icon">
            <i
              className="pi pi-user"
              style={{ color: "#3DAE2B", fontSize: "1em" }}
            ></i>
          </div>
        )}

        <div className="card-container-text">
          <h1>{name}</h1>
          <span>{email}</span>
        </div>
      </div>
      <div className="card-container-buttons">
        <div className="styles-buttons">
          {route === "profile" ? (
            <ProfileEdit id={idItem} />
          ) : (
            <UserEdit id={idItem} />
          )}
        </div>
        <div className="styles-buttons">
          {route === "profile" ? (
            <ProfileView id={idItem} />
          ) : (
            <UserView id={idItem} />
          )}
        </div>
        <div className="styles-buttons">
          <Button
            value={idItem}
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger p-button-outlined"
            onClick={handleDelete}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default CardList;
