import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToasterTypes } from "../../../enums/ToasterTypes";
import { RootState } from "../../../store";
import {
  getComodatoStatus,
  getComodatoStatusByType,
  putComodatos,
} from "../../../store/comodatos/comodatos.actions";
import { updateToaster } from "../../../store/helper/helper.store";
import { cpfCnpjMask } from "../../../util/mask";
import DefaultSelect from "../../DefaultSelect";
import ProgressLoad from "../../ProgressLoad";
import { format } from "date-fns";

interface ComodatoProps {
  comodato?: any;
}

const CancelComodato: React.FC<ComodatoProps> = ({ comodato }) => {
  const { currentComodato } = useSelector(
    (state: RootState) => state.comodatos
  );
  const comodatos = useSelector((state: RootState) => state.comodatos);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<any>(
    currentComodato?.idStatusSolicitacaoComodato
  );
  const history = useHistory();

  function getFormatDate(date = new Date(), dateFormat = "dd/MM/yyyy") {
    return format(new Date(date), dateFormat);
  }
  
  useEffect(() => {
    dispatch(
      getComodatoStatusByType(currentComodato?.idTipoSolicitacaoComodato)
    );
  }, [dispatch, currentComodato?.idTipoSolicitacaoComodato]);

  const handlePutComodato = async () => {
    const newComodato = {
      ...currentComodato,
      idStatusSolicitacaoComodato: status,
    };
    const response: any = await putComodatos(newComodato);
    if (response.status == 200) {
      dispatch(
        updateToaster({
          type: ToasterTypes.success,
          title: "Sucesso",
          message: "Alteração realizada com sucesso",
        })
      );
      history.push("/comodatos");
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: 10,
        marginTop: 20,
        marginBottom: 20,
        borderRadius: 10,
        border: "1px solid #ddd",
      }}
    >
      {loading && <ProgressLoad />}
      {!loading && (
        <>
          <>
            <div className="title-field">
              <p>Dados do Estabelecimento Solicitante</p>
            </div>
            <div className="field-group-container">
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Data de solicitação
                </label>
                <InputText
                  id="in"
                  placeholder={getFormatDate(currentComodato?.dtSolicitacao)}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Códito Digital do Cliente
                </label>
                <InputText
                  id="in"
                  placeholder={currentComodato?.cliente?.cdDigital}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  CNPJ/CPF
                </label>
                <InputText
                  id="in"
                  placeholder={cpfCnpjMask(currentComodato?.cliente?.txCpfCnpj)}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Razão Social / Nome
                </label>
                <InputText
                  id="in"
                  placeholder={currentComodato?.cliente?.nmCliente}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  CEP
                </label>
                <InputText
                  id="in"
                  placeholder={currentComodato?.cliente?.enderecos[0]?.txCep}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Logradouro
                </label>
                <InputText
                  id="in"
                  placeholder={
                    currentComodato?.cliente?.enderecos[0]?.txLogradouro
                  }
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Número
                </label>
                <InputText
                  id="in"
                  placeholder={currentComodato?.cliente?.enderecos[0]?.txNumero}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  UF
                </label>
                <InputText
                  id="in"
                  placeholder={currentComodato?.cliente?.enderecos[0]?.cdUf}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Bairro
                </label>
                <InputText
                  id="in"
                  placeholder={currentComodato?.cliente?.enderecos[0]?.nmBairro}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Cidade
                </label>
                <InputText
                  id="in"
                  placeholder={currentComodato?.cliente?.enderecos[0]?.nmCidade}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Complemento
                </label>
                <InputText
                  id="in"
                  placeholder={
                    currentComodato?.cliente?.enderecos[0]?.txComplemento
                  }
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Ponto de Referência
                </label>
                <InputText
                  id="in"
                  placeholder={
                    currentComodato?.cliente?.enderecos[0]?.txPontoReferencia
                  }
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Telefone para contato
                </label>
                <InputText
                  id="in"
                  placeholder={currentComodato?.txTelefoneContato}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  E-mail para contato
                </label>
                <InputText
                  id="in"
                  placeholder={currentComodato?.txEmailContato}
                  disabled={true}
                />
              </span>
            </div>
          </>
          <>
            <div className="title-field">
              <p>Dados da solicitação de cancelamento</p>
            </div>
            <div className="field-group-container" style={{ marginBottom: 0 }}>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Equipamento solicitado
                </label>
                <InputText
                  id="in"
                  placeholder={currentComodato?.produto.dsProduto}
                  disabled={true}
                />
              </span>
              <span className="field-group-item">
                <label className="field-group-label" htmlFor="in">
                  Número de registro
                </label>
                <InputText
                  id="in"
                  placeholder={currentComodato?.txRegistro}                  
                  // disabled={true}
                />
              </span>
            </div>
            <div className="observation-container" style={{ marginBottom: 40 }}>
              <span className="field-group-item" style={{ width: "100%" }}>
                <label className="field-group-label" htmlFor="in">
                  Motivo do cancelamento
                </label>
                <InputTextarea
                  id="in"
                  placeholder={currentComodato?.txMotivoCancelamento ? currentComodato?.txMotivoCancelamento : "Nenhum motivo relatado"}
                  disabled={true}
                  autoResize
                  // style={{ width: "100% !important" }}
                />
              </span>
            </div>
          </>
          <>
            <div className="title-field">
              <p>Processo de aprovação</p>
            </div>
            <div
              className="field-group-container"
              style={{ marginBottom: 0, justifyContent: "space-between" }}
            >
              <div style={{ display: "flex" }}>
                <DefaultSelect
                  label="Status"
                  value={status}
                  onChange={setStatus}
                  options={comodatos.typesById}
                  disabled={currentComodato?.status?.id === 16}
                />
              </div>

              <Button
                id="button"
                label="Salvar"
                className="p-button-lg"
                onClick={handlePutComodato}
                disabled={currentComodato?.status?.id === 16}
                style={{
                  width: "160px",
                  height: "40px",
                  alignSelf: "center",
                  fontSize: "16px",
                }}
                // loading={buttonLoading}
              />
            </div>
          </>
        </>
      )}
    </div>
  );
};

export default CancelComodato;
