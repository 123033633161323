import axios from 'axios';
import { logout, TOKEN_KEY } from '../store/auth/token.service';

const api = axios.create({
  //baseURL: 'https://api.guaracamp.com.br/api/v1',
  baseURL: "https://api.guaracamp.com.br/api/v1",
  // baseURL: "https://3d79-2804-d41-ab21-9b00-7596-1f4d-cc50-da8c.ngrok.io/api/v1",
});

api.interceptors.request.use(async (config: any) => {
  const token = await localStorage.getItem(TOKEN_KEY);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      logout();
      window.location.pathname = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;
