import React from "react";

import "./styles.css";

import { Oval } from "react-loader-spinner";

const ProgressLoad: React.FC = () => {
  return (
    <div className="div-style-progress">
      <Oval color="#3dae2b" height={40} width={40} />
    </div>
  );
};

export default ProgressLoad;
