import { createSlice } from "@reduxjs/toolkit";

interface CardUpdate {
  id: number;
  txUrl: string;
  txImagemBase64: string;
}

interface CardPosition {
  origin: string;
  destiny: string;
}

//Cupons

export interface CuponsProps {
  id: number;
  dsCupom: string;
  dtValidadeDe: string;
  dtValidadeAte: string;
  inAtivo: boolean;
  inVisivel: boolean;
  cdCupom: string;
  idTipoDesconto: number;
  idTipoAplicacao: number;
  idTipoAbrangencia: number | null;
  vlCupom: number;
  qtLiberada: number;
  qtUtilizada: number | null;
  itens: CuponsProductsProps[];
  categorias: CuponsCategoriasProps[];
  abrangenciaTipoEstabelecimento: AbrangenciaTipoEstabelecimentoProps[];
  abrangenciaClienteApp: CuponsAbrangenciaClienteAppProps[];
}

export interface CuponsAbrangenciaClienteAppProps {
  idClienteApp: number;
  nmCliente: string;
}

export interface AbrangenciaTipoEstabelecimentoProps {
  idTipoEstabelecimento: number;
  dsTipoEstabelecimento: string;
  checked: boolean;
}

export interface ClientesAppProps {
  idClienteApp: number;
  nmCliente: string;
  clienteApp?: any;
}

export interface CuponsCategoriasProps {
  idCategoriaProduto: number;
  dsCategoriaProduto: string;
  checked: boolean;
}

export interface CuponsProductsProps {
  idProduto: number;
  checked: boolean;
  dsProduto: string;
}

interface TipoOptions {
  id: number;
  name: string;
  label: string;
}
interface GruposOptions {
  id: number;
  name: string;
  label: string;
}
interface SubGrupoOptions {
  id: number;
  name: string;
  label: string;
}
interface ClassesOptions {
  id: number;
  name: string;
  label: string;
}
interface SaboresOptions {
  id: number;
  name: string;
  label: string;
}

interface SelectsOptions {
  tipos: TipoOptions[];
  grupos: GruposOptions[];
  subGrupos: SubGrupoOptions[];
  classes: ClassesOptions[];
  sabores: SaboresOptions[];
}

interface cardImage {
  id: number;
  dsProduto: string;
  txImagemProduto: string;
}

interface cardFeatured {
  id: number;
  dsProduto: string;
  inDestaque: boolean;
}

export interface AcumuloDePontosProps {
  id: number;
  idProduto: number;
  descricao: string;
  idTipoPontoRegraPontuacao: any | null;
  vlItem: any | null;
  qtPonto: any | null;
}

export interface ProgramaDePontosProps {
  idProduto: number;
  id: number;
  descricao: string;
  imagem: string;
  qtPontoResgate: any | null;
  qtMaxItemPedido: any | null;
  inAtivo: boolean;
  pontoRegraResgateTipoEstabelecimento: ProgramaDePontosAbrangenciaTipoEstabelecimentoProps[];
}

export interface ProgramaDePontosAbrangenciaTipoEstabelecimentoProps {
  idTipoEstabelecimento: number;
  dsTipoEstabelecimento: string;
  checked: boolean;
}

interface MerchandisingProps {
  id: number;
  idProduto: number;
  descricao: string;
  imagem: string;
  qtMinDiaResgate: any | null;
  qtMaxPedido: any | null;
  matMerchanTipoEstabelecimento: MerchandisingAbrangenciaTipoEstabelecimentoProps[];
  inAtivo: boolean;
}

export interface MerchandisingAbrangenciaTipoEstabelecimentoProps {
  idTipoEstabelecimento: number;
  dsTipoEstabelecimento: string;
  checked: boolean;
}

interface marketingProps {
  cardsUpload: CardUpdate[];
  cardPosition: CardPosition;
  cupons: CuponsProps[];
  cuponsProductsList: CuponsProductsProps[];
  cuponsCategoriesList: CuponsCategoriasProps[];
  clientesAppList: ClientesAppProps[];
  abrangenciaTiposEstabelecimentosList: AbrangenciaTipoEstabelecimentoProps[];
  cuponsAbrangenciaClienteAppList: CuponsAbrangenciaClienteAppProps[];
  selectsOptions: SelectsOptions;
  cardsImages: cardImage[];
  cardsFeatured: cardFeatured[];
  acumuloDePontosList: AcumuloDePontosProps[];
  programaDePontosList: ProgramaDePontosProps[];
  pontoRegraResgateTipoEstabelecimentoList: ProgramaDePontosAbrangenciaTipoEstabelecimentoProps[];
  merchandisingList: MerchandisingProps[];
  merchandisingTipoEstabelecimentoList: MerchandisingAbrangenciaTipoEstabelecimentoProps[];
}

export const initialState: marketingProps = {
  cardsUpload: [],
  cardPosition: { origin: "", destiny: "" },
  cupons: [],
  cuponsProductsList: [],
  cuponsCategoriesList: [],
  clientesAppList: [],
  abrangenciaTiposEstabelecimentosList: [],
  cuponsAbrangenciaClienteAppList: [],
  selectsOptions: {
    tipos: [],
    grupos: [],
    subGrupos: [],
    classes: [],
    sabores: [],
  },
  cardsImages: [],
  cardsFeatured: [],
  acumuloDePontosList: [],
  pontoRegraResgateTipoEstabelecimentoList: [],
  programaDePontosList: [],
  merchandisingList: [],
  merchandisingTipoEstabelecimentoList: [],
};

const marketing = createSlice({
  name: "marketing",
  initialState,
  reducers: {
    updateCardsUpload(state, action) {
      state.cardsUpload = action.payload;
    },
    updateCardsPositionOrigin(state, action) {
      state.cardPosition.origin = action.payload;
    },
    updateCardsPositionDestiny(state, action) {
      state.cardPosition.destiny = action.payload;
    },
    addCardsUpload(state, action) {
      state.cardsUpload = action.payload;
    },
    modifiedCardUpload(state, action) {
      state.cardsUpload = state.cardsUpload.map((card) =>
        card.id === action.payload.id ? action.payload : card
      );
    },
    trashCardUpload(state, action) {
      state.cardsUpload = state.cardsUpload.map((card) =>
        card.id === action.payload.id ? action.payload : card
      );
    },
    //cupons
    updateCupons(state, action) {
      state.cupons = action.payload;
    },
    addCupom(state, action) {
      state.cupons.push(action.payload);
    },
    deleteCupom(state, action) {
      state.cupons = state.cupons.filter(
        (cupom) => cupom.id !== action.payload
      );
    },
    updateProducts(state, action) {
      state.cuponsProductsList = action.payload;
    },
    updateCategories(state, action) {
      state.cuponsCategoriesList = action.payload;
    },
    updateCupom(state, action) {
      state.cupons = state.cupons.map((cupom) =>
        cupom.id === action.payload.id ? action.payload : cupom
      );
    },
    updateAbrangenciaTipoEstabelecimento(state, action) {
      state.abrangenciaTiposEstabelecimentosList = action.payload;
    },
    updateAbrangenciaClienteApp(state, action) {
      state.cuponsAbrangenciaClienteAppList = action.payload;
    },
    updateClientesApp(state, action) {
      state.clientesAppList = action.payload;
    },
    //select:
    updateSelectOptionsTiposProduto(state, action) {
      const arrayTipo = [];
      for (let tipo of action.payload) {
        arrayTipo.push({
          id: tipo.id,
          name: tipo.dsTipoProduto,
          label: tipo.dsTipoProduto,
        });
      }
      state.selectsOptions.tipos = arrayTipo;
    },
    updateSelectOptionsGrupos(state, action) {
      const arrayGrupos = [];
      for (let grupo of action.payload) {
        arrayGrupos.push({
          id: grupo.id,
          name: grupo.dsGrupoProduto,
          label: grupo.dsGrupoProduto,
        });
      }
      state.selectsOptions.grupos = arrayGrupos;
    },
    updateSelectOptionsSubGrupos(state, action) {
      const arraySubGrupos = [];
      for (let subGrupo of action.payload) {
        arraySubGrupos.push({
          id: subGrupo.id,
          name: subGrupo.dsSubgrupoProduto,
          label: subGrupo.dsSubgrupoProduto,
        });
      }
      state.selectsOptions.subGrupos = arraySubGrupos;
    },
    updateSelectOptionsClasses(state, action) {
      const arrayClasses = [];
      for (let classe of action.payload) {
        arrayClasses.push({
          id: classe.id,
          name: classe.dsClasseProduto,
          label: classe.dsClasseProduto,
        });
      }
      state.selectsOptions.classes = arrayClasses;
    },
    updateSelectOptionsSabores(state, action) {
      const arraySabores = [];
      for (let sabor of action.payload) {
        arraySabores.push({
          id: sabor.id,
          name: sabor.dsSaborProduto,
          label: sabor.dsSaborProduto,
        });
      }
      state.selectsOptions.sabores = arraySabores;
    },
    //CardImg:
    updateCardsImages(state, action) {
      state.cardsImages = action.payload;
    },
    modifiedCardImages(state, action) {
      for (let card of state.cardsImages) {
        if (card.id === action.payload.id) {
          card.txImagemProduto = action.payload.txImagemProduto;
          break;
        }
      }
    },
    deleteImageCard(state, action) {
      for (let card of state.cardsImages) {
        if (card.id === action.payload) {
          card.txImagemProduto = "";
          break;
        }
      }
    },
    updateFeatured(state, action) {
      state.cardsFeatured = action.payload;
    },
    //Acumulo de pontos:
    updateAcumuloDePontos(state, action) {
      state.acumuloDePontosList = action.payload;
    },
    //Programa de pontos:
    updateProgramaDePontos(state, action) {
      state.programaDePontosList = action.payload;
    },
    updateResgateTipoEstabelecimento(state, action) {
      state.pontoRegraResgateTipoEstabelecimentoList = action.payload;
    },
    //Materiais de Merchandising:
    updateMerchandising(state, action) {
      state.merchandisingList = action.payload;
    },
    updateMerchandisingTipoEstabelecimento(state, action) {
      state.merchandisingTipoEstabelecimentoList = action.payload;
    },
  },
});

export const {
  updateCardsUpload,
  addCardsUpload,
  modifiedCardUpload,
  trashCardUpload,
  updateCardsPositionOrigin,
  updateCardsPositionDestiny,
  updateSelectOptionsTiposProduto,
  updateSelectOptionsGrupos,
  updateSelectOptionsSubGrupos,
  updateSelectOptionsClasses,
  updateSelectOptionsSabores,
  updateCardsImages,
  modifiedCardImages,
  deleteImageCard,
  updateCupons,
  addCupom,
  deleteCupom,
  updateCupom,
  updateProducts,
  updateCategories,
  updateFeatured,
  updateAbrangenciaTipoEstabelecimento,
  updateAbrangenciaClienteApp,
  updateClientesApp,
  updateAcumuloDePontos,
  updateProgramaDePontos,
  updateMerchandising,
  updateResgateTipoEstabelecimento,
  updateMerchandisingTipoEstabelecimento
} = marketing.actions;
export default marketing.reducer;
