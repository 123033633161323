import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { COLORS } from '../../../constants'
import "./style.css"

import { Button } from 'primereact/button'

import {asyncGetParameters, asyncPutParameters} from '../../../store/admin/admin.actions'
import { RootState } from '../../../store';

import CardParameters from './CardParameters';
import { updateParameters } from '../../../store/admin/admin.store';

import ProgressLoad from '../../../components/ProgressLoad';

import { updateLoading } from '../../../store/loading/loading.store';

const Parameters:React.FC = () => {
    const dispatch = useDispatch()

    const {loading} = useSelector((state: RootState) => state.loading)

    useEffect(() => {
        dispatch(asyncGetParameters())
    },[dispatch])

    const parameters = useSelector((state: RootState)=> state.admin.parameters)

    const handdleClickSave = () =>{
        if(!loading){
            dispatch(updateLoading(true))
            dispatch(asyncPutParameters(parameters))
        }
    }

    const handleChange = (event: any) =>{
        const id = parseInt(event.target.id)

        const index = parameters.findIndex(item => item.id === id )

        const newArr = [...parameters]

        newArr[index] = {...newArr[index], txValorParametro: event.target.value}
        dispatch(updateParameters(newArr))
    }

    return (
        <main className="main-page">
            <section>
                <p className="title">Parâmetros</p>
            </section>
            <section style={{background: COLORS.white}} className="section-parameters-descript">
                <div className="div-btn-salvar-descript">
                    <Button label="Salvar" onClick={handdleClickSave} className="btn-salvar-descript"/>
                </div>
                <div className="div-parameters">
                    {/* <div className="div-desc-inputs-parameters">
                        <p>Descrição</p>
                        <p>Valor</p>
                    </div> */}
                    <div className="div-parameters-inputs">
                        {loading ? 
                            <div className='div-not-find'>
                            <ProgressLoad/>
                            </div> 
                        : parameters.length > 0 ?
                            parameters.map(parameter => (
                                <CardParameters key={parameter.id} txValorParametro={parameter.txValorParametro} id={parameter.id.toString()} dsParametro={parameter.dsParametro} handleChange={handleChange} txTipoParametro={parameter.txTipoParametro}/>
                            ))
                        :
                        <div className='div-not-find'>
                          <p>Não foi encontrado nenhum registro.</p>
                        </div>}
                    </div>
                </div>
            </section>
        </main>
    )
}
export default Parameters