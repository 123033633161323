import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { Sidebar } from "primereact/sidebar";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ItensListPromotion from "../../../../components/Comercial/Promotion/ItensList";
import DefaultInput from "../../../../components/DefaultInput";
import ProgressLoad from "../../../../components/ProgressLoad";
import { COLORS } from "../../../../constants";
import { RootState } from "../../../../store";
import { asyncGetPromotionById } from "../../../../store/comercial/comercial.actions";
import { ClientesAppProps } from "../../../../store/comercial/comercial.store";
import Select from "react-select";
import EstablishmentsTypesPromotion from "../../../../components/Comercial/Promotion/AbrangenciaEstablishmentList";

interface PromotionViewProps {
  id: number;
}

const categories = [
  { name: "Todos", value: 0 },
  { name: "Tipo de estabelecimento", value: 1 },
  { name: "Cliente", value: 3 },
  // { name: "Localização", value: 3 },
];

export type SelectProps = {
  label: string;
  value: number;
};

const PromotionView: React.FC<PromotionViewProps> = ({ id }) => {
  const [visible, setVisible] = useState(false);
  const [dsPromocao, setDsPromocao] = useState("");
  const [dtValidadeDe, setDtValidadeDe] = useState("");
  const [dtValidadeAte, setDtValidadeAte] = useState("");
  const [inAtivo, setInAtivo] = useState<boolean>(false);
  const [promotion, setPromotion] = useState<any>();
  const [txImagemPromocao, setTxImagemPromocao] = useState("");
  const [errors, setErrors] = useState({} as any);
  const [step, setStep] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [allClients, setAllClients] = useState<any>([]);
  const [clientSelected, setClientSelected] = useState<any>("");
  const [filteredClients, setFilteredClients] = useState<ClientesAppProps[]>(
    []
  );

  const { loading } = useSelector((state: RootState) => state.loading);

  const comercial = useSelector((state: RootState) => state.comercial);

  const getPromotion = async () => {
    const response: any = await asyncGetPromotionById(id);
    const checkboxesProdutos = markCheckboxes(response);
    const checkboxesTiposEstabelecimento =
      markCheckboxesTiposEstabelecimentos(response);

    checkboxesProdutos.abrangenciaTipoEstabelecimento =
      checkboxesTiposEstabelecimento.abrangenciaTipoEstabelecimento;

    if (response?.idTipoAbrangencia === null) {
      setSelectedCategory(categories[0]);
    } else if (response?.idTipoAbrangencia === 1) {
      setSelectedCategory(categories[response?.idTipoAbrangencia]);
    } else {
      setSelectedCategory(categories[response?.idTipoAbrangencia - 1]);
    }

    setFilteredClients(response?.abrangenciaClienteApp);
    setTxImagemPromocao(response?.txImagemPromocao);
    setPromotion(checkboxesProdutos);
    setVisible(true);
  };

  const handleCheckboxes = (event: any) => {
    const newArr = { ...promotion };
    const index = newArr.itens.findIndex(
      (chk: any) => chk.idProduto == event.target.value
    );

    newArr.itens[index] = {
      ...newArr.itens[index],
      checked: !newArr.itens[index].checked,
    };

    setPromotion(newArr);
  };

  const handleSelect = (e: any) => {
    const index = promotion.itens.findIndex(
      (item: any) => item.idProduto == parseInt(e.target.id)
    );

    let promo = { ...promotion };
    const newArr = [...promotion.itens];
    newArr[index] = { ...newArr[index], idTipoDesconto: e.target.value };

    promo.itens = newArr;

    setPromotion(promo);
  };

  const handleAmountDiscount = (e: any) => {
    const index = promotion.itens.findIndex(
      (item: any) => item.idProduto == e.target.id
    );

    let promo = { ...promotion };
    const newArr = [...promotion.itens];
    newArr[index] = {
      ...newArr[index],
      vlItemPromocao: parseInt(e.target.value),
    };

    promo.itens = newArr;

    setPromotion(promo);
  };

  const handleQuantity = (e: any) => {
    const index = promotion.itens.findIndex(
      (item: any) => item.idProduto == e.target.id
    );
    let promo = { ...promotion };
    const newArr = [...promotion.itens];
    newArr[index] = { ...newArr[index], qtItemPromocao: e.target.value };

    promo.itens = newArr;

    setPromotion(promo);
  };

  const handleCheckboxesTiposEstabelecimento = (event: any) => {
    const newArr = { ...promotion };
    const index = newArr.abrangenciaTipoEstabelecimento.findIndex(
      (chk: any) => chk.idTipoEstabelecimento == event.target.value
    );

    newArr.abrangenciaTipoEstabelecimento[index] = {
      ...newArr.abrangenciaTipoEstabelecimento[index],
      checked: !newArr.abrangenciaTipoEstabelecimento[index].checked,
    };

    setPromotion(newArr);
  };

  const markCheckboxesTiposEstabelecimentos = (r: any) => {
    let response = { ...r };
    if (!response) {
      return;
    }

    const result = [
      ...comercial.promotionsAbrangenciaTiposEstabelecimentosList,
    ];
    for (let i = 0; i <= result.length; i++) {
      for (
        let j = 0;
        j <= response?.abrangenciaTipoEstabelecimento?.length;
        j++
      ) {
        if (
          result[i]?.idTipoEstabelecimento ===
          response?.abrangenciaTipoEstabelecimento[j]?.idTipoEstabelecimento
        ) {
          if (result[i]) {
            result[i] = {
              ...result[i],
              checked: true,
            };
          }
        }
      }
    }

    response.abrangenciaTipoEstabelecimento = result;

    return response;
  };

  const markCheckboxes = (r: any) => {
    let response = { ...r };
    if (!response) {
      return;
    }

    const result = [...comercial.productsList];
    for (let i = 0; i <= result.length; i++) {
      for (let j = 0; j <= response?.itens?.length; j++) {
        if (result[i]?.idProduto === response?.itens[j]?.idProduto) {
          if (result[i]) {
            result[i] = {
              ...result[i],
              checked: true,
              qtItemPromocao: response?.itens[j]?.qtItemPromocao,
              idTipoDesconto: response?.itens[j]?.idTipoDesconto,
              vlItemPromocao: response?.itens[j]?.vlItemPromocao,
            };
          }
        }
      }
    }
    response.itens = result;
    return response;
  };

  useEffect(() => {
    if (promotion) {
      let result = promotion;
      result = {
        ...result,
        abrangenciaClienteApp: filteredClients,
      };
      setPromotion(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredClients]);

  return (
    <div className="promocoes-container">
      <Button
        icon="pi pi-search"
        onClick={getPromotion}
        className="p-button-rounded p-button-secondary p-button-outlined"
      />

      <Sidebar
        className="sidebar-promotion-container"
        visible={visible}
        position="right"
        onHide={() => setVisible(false)}
      >
        <div className="sidebar-promotion-content-top">
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-danger p-button-text"
            onClick={() => setVisible(false)}
          />
          <p
            style={{ color: COLORS.primaryGreen }}
            className="sidebar-promotion-title"
          >
            Visualizar Promoção
          </p>
          {step === 1 ? (
            <div className="sidebar-promotion-principal">
              <div className="sidebar-promotion-buttons">
                <div className="sidebar-promotion-active">
                  <button
                    value={step}
                    onClick={() => setStep(1)}
                    className="promotion-buttons-active"
                  >
                    Promoção
                  </button>
                </div>
                <div className="sidebar-promotion-noactive">
                  {selectedCategory.value === 0 ? (
                    <button
                      value={step}
                      onClick={() => setStep(2)}
                      className="promotion-buttons-noactive"
                      disabled
                    >
                      Abrangência
                    </button>
                  ) : (
                    <button
                      value={step}
                      onClick={() => setStep(2)}
                      className="promotion-buttons-noactive"
                    >
                      Abrangência
                    </button>
                  )}
                </div>
              </div>
              <section className="inputs-section">
                <div className="sidebar-promotion-content">
                  <div className="sidebar-promotion-left">
                    <DefaultInput
                      label="Descrição"
                      value={promotion?.dsPromocao}
                      onChange={setDsPromocao}
                      disabled={true}
                    />
                    <div className="sidebar-promotion-left-inputs">
                      <DefaultInput
                        label="De"
                        type="date"
                        value={promotion?.dtValidadeDe}
                        onChange={setDtValidadeDe}
                        disabled={true}
                      />
                      <DefaultInput
                        label="Até"
                        type="date"
                        value={promotion?.dtalidadeAte}
                        onChange={setDtValidadeAte}
                        disabled={true}
                      />
                      <div className="p-field-checkbox">
                        <Checkbox
                          inputId="active"
                          checked={promotion?.inAtivo}
                          onChange={(e) => setInAtivo(e.checked)}
                        />
                        <label htmlFor="active">Ativo</label>
                      </div>
                    </div>
                  </div>

                  <div className="sidebar-promotion-right">
                    <p>Imagem</p>
                    {txImagemPromocao ? (
                      <div className="cardImgSrc">
                        <img src={txImagemPromocao} alt="Imagem do promoção" width="100px" />
                      </div>
                    ) : (
                      <div
                        className="divDropZone"
                        style={{ cursor: "pointer" }}
                      ></div>
                    )}
                  </div>
                </div>
              </section>

              <p>Itens</p>

              <div className="sidebar-promotion-itens-header">
                <h2 className="sidebar-promotion-itens-header-t1">Produto</h2>
                <h2 className="sidebar-promotion-itens-header-t2">Quantidade</h2>
                <h2 className="sidebar-promotion-itens-header-t3">Tipo de desconto</h2>
                <h2 className="sidebar-promotion-itens-header-t4">Valor/Percentual</h2>
              </div>
              <div className="sidebar-promotion-itens-header-responsive">
                <h2 className="sidebar-promotion-itens-header-t1-responsive">
                  Prod
                </h2>
                <h2 className="sidebar-promotion-itens-header-t2-responsive">
                  Quant
                </h2>
                <h2 className="sidebar-promotion-itens-header-t3-responsive">
                  Tipo Desc
                </h2>
                <h2 className="sidebar-promotion-itens-header-t4-responsive">
                  V/P
                </h2>
              </div>

              <div className="sidebar-promotion-itens">
                <div className="sidebar-promotion-itens-content">
                  {promotion &&
                    (loading ? (
                      <div className="divNotFind">
                        <ProgressLoad />
                      </div>
                    ) : (
                      <ItensListPromotion
                        itens={promotion.itens}
                        // products={promotion.products}
                        handleCheckboxes={handleCheckboxes}
                        handleSelect={handleSelect}
                        handleAmountDiscount={handleAmountDiscount}
                        handleQuantity={handleQuantity}
                        errors={errors}
                        view
                      />
                    ))}
                </div>
              </div>
              <h4 style={{ marginLeft: "16px", marginBottom: "4px" }}>
                Abrangência
              </h4>
              <div className="sidebar-promotion-coverage">
                {categories.map((category) => {
                  return (
                    <div key={category.value} className="p-field-radiobutton">
                      <RadioButton
                        inputId={category.name}
                        name="category"
                        value={category}
                        onChange={(e) => setSelectedCategory(e.value)}
                        checked={selectedCategory.value === category.value}
                        disabled={true}
                        className="radiobutton-style"
                      />
                      <label htmlFor={category.name}>{category.name}</label>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="sidebar-promotion-principal">
              <div className="sidebar-promotion-buttons">
                <div className="sidebar-promotion-noactive">
                  <button
                    value={step}
                    onClick={() => setStep(1)}
                    className="promotion-buttons-noactive"
                  >
                    Promoção
                  </button>
                </div>
                <div className="sidebar-promotion-active">
                  <button
                    value={step}
                    onClick={() => setStep(2)}
                    className="promotion-buttons-active"
                  >
                    Abrangência
                  </button>
                </div>
              </div>

              {selectedCategory.value === 1 && (
                <div className="sidebar-promotion-establishmentsTypes-container">
                  <h3>Tipos de Estabelecimento</h3>
                  <div className="sidebar-promotion-establishmentsTypes-content">
                    {loading ? (
                      <div className="divNotFind">
                        <ProgressLoad />
                      </div>
                    ) : (
                      <EstablishmentsTypesPromotion
                        handleCheckboxes={handleCheckboxesTiposEstabelecimento}
                        establishments={
                          promotion.abrangenciaTipoEstabelecimento
                        }
                        view
                      />
                    )}
                  </div>
                </div>
              )}

              {selectedCategory.value === 3 && (
                <div className="sidebar-promotion-clients-container">
                  <h3>Clientes disponíveis</h3>
                  <div className="sidebar-promotion-clients-content">
                    {loading ? (
                      <div className="divNotFind">
                        <ProgressLoad />
                      </div>
                    ) : (
                      <div className="sidebar-cupons-clients-input">
                        <div className="sidebar-cupons-clients-input-header">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder="Clientes..."
                            value={clientSelected}
                            isClearable={true}
                            isSearchable={true}
                            options={allClients}
                            onChange={(text) => {
                              setClientSelected(text as SelectProps);
                            }}
                            noOptionsMessage={() =>
                              "Não foi encontrado nenhum registro."
                            }
                            isOptionDisabled={(allClients) =>
                              allClients.disabled
                            }
                          />

                          {clientSelected !== "" ? (
                            <Button
                              label="Adicionar"
                              className="p-button-raised p-button-secondary p-button-text"
                              style={{ height: 36, marginLeft: 20 }}
                              disabled
                            />
                          ) : (
                            <Button
                              label="Adicionar"
                              className="p-button-raised p-button-secondary p-button-text"
                              style={{ height: 36, marginLeft: 20 }}
                              disabled
                            />
                          )}
                        </div>
                        <h1 style={{ margin: 0, fontSize: 20, marginTop: 24 }}>
                          Clientes Selecionados
                        </h1>
                        <div className="sidebar-cupons-clients-filteredList">
                          {filteredClients.length > 0 ? (
                            filteredClients.map((client) => {
                              return (
                                <div
                                  key={client?.idClienteApp}
                                  className="sidebar-cupons-clients-filteredList-client"
                                >
                                  {client?.clienteApp?.nmCliente || client?.nmCliente}

                                  <Button
                                    value={client?.idClienteApp}
                                    icon="pi pi-trash"
                                    className="p-button-rounded p-button-danger p-button-outlined"
                                    style={{ width: 28, height: 28 }}
                                    disabled
                                  />
                                </div>
                              );
                            })
                          ) : (
                            <h1 style={{ margin: 0, fontSize: 16 }}>
                              Nenhum cliente foi adicionado.
                            </h1>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* {selectedCategory.value === 4 && (
                <div className='sidebar-promotion-localization-container'>
                  <h3>Localização</h3>
                  <div className='sidebar-promotion-localization-content'>
                    {loading ? (
                      <div className='divNotFind'>
                        <ProgressLoad />
                      </div>
                    ) : (
                      // <ItensListCupons
                      //   handleCheckboxes={handleCheckboxesProduto}
                      //   itens={updateProductsCupons}
                      // />
                      <div className='sidebar-promotion-localization-selects'>
                        <div className='localization-selects-content'>
                          <div>
                              <p>UF</p>
                              <Dropdown value={selectUF} emptyMessage="Não foi encontrado nenhum registro."  onChange={(e) => setSelectUF(e.value)} className="localization-selects"/>
                          </div>
                          <div>
                              <p>Cidade</p>
                              <Dropdown value={selectUF} emptyMessage="Não foi encontrado nenhum registro."  onChange={(e) => setSelectUF(e.value)} className="localization-selects"/>
                          </div>
                          <div>
                              <p>Bairro</p>
                              <Dropdown value={selectUF} emptyMessage="Não foi encontrado nenhum registro."  onChange={(e) => setSelectUF(e.value)} className="localization-selects"/>
                          </div>
                          <button style={{width: '140px', height: '28px', marginTop: '60px'}}>Adicionar</button>
                        </div>
                        

                        <div className='sidebar-promotion-localization-list'>
                          <h3>Locais selecionados</h3>
                          <span>Bairro, cidade, UF</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )} */}
            </div>
          )}
        </div>
        <div></div>
      </Sidebar>
    </div>
  );
};

export default PromotionView;
