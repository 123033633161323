import React from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { COLORS } from '../../../../constants';
import { asyncGetTopicById } from '../../../../store/admin/admin.actions';
import DefaultTextarea from '../../../../components/DefaultTextArea';

interface TopicEditProps {
  id: number;
}

const HelpView = ({ id }: TopicEditProps) => {
  const [visible, setVisible] = useState(false);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [topic, setTopic] = useState<any>(null);

  const getTopic = async () => {
    const response: any = await asyncGetTopicById(id);
    setTopic(response);

    setVisible(true);
  };

  return (
    <div className='sidebarv2-wrapper'>
      <Button
        icon='pi pi-search'
        onClick={getTopic}
        className='p-button-rounded p-button-secondary p-button-outlined'
      />
      <Sidebar
        className='sidebar-help-container'
        visible={visible}
        position='right'
        onHide={() => setVisible(false)}
      >
        <div className='sidebar-help-content-top'>
          <Button
            icon='pi pi-times'
            className='p-button-rounded p-button-danger p-button-text'
            onClick={() => setVisible(false)}
          />
          <p
            style={{ color: COLORS.primaryGreen }}
            className='sidebar-help-title'
          >
            Visualizar Tópico
          </p>
          <div className='sidebar-help-inputs'>
            <h4>Pergunta</h4>
            <DefaultTextarea
              value={topic?.txPergunta}
              onChange={setQuestion}
              disabled={false}
            />

            <h4>Resposta</h4>
            <DefaultTextarea
              value={topic?.txResposta}
              onChange={setAnswer}
              disabled={false}
            />
          </div>
        </div>
        <div></div>
      </Sidebar>
    </div>
  );
};

export default HelpView;
