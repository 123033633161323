import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppData } from "..";
import { ApprovalTypes } from "../../../enums/ApprovalsTypes";
import { ToasterTypes } from "../../../enums/ToasterTypes";
import { RootState } from "../../../store";
import { asyncGetErpData } from "../../../store/approvals/approvals.actions";
import { updateErpData } from "../../../store/approvals/approvals.store";
import { updateToaster } from "../../../store/helper/helper.store";
import { cellMask, cepMask, cpfCnpjMask, phoneMask } from "../../../util/mask";
import "../../FieldGroup/styles.css";
import "../styles.css";

interface ApprovallAppProps {
  approval?: any;
}

const ApprovalApp: React.FC<ApprovallAppProps> = ({ approval }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const approvals = useSelector((state: RootState) => state.approvals);
  const [numeroDocumento, setNumeroDocumento] = useState("");

  const handleInput = (e: any) => {};

  const getErpData = async (id: number) => {
    setLoading(true);

    let request = await asyncGetErpData(id)
      .then((value: any) => {
        if (value.data === null) {
          setData(null);
        } else {
          const dadosDoEnpoint = value.data;
          const dadosERP = {
            enderecos: [
              {
                cdUf: dadosDoEnpoint.txUfPrincipal,
                id: dadosDoEnpoint.id,
                // idClientePreCadastro: 20319,
                idTipoEndereco: 1,
                idTipoEnderecoNavigation: null,
                nmBairro: dadosDoEnpoint.txBairroPrincipal,
                nmCidade: dadosDoEnpoint.txMunicipioPrincipal,
                txCep: dadosDoEnpoint.txCepPrincipal,
                txComplemento: dadosDoEnpoint.txComplementoPrincipal,
                txLogradouro: dadosDoEnpoint.txEnderecoPrincipal,
                txNumero: "",
                txPontoReferencia: dadosDoEnpoint.txComplementoPrincipal,
              },
              {
                cdUf: dadosDoEnpoint.txUfEntrega,
                id: dadosDoEnpoint.id,
                // idClientePreCadastro: 20319,
                idTipoEndereco: 2,
                idTipoEnderecoNavigation: null,
                nmBairro: dadosDoEnpoint.txBairroEntrega,
                nmCidade: dadosDoEnpoint.txMunicipioEntrega,
                txCep: dadosDoEnpoint.txCepEntrega,
                txComplemento: dadosDoEnpoint.txComplementoEntrega,
                txLogradouro: dadosDoEnpoint.txEnderecoEntrega,
                txNumero: "",
                txPontoReferencia: dadosDoEnpoint.txComplementoEntrega,
              },
            ],
            id: dadosDoEnpoint.id,
            idSolicitacaoCadastro: "",
            idTipoEstabelecimento: null,
            idTipoPessoa: null,
            nmCliente: dadosDoEnpoint.nmCliente,
            nmFantasia: dadosDoEnpoint.nmFantasia,
            tipoEstabelecimento: {
              cdTipoEstabelecimentoErp: dadosDoEnpoint.cdTipoEstabelecimento,
              dsTipoEstabelecimento: dadosDoEnpoint.dsTipoEstabelecimento,
              id: null,
            },
            //não tem os tipos de estabelecimento quando vem do
            tipoPessoa: {
              dsTipoPessoa: dadosDoEnpoint.dsTipoPessoa,
              cdTipoPessoa: null,
              id: null,
            },
            txCelular: dadosDoEnpoint.txDddCelular + dadosDoEnpoint.txCelular,
            txCpfCnpj: dadosDoEnpoint.txCpfCnpj,
            txEmail: dadosDoEnpoint.txEmail,
            txTelefone: dadosDoEnpoint.txDdd + dadosDoEnpoint.txTelefone,
          };
          dispatch(updateErpData(dadosERP));
          setNumeroDocumento(
            value.data?.numeroDocumento
              ? value.data?.numeroDocumento
              : value.data?.txCpfCnpj
          );
          setData(value.data);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getErpData(approvals.evaluation.pre.txCpfCnpj);
  }, []);

  return (
    <>
      <div className="field-group-container">
        <span className="field-group-item">
          <label className="field-group-label" htmlFor="in">
            Tipo de estabelecimento
          </label>
          <InputText
            id="in"
            placeholder={data?.dsTipoEstabelecimento}
            disabled={true}
          />
        </span>
        <span className="field-group-item">
          <label className="field-group-label" htmlFor="in">
            Tipo de cliente
          </label>
          <InputText id="in" placeholder={data?.dsTipoPessoa} disabled={true} />
        </span>
      </div>
      <>
        <div className="title-field">
          <p>Dados</p>
        </div>
        <div className="field-group-container">
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              CNPJ / CPF
            </label>
            <InputText
              id="in"
              placeholder={cpfCnpjMask(numeroDocumento)}
              disabled={true}
            />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Razão Social / Nome
            </label>
            <InputText id="in" placeholder={data?.nmCliente} disabled={true} />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              E-mail
            </label>
            <InputText id="in" placeholder={data?.txEmail} disabled={true} />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Celular
            </label>
            <InputText
              id="in"
              placeholder={cellMask(data?.txDddCelular + data?.txCelular)}
              disabled={true}
            />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Telefone
            </label>
            <InputText
              id="in"
              placeholder={phoneMask(data?.txDdd + data?.txTelefone)}
              onChange={handleInput}
              disabled={true}
            />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Nome Fantasia
            </label>
            <InputText
              id="in"
              placeholder={data?.nmFantasia}
              onChange={handleInput}
              disabled={true}
            />
          </span>
        </div>
      </>
      <>
        <div className="title-field">
          <p>Endereço de Cadastro</p>
        </div>
        <div className="field-group-container">
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              CEP
            </label>
            <InputText
              id="in"
              placeholder={cepMask(data?.txCepPrincipal)}
              disabled={true}
            />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Logradouro
            </label>
            <InputText
              id="in"
              placeholder={data?.txEnderecoPrincipal}
              disabled={true}
            />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Número
            </label>
            <InputText
              id="in"
              placeholder={data?.txNumeroPrincipal}
              disabled={true}
            />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Bairro
            </label>
            <InputText
              id="in"
              placeholder={data?.txBairroPrincipal}
              disabled={true}
            />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Cidade
            </label>
            <InputText
              id="in"
              placeholder={data?.txMunicipioPrincipal}
              onChange={handleInput}
              disabled={true}
            />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              UF
            </label>
            <InputText
              id="in"
              placeholder={data?.txUfPrincipal}
              onChange={handleInput}
              disabled={true}
            />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Complemento
            </label>
            <InputText
              id="in"
              placeholder={data?.txComplementoPrincipal}
              onChange={handleInput}
              disabled={true}
            />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Ponto de Referência
            </label>
            <InputText
              id="in"
              placeholder={data?.txPontoReferenciaPrincipal}
              onChange={handleInput}
              disabled={true}
            />
          </span>
        </div>
      </>
      <>
        <div className="title-field">
          <p>Endereço de Entrega</p>
        </div>
        <div className="field-group-container">
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              CEP
            </label>
            <InputText
              id="in"
              placeholder={cepMask(data?.txCepEntrega)}
              disabled={true}
            />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Logradouro
            </label>
            <InputText
              id="in"
              placeholder={data?.txEnderecoEntrega}
              disabled={true}
            />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Número
            </label>
            <InputText
              id="in"
              placeholder={data?.txNumeroEntrega}
              disabled={true}
            />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Bairro
            </label>
            <InputText
              id="in"
              placeholder={data?.txBairroEntrega}
              disabled={true}
            />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Cidade
            </label>
            <InputText
              id="in"
              placeholder={data?.txMunicipioEntrega}
              onChange={handleInput}
              disabled={true}
            />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              UF
            </label>
            <InputText
              id="in"
              placeholder={data?.txUfEntrega}
              onChange={handleInput}
              disabled={true}
            />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Complemento
            </label>
            <InputText
              id="in"
              placeholder={data?.txComplementoEntrega}
              onChange={handleInput}
              disabled={true}
            />
          </span>
          <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">
              Ponto de Referência
            </label>
            <InputText
              id="in"
              placeholder={data?.txPontoReferenciaEntrega}
              onChange={handleInput}
              disabled={true}
            />
          </span>
        </div>
      </>
    </>
  );
};

export default ApprovalApp;
