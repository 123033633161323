import { DataTable } from "primereact/datatable";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProgressLoad from "../../components/ProgressLoad";
import StatusTag from "../../components/StatusTag";
import { StatusComodatos } from "../../enums/StatusComodatos";
import { RootState } from "../../store";
import ComodatosFilter from "./ComodatosFilter";
import { COLORS } from "../../constants";
import { Column } from "primereact/column";
import { useHistory } from "react-router-dom";
import { getComodatos } from "../../store/comodatos/comodatos.actions";
import { cpfCnpjMask } from "../../util/mask";
import { format } from "date-fns";

const Comodatos: React.FC = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.loading);
  const comodatos = useSelector((state: RootState) => state.comodatos);
  const history = useHistory();

  function getFormatDate(date = new Date(), dateFormat = "dd/MM/yyyy") {
    return format(new Date(date), dateFormat);
  }

  useEffect(() => {
    dispatch(getComodatos());
  }, []);

  const typeTemplate = (e: any) => {
    return <strong>{e.tipoSolicitacao.dsTipoSolicitacaoComodato}</strong>;
  };

  const dateTemplate = (e: any) => (
    <span>{getFormatDate(e.dtSolicitacao)}</span>
  );

  const documentNumberTemplate = (e: any) => (
    <span>{cpfCnpjMask(e.cliente.txCpfCnpj)}</span>
  );

  const nameTemplate = (e: any) => <span>{e.cliente.nmCliente}</span>;

  const statusTemplate = (e: any) => {
    let color: string = "";
    switch (e.idStatusSolicitacaoComodato) {
      case StatusComodatos.solicitado ||
        StatusComodatos.aguardandoContatoTelefonico ||
        StatusComodatos.aguardandoEntrega ||
        StatusComodatos.aguardandoTrocaDoProduto ||
        StatusComodatos.aguardandoVisitaComercial ||
        StatusComodatos.aguardandoVisitaTecnica ||
        StatusComodatos.emElaboracaoContratual:
        color = COLORS.primaryOrange;
        break;
      case StatusComodatos.aprovado || StatusComodatos.entregue:
        color = COLORS.primaryGreen;
        break;
      case StatusComodatos.reprovado:
        color = "red";
        break;
      case StatusComodatos.contratoCancelado || StatusComodatos.cancelado:
        color = "#AA0061";
        break;
      default:
        color = COLORS.primaryOrange;
        break;
    }
    return (
      <StatusTag color={color} value={e.status.dsStatusSolicitacaoComodato} />
    );
  };

  const handleRow = (row: any) => {
    history.push(`/comodatos/evaluation/${row.data.id}`);
  };

  const styles = {
    table: {
      backgroundColor: COLORS.white,
      fontSize: 12,
      border: "1px solid #E0E0E0",
    },
  };

  const headerStyle = {
    // textAlign: 'center',
    color: "#606060",
    cursor: "default",
  };

  const columnStyle = {
    // textAlign: 'center',
    color: "#606060",
    cursor: "pointer",
  };

  return (
    <div className="main-page">
      <div className="card">
        <ComodatosFilter />
        {loading ? (
          <div style={{ margin: "200px 0px" }}>
            <ProgressLoad />
          </div>
        ) : (
          <DataTable
            className="approval-table"
            value={comodatos.list}
            stripedRows
            onRowClick={handleRow}
            style={styles.table}
            responsiveLayout="stack"
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 15, 20, 30, 50]}
            rowHover
            emptyMessage="Não foi encontrado nenhum registro."
          >
            <Column
              style={columnStyle}
              headerStyle={headerStyle}
              header="Tipo"
              body={typeTemplate}
            ></Column>
            <Column
              style={columnStyle}
              headerStyle={headerStyle}
              header="Data Solicitação"
              body={dateTemplate}
            ></Column>
            <Column
              style={columnStyle}
              headerStyle={headerStyle}
              header="CNPJ / CPF"
              body={documentNumberTemplate}
            ></Column>
            <Column
              style={columnStyle}
              headerStyle={headerStyle}
              header="Razão Social / Nome"
              body={nameTemplate}
            ></Column>
            <Column
              style={columnStyle}
              headerStyle={headerStyle}
              header="Status"
              body={statusTemplate}
            ></Column>
          </DataTable>
        )}
      </div>
    </div>
  );
};

export default Comodatos;
