import { confirmDialog } from "primereact/confirmdialog";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import Parser from "react-html-parser";

import CardListHelp from "../../../components/Help/CardListHelp";
import "./styles.css";
import HelpCreate from "./HelpCreate";
import {
  asyncDeleteTopics,
  asyncGetTopics,
} from "../../../store/admin/admin.actions";
import ProgressLoad from "../../../components/ProgressLoad";

const HelpPage: React.FC = () => {
  const dispatch = useDispatch();

  const { loading } = useSelector((state: RootState) => state.loading);

  const admin = useSelector((state: RootState) => state.admin);

  const deleteConfirm = (e: any) => {
    const id = e.target.value | e.target.parentNode.value;
    confirmDialog({
      message: "Você quer deletar este registro?",
      header: "Confirmação de exclusão",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => handleDelete(id),
      acceptLabel: "Sim",
      rejectLabel: "Não",
    });
  };

  const handleDelete = (id: number) => {
    if (id) dispatch(asyncDeleteTopics(id));
  };

  useEffect(() => {
    dispatch(asyncGetTopics());
  }, [dispatch]);


  return (
    <div className='main-page'>
      <p className='title'>Ajuda (FAQ)</p>
      <HelpCreate />

      {loading ? (
        <div className='divNotFind'>
          <ProgressLoad />
        </div>
      ) : admin.topics.length === 0 ? (
        <div className='divNotFind'>
          <p>Não foi encontrado nenhum registro.</p>
        </div>
      ) : (
        admin.topics.map((question, i) => (
          <CardListHelp
            id={question.id}
            key={i}
            question={question.txPergunta}
            // question={Parser(question.txPergunta)}
            handleDelete={deleteConfirm}
          />
        ))
      )}
    </div>
  );
};

export default HelpPage;
