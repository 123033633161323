import React from 'react';
import './style.css';
import headerImg from '../../assets/images/logo-guaracamp.png';
import { Button } from 'primereact/button';

import { COLORS } from '../../constants';
import { logout } from '../../store/auth/token.service'

const Header: React.FC = () => {
  const handleClick = () =>{
    logout()
    window.location.pathname = '/login'
  }

  return (
    <section style={{ background: COLORS.white }} className='secHeader'>
      <img src={headerImg} alt='Logo Guaracamp' className='headerImg' />
      <Button
        label='Sair'
        style={{ color: COLORS.primaryGreen }}
        onClick={handleClick}
        className='p-button-outlined btnHeader'
      />
    </section>
  );
};

export default Header;
