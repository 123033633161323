import React, { useEffect, useRef } from "react";

import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { useState } from "react";
import DefaultInput from "../../../../components/DefaultInput";
// import '../styles.css'
import CheckboxDefault from "../../../../components/CheckboxDefault";
import { COLORS } from "../../../../constants";
import { ProfileProps } from "../../../../store/admin/admin.store";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  asyncGetProfilesToUser,
  asyncPostUser,
} from "../../../../store/admin/admin.actions";
import * as Yup from "yup";
import getValidationErrors from "../../../../util/validationError";
import { StateProps } from "../../../../enums";

const UserCreate = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [txEmail, setTxEmail] = useState("");
  const [nmUsuario, setNmUsuario] = useState("");
  const [perfis, setPerfis] = useState<ProfileProps[]>([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errors, setErrors] = useState({} as any);
  const [errorMessage, setErrorMessage] = useState(false);

  const admin = useSelector((state: RootState) => state.admin);
  useEffect(() => {
    dispatch(asyncGetProfilesToUser());
  }, [dispatch]);

  useEffect(() => {
    setPerfis(admin.profilesList);
  }, [admin.profilesList]);

  const userModules: any = [];
  perfis.map((module) => {
    if (module.checked)
      userModules.push({
        idPerfil: module.idItem,
      });
    return 0;
  });

  const handleSubmit = async () => {
    setErrors({} as StateProps);
    setButtonLoading(true);
    setErrorMessage(true);

    try {
      const userModules: any = [];
      perfis.map((module) => {
        if (module.checked)
          userModules.push({
            idPerfil: module.idItem,
          });
        return 0;
      });

      const user = {
        txEmail: txEmail,
        nmUsuario: nmUsuario,
        perfis: userModules,
      };

      const schema = Yup.object().shape({
        txEmail: Yup.string()
          .email("Deve ser um e-mail válido")
          .required("Username obrigatório."),
        nmUsuario: Yup.string().required("Nome obrigatório."),
        perfis: Yup.array().min(1, "Perfil obrigatório."),
      });

      await schema.validate(user, {
        abortEarly: false,
      });

      dispatch(asyncPostUser(user));
      setPerfis(admin.profilesList);
      setNmUsuario("");
      setTxEmail("");
      setVisible(false);
      setErrorMessage(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrors(getValidationErrors(err));
        return;
      }
    } finally {
      setTimeout(() => {
        setButtonLoading(false);
      }, 1000);
    }
  };

  const handleCheckboxes = (event: any) => {
    const index = perfis.findIndex((chk) => chk.idItem === event.target.value);
    const newArr = [...perfis];
    newArr[index] = { ...newArr[index], checked: !newArr[index].checked };
    setPerfis(newArr);
  };

  const handleCancel = () => {
    setTxEmail("");
    setNmUsuario("");
    setPerfis(admin.profilesList);
    setVisible(false);
    setErrors("");
  };

  return (
    <div className="sidebarv2-wrapper">
      <Button
        label="Novo usuário"
        onClick={() => setVisible(true)}
        className="sidebarv2-open-button"
        color="#AA0061"
      />
      <Sidebar
        className="sidebar-users-container"
        visible={visible}
        position="right"
        onHide={handleCancel}
      >
        <div className="sidebar-users-content-top">
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-danger p-button-text"
            onClick={handleCancel}
          />
          <p
            style={{ color: COLORS.primaryGreen }}
            className="sidebar-users-title"
          >
            Cadastrar Usuário
          </p>
          <div className="sidebar-users-inputs">
            <DefaultInput
              label="Username(Email)"
              value={txEmail}
              onChange={setTxEmail}
              disabled={false}
              error={errors.txEmail}
            />
            <DefaultInput
              label="Nome"
              value={nmUsuario}
              onChange={setNmUsuario}
              disabled={false}
              error={errors.nmUsuario}
            />
          </div>
          <p className="sidebar-users-checkbox-title">
            Defina os perfis do usuário:
          </p>
          <div className="sidebar-users-checkboxes">
            {perfis.map((module, i) => (
              <CheckboxDefault
                key={i}
                checkbox={module}
                disabled={false}
                handleCheckboxes={handleCheckboxes}
              />
            ))}
          </div>
        </div>

        <div className="sidebar-users-content-bottom">
          <div className="sidebar-users-actions">
            <Button
              label="Cancelar"
              className="p-button-outlined p-button-secondary"
              onClick={handleCancel}
              style={{ marginRight: "20px", width: "40%" }}
            />
            <Button
              id="button"
              disabled={userModules.length === 0}
              label="Salvar"
              className="p-button-lg"
              onClick={handleSubmit}
              style={{ width: "40%" }}
              loading={buttonLoading}
            />
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default UserCreate;
