import { Dispatch } from 'redux';
import { SelectOptions } from '../../components/DefaultSelect';
import GuaracampAPI from '../../services/GuaracampAPI';
import { updateLoading } from '../loading/loading.store';
import {
  setCurrentApproval,
  updateAppData,
  updateApprovals,
  updateConfirmData,
  updateErpData,
  updatePreData,
  updateSerproData,
  updateStatus,
  updateTypes,
} from './approvals.store';

export const getApprovals = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get('/solicitacaocadastro?pagina=1&registros=500')
      .then((res: any) => {
        dispatch(updateApprovals(res.data.lista));
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getApprovalById = (id: number) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get(`/solicitacaocadastro/${id}`)
      .then((res: any) => {
        dispatch(setCurrentApproval(res.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getAppData = (id: number) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get(`/clientes/app/${id}`)
      .then((res: any) => {
        dispatch(updateAppData(res.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const asyncGetAppData = async (id: number) => {
  try {
    const retorno = await GuaracampAPI.get(`/clientes/app/${id}`);
    return retorno;
  } catch (error) {
    throw error;
  }
};

export const asyncGetPreData = async (id: number) => {
  try {
    const retorno = await GuaracampAPI.get(`/precadastros/${id}`);
    return retorno;
  } catch (error) {
    throw error;
  }
};

export const getPostRegisterData = (id: number) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get(`/precadastros/${id}`)
      .then((res: any) => {
        dispatch(updatePreData(res.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const asyncGetErpData = async (id: number) => {
  try {
    const retorno = await GuaracampAPI.get(`/clientes/erp/documento/${id}`);
    return retorno;
  } catch (error) {
    throw error;
  }
};

export const getSerproData = (id: number) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get(`/clientes/serpro/${id}`)
      .then((res: any) => {
        dispatch(updateSerproData(res.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const seacrhApprovals = (data: any) => {
  const type = data.type ? `&tipo=${data.type}` : '';
  const status = data.status ? `&status=${data.status}` : '';
  const document = data.document ? `&documento=${data.document}` : '';

  return (dispatch: Dispatch) => {
    GuaracampAPI.get(
      `/solicitacaocadastro/pesquisa?pagina=1&registros=500${type}${status}${document}`
    )
      .then((res: any) => {
        dispatch(updateApprovals(res.data.lista));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getApprovalTypes = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get('/tiposolicitacaocadastro')
      .then(async (res: any) => {
        const selectOptions = (await res.data.map((v: any) => {
          return { name: v.dsTipoSolicitacaoCadastro, value: v.id };
        })) as SelectOptions[];
        dispatch(updateTypes(selectOptions));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getApprovalStatus = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get('/statussolicitacaocadastro')
      .then(async (res: any) => {
        const selectOptions = (await res.data.map((v: any) => {
          return { name: v.dsStatusSolicitacaoCadastro, value: v.id };
        })) as SelectOptions[];
        dispatch(updateStatus(selectOptions));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const approve = async (data: any) => {
  let response = null;
  await GuaracampAPI.post('/solicitacaocadastro/aprovacao', data)
    .then(async (res: any) => {
      response = res;
    })
    .catch((error) => {
      console.error({
        msg: 'Erro ao aprovar solicitação',
        error: error.response.data,
        status: error.response.status,
      });
    });
  return response;
};

export const disapprove = async (idApprove: number) => {
  let response: any;
  await GuaracampAPI.post(`/solicitacaocadastro/${idApprove}/reprovacao`)
    .then(async (res: any) => {
      response = res;
    })
    .catch((error) => {
      throw error;
    });

  return response;
};
