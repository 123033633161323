import { InputText } from "primereact/inputtext"

interface DefaultInputProps {
    label: string;
    value: string;
    onChange: React.Dispatch<React.SetStateAction<string>>
    disabled: boolean;
    type?: string;
    showLabel?:  boolean;
    min?: any;
    required?: boolean;
    error?: string;
    maxLength?: number;
}

  const DefaultInput: React.FC<DefaultInputProps> = ({ label, value, onChange, disabled, type, showLabel = true, min, required = false, error = '', maxLength}) => {
    return (
        <span className="field-group-item">
            <label className="field-group-label" htmlFor="in">{ label }</label>
            <InputText id="in" value={value} onChange={(e) => onChange(e.target.value)} disabled={disabled} type={type} min={min} required={required} maxLength={maxLength} />
            {error !== undefined && error !== '' && <span style={{ fontSize: '12px', color: 'red', margin: '0'}}>{error}</span>}
        </span>
    )   
}


export default DefaultInput
