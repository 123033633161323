import { configureStore, Action, ThunkAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import authReducer from "./auth/auth.store";
import approvalsReducer from "./approvals/approvals.store";
import adminReducer from "./admin/admin.store";
import comercialReducer from "./comercial/comercial.store";
import comodatosReducer from "./comodatos/comodatos.store";
import marketingReducer from "./marketing/marketing.store";
import helperReducer from "./helper/helper.store";
import loadingReducer from "./loading/loading.store";

const store = configureStore({
  reducer: {
    auth: authReducer,
    approvals: approvalsReducer,
    comodatos: comodatosReducer,
    admin: adminReducer,
    comercial: comercialReducer,
    marketing: marketingReducer,
    helper: helperReducer,
    loading: loadingReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
