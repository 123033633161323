import React, { useEffect } from 'react'

import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { useState } from 'react';
import DefaultInput from '../../../../components/DefaultInput';
import CheckboxDefault from '../../../../components/CheckboxDefault';
import { COLORS } from '../../../../constants';
import { ModulesProps } from '../../../../store/admin/admin.store';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { asyncGetProfileById } from '../../../../store/admin/admin.actions';
import ProgressLoad from '../../../../components/ProgressLoad'

interface ProfileViewProps {
    id: number;
}

const ProfileView: React.FC<ProfileViewProps> = ({ id }) => {
    const [ visible, setVisible ] = useState(false)
    const [ modules, setModules ] = useState<ModulesProps[]>([])
    const [ profile, setProfile ] = useState<any>()

    const admin = useSelector((state: RootState) => state.admin);

    const getProfile = async () => {
        const response = await asyncGetProfileById(id)
        setProfile(response)
        setVisible(true)
    }

    useEffect(() => {
        const markCheckboxes = () => {
            const result = [...modules]
            for(let i = 0; i < result.length; i++){
                for(let j = 0; j < profile?.modulos.length; j++){
                    if(result[i]?.idItem === profile?.modulos[j]?.idModulo){
                        if(result[i]){
                            result[i] = {...result[i], checked: true}
                        }
                    }
                }
            }
            setModules(result)
        }
        markCheckboxes()
    }, [profile?.modulos])

    useEffect(() => {
      setModules(admin.modulos)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [admin.modulos])

    const handleCheckboxes = (event: any) => {}

    return (
        <div className="sidebarv2-wrapper">
            <Button icon='pi pi-search' onClick={getProfile} className='p-button-rounded p-button-secondary p-button-outlined'/>
            <Sidebar className="sidebar-perfis-container" visible={visible} position="right" onHide={() => setVisible(false)}>
            <div className="sidebar-perfis-content-top">
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" onClick={() => setVisible(false)}/>
                <p style={{ color: COLORS.primaryGreen }} className="sidebar-perfis-title">Visualização de Perfil</p>
                <div className="sidebar-perfis-inputs">
                    <DefaultInput
                        label="Descrição"
                        value={profile?.dsPerfil}
                        onChange={setProfile}
                        disabled={true}
                    />
                </div>
                <p className="sidebar-perfis-checkbox-title">Defina as permissões do perfil</p>
                <div className="sidebar-perfis-checkboxes">
                {       admin.modulos.length === 0 ? (
                        <div className="divNotFind">
                            <p>Não foi encontrado nenhum registro.</p>
                        </div>
                        ) : (modules.map((module, i) => (
                        <CheckboxDefault key={i} checkbox={module} disabled={true} handleCheckboxes={handleCheckboxes} />
                        )))
                    }
                </div>
            </div>
            <div>
                
            </div>
            </Sidebar>
        </div>
    )
}

export default ProfileView
