import React, { useEffect, useState } from "react";
import CardList from "../../../components/CardList";
import "./styles.css";
import Filter from "../../../components/Filter";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  asyncDeleteUser,
  asyncGetUserById,
  asyncGetUsers,
  asyncSearchUser,
} from "../../../store/admin/admin.actions";
import { confirmDialog } from "primereact/confirmdialog";
import UserCreate from "./UserCreate";
import { Oval } from "react-loader-spinner";
import { updateLoading } from "../../../store/loading/loading.store";

const AdminUsers: React.FC = () => {
  const dispatch = useDispatch();
  const [txEmail, setTxEmail] = useState("");
  const [nmUsuario, setNmUsuario] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newName, setNewName] = useState("");
  const [user, setUser] = useState<any>();

  const admin = useSelector((state: RootState) => state.admin);
  const { loading } = useSelector((state: RootState) => state.loading);

  const deleteConfirm = (e: any) => {
    const id = e.target.value | e.target.parentNode.value;
    confirmDialog({
      message: "Você quer deletar este registro?",
      header: "Confirmação de exclusão",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => handleDelete(id),
      acceptLabel: "Sim",
      rejectLabel: "Não",
    });
  };

  const handleDelete = (id: number) => {
    if (id) dispatch(asyncDeleteUser(id));
  };

  const passInputs = [
    {
      type: "text",
      label: "Username(Email)",
      value: txEmail,
      onChange: setTxEmail,
    },
    {
      type: "text",
      label: "Nome",
      value: nmUsuario,
      onChange: setNmUsuario,
    },
  ];

  const passInputsSideBar = [
    {
      type: "text",
      label: "Username(Email)",
      value: newEmail,
      onChange: setNewEmail,
    },
    {
      type: "text",
      label: "Nome",
      value: newName,
      onChange: setNewName,
    },
  ];

  useEffect(() => {
    dispatch(asyncGetUsers());
  }, [dispatch]);

  const handleClickFilter = () => {
    if (nmUsuario !== "" || txEmail !== "") {
      dispatch(updateLoading(true));
      dispatch(asyncSearchUser(nmUsuario, txEmail));
    } else {
      dispatch(asyncGetUsers());
      dispatch(updateLoading(true));
    }
  };

  const handleClickClean = () => {
    setTxEmail("");
    setNmUsuario("");
    dispatch(asyncGetUsers());
  };

  const idUsuario = Number(localStorage.getItem("@USER_ID_GUARACAMP"));

  const getUser = async () => {
    const response = await asyncGetUserById(idUsuario);
    setUser(response);
  };

  useEffect(() => {
    if (idUsuario) {
      getUser();
    }
  }, []);

  const administrativePerfil = user?.perfis.find(
    (perfil: any) => perfil.idPerfil === 74
  );

  return (
    <div className="main-page">
      <p className="title">Usuários</p>
      <Filter
        inputs={passInputs}
        handleClickFilter={handleClickFilter}
        handleClickClean={handleClickClean}
      />
      <UserCreate />
      {loading ? (
        <div className="divNotFind">
          <Oval color="#3dae2b" height={40} width={40} />
        </div>
      ) : admin.users.length === 0 ? (
        <div className="divNotFind">
          <p>Não foi encontrado nenhum registro.</p>
        </div>
      ) : (
        admin.users.map((user, i) => (
          <CardList
            disabled={administrativePerfil === undefined}
            handleDelete={deleteConfirm}
            key={i}
            name={user.nmUsuario}
            email={user.txEmail}
            idItem={user.id}
            inputs={passInputsSideBar}
            route="user"
          />
        ))
      )}
    </div>
  );
};

export default AdminUsers;
