import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Sidebar } from "primereact/sidebar";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DefaultInput from "../../../../components/DefaultInput";
import { COLORS } from "../../../../constants";
import { RadioButton } from "primereact/radiobutton";
import DefaultSelect, {
  SelectOptions,
} from "../../../../components/DefaultSelect";
import { RootState } from "../../../../store";
import { asyncGetCupomById } from "../../../../store/marketing/marketing.actions";
import ItensListCupons from "../../../../components/Marketing/ProductsList";
import CategoryListCupons from "../../../../components/Marketing/CategoriesList";
import ProgressLoad from "../../../../components/ProgressLoad";
import EstablishmentsTypesCupons from "../../../../components/Marketing/AbrangenciaEstablishmentList";
import { ClientesAppProps } from "../../../../store/marketing/marketing.store";
import Select from "react-select";

interface CuponsViewProps {
  id: number;
}

const categories = [
  { name: "Por pedido", value: 1 },
  { name: "Por categoria", value: 2 },
  { name: "Por produto", value: 3 },
];

const categoriesCoverage = [
  { name: "Todos", value: 0 },
  { name: "Tipo de estabelecimento", value: 1 },
  { name: "Cliente", value: 3 },
  // { name: "Localização", value: 4 },
];

export type SelectProps = {
  label: string;
  value: number;
};

const CuponsView = ({ id }: CuponsViewProps) => {
  const [visible, setVisible] = useState(false);
  const [step, setStep] = useState(1);
  const [dsCupom, setDsCupom] = useState("");
  const [cdCupom, setCdCupom] = useState("");
  const [dtValidadeDe, setDtValidadeDe] = useState("");
  const [dtValidadeAte, setDtValidadeAte] = useState("");
  const [vlCupom, setVlCupom] = useState("");
  const [qtLiberada, setQtLiberada] = useState("");
  const [qtUtilizada, setQtUtilizada] = useState("");
  const [idTipoDesconto, setIdTipoDesconto] = useState("");
  const [inAtivo, setInAtivo] = useState<boolean>(false);
  const [inVisivel, setInVisivel] = useState<boolean>(false);
  const [cupom, setCupom] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [selectedCategoryCoverage, setSelectedCategoryCoverage] = useState(
    categoriesCoverage[0]
  );

  const [allClients, setAllClients] = useState<any>([]);
  const [clientSelected, setClientSelected] = useState<any>("");
  const [filteredClients, setFilteredClients] = useState<ClientesAppProps[]>(
    []
  );

  const marketing = useSelector((state: RootState) => state.marketing);

  const options: SelectOptions[] = [
    {
      name: "Valor",
      value: 2,
    },
    {
      name: "Porcentagem",
      value: 1,
    },
  ];

  const getCupom = async () => {
    setLoading(true);
    try {
      const response: any = await asyncGetCupomById(id);
      const checkboxesProdutos = markCheckboxesProducts(response);
      const checkboxesCategorias = markCheckboxesCategories(response);
      const checkboxesTiposEstabelecimento =
        markCheckboxesTiposEstabelecimentos(response);

      checkboxesProdutos.categorias = checkboxesCategorias.categorias;
      checkboxesProdutos.abrangenciaTipoEstabelecimento =
        checkboxesTiposEstabelecimento.abrangenciaTipoEstabelecimento;

      setSelectedCategory(categories[response?.idTipoAplicacao - 1]);

      if (response?.idTipoAbrangencia === null) {
        setSelectedCategoryCoverage(categoriesCoverage[0]);
      } else if (response?.idTipoAbrangencia === 1) {
        setSelectedCategoryCoverage(
          categoriesCoverage[response?.idTipoAbrangencia]
        );
      } else {
        setSelectedCategoryCoverage(
          categoriesCoverage[response?.idTipoAbrangencia - 1]
        );
      }

      setFilteredClients(response?.abrangenciaClienteApp);

      setCupom(checkboxesProdutos);
    } catch {
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      setVisible(true);
    }
  };

  const handleCheckboxesProduto = (event: any) => {
    const newArr = { ...cupom };
    const index = newArr.itens.findIndex((chk: any) => {
      return chk.idProduto == event.target.value;
    });

    newArr.itens[index] = {
      ...newArr.itens[index],
      checked: !newArr.itens[index].checked,
    };

    setCupom(newArr);
  };

  const handleCheckboxesCategoria = (event: any) => {
    const newArr = { ...cupom };
    const index = newArr.categorias.findIndex(
      (chk: any) => chk.idCategoriaProduto == event.target.value
    );

    newArr.categorias[index] = {
      ...newArr.categorias[index],
      checked: !newArr.categorias[index].checked,
    };

    setCupom(newArr);
  };

  const handleCheckboxesTiposEstabelecimento = (event: any) => {
    const newArr = { ...cupom };
    const index = newArr.abrangenciaTipoEstabelecimento.findIndex(
      (chk: any) => chk.idTipoEstabelecimento == event.target.value
    );

    newArr.abrangenciaTipoEstabelecimento[index] = {
      ...newArr.abrangenciaTipoEstabelecimento[index],
      checked: !newArr.abrangenciaTipoEstabelecimento[index].checked,
    };

    setCupom(newArr);
  };

  const markCheckboxesProducts = (r: any) => {
    let response = { ...r };
    if (!response) {
      return;
    }

    const result = [...marketing.cuponsProductsList];
    for (let i = 0; i <= result.length; i++) {
      for (let j = 0; j <= response?.itens?.length; j++) {
        if (result[i]?.idProduto === response?.itens[j]?.idProduto) {
          if (result[i]) {
            result[i] = {
              ...result[i],
              checked: true,
            };
          }
        }
      }
    }
    response.itens = result;

    return response;
  };

  const markCheckboxesCategories = (r: any) => {
    let response = { ...r };
    if (!response) {
      return;
    }

    const result = [...marketing.cuponsCategoriesList];
    for (let i = 0; i <= result.length; i++) {
      for (let j = 0; j <= response?.categorias?.length; j++) {
        if (result[i]?.idCategoriaProduto === response?.categorias[j]?.idCategoriaProduto) {
          if (result[i]) {
            result[i] = {
              ...result[i],
              checked: true,
            };
          }
        }
      }
    }

    response.categorias = result;

    return response;
  };

  const markCheckboxesTiposEstabelecimentos = (r: any) => {
    let response = { ...r };
    if (!response) {
      return;
    }

    const result = [...marketing.abrangenciaTiposEstabelecimentosList];
    for (let i = 0; i <= result.length; i++) {
      for (
        let j = 0;
        j <= response?.abrangenciaTipoEstabelecimento?.length;
        j++
      ) {
        if (
          result[i]?.idTipoEstabelecimento ===
          response?.abrangenciaTipoEstabelecimento[j]?.idTipoEstabelecimento
        ) {
          if (result[i]) {
            result[i] = {
              ...result[i],
              checked: true,
            };
          }
        }
      }
    }

    response.abrangenciaTipoEstabelecimento = result;

    return response;
  };

  useEffect(() => {
    if (cupom) {
      let result = cupom;
      result = {
        ...result,
        abrangenciaClienteApp: filteredClients,
      };
      setCupom(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredClients]);

  return (
    <div className="sidebarv2-wrapper">
      <Button
        icon="pi pi-search"
        onClick={getCupom}
        className="p-button-rounded p-button-secondary p-button-outlined"
      />

      <Sidebar
        className="sidebar-cupons-container"
        visible={visible}
        position="right"
        onHide={() => setVisible(false)}
      >
        <div className="sidebar-cupons-content-top">
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-danger p-button-text"
            onClick={() => setVisible(false)}
          />
          <p
            style={{ color: COLORS.primaryGreen }}
            className="sidebar-cupons-title"
          >
            Visualizar Cupom
          </p>

          {step === 1 && (
            <div className="sidebar-cupons-board">
              <div className="sidebar-cupons-buttons">
                <div className="sidebar-cupons-active">
                  <button
                    value={step}
                    onClick={() => setStep(1)}
                    className="cupons-buttons-active"
                  >
                    Cupom
                  </button>
                </div>
                <div className="sidebar-cupons-noactive">
                  {selectedCategory.value === 1 ? (
                    <button
                      value={step}
                      onClick={() => setStep(2)}
                      className="cupons-buttons-noactive"
                      disabled
                    >
                      Aplicação
                    </button>
                  ) : (
                    <button
                      value={step}
                      onClick={() => setStep(2)}
                      className="cupons-buttons-noactive"
                    >
                      Aplicação
                    </button>
                  )}
                </div>
                <div className="sidebar-cupons-noactive">
                  {selectedCategoryCoverage.value == 0 ? (
                    <button
                      value={step}
                      onClick={() => setStep(3)}
                      className="cupons-buttons-noactive"
                      disabled
                    >
                      Abrangência
                    </button>
                  ) : (
                    <button
                      value={step}
                      onClick={() => setStep(3)}
                      className="cupons-buttons-noactive"
                    >
                      Abrangência
                    </button>
                  )}
                </div>
              </div>
              <div className="sidebar-cupons-content">
                <div className="sidebar-cupons-sectionOne">
                  <DefaultInput
                    label="Código"
                    value={cupom?.cdCupom}
                    onChange={setCdCupom}
                    disabled={true}
                  />
                  <DefaultInput
                    label="Descrição"
                    value={cupom?.dsCupom}
                    onChange={setDsCupom}
                    disabled={true}
                  />
                </div>
                <div className="sidebar-cupons-date">
                  <DefaultInput
                    label="De"
                    type="date"
                    value={cupom?.dtValidadeDe}
                    onChange={setDtValidadeDe}
                    disabled={true}
                  />
                  <DefaultInput
                    label="Até"
                    type="date"
                    value={cupom?.dtValidadeAte}
                    onChange={setDtValidadeAte}
                    disabled={true}
                  />
                </div>
                <div className="sidebar-cupons-sectionTwo">
                  <DefaultSelect
                    label="Tipo de desconto"
                    options={options}
                    onChange={(e: any) => setIdTipoDesconto(e.target.value)}
                    idItem="desconto"
                    value={cupom?.idTipoDesconto}
                    disabled={true}
                  />
                  <div>
                    <DefaultInput
                      type="number"
                      label="Valor/Percentual"
                      value={cupom?.vlCupom}
                      onChange={setVlCupom}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sidebar-cupons-sectionTwo">
                  <div>
                    <DefaultInput
                      type="number"
                      label="Quantidade Liberada"
                      value={cupom?.qtLiberada}
                      onChange={setQtLiberada}
                      disabled={true}
                    />
                  </div>
                  <div>
                    <DefaultInput
                      type="number"
                      label="Quantidade Utilizada"
                      value={cupom?.qtUtilizada}
                      onChange={setQtUtilizada}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="sidebar-cupons-sectionCheckbox">
                  <div className="p-field-checkbox">
                    <Checkbox
                      inputId="active"
                      checked={cupom?.inAtivo}
                      onChange={(e) => setInAtivo(e.checked)}
                      disabled={true}
                    />
                    <label htmlFor="active" style={{ marginLeft: 4 }}>
                      Ativo
                    </label>
                  </div>

                  <div className="p-field-checkbox">
                    <Checkbox
                      inputId="plataformVisible"
                      checked={cupom?.inVisivel}
                      onChange={(e) => setInVisivel(e.checked)}
                      disabled={true}
                    />
                    <label htmlFor="active" style={{ marginLeft: 4 }}>
                      Visível nas plataformas
                    </label>
                  </div>
                </div>
                <h4>Aplicação</h4>
                <div className="sidebar-cupons-sectionThree">
                  {categories.map((category) => {
                    return (
                      <div key={category.value} className="p-field-radiobutton">
                        <RadioButton
                          inputId={category.name}
                          name="category"
                          value={category}
                          onChange={(e) => setSelectedCategory(e.value)}
                          checked={selectedCategory.value === category.value}
                          disabled={true}
                          className="radiobutton-style"
                        />
                        <label htmlFor={category.name}>{category.name}</label>
                      </div>
                    );
                  })}
                </div>
                <h4>Abrangência</h4>
                <div className="sidebar-promotion-coverage">
                  {categoriesCoverage.map((category) => {
                    return (
                      <div key={category.value} className="p-field-radiobutton">
                        <RadioButton
                          inputId={category.name}
                          name="category"
                          value={category}
                          onChange={(e) => setSelectedCategoryCoverage(e.value)}
                          checked={
                            selectedCategoryCoverage.value === category.value
                          }
                          disabled={true}
                          className="radiobutton-style"
                        />
                        <label htmlFor={category.name}>{category.name}</label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="sidebar-cupons-board">
              <div className="sidebar-cupons-buttons">
                <div className="sidebar-cupons-noactive">
                  <button
                    value={step}
                    onClick={() => setStep(1)}
                    className="cupons-buttons-noactive"
                  >
                    Cupom
                  </button>
                </div>
                <div className="sidebar-cupons-active">
                  <button
                    value={step}
                    onClick={() => setStep(2)}
                    className="cupons-buttons-active"
                  >
                    Aplicação
                  </button>
                </div>
                <div className="sidebar-cupons-noactive">
                  {selectedCategoryCoverage.value == 0 ? (
                    <button
                      value={step}
                      onClick={() => setStep(3)}
                      className="cupons-buttons-noactive"
                      disabled
                    >
                      Abrangência
                    </button>
                  ) : (
                    <button
                      value={step}
                      onClick={() => setStep(3)}
                      className="cupons-buttons-noactive"
                    >
                      Abrangência
                    </button>
                  )}
                </div>
              </div>

              {selectedCategory.value === 1 && (
                <div>
                  <h3>Pedidos</h3>
                  <div className="sidebar-cupons-container">
                    <h4>
                      O cupom será gerado no pedido que está sendo realizado no
                      carrinho.
                    </h4>
                  </div>
                </div>
              )}
              {selectedCategory.value === 2 && (
                <div className="sidebar-cupons-itens">
                  <h3>Categorias</h3>
                  <div className="sidebar-cupons-categories-products">
                    {loading ? (
                      <div className="divNotFind">
                        <ProgressLoad />
                      </div>
                    ) : (
                      <CategoryListCupons
                        handleCheckboxes={handleCheckboxesCategoria}
                        itens={cupom.categorias}
                        view
                      />
                    )}
                  </div>
                </div>
              )}
              {selectedCategory.value === 3 && (
                <div className="sidebar-cupons-itens">
                  <h3>Produtos</h3>
                  <div className="sidebar-cupons-categories-products">
                    {loading ? (
                      <div className="divNotFind">
                        <ProgressLoad />
                      </div>
                    ) : (
                      <ItensListCupons
                        handleCheckboxes={handleCheckboxesProduto}
                        itens={cupom.itens}
                        view
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {step === 3 && (
            <div className="sidebar-cupons-board">
              <div className="sidebar-cupons-buttons">
                <div className="sidebar-cupons-noactive">
                  <button
                    value={step}
                    onClick={() => setStep(1)}
                    className="cupons-buttons-noactive"
                  >
                    Cupom
                  </button>
                </div>
                <div className="sidebar-cupons-noactive">
                  {selectedCategory.value === 1 ? (
                    <button
                      value={step}
                      onClick={() => setStep(2)}
                      className="cupons-buttons-noactive"
                      disabled
                    >
                      Aplicação
                    </button>
                  ) : (
                    <button
                      value={step}
                      onClick={() => setStep(2)}
                      className="cupons-buttons-noactive"
                    >
                      Aplicação
                    </button>
                  )}
                </div>
                <div className="sidebar-cupons-active">
                  <button
                    value={step}
                    onClick={() => setStep(3)}
                    className="cupons-buttons-active"
                  >
                    Abrangência
                  </button>
                </div>
              </div>

              {selectedCategoryCoverage.value === 1 && (
                <div className="sidebar-cupons-abrangencia-container">
                  <h3>Tipos de Estabelecimento</h3>
                  <div className="sidebar-cupons-establishmentsTypes-content">
                    {loading ? (
                      <div className="divNotFind">
                        <ProgressLoad />
                      </div>
                    ) : (
                      <EstablishmentsTypesCupons
                        handleCheckboxes={handleCheckboxesTiposEstabelecimento}
                        establishments={cupom.abrangenciaTipoEstabelecimento}
                        view
                      />
                    )}
                  </div>
                </div>
              )}

              {selectedCategoryCoverage.value === 3 && (
                <div className="sidebar-cupons-abrangencia-container">
                  <h3>Clientes disponíveis</h3>
                  <div className="sidebar-cupons-clients-content">
                    {loading ? (
                      <div className="divNotFind">
                        <ProgressLoad />
                      </div>
                    ) : (
                      <div className="sidebar-cupons-clients-input">
                        <div className="sidebar-cupons-clients-input-header">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder="Clientes..."
                            value={clientSelected}
                            isClearable={true}
                            isSearchable={true}
                            options={allClients}
                            onChange={(text) => {
                              setClientSelected(text as SelectProps);
                            }}
                            noOptionsMessage={() =>
                              "Não foi encontrado nenhum registro."
                            }
                            isOptionDisabled={(allClients) =>
                              allClients.disabled
                            }
                          />
                          {clientSelected !== "" ? (
                            <Button
                              label="Adicionar"
                              className="p-button-raised p-button-secondary p-button-text"
                              style={{ height: 36, marginLeft: 20 }}
                              disabled
                            />
                          ) : (
                            <Button
                              label="Adicionar"
                              className="p-button-raised p-button-secondary p-button-text"
                              style={{ height: 36, marginLeft: 20 }}
                              disabled
                            />
                          )}
                        </div>
                        <h1 style={{ margin: 0, fontSize: 20, marginTop: 24 }}>
                          Clientes Selecionados
                        </h1>
                        <div className="sidebar-cupons-clients-filteredList">
                          {filteredClients.length > 0 ? (
                            filteredClients.map((client) => {
                              return (
                                <div
                                  key={client?.idClienteApp}
                                  className="sidebar-cupons-clients-filteredList-client"
                                >
                                  <h1>
                                    {client?.clienteApp?.nmCliente || client?.nmCliente}
                                  </h1>
                                  <Button
                                    value={client?.idClienteApp}
                                    icon="pi pi-trash"
                                    className="p-button-rounded p-button-danger p-button-outlined"
                                    style={{ width: 28, height: 28 }}
                                    disabled
                                  />
                                </div>
                              );
                            })
                          ) : (
                            <h1 style={{ margin: 0, fontSize: 16 }}>
                              Nenhum cliente foi adicionado.
                            </h1>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* {selectedCategoryCoverage.value === 4 && (
            <div className='sidebar-cupons-abrangencia-container'>
              <h3>Localização</h3>
              <div className='sidebar-cupons-localization-content'>
                {loading ? (
                  <div className='divNotFind'>
                    <ProgressLoad />
                  </div>
                ) : (
                  // <ItensListCupons
                  //   handleCheckboxes={handleCheckboxesProduto}
                  //   itens={updateProductsCupons}
                  // />
                  <div className='sidebar-cupons-localization-selects'>
                    <div className='localization-selects-content'>
                      <div>
                          <p>UF</p>
                          <Dropdown value={selectUF} emptyMessage="Não foi encontrado nenhum registro."  onChange={(e) => setSelectUF(e.value)} className="localization-selects"/>
                      </div>
                      <div>
                          <p>Cidade</p>
                          <Dropdown value={selectUF} emptyMessage="Não foi encontrado nenhum registro."  onChange={(e) => setSelectUF(e.value)} className="localization-selects"/>
                      </div>
                      <div>
                          <p>Bairro</p>
                          <Dropdown value={selectUF} emptyMessage="Não foi encontrado nenhum registro."  onChange={(e) => setSelectUF(e.value)} className="localization-selects"/>
                      </div>
                      <button style={{width: '140px', height: '28px', marginTop: '60px'}}>Adicionar</button>
                    </div>
                  
                    <div className='sidebar-cupons-localization-list'>
                      <h3>Locais selecionados</h3>
                      <span>Bairro, cidade, UF</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )} */}
        </div>
        <div></div>
      </Sidebar>
    </div>
  );
};

export default CuponsView;
