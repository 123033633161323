import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ComodatoUserInfo from "../../components/ComodatoUserInfo";
import { ComodatosTypes } from "../../enums/ComodatosTypes";
import { RootState } from "../../store";
import { TabView, TabPanel } from "primereact/tabview";
import NewComodato from "../../components/ComodatoFields/NewComodato";
import MaintenanceComodato from "../../components/ComodatoFields/MaintenanceComodato";
import CancelComodato from "../../components/ComodatoFields/CancelComodato";
import { getComodatoById } from "../../store/comodatos/comodatos.actions";
import ProgressLoad from "../../components/ProgressLoad";

interface ComodatoEvaluationProps {
  match: any;
}

const ComodatosEvaluation: React.FC<ComodatoEvaluationProps> = ({ match }) => {
  const dispatch = useDispatch();
  const { currentComodato } = useSelector(
    (state: RootState) => state.comodatos
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getComodatoById(match.params.id));
  }, [dispatch]);

  const getTitle = (comodatoTypeId: number) => {
    switch (comodatoTypeId) {
      case ComodatosTypes.contratacao:
        return "Avaliação de Novo Contrato";
      case ComodatosTypes.manutencao:
        return "Alteração de Solicitação de Manutenção";
      case ComodatosTypes.cancelamento:
        return "Alteração de Solicitação de Cancelamento";
      default:
        return "Título não informado";
    }
  };

  // const PanelBody = () => {
  //   return (
  //     <TabView>
  //       <NewComodato />
  //       {/* {ComodatosTypes.novo && <NewComodato />} */}
  //       {/* {ComodatosTypes.manutencao && <MaintenanceComodato />} */}
  //       {/* {ComodatosTypes.cancelamento && <CancelComodato />}         */}
  //     </TabView>
  //   );
  // };

  return (
    <div className="content-page">
      {loading && <ProgressLoad />}
      {!loading && (
        <>
          <p className="title">
            {getTitle(currentComodato?.idTipoSolicitacaoComodato)}
          </p>
          <ComodatoUserInfo />
          {currentComodato?.idTipoSolicitacaoComodato === 1 && <NewComodato />}
          {currentComodato?.idTipoSolicitacaoComodato === 2 && (
            <MaintenanceComodato />
          )}
          {currentComodato?.idTipoSolicitacaoComodato === 3 && (
            <CancelComodato />
          )}
        </>
      )}
    </div>
  );
};

export default ComodatosEvaluation;
