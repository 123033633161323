import { createSlice } from "@reduxjs/toolkit";
import { SelectOptions } from "../../components/DefaultSelect";

interface comodatoProps {
  list: [];
  types: SelectOptions[];
  typesById: SelectOptions[];
  status: SelectOptions[];
  evaluation: {
    contratacao: any;
    manutencao: any;
    cancelamento: any;
  };
  currentComodato?: any;
}

const initialState: comodatoProps = {
  list: [],
  types: [],
  typesById: [],
  status: [],
  evaluation: {
    contratacao: null,
    manutencao: null,
    cancelamento: null,
  },
};

const comodatos = createSlice({
  name: "comodato",
  initialState,
  reducers: {
    updateComodatos(state, action) {
      state.list = action.payload;
    },
    updateTypes(state, action) {
      state.types = action.payload;
    },
    updateTypesById(state, action) {
      state.typesById = action.payload;
    },
    updateStatus(state, action) {
      state.status = action.payload;
    },
    setCurrentComodato(state, action) {
      state.currentComodato = action.payload;
    },
  },
});

export const {
  updateComodatos,
  updateTypes,
  updateTypesById,
  updateStatus,
  setCurrentComodato,
} = comodatos.actions;
export default comodatos.reducer;
