import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Header from "../components/Header";
// import MainMenu from '../components/MainMenu';
import MainMenu2 from "../components/MainMenu2";
import Routes from "../pages/routes";
import { asyncGetModules } from "../store/admin/admin.actions";
import { isAuthenticated } from "../store/auth/token.service";
import { updateLoading } from "../store/loading/loading.store";
import "./styles.css";

const Layout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated()) {
      dispatch(updateLoading(true));
      dispatch(asyncGetModules());
    }
  }, [dispatch]);

  return (
    <div>
      {isAuthenticated() && <Header />}
      {isAuthenticated() && <MainMenu2 />}
      <div className="layout-content">
        <Routes />
      </div>
    </div>
  );
};

export default Layout;
