import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DefaultInput from "../../../components/DefaultInput";
import DefaultSelect from "../../../components/DefaultSelect";
import { RootState } from "../../../store";
import {
  getApprovalStatus,
  getApprovalTypes,
  seacrhApprovals,
} from "../../../store/approvals/approvals.actions";
import { cpfCnpjMask, onlyNumbers } from "../../../util/mask";
import { COLORS } from "../../../constants";

import "./styles.css";
const ApprovalsFilter = () => {
  const dispatch = useDispatch();
  const [type, setType] = useState<any>(null);
  const [status, setStatus] = useState<any>(null);
  const [document, setDocument] = useState("");

  const approvals = useSelector((state: RootState) => state.approvals);

  const documentModified = cpfCnpjMask(document);

  useEffect(() => {
    dispatch(getApprovalTypes());
    dispatch(getApprovalStatus());
    
  }, [dispatch]);

  const handleFilter = () => {
    dispatch(
      seacrhApprovals({ type, status, document: onlyNumbers(document) })
    );
  };

  const handleClickClean = () => {
    setType("");
    setStatus("");
    setDocument("#");
  };

  return (
    <div className="approvals-filter-container">
      <div className="approvals-filter-inputs">
        <DefaultSelect
          label="Tipo"
          value={type}
          onChange={setType}
          options={approvals.types}
        />
        <DefaultSelect
          label="Status"
          value={status}
          onChange={setStatus}
          options={approvals.status}
        />
        <DefaultInput
          label="CNPJ / CPF"
          value={cpfCnpjMask(document)}
          onChange={(t) => setDocument(onlyNumbers(t))}
          disabled={false}
        />
      </div>
      <div>
        <Button
          label="Limpar filtros"
          style={{ color: COLORS.primaryGreen }}
          className="p-button-outlined"
          onClick={handleClickClean}
        />
        <Button
          onClick={handleFilter}
          className="approvals-filter-button"
          label="Pesquisar"
        />
      </div>
    </div>
  );
};

export default ApprovalsFilter;
