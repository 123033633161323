import React, { useEffect, useState } from 'react';

import './styles.css';
// import TermsOfUse from './TermsOfUse';
import PrivactyPolicies from './PrivactyPolicies';

//Usando Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { updateLoading } from '../../../store/loading/loading.store';
import { asyncGetTermsAndPolicies, asyncPutTermsAndPolicies } from '../../../store/admin/admin.actions';
import ProgressLoad from '../../../components/ProgressLoad';
import { Button } from "primereact/button";
import { Editor } from "primereact/editor";

const TermsAndPolicies: React.FC = () => {
  const dispatch = useDispatch()
  const [btnTermsOfUse, setBtnTermsOfUse] = useState('')
  const [btnPrivactyPolicies, setBtnPrivactyPolicies] = useState('none')
  
  const termsAndPolicies = useSelector((state: RootState) => state.admin.termsAndPolicies);
  const {loading} = useSelector((state: RootState) => state.loading)

  const [textTermsOfUse, setTextTermsOfUse] = useState('')
  const [textPrivactyPolicies, setTextPrivactyPolicies] = useState('')

  useEffect(() => {
    dispatch(updateLoading(true))
    dispatch(asyncGetTermsAndPolicies())
  },[dispatch])

  useEffect(() => {
    let result = termsAndPolicies
    setTextTermsOfUse(result[0].txConteudo)
    setTextPrivactyPolicies(result[1].txConteudo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termsAndPolicies])

  const clickBtn = (e:string) =>{
    switch(e){
      case 'TermsOfUse':
        setBtnTermsOfUse('')
        setBtnPrivactyPolicies('none')
      break;
      case 'PrivactyPolicies':
        setBtnTermsOfUse('none')
        setBtnPrivactyPolicies('')
      break;
    }
  }

  const handleSubmit = () =>{
    const termsId = termsAndPolicies.findIndex(item => item.txTipoConteudo === 'termos')
    const policesId = termsAndPolicies.findIndex(item => item.txTipoConteudo === 'politicas')
    
    const newArr = [...termsAndPolicies]

    newArr[termsId] = {...newArr[termsId], txConteudo: textTermsOfUse}
    newArr[policesId] = {...newArr[policesId], txConteudo: textPrivactyPolicies}

    dispatch(updateLoading(true))
    dispatch(asyncPutTermsAndPolicies(newArr))
  }

  return (
  <main className="main-page">
    <section>
      <p className="title">Termos e Políticas</p>
    </section>
    <section className="sec-TermsAndPolicies">
      {
        btnTermsOfUse !== 'none' ?
        <div className="div-TermsAndPolicies-btn">
          <p onClick={()=> clickBtn('TermsOfUse')} className='TermsAndPolicies-buttons-active'>TERMOS DE USO</p>
          <p onClick={()=> clickBtn('PrivactyPolicies')} className='TermsAndPolicies-buttons-noactive'>POLÍTICAS DE PRIVACIDADE</p>
        </div>
        :
        <div className="div-TermsAndPolicies-btn">
          <p onClick={()=> clickBtn('TermsOfUse')} className='TermsAndPolicies-buttons-noactive'>TERMOS DE USO</p>
          <p onClick={()=> clickBtn('PrivactyPolicies')} className='TermsAndPolicies-buttons-active'>POLÍTICAS DE PRIVACIDADE</p>
      </div>
      }
      {
        loading ? 
        <div style={{margin: '200px 0px'}}>
          <ProgressLoad/>
        </div>
        :
        <div>
          <section style={{ display: btnTermsOfUse }}>
            <div className="div-titleAndBtn-TermsOfUseAndPrivactyPolicies">
              <p>Texto - Termos de uso</p>
              <Button label="Salvar" className="btn-salvar" onClick={handleSubmit} />
            </div>
            <div className="div-textarea-TermsOfUseAndPrivactyPolicies">
              <Editor style={{ maxHeight: '320px', overflow: 'auto' }} value={textTermsOfUse} onTextChange={(e:any) => setTextTermsOfUse(e.htmlValue)} />              
            </div>
          </section>          
          <section style={{ display: btnPrivactyPolicies }}>
            <div className="div-titleAndBtn-TermsOfUseAndPrivactyPolicies">
              <p>Texto - Políticas de privacidade</p>
              <Button label="Salvar" className="btn-salvar" onClick={handleSubmit} />
            </div>
            <div className="div-textarea-TermsOfUseAndPrivactyPolicies">
              <Editor style={{ height: '400px' }} value={textPrivactyPolicies} onTextChange={(e:any) => setTextPrivactyPolicies(e.htmlValue)} />              
            </div>
          </section>
        </div>
      }
      <div>

      </div>
    </section>
  </main>
  )
}

export default TermsAndPolicies;
