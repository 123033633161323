import React from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { useState } from "react";
import { COLORS } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { asyncPostTopic } from "../../../../store/admin/admin.actions";
import * as Yup from "yup";
import getValidationErrors from "../../../../util/validationError";
import { StateProps } from "../../../../enums";
import DefaultTextarea from "../../../../components/DefaultTextArea";
import { RootState } from "../../../../store";
import { Editor } from "primereact/editor";
import DefaultEditor from "../../../../components/DefaultEditor";

const HelpCreate = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const [errors, setErrors] = useState({} as any);
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubmit = async () => {
    setErrors({} as StateProps);
    setButtonLoading(true);

    try {
      const topic = {
        txPergunta: question,
        txResposta: answer,
      };

      const schema = Yup.object().shape({
        txPergunta: Yup.string().required("Pergunta obrigatória."),
        txResposta: Yup.string().required("Resposta obrigatória."),
      });

      await schema.validate(topic, {
        abortEarly: false,
      });

      dispatch(asyncPostTopic(topic));
      setQuestion("");
      setAnswer("");
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrors(getValidationErrors(err));

        return;
      }
    } finally {
      setTimeout(() => {
        setButtonLoading(false);
        setVisible(false);
      }, 1000);
    }
  };

  const handleCancel = () => {
    setQuestion("");
    setAnswer("");
    setVisible(false);
    setErrors("");
  };

  return (
    <div className="sidebarv2-wrapper">
      <Button
        label="Novo tópico"
        onClick={() => setVisible(true)}
        className="sidebarv2-open-button"
        color="#AA0061"
      />
      <Sidebar
        className="sidebar-help-container"
        visible={visible}
        position="right"
        onHide={handleCancel}
      >
        <div className="sidebar-help-content-top">
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-danger p-button-text"
            onClick={handleCancel}
          />
          <p
            style={{ color: COLORS.primaryGreen }}
            className="sidebar-help-title"
          >
            Cadastrar Tópico
          </p>
          <div className="sidebar-help-inputs">
            <h4>Pergunta</h4>
            <DefaultEditor  value={question} onTextChange={(e: any) => setQuestion(e.htmlValue)} error={errors.txPergunta} />      
            <h4>Resposta</h4>
            <DefaultEditor  value={answer} onTextChange={(e: any) => setAnswer(e.htmlValue)} error={errors.txResposta} />  
          </div>
        </div>
        <div className="sidebar-help-actions">
          <Button
            label="Cancelar"
            className="p-button-outlined p-button-secondary"
            onClick={handleCancel}
            style={{ marginRight: "20px", width: "40%" }}
          />
          <Button
            id="button"
            label="Salvar"
            className="p-button-lg"
            onClick={handleSubmit}
            style={{ width: "40%" }}
            loading={buttonLoading}
          />
        </div>
      </Sidebar>
    </div>
  );
};

export default HelpCreate;
