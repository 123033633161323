import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { isAuthenticated } from "../store/auth/token.service";
import AdminUsers from "./Admin/Users";
import ApprovalEvaluation from "./ApprovalEvaluation";
import Approvals from "./Approvals";
import Comodatos from "./Comodatos";
import ComodatosEvaluation from "./ComodatosEvaluation";
import Home from "./Home";
import Login from "./Login";
import Profiles from "./Admin/Profiles";
import Promotion from "./Comercial/Promotion";
import Notification from "./Comercial/Notifications";
import BannerCarrossel from "./Marketing/BannerCarrossel";
import Cupons from "./Marketing/Cupons";
import Images from "./Marketing/Images";
import HelpPage from "./Admin/Help";
import Parameters from "./Admin/Parameters";
import Featured from "./Marketing/Featured";
import TermsAndPolicies from "./Admin/TermsAndPolicies";
import AcumuloDePontos from "./Marketing/PointsAccumulation";
import ProgramaDePontos from "./Marketing/PointsProgram";
import MerchandisingMaterials from "./Marketing/Merchandising";

const PrivateRoute = ({ component: Component, route, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          // Redireciona pra home e passa o estado para o app nao ficar perdido
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const Routes = () => (
  <Router>
    <Switch>
      <PrivateRoute exact route="home" path="/" component={Home} />
      <Route exact path="/login" component={Login} />
      <PrivateRoute
        exact
        route="approvals"
        path="/approvals"
        component={Approvals}
      />
      <PrivateRoute
        exact
        route="approvals"
        path="/approvals/evaluation/:id"
        component={ApprovalEvaluation}
      />
      <PrivateRoute
        exact
        route="comodatos"
        path="/comodatos"
        component={Comodatos}
      />
      <PrivateRoute
        exact
        route="comodatos"
        path="/comodatos/evaluation/:id"
        component={ComodatosEvaluation}
      />
      <PrivateRoute route="admin" path="/admin/users" component={AdminUsers} />
      <PrivateRoute route="admin" path="/admin/profiles" component={Profiles} />
      <PrivateRoute route="admin" path="/admin/ajuda" component={HelpPage} />
      <PrivateRoute
        route="admin"
        path="/admin/parameters"
        component={Parameters}
      />
      <PrivateRoute
        route="admin"
        path="/admin/termsAndPolicies"
        component={TermsAndPolicies}
      />
      <PrivateRoute
        route="marketing"
        path="/marketing/bannercarrossel"
        component={BannerCarrossel}
      />
      <PrivateRoute
        route="marketing"
        path="/marketing/featured"
        component={Featured}
      />
      <PrivateRoute
        route="marketing"
        path="/marketing/images"
        component={Images}
      />
      <PrivateRoute
        route="marketing"
        path="/marketing/promocoes"
        component={Promotion}
      />
      <PrivateRoute
        route="comercial"
        path="/comercial/notificacoes"
        component={Notification}
      />
      <PrivateRoute
        route="marketing"
        path="/marketing/cupons"
        component={Cupons}
      />
      <PrivateRoute
        route="marketing"
        path="/marketing/acumulodepontos"
        component={AcumuloDePontos}
      />
      <PrivateRoute
        route="marketing"
        path="/marketing/programadepontos"
        component={ProgramaDePontos}
      />
      <PrivateRoute
        route="marketing"
        path="/marketing/merchandising"
        component={MerchandisingMaterials}
      />
    </Switch>
  </Router>
);

export default Routes;
