import { Dispatch } from "redux";
import { ToasterTypes } from "../../enums/ToasterTypes";
import GuaracampAPI from "../../services/GuaracampAPI";
import { updateToaster } from "../helper/helper.store";
import { updateLoading } from "../loading/loading.store";
import {
  updateUsers,
  deleteUser,
  updateProfiles,
  deleteProfile,
  addProfile,
  addUser,
  ProfileProps,
  updateProfilesList,
  updateProfile,
  updateTopics,
  addTopic,
  deleteTopic,
  updateParameters,
  updateUser,
  updateTopic,
  updateTermsAndPolicies,
  updateModules,
} from "./admin.store";

//users.
export const asyncGetUsers = () => {
  return (dispatch: any) => {
    GuaracampAPI.get("/usuario?pagina=1&registros=0")
      .then((res) => {
        dispatch(updateUsers(res.data));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

export const asyncGetUserById = async (id: number) => {
  let response = null;
  await GuaracampAPI.get(`/usuario/${id}`)
    .then((res) => {
      response = res.data;
    })
    .catch((error) => {});

  return response;
};

export const asyncPostUser = (data: any) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.post("/usuario", data)
      .then((res: any) => {
        dispatch(addUser(res.data.data));
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Usuário criado com sucesso.",
          })
        );
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

export const asyncUpdateUser = (data: any) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.post("/usuario", data)
      .then((res: any) => {
        dispatch(updateUser(res.data.data));
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Usuário atualizado com sucesso.",
          })
        );
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

export const asyncDeleteUser = (id: number) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.delete(`/usuario/${id}`)
      .then((res) => {
        dispatch(deleteUser(id));
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Usuário deletado com sucesso.",
          })
        );
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

export const asyncSearchUser = (nome: string, email: string) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get(`/usuario/admin/pesquisa?nome=${nome}&email=${email}`)
      .then((res) => {
        dispatch(updateUsers(res.data));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

//Profiles.
export const asyncGetProfiles = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get("/perfil")
      .then((res) => {
        dispatch(updateProfiles(res.data));
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

export const asyncGetProfilesToUser = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get("/perfil")
      .then((res: any) => {
        const result: ProfileProps[] = [];
        res.data.map((r: any) =>
          result.push({
            idItem: r.id,
            descricao: r.dsPerfil,
            checked: false,
          })
        );
        dispatch(updateProfilesList(result));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

export const asyncGetProfileById = async (id: number) => {
  let response = null;
  await GuaracampAPI.get(`/perfil/${id}`)
    .then((res) => {
      response = res.data;
    })
    .catch((error) => {});

  return response;
};

export const asyncSearchProfile = (query: string) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get(`/perfil/pesquisa?descricao=${query}`)
      .then((res) => {
        dispatch(updateProfiles(res.data));
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

export const asyncPostProfile = (data: any) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.post("/perfil", data)
      .then((res: any) => {
        const profile = {
          id: res.data.data.id,
          dsPerfil: res.data.data.dsPerfil,
          modulos: res.data.data.perfisXmodulos,
        };

        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Perfil criado com sucesso.",
          })
        );

        dispatch(addProfile(profile));
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

export const asyncDeleteProfile = (id: number) => {
  return (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {
      GuaracampAPI.delete(`/perfil/${id}`)
        .then((res) => {
          dispatch(
            updateToaster({
              type: ToasterTypes.success,
              title: "Sucesso",
              message: "Perfil deletado com sucesso.",
            })
          );

          dispatch(deleteProfile(id));
        })
        .catch((error) => {
          dispatch(
            updateToaster({
              type: ToasterTypes.error,
              title: "Erro",
              /* message: 'Erro ao tentar deletar.' */
              message: error.response.data.erros[0].message,
            })
          );
        });
    });
  };
};

export const asyncUpdateProfile = (data: any) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.post("/perfil", data)
      .then((res: any) => {
        const profile = {
          id: res.data.data.id,
          dsPerfil: res.data.data.dsPerfil,
          modulos: res.data.data.perfisXmodulos,
        };
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Perfil atualizado com sucesso.",
          })
        );

        dispatch(updateProfile(profile));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Erro",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

//Ajuda
export const asyncGetTopics = () => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.get("/ajuda")
      .then((res) => {
        dispatch(updateTopics(res.data));
        dispatch(updateLoading(false));
      })
      .catch((error) => {});
  };
};

export const asyncPostTopic = (data: any) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.post("/ajuda", data)
      .then((res: any) => {
        dispatch(addTopic(res.data.data));
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Tópico de ajuda criado com sucesso.",
          })
        );
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
};

export const asyncDeleteTopics = (id: number) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.delete(`/ajuda/${id}`)
      .then((res) => {
        dispatch(deleteTopic(id));
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Tópico deletado com sucesso.",
          })
        );
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

export const asyncGetTopicById = async (id: number) => {
  let response = null;
  await GuaracampAPI.get(`/ajuda/${id}`)
    .then((res) => {
      response = res.data;
    })
    .catch((error) => {});

  return response;
};

export const asyncUpdateTopic = (data: any) => {
  return (dispatch: Dispatch) => {
    GuaracampAPI.post("/ajuda", data)
      .then((res: any) => {
        dispatch(updateTopic(res.data.data));
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Tópico atualizado com sucesso.",
          })
        );
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

/*Parameters*/
export const asyncGetParameters = () => {
  return (dispatch: any) => {
    GuaracampAPI.get("/parametro")
      .then((res) => {
        dispatch(updateParameters(res.data));
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

export const asyncPutParameters = (data: any) => {
  return (dispatch: any) => {
    GuaracampAPI.put("/parametro", data)
      .then((res) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Dados salvos com sucesso.",
          })
        );
        dispatch(updateParameters(res.data));
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

/* Terms And Policies */
export const asyncGetTermsAndPolicies = () => {
  return (dispatch: any) => {
    GuaracampAPI.get("/TermoPolitica")
      .then((res) => {
        dispatch(updateTermsAndPolicies(res.data));
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

export const asyncPutTermsAndPolicies = (data: any) => {
  return (dispatch: any) => {
    GuaracampAPI.put("/TermoPolitica", data)
      .then((res) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.success,
            title: "Sucesso",
            message: "Termos e políticas salvos com sucesso.",
          })
        );
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};

/*Modulos*/
export const asyncGetModules = () => {
  return (dispatch: any) => {
    GuaracampAPI.get("/modulo")
      .then((res) => {
        dispatch(updateModules(res.data));
      })
      .catch((error) => {
        dispatch(
          updateToaster({
            type: ToasterTypes.error,
            title: "Error",
            message: error.response.data.erros[0].message,
          })
        );
      });
  };
};
