import { useEffect, useState } from "react";
import "./style.css";
import { COLORS } from "../../../constants";

import { Button } from "primereact/button";
import { DataScroller } from "primereact/datascroller";
import { Dropdown } from "primereact/dropdown";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";

import {
  asyncGetTiposProduto,
  asyncGetGrupos,
  asyncGetSubGrupos,
  asyncGetClasses,
  asyncGetSabores,
  asyncGetFilterProduct,
  asyncPutFeatured,
  newCardSavePutFeatured,
} from "../../../store/marketing/marketing.actions";
import CardFeatured from "../../../components/Marketing/CardFeatured";
import { updateFeatured } from "../../../store/marketing/marketing.store";
import ProgressLoad from "../../../components/ProgressLoad";

import { updateLoading } from "../../../store/loading/loading.store";

const Featured: React.FC = () => {
  const dispatch = useDispatch();

  const [selectTipo, setSelectTipo] = useState<any>("");
  const [selectGrupo, setSelectGrupo] = useState<any>("");
  const [selectSubGrupo, setSelectSubGrupo] = useState<any>("");
  const [selectClasse, setSelectClasse] = useState<any>("");
  const [selectSabor, setSelectSabor] = useState<any>("");
  const [buttonLoadingProductsList, setButtonLoadingProductsList] = useState(false);
  const [buttonLoadingSave, setButtonLoadingSave] = useState(false);
  const [buttonLoadingFilters, setButtonLoadingFilters] = useState(false);

  const { loading } = useSelector((state: RootState) => state.loading);
  const marketing = useSelector((state: RootState) => state.marketing);

  useEffect(() => {
    dispatch(asyncGetTiposProduto());
    dispatch(asyncGetGrupos());
    dispatch(asyncGetSubGrupos());
    dispatch(asyncGetClasses());
    dispatch(asyncGetSabores());
    dispatch(asyncGetFilterProduct(selectTipo.id, selectGrupo.id, selectSubGrupo.id, selectClasse.id, selectSabor.id));
  }, [dispatch]);

  const handdleSubmitSelect = () => {
    setButtonLoadingProductsList(true);

    try {
      dispatch(
        asyncGetFilterProduct(selectTipo.id, selectGrupo.id, selectSubGrupo.id, selectClasse.id, selectSabor.id)
      );
    } catch {
    } finally {
      setTimeout(() => {
        setButtonLoadingProductsList(false);
      }, 1000);
    }
  };

  const handleChange = (event: any) => {
    const id = parseInt(event.target.id);

    const index = marketing.cardsFeatured.findIndex((item) => item.id === id);

    const newArr = [...marketing.cardsFeatured];

    newArr[index] = { ...newArr[index], inDestaque: event.target.checked };

    dispatch(updateFeatured(newArr));
  };

  const itemTemplate = () => {
    return (
      <>
        {loading ? (
          <div className="divNotFind">
            <ProgressLoad />
          </div>
        ) : marketing.cardsFeatured.length === 0 ? (
          <div className="divNotFind">
            <p>Não foi encontrado nenhum registro.</p>
          </div>
        ) : (
          <div>
            {marketing.cardsFeatured.map((card) => (
              <CardFeatured
                key={card.id}
                id={card.id}
                dsProduto={card.dsProduto}
                inDestaque={card.inDestaque}
                handleChange={handleChange}
              />
            ))}
          </div>
        )}
      </>
    );
  };
  const handdleClickSave = () => {
    setButtonLoadingSave(true);

    try {
      if (marketing.cardsFeatured.length > 0 && !loading) {
        dispatch(updateLoading(true));
        const arraySave: newCardSavePutFeatured[] = [];

        for (let card of marketing.cardsFeatured) {
          arraySave.push({
            id: card.id,
            inDestaque: card.inDestaque,
          });
        }
        if (arraySave.length > 0 && !loading) {
          dispatch(asyncPutFeatured(arraySave));
        }
      }
    } catch {
    } finally {
      setTimeout(() => {
        setButtonLoadingSave(false);
      }, 1000);
    }
  };
  const handdleClear = () => {
    setButtonLoadingFilters(true);

    try {
      dispatch(updateLoading(true));
      setSelectTipo("");
      setSelectGrupo("");
      setSelectSubGrupo("");
      setSelectClasse("");
      setSelectSabor("");
      dispatch(updateFeatured(""));
      dispatch(
        asyncGetFilterProduct(selectTipo.id, selectGrupo.id, selectSubGrupo.id, selectClasse.id, selectSabor.id)
      );
    } catch {
    } finally {
      setTimeout(() => {
        setButtonLoadingFilters(false);
      }, 1000);
    }
  };

  return (
    <main className="main-page">
      <p className="title">Produtos Destaques</p>

      <section style={{ background: COLORS.white }} className="section-select-inputs-featured">
        <div>
          <p className="dropdown-label">Tipo</p>
          <Dropdown
            emptyMessage="Não foi encontrado nenhum registro."
            value={selectTipo}
            options={marketing.selectsOptions.tipos}
            onChange={(e) => setSelectTipo(e.value)}
            className="TreeSelect"
          />
        </div>
        <div>
          <p className="dropdown-label">Grupo</p>
          <Dropdown
            emptyMessage="Não foi encontrado nenhum registro."
            value={selectGrupo}
            options={marketing.selectsOptions.grupos}
            onChange={(e) => setSelectGrupo(e.value)}
            className="TreeSelect"
          />
        </div>
        <div>
          <p className="dropdown-label">SubGrupo</p>
          <Dropdown
            emptyMessage="Não foi encontrado nenhum registro."
            value={selectSubGrupo}
            options={marketing.selectsOptions.subGrupos}
            onChange={(e) => setSelectSubGrupo(e.value)}
            className="TreeSelect"
          />
        </div>
        <div>
          <p className="dropdown-label">Classe</p>
          <Dropdown
            emptyMessage="Não foi encontrado nenhum registro."
            value={selectClasse}
            options={marketing.selectsOptions.classes}
            onChange={(e) => setSelectClasse(e.value)}
            className="TreeSelect"
          />
        </div>
        <div>
          <p className="dropdown-label">Sabor</p>
          <Dropdown
            emptyMessage="Não foi encontrado nenhum registro."
            value={selectSabor}
            options={marketing.selectsOptions.sabores}
            onChange={(e) => setSelectSabor(e.value)}
            className="TreeSelect"
          />
        </div>
        <div className="div-btn-featured">
          <Button
            label="Limpar filtros"
            style={{ color: COLORS.primaryGreen }}
            className="p-button-outlined btnSelect"
            onClick={handdleClear}
            loading={buttonLoadingFilters}
          />
          <Button
            label="Listar produtos"
            style={{ color: COLORS.primaryGreen }}
            className="p-button-outlined btnSelect"
            onClick={handdleSubmitSelect}
            loading={buttonLoadingProductsList}
          />
        </div>
      </section>

      <div className="btn-card-save">
        <Button label="Salvar" onClick={handdleClickSave} className="btn-salvar" loading={buttonLoadingSave} />
      </div>
      <section style={{ background: COLORS.white }} className="section-cards-featured">
        <div className="dataScroller-card">
          <DataScroller
            value={[{}]}
            itemTemplate={itemTemplate}
            rows={2}
            inline
            scrollHeight="500px"
            header={
              <div className="div-desc-inputs-cardsFeatured">
                <p>Produtos</p>
                <p>Esse produto é um destaque?</p>
              </div>
            }
          />
        </div>
      </section>
    </main>
  );
};

export default Featured;
