import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';

import './style.css'

interface Parameters{
    id: string;
    dsParametro: string;
    txTipoParametro: string;
    txValorParametro: string;
    handleChange: (event:any) => void;
}

const CardParameters = ({id, dsParametro, txTipoParametro, txValorParametro, handleChange}:Parameters)=>{
    const options = [
        {label: 'Sim', value: 'S'},
        {label: 'Não', value: 'N'}
    ];

    return (
        <div className="card-parameters">
            <p>{dsParametro}</p>
            {txTipoParametro === 'T' ? 
                <InputText id={id} value={txValorParametro} onChange={handleChange} />
            :txTipoParametro === 'N' ?
                <InputText id={id} value={txValorParametro} onChange={handleChange} keyfilter='int'/>
            :txTipoParametro === 'C'?
                <InputText id={id} value={txValorParametro} onChange={handleChange} keyfilter='money'/>
            :txTipoParametro === 'B'?
                <SelectButton unselectable={false} id={id} value={txValorParametro} options={options} onChange={handleChange} />
            :null}
        </div>
    )
}

export default CardParameters
