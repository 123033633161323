import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface HelperProps {
    toaster: ToasterProps;
}

interface ToasterProps {
    type?: string;
    title?: string;
    message?: string;
}

const initialState: HelperProps = {
    toaster: {  }
}

const helper = createSlice({
    name: 'helper',
    initialState,
    reducers: {
        updateToaster(state, action: PayloadAction<ToasterProps>){
            state.toaster = action.payload
        }
    }
})

export const { updateToaster } = helper.actions;
export default helper.reducer;
