export const COLORS = {
    // base colors
    primaryGreen: '#3DAE2B',
    primaryOrange: '#FF9500',

    // colors
    black: "#000000",
    white: "#FFFFFF",
};

const appTheme = { COLORS };

export default appTheme;