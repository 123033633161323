import { createSlice } from '@reduxjs/toolkit';
import { AppData } from '../../components/ApprovallFields';
import { SelectOptions } from '../../components/DefaultSelect';
interface approvalsProps {
    list: [];
    types: SelectOptions[];
    status: SelectOptions[];
    evaluation: {
        app: any;
        erp: any;
        serpro: any;
        confirm: any;
        pre: any;
    };
    currentApproval?: any,
    confirmRegisterData?: AppData
}

const initialState: approvalsProps = {
    list: [],
    types: [],
    status: [],
    evaluation: {
        app: null,
        erp: null,
        serpro: null,
        confirm: null,
        pre: null
    }
}

const approvals = createSlice({
    name: 'approvals',
    initialState,
    reducers: {
        updateApprovals(state, action){
            state.list = action.payload
        },
        updateTypes(state, action){
            state.types = action.payload
        },
        updateStatus(state, action){
            state.status = action.payload
        },
        updateAppData(state, action){
            state.evaluation.app = action.payload
        },
        updateErpData(state, action){
            state.evaluation.erp = action.payload
        },
        updatePreData(state, action){
            state.evaluation.pre = action.payload
        },
        updateSerproData(state, action){
            state.evaluation.serpro = action.payload
        },
        updateConfirmData(state, action){
            state.evaluation.confirm = action.payload
        },
        setCurrentApproval(state, action){
            state.currentApproval = action.payload
        },
        updateConfirmRegisterData(state, action){
            state.confirmRegisterData = action.payload
        }
    }
})

export const { updateApprovals, updateTypes, updateStatus, updateAppData, updatePreData, setCurrentApproval, updateConfirmRegisterData, updateErpData, updateSerproData, updateConfirmData } = approvals.actions;
export default approvals.reducer;
