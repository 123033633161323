import React from 'react';
import { Tag } from 'primereact/tag';

interface StatusTagProps {
  color: string;
  value: string;
}

const StatusTag: React.FC<StatusTagProps> = ({ color, value }) => {
  const styles = {
    background: 'none',
    color,
    border: `1px solid ${color}`,
    padding: '2px 20px',
    fontSize: 12,
    fontWeight: 500
  };

  return <Tag className='p-tag-rounded' value={value} style={styles}></Tag>;
};

export default StatusTag;
